import React, { Component, Fragment } from 'react';

import { BsPlayCircleFill, BsFillCalendar2WeekFill, BsSaveFill, BsFillFileEarmarkPersonFill, BsGlobe, BsFillTrophyFill } from "react-icons/bs"
import { RiFlashlightLine, RiFilterFill } from "react-icons/ri"
import { HiDocumentText } from "react-icons/hi"
import { RiStarSLine } from "react-icons/ri"
import { AiFillHeart } from "react-icons/ai"
import { ImBlocked } from "react-icons/im"
import { IoMdArrowDropdownCircle, IoIosAddCircle } from "react-icons/io"

import { Link } from 'react-router-dom';





const RecentList = [
    {
        imageUrl: 'blog.png',
        title: 'You work your way to creative thinking',
        meta: 'Lifestyle',
        date: '24 May 2020',
    },
    {
        imageUrl: 'blog.png',
        title: 'If you do it right, it will last ever forever',
        meta: 'Food',
        date: '24 May 2020',
    },
    {
        imageUrl: 'blog.png',
        title: 'It’s no secret that the digital industry is booming',
        meta: 'Lifestyle',
        date: '24 May 2020',
    },
    {
        imageUrl: 'blog.png',
        title: 'Ways your mother lied to you about food stuffs',
        meta: 'Lifestyle',
        date: '24 May 2020',
    },
    {
        imageUrl: 'blog.png',
        title: 'Good design is obvious and transparent',
        meta: 'Tech',
        date: '24 May 2020',
    },
];

const blogList = [
    {
        imageUrl: 'blog.png',
        title: 'Aenean  Dieting Strategies That Almost Always Backfire',
        meta: 'Lifestyle',
        des: 'Human coronaviruses are common and are typically associated with mild illnesses, similar to the common cold. We are digital agency.',
    },
    {
        imageUrl: 'blog.png',
        title: 'The doner is a Turkish creation of meat, often lamb.',
        meta: 'Food',
        des: 'Human coronaviruses are common and are typically associated with mild illnesses, similar to the common cold. We are digital agency.',
    },
    {
        imageUrl: 'blog.png',
        title: 'The only nutrition program follow & supremely effective',
        meta: 'Lifestyle',
        des: 'Human coronaviruses are common and are typically associated with mild illnesses, similar to the common cold. We are digital agency.',
    },
    {
        imageUrl: 'blog.png',
        title: 'Neque porro quisquam est, qui dolorem ipsum quia dolor',
        meta: 'Lifestyle',
        des: 'Human coronaviruses are common and are typically associated with mild illnesses, similar to the common cold. We are digital agency.',
    },
    {
        imageUrl: 'blog.png',
        title: 'Steak salad with goat cheese and arugula for your family?',
        meta: 'Tech',
        des: 'Human coronaviruses are common and are typically associated with mild illnesses, similar to the common cold. We are digital agency.',
    },
    {
        imageUrl: 'blog.png',
        title: 'Egg salad sandwich with avocado and watercress chip pancakes',
        meta: 'Lifistyle',
        des: 'Human coronaviruses are common and are typically associated with mild illnesses, similar to the common cold. We are digital agency.',
    },
    {
        imageUrl: 'blog.png',
        title: 'Broad beans, tomato, garlic & mozzarella cheese bruschetta',
        meta: 'Fashion',
        des: 'Human coronaviruses are common and are typically associated with mild illnesses, similar to the common cold. We are digital agency.',
    },
    {
        imageUrl: 'blog.png',
        title: 'Steak salad with goat cheese and arugula for your family?',
        meta: 'Food',
        des: 'Human coronaviruses are common and are typically associated with mild illnesses, similar to the common cold. We are digital agency.',
    },
    {
        imageUrl: 'blog.png',
        title: 'Egg salad sandwich with avocado and watercress chip pancakes',
        meta: 'Lifistyle',
        des: 'Human coronaviruses are common and are typically associated with mild illnesses, similar to the common cold. We are digital agency.',
    },
];

class SelectQuestion extends Component {
    render() {
        return (
            <Fragment>

                <div className='d-flex justify-content-end mt-5 mr-5'>
                    <div className='d-flex justify-content-end'>
                        <button className='btn btn-info mr-2' > <IoIosAddCircle fontSize="2.5em" title='Add' /></button>
                        <button className='btn btn-warning' ><BsFillTrophyFill fontSize="2.5em" title='Winner' /></button>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-4'></div>
                    <div className='col-md-4'>
                        <div className='nav-bar d-flex justify-content-center mt-5 pt-3 pb-3 selected-question' >
                            <Link to="" className="icon-1 mr-5 pr-2"> Branch </Link> <IoMdArrowDropdownCircle fontSize="1.8em" />
                            <Link to="" className="icon-2 mr-5 pr-2" > Chapter </Link> <IoMdArrowDropdownCircle fontSize="1.8em" />
                            <Link to="" className="icon-3 pr-2">Sub-Chapter </Link><IoMdArrowDropdownCircle fontSize="1.8em" />
                        </div>
                    </div>
                    <div className='col-md-4'></div>
                </div>


                <div className="blog-page pt-lg--7 pb-lg--7 pt-5 pb-5 bg-white text-grey-900">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                {/* <div className="bg-greyblue side-wrap rounded-lg p-4 mb-4">
                  <div className="form-group mb-1">
                    <label className="fw-700 text-grey-900">
                      Search by Keyword
                    </label>
                  </div>
                  <div className="form-group icon-input mb-0">
                    <input
                      type="text"
                      className="form-control style1-input pl-5 border-size-md border-light font-xsss"
                    />
                    <i className="ti-search text-grey-500 font-xs"></i>
                  </div>
                </div> */}
                                <div className="bg-greyblue side-wrap rounded-lg p-4 mb-4">
                                    <div className="form-group mb-0">
                                        <RiFilterFill fontSize="2em" />

                                        <label className="fw-700 text-grey-900">Filters</label>
                                    </div>
                                    <ul className="recent-post mt-2 list-style-disc pl-4 filter-list">
                                        <li>
                                            <BsFillCalendar2WeekFill fontSize="1.5em" className="mr-2 mb-3 " />
                                            <a href='#' className="fw-500 lh-24 font-xsss text-grey-500" > Calendar </a>
                                        </li>
                                        <li>
                                            <BsSaveFill fontSize="1.5em" className="mr-2 mb-3" />
                                            <a
                                                href="/blog-sidebar"
                                                className="fw-500 lh-24 font-xsss text-grey-500 "
                                            >
                                                Save Post
                                            </a>
                                        </li>
                                        <li>
                                            <AiFillHeart fontSize="1.5em" className="mr-2 mb-3" />
                                            <a
                                                href="/blog-sidebar"
                                                className="fw-500 lh-24 font-xsss text-grey-500 "
                                            >
                                                Like Post
                                            </a>
                                        </li>
                                        <li>
                                            <BsFillFileEarmarkPersonFill fontSize="1.5em" className="mr-2 mb-3" />
                                            <a
                                                href="/blog-sidebar"
                                                className="fw-500 lh-24 font-xsss text-grey-500 "
                                            >
                                                People
                                            </a>
                                        </li>
                                        <li>
                                            <ImBlocked fontSize="1.5em" className="mr-2 mb-3" />
                                            <a
                                                href="/blog-sidebar"
                                                className="fw-500 lh-24 font-xsss text-grey-500 "
                                            >
                                                Blocked
                                            </a>
                                        </li>
                                        <li>
                                            <BsGlobe fontSize="1.5em" className="mr-2 mb-3" />
                                            <a
                                                href="/blog-sidebar"
                                                className="fw-500 lh-24 font-xsss text-grey-500 "
                                            >
                                                Globel
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="slidecontainer mt-5" >
                                <button className='btn btn-info mb-2 d-flex justify-content-center'>400 Question Selected</button><br></br>
                                <input type="range" min="1" max="100" value="50" />
                            </div>

                        </div>
                    </div>
                </div>

                {/* <Footer /> */}
            </Fragment>
        );
    }
}

export default SelectQuestion;
