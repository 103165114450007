import React, { useState } from 'react'
import { Row, Col, Input } from "reactstrap"
import AdminServices from '../../../../services/AdminServices'
import Swal from 'sweetalert2'
const RewardSettings = () => {

  const [input, setInput] = useState({
    royaltyFree: "",
    royaltyPremium: "",
    pubicationPenaltyCoefficientFree: "",
    pubicationPenaltyCoefficientPremium: "",
    commisionRates: "",
    userPenaltyCoefficient: "-10"
  })
  const submitBtn = async () => {
    try {
      await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //   setLoading(true);
           
          var response = await AdminServices.RewardSettings(input);
           
          if (response.data.status === true) {
             
            Swal.fire("Added!", "" + response.data.message, "success");

          } else {
            Swal.fire("Error!", response.data.message, "danger");
          }
          //   setLoading(false);
        }
      });
    }
    catch (error) {
      {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    }
  }

  return (
    <div>
      <div className='quizs p-5 security ' >
        <h1 className='text-white d-flex justify-content-center mb-5'>Default coefficient:</h1>
        <Row>
          <Col md="6" >
            <h4 className='text-white'>Royalties coefficient  </h4>
          </Col>
          <Col md="3">
            <Input className='mb-4' placeholder='Premium' style={{ backgroundColor: "rgb(24, 24, 24)" }}
              name="royaltyPremium"
              value={input.royaltyPremium}
              onChange={(e) => { setInput({ ...input, royaltyPremium: e.target.value }) }}
            />
          </Col>
          <Col md="3">
            <Input className='mb-4' placeholder='Free' style={{ backgroundColor: "rgb(24, 24, 24)" }}
              name="royaltyFree"
              value={input.royaltyFree}
              onChange={(e) => { setInput({ ...input, royaltyFree: e.target.value }) }}
            />
          </Col>

        
          <Col md="6">
            <h4 className='text-white'>Publication Penalty Coefficient  </h4>
          </Col>
          <Col md="3">
            <Input className='mb-4' placeholder='Premium' style={{ backgroundColor: "rgb(24, 24, 24)" }}
              name="pubicationPenaltyCoefficientPremium"
              value={input.pubicationPenaltyCoefficientPremium}
              onChange={(e) => { setInput({ ...input, pubicationPenaltyCoefficientPremium: e.target.value }) }}
            />
          </Col>
          <Col md="3">
            <Input className='mb-4' placeholder='Free' style={{ backgroundColor: "rgb(24, 24, 24)" }}
              name="pubicationPenaltyCoefficientFree"
              value={input.pubicationPenaltyCoefficientFree}
              onChange={(e) => { setInput({ ...input, pubicationPenaltyCoefficientFree: e.target.value }) }}
            />
          </Col>

          <Col md="6">
            <h4 className='text-white'> Commision rates x_i = </h4>
          </Col>
          <Col md="6">
            <Input className='mb-4' style={{ backgroundColor: "rgb(24, 24, 24)" }}
              name="commisionRates"
              value={input.commisionRates}
              onChange={(e) => { setInput({ ...input, commisionRates: e.target.value }) }}
            />
          </Col>

          <Col md="6">
            <h4 className='text-white'>User Penalty Coefficient = </h4>
          </Col>
          <Col md="6">
            <Input className='mb-4' style={{ backgroundColor: "rgb(24, 24, 24)" }}
              name='userPenaltyCoefficient'
              value={input.userPenaltyCoefficient}
              onChange={(e) => { setInput({ ...input, userPenaltyCoefficient: e.target.value }) }}
            />
          </Col>

        </Row>
      </div>

      <div className='d-flex justify-content-center mt-5 mb-5'>
        <button className='btn text-white' onClick={submitBtn}> Save</button>
      </div>
    </div>
  )
}

export default RewardSettings