import React, { useEffect, useState, useRef } from 'react'
import Header from '../../components/Header'
import ChatList from './ChatList';
import sendIcon from "../../view/assests/iCON/iCON/noun-send-4514372.png"
import smiley from "../../view/assests/iCON/iCON/noun-smiley-2561875.png"
import {
    addDoc, collection, serverTimestamp, query,
    orderBy,
    onSnapshot,
    where,
} from "firebase/firestore";
import { API_BASE_URL } from '../../constant';
import { database } from '../../components/firebase';
import { ImAttachment } from "react-icons/im"
import OtherUserProfileServices from '../services/OtherUserProfileServices';
import EmojiPicker from 'emoji-picker-react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useDropzone } from 'react-dropzone';
import * as AWS from "aws-sdk";
import awsConfig from "../../awsConfi";
const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    color: "white",
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};

const Chat = (props) => {
    const { id } = useParams()
    const messagesEndRef = useRef(null)
    const [input, setinput] = useState("");
    const [getMessages, setGetMessage] = useState([]);
    const [selectedEmoji, setSelectedEmoji] = useState("");

    const onEmojiClick = (event, emojiObject) => {
        setSelectedEmoji(event.emoji);
        var tempEmoji = [...input, event.emoji];
        setinput(tempEmoji.join(""))
    };


    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }
    // scrollToBottom()

    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let data = JSON.parse(localStorageObject);
    const [show, setShow] = useState(false)
    const [showUploadBtn, setShowUploadBtn] = useState()

    useEffect(() => {

        GetChat(parseInt(id))
        setReceiver(parseInt(id))
        getById(parseInt(id))
    }, [id])

    const GetChat = async (receiverId) => {

        var chatKey = Math.min(data.id, parseInt(receiverId)) + "-" + Math.max(data.id, parseInt(receiverId))
        const q = query(
            collection(database, "msg"),
            orderBy("createdAt"),
            where("chatKey", "==", chatKey),
            // or((where('receiverId', '==', receiverId), where('senderId', '==', data.id)), where('receiverId', '==', data.id), where('senderId', '==', receiverId))
        );

        const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
            let messages = [];
            QuerySnapshot.forEach((doc) => {
                messages.push({ ...doc.data(), id: doc.id });
            });
            setGetMessage(messages);
            console.log("new msgs => ", messages)
        });
    }


    const [getData, setGetData] = useState("")
    async function getById(receiverId) {

        let response = await OtherUserProfileServices.getStudentById(receiverId, data.id, true);
        setGetData(response.data.payload)
    }

    const sendMessage = async (event) => {
        setinput("");
        setShowUploadBtn(false)
        setChatContent([])
        setFiles([])
        scrollToBottom()
        setProgressBar(0)
        event.preventDefault();
         
        if (files.length == 0) {
            if (input.trim() === "") {
                alert("Enter valid message");
                return;
            }
        }
        try {
             
            await addDoc(collection(database, "msg"), {
                text: input.trim() === "" ? chatContent : input,
                receiverName: getData.firstName + " " + getData.lastName,
                senderName: data.firstName + " " + data.lastName,
                senderImage: getData.imagePath,
                receiverImage: data.imagePath,
                mediaType:input.trim() === ""  ?contentType: "none"  ,
                createdAt: serverTimestamp(),
                senderId: data.id,
                receiverId: parseInt(receiver),
                chatKey: Math.min(data.id, parseInt(receiver)) + "-" + Math.max(data.id, parseInt(receiver))
            });
            setSelectedEmoji(false)
        }
        catch (e) {
            console.log(e)
        }

    };
    const handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
            sendMessage(event)
        }
    };
    const [receiver, setReceiver] = useState()
    const [chatUserList, setChatUserList] = useState([])
    const [chatContent, setChatContent] = useState([])
    const [progressBar, setProgressBar] = useState(0)
    const SendReceiverId = (receiverId, chat) => {

        setChatUserList(chat)
        setReceiver(receiverId)
        getById(receiverId)
        GetChat(receiverId)
        setShow(true)
        scrollToBottom()
    }

    function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }
    function replaceAll(str, match, replacement) {
        return str.replace(new RegExp(escapeRegExp(match), 'g'), () => replacement);
    }

    const BUCKET_NAME = awsConfig.bucketName;
    const AWS_Base_URL = awsConfig.AWS_Base_URL;

    const generateUUID = () => {
        var d = new Date().getTime();
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16;
            if (d > 0) {
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }
    const ImageUploadOnAws = async (videos) => {
        const guid = generateUUID();
        const uploadPromises = [];
        const imageUrlPattern = /\.(jpeg|jpg|gif|png|webp)$/i;
        const videoUrlPattern = /\.(mp4|avi|mov|mkv|webm)$/i;

        for (const video of videos) {
            const fileName = guid + '_' + replaceAll(video.name, " ", "_");
            const uploadPromise = new Promise((resolve, reject) => {
                let bucket = new AWS.S3({
                    params: { Bucket: BUCKET_NAME }
                });

                let params = {
                    Key: "chat/content" + "/" + fileName,
                    ContentType: video.type,
                    Body: video
                };
                 
                bucket.upload(params)
                    .on("httpUploadProgress", evt => {
                        let progress = parseInt((evt.loaded * 100) / evt.total);
                        console.log(progress);
                        setProgressBar(progress)
                    })
                    .send((err, data) => {
                        if (err) {
                            console.error("Error uploading file:", err);
                            reject(err);
                        } else {
                            console.log(`File uploaded successfully`);
                            const fileUrl = AWS_Base_URL + params.Key;
                            resolve(fileUrl);
                        }
                    });
            });
            uploadPromises.push(uploadPromise);
             
            if (video.name.match(imageUrlPattern)) {
                console.log("This is an image URL.");
                setContentType("images");
            } else if (video.name.match(videoUrlPattern)) {
                console.log("This is a video URL.");
                setContentType("videos");
            } else {
                console.log("This is neither an image nor a video URL.");
                setContentType("none");
            }
        }

        try {
            const uploadedFiles = await Promise.all(uploadPromises);
            console.log("All files uploaded successfully", uploadedFiles);
            setChatContent(uploadedFiles)
        } catch (error) {
            console.error("One or more file uploads failed:", error);
        }
    };

    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': [],
            "video/*": []
        },
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            ImageUploadOnAws(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })))
        }
    });
    const [contentType, setContentType] = useState("")
    const thumbs = files.map((file) => {

        return (
            <div style={thumb} key={file.name}>
                <div style={thumbInner}>
                    {file.type.startsWith('video/') ? (
                        <video
                            src={file.preview}
                            style={img}
                            controls
                            // Revoke data uri after video is loaded
                            onLoad={() => {
                                URL.revokeObjectURL(file.preview);
                            }}
                        />
                    ) : (
                         <img alt=""
                            src={file.preview}
                            style={img}
                            // Revoke data uri after image is loaded
                            onLoad={() => {
                                URL.revokeObjectURL(file.preview);
                            }}
                        />
                    )}
                </div>
            </div>
        );
    });


    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);
    return (
        <>
            <Header />
            <div className="main-wrapper" style={{ background: "#181818", overflowY: "hidden" }}>
                <div
                //  className="main-content"
                >
                    <div className="middle-sidebar-bottom theme-dark-bg">
                        <div className="middle-sidebar-left ml-5 mr-3 pr-0">
                            <div className="row mt-3">

                                <ChatList SendReceiverId={SendReceiverId} />
                                {chatUserList.length != 0 ?
                                    <div className="col-lg-6 col-xl-8 col-md-6 pl-0 d-none d-lg-block d-md-block" style={{ marginTop: "100px" }}>
                                        <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar" style={{ bottom: "80px" }}>
                                            <div className="chat-body p-3 ">

                                                <div className="messages-content pb-5" style={{ height: "0px" }}>
                                                    {getMessages.map((value, index) => value.senderId == data.id ?
                                                        (
                                                            <div className="message-item outgoing-message">
                                                                <div className="message-user">
                                                                    <figure className="avatar">
                                                                         <img alt=""
                                                                            src={API_BASE_URL + data?.imagePath}
                                                                            alt="avater"
                                                                        />
                                                                    </figure>
                                                                    <div>
                                                                        <h5>{data.firstName}</h5>

                                                                    </div>
                                                                </div>
                                                                {value.mediaType === "images" ? (
                                                                    value.text?.map((imageSrc, index) => (
                                                                         <img alt="" key={index} src={imageSrc} alt={`Image ${index}`} width={200} />
                                                                    ))
                                                                    // <h1>images</h1>
                                                                ) : value.mediaType === "videos" ? (
                                                                    value.text?.map((imageSrc, index) => (
                                                                        <video
                                                                            key={index}
                                                                            src={imageSrc}
                                                                            width={300}
                                                                            controls
                                                                        />
                                                                    ))
                                                                    // <h1>video</h1>

                                                                ) : (
                                                                  <div className="message-wrap " style={{ backgroundColor: "#E84545" }}>{value.text}</div>
                                                                  
                                                                )
                                                                }
                                                            </div>
                                                        )
                                                        :
                                                        <div className="message-item">
                                                            <div className="message-user">
                                                                <figure className="avatar">
                                                                     <img alt=""
                                                                        src={API_BASE_URL + value.receiverImage}
                                                                        alt="avater"
                                                                    />
                                                                </figure>
                                                                <div>
                                                                    <h5>{value.senderName}</h5>
                                                                    {/* <div className="time">01:35 PM</div> */}
                                                                </div>
                                                            </div>
                                                            {value.mediaType === "images" ? (
                                                                value.text?.map((imageSrc, index) => (
                                                                     <img alt="" key={index} src={imageSrc} alt={`Image ${index}`} width={200} />
                                                                ))
                                                            ) : value.mediaType === "videos" ? (
                                                                value.text?.map((imageSrc, index) => (
                                                                    <video
                                                                        key={index}
                                                                        src={imageSrc}
                                                                        width={300}
                                                                        controls
                                                                    />
                                                                ))

                                                            ) : (
                                                                <div className="message-wrap " >{value.text}</div>
                                                            )
                                                            }


                                                        </div>

                                                    )}
                                                    <div className="clearfix"></div>
                                                    <div ref={messagesEndRef} />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="chat-bottom p-3 "
                                            style={{ width: '98%', backgroundColor: "#181818" }}
                                        >
                                            {selectedEmoji ? (
                                                <EmojiPicker
                                                    onEmojiClick={onEmojiClick}
                                                />
                                            ) : null}

                                            <div className="chat-form" >

                                                <div className="d-flex" style={{ justifyContent: "space-between" }}>
                                                    <ImAttachment fontSize="25px" color='grey' className='m-2' title='attachment' onClick={() => setShowUploadBtn(!showUploadBtn)} />
                                                    {showUploadBtn ? <div>
                                                        <section className="container">
                                                            <div {...getRootProps({ className: 'dropzone' })} style={{
                                                                color: "grey", border: "1px dotted grey",
                                                                padding: " 10px 100px 0px 100px"
                                                            }}  >
                                                                <input {...getInputProps()} onChange={ImageUploadOnAws} />
                                                                <p>Drag 'n' drop some files here, or click to select files</p>
                                                            </div>
                                                            <aside style={thumbsContainer}>
                                                                {thumbs}
                                                            </aside>
                                                            <h1 className='text-center'style={{color:"#dc3545"}}>{progressBar}%</h1>
                                                            <input type="range" value={progressBar}/>
                                                        </section>
                                                    </div> : <>
                                                         <img alt="" src={smiley} style={{ width: "30px", margin: "4px" }} onClick={() => setSelectedEmoji(!selectedEmoji)} />
                                                        <input type="text"
                                                            name="input "
                                                            value={input}
                                                            onChange={(e) => setinput(e.target.value)}
                                                            onKeyDown={handleKeyDown}
                                                            placeholder="Start typing.."
                                                            style={{ width: "85%" }}
                                                        />
                                                    </>}

                                                    <button className="sendBtn" style={{ width: "50px", marginLeft: "7px" }} onClick={sendMessage}>
                                                         <img alt="" src={sendIcon} width="30px" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   :""}

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

export default Chat