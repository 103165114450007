import React, { useState, useEffect } from 'react'
import branch from "../../../../assets/iCON/noun-hub-2586471.png"
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md"
import AllFilterServices from '../../../services/AllFilterServices'
import { SessionLogoutMsg } from '../../../../constant'
import { useDispatch, useSelector } from "react-redux";
import document from "../../../../assets/iCON/noun-document-1842194.png"
import { useHistory } from 'react-router-dom';
import { radarData, topUserList } from "../../QuizResult/store/features/TopUserSlice"

export var exportselectedBranch = []
export var exportselectedChapter = []
export var exportselectedSubchapter = []

export const BranchFilter = () => {
    
    const dispatch = useDispatch();
    const BranchFilterData = useSelector(state => state.quiz.brancheFilter)
    const ChapterFilterData = useSelector(state => state.quiz.chapterByBranchFilter)
    const SubChapterFilterData = useSelector(state => state.quiz.subchapterByChapterFilter)

    const [selectedBranch, setSelectedBranch] = useState(exportselectedBranch ? exportselectedBranch : [])
    const [selectedChapter, setSelectedChapter] = useState(exportselectedChapter ? exportselectedChapter : [])
    const [selectedSubchapter, setSelectedSubchapter] = useState(exportselectedSubchapter ? exportselectedSubchapter : [])
    var SelectedFilterData = {
        branchId: selectedBranch,
        chapterId: selectedChapter,
        subChapterId: selectedSubchapter,
        startDate: null,
        endDate: null,
        countryId: [],
        universityId: [],
        levelId: []
    }
    const [BranchName, setBranchName] = useState('');
    const [branchSearch, setBranchSearch] = useState();
    const [isActive, setIsActive] = useState(false);
    const [branchs, setBranch] = useState([]);
    useEffect(async () => {
        // AllBranches()
        AllChapter()
        AllSubChapter()
    }, [])

 
    const AllChapter = async () => {
        var response = await AllFilterServices.getAllChapters()
        if (response.data.message == SessionLogoutMsg) {
            history.push("/login")
            localStorage.removeItem('Exe-Med_Student_Info');
        }
        else {
            setChapterList(response.data.payload);
        }
    }
    const [subChapter, setSubChapter] = useState(selectedChapter.length == 0 ? false : true);
    const [subChapterList, setSubchapterList] = useState([]);

    const AllSubChapter = async () => {
        var response = await AllFilterServices.getAllSubchapter()
        if (response.data.message == SessionLogoutMsg) {
            history.push("/login")
            localStorage.removeItem('Exe-Med_Student_Info');
        }
        else {
            setSubchapterList(response.data.payload);
        }
    }
    const [chapterName, setChapterName] = useState('');
    const [chapterSearch, setChapterSearch] = useState();
    const handleChapterSearch = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = chapterList.filter((user) => {
                return user.title.toLowerCase().startsWith(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setChapterSearch(results);
        } else {
            setChapterSearch(chapterList);
            // If the text field is empty, show all users
        }
        setChapterName(keyword);
    }
    const [chapter, setChapter] = useState(selectedChapter.length == 0 ? false : true);
    const [chapterList, setChapterList] = useState([]);


    let history = useHistory()
    const [chnageChapter, setChnageChapter] = useState([])
    const [chnageSubChapter, setChnageSubChapter] = useState([])

    const handleBranchSearch = (e) => {
         
        const keyword = e.target.value;
        // var NotFound = branchs.find(value => value.title == keyword)

        if (keyword !== '') {
            const results = branchs.filter((user) => {
                return user.title.toLowerCase().startsWith(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setBranchSearch(results);
        } else {
            setBranchSearch(branchs);
            // If the text field is empty, show all users
        }
        setBranchName(keyword);
    }
    const handleSelectAllBranch = async (e) => {

         
        if (e.target.checked == true) {
            var tempSelectedBranch = branchs.map(li => li.id);
            setSelectedBranch(tempSelectedBranch)
            SelectedFilterData.branchId = tempSelectedBranch;
            dispatch(topUserList(SelectedFilterData))
            dispatch(radarData(SelectedFilterData))
            // localStorage.setItem("Branch_List_"+data.id, JSON.stringify(tempSelectedBranch));
            var response = await AllFilterServices.GetAllChaptersByBranchIds(tempSelectedBranch);
        }
        else {
            setSelectedBranch([])
            localStorage.removeItem("Branche_List", JSON.stringify(tempSelectedBranch));
            SelectedFilterData.branchId = [];
            dispatch(topUserList(SelectedFilterData))
            dispatch(radarData(SelectedFilterData))
            var response = await AllFilterServices.GetAllChaptersByBranchIds([]);

        }
         
        if (response.data.message == SessionLogoutMsg) {
            history.push("/login")
            localStorage.removeItem('Exe-Med_Student_Info');
        }
        else {
            setChnageChapter(response.data.payload)
        }

        if (e.target.checked == false) {
            var tempSelectedChapters = selectedChapter;
            tempSelectedChapters.forEach(element => {
                if (!response.data.payload.some(x => x.id == element)) {
                    tempSelectedChapters = selectedChapter.filter(function (obj) {
                        return obj != element;
                    });
                }
            });

            var response2 = await AllFilterServices.GetAllSubChaptersByChapterIds(selectedChapter);
            setChnageSubChapter(response2.data.payload)
            var tempSelectedSubChapters = selectedSubchapter;
            tempSelectedSubChapters.forEach(element => {
                const index = response2.data.payload.some(x => x.id == element);
                 
                if (!index) {
                    selectedSubchapter = selectedSubchapter.filter(function (obj) {
                        return obj != element;
                    });
                }
            });
        }
    };
    const BranchFilterChange = async (e) => {
         
        var Id = e.target.getAttribute('data-questionId')
        if (e.target.checked == false) {
            var tempSelectedBranch = selectedBranch.filter(function (obj) {
                return obj != Id;
            });
            //   setSelectedBranch(tempSelectedBranch)
            SelectedFilterData.branchId = tempSelectedBranch;
            // localStorage.setItem("Branch_List_"+data.id, JSON.stringify(tempSelectedBranch));
            dispatch(topUserList(SelectedFilterData))
            dispatch(radarData(SelectedFilterData))
        }
        else {
            var tempSelectedBranch = selectedBranch;
            tempSelectedBranch.push(parseInt(Id))
            //   setSelectedBranch(tempSelectedBranch)
            // localStorage.setItem("Branch_List_"+data.id, JSON.stringify(tempSelectedBranch));
             
            SelectedFilterData.branchId = tempSelectedBranch;
            dispatch(topUserList(SelectedFilterData))
            dispatch(radarData(SelectedFilterData))
            // dispatch(quizQuestion(SelectedFilterData))
        }


        e.target.parentNode.childNodes.forEach(function (element) {
            if (e.target.checked == true) {

                element.classList.add("change-color")
                element.classList.add("checkbox-color")
            }
            else {
                element.classList.remove("change-color")
            }
        });

        var response = await AllFilterServices.GetAllChaptersByBranchIds(selectedBranch);
         
        if (response.data.message == SessionLogoutMsg) {
            history.push("/login")
            localStorage.removeItem('Exe-Med_Student_Info');
        }
        else {
            setChnageChapter(response.data.payload)
        }

        if (e.target.checked == false) {
            var tempSelectedChapters = selectedChapter;
            tempSelectedChapters.forEach(element => {

                if (!response.data.payload.some(x => x.id == element)) {
                    tempSelectedChapters = selectedChapter.filter(function (obj) {
                        return obj != element;
                    });
                }
            });

            var response2 = await AllFilterServices.GetAllSubChaptersByChapterIds(selectedChapter);
            setChnageSubChapter(response2.data.payload)
            var tempSelectedSubChapters = selectedSubchapter;
            tempSelectedSubChapters.forEach(element => {
                const index = response2.data.payload.some(x => x.id == element);
                if (!index) {
                    tempSelectedSubChapters = selectedSubchapter.filter(function (obj) {
                        return obj != element;
                    });
                }
            });
        }
    }
    const handleSelectAllChapter = async (e) => {
         
        if (e.target.checked == true) {
            var tempSelectedChapter = chnageChapter.map(li => li.id);
            setSelectedChapter(tempSelectedChapter)
            SelectedFilterData.chapterId = tempSelectedChapter;
            dispatch(topUserList(SelectedFilterData))
            dispatch(radarData(SelectedFilterData))
            // localStorage.setItem("Chapter_List", JSON.stringify(tempSelectedChapter));
            var response2 = await AllFilterServices.GetAllSubChaptersByChapterIds(tempSelectedChapter);

        }
        else {
            setSelectedChapter([])
            localStorage.removeItem("Chapter_List", JSON.stringify(tempSelectedChapter));
            SelectedFilterData.chapterId = [];
            dispatch(topUserList(SelectedFilterData))
            dispatch(radarData(SelectedFilterData))
            var response2 = await AllFilterServices.GetAllSubChaptersByChapterIds([]);
        }

        setChnageSubChapter(response2.data.payload)
        var tempSelectedSubChapters = selectedSubchapter;
        tempSelectedSubChapters.forEach(element => {
            const index = response2.data.payload.some(x => x.id == element);
            if (!index) {
                tempSelectedSubChapters = selectedSubchapter.filter(function (obj) {
                    return obj != element;
                });
            }
        });
    }
    const ChapterFilterChange = async (e) => {
        var Id = e.target.getAttribute('data-questionId')
        if (e.target.checked == false) {
            var tempSelectedChapter = selectedChapter.filter(function (obj) {
                return obj != Id;
            });
            setSelectedChapter(tempSelectedChapter)
            SelectedFilterData.chapterId = tempSelectedChapter;
            // localStorage.setItem("Chapter_List", JSON.stringify(tempSelectedChapter));
            dispatch(topUserList(SelectedFilterData))
            dispatch(radarData(SelectedFilterData))
        }
        else {
            var tempSelectedChapter = selectedChapter;
            tempSelectedChapter.push(parseInt(Id))
            setSelectedChapter(tempSelectedChapter)
            // localStorage.setItem("Chapter_List", JSON.stringify(tempSelectedChapter));
            dispatch(radarData(SelectedFilterData))
            SelectedFilterData.chapterId = tempSelectedChapter;
            dispatch(topUserList(SelectedFilterData))
        }

        e.target.parentNode.childNodes.forEach(function (element) {
            if (e.target.checked == true) {
                element.classList.add("change-color")
                element.classList.add("checkbox-color")
            }
            else {
                element.classList.remove("change-color")
            }
        });
        var response = await AllFilterServices.GetAllSubChaptersByChapterIds(selectedChapter);
        if (response.data.message == SessionLogoutMsg) {
            history.push("/login")
            localStorage.removeItem('Exe-Med_Student_Info');
        }
        else {
            setChnageSubChapter(response.data.payload)
        }
    }

    const [subChapterName, setSubChapterName] = useState('');
    const [subChapterSearch, setSubChapterSearch] = useState(subChapterList);
    const handleSubChapterSearch = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = subChapterList.filter((user) => {
                return user.title.toLowerCase().startsWith(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setSubChapterSearch(results);
        } else {
            setSubChapterSearch(subChapterList);
            // If the text field is empty, show all users
        }
        setSubChapterName(keyword);
    }
    const SubchapterFilterChange = (e) => {
        var Id = e.target.getAttribute('data-questionId')
        if (e.target.checked == false) {
            var tempSelectedSubChapters = selectedSubchapter.filter(function (obj) {
                return obj != Id;
            });
            setSelectedSubchapter(tempSelectedSubChapters)
            SelectedFilterData.subChapterId = tempSelectedSubChapters;
            localStorage.setItem("Subchapter_List", JSON.stringify(tempSelectedSubChapters));
            dispatch(topUserList(SelectedFilterData))
            dispatch(radarData(SelectedFilterData))
        }
        else {
            var tempSelectedSubChapters = selectedSubchapter;
            tempSelectedSubChapters.push(parseInt(Id))
            setSelectedSubchapter(tempSelectedSubChapters)
            //   localStorage.setItem("Subchapter_List", JSON.stringify(tempSelectedSubChapters));
             
            SelectedFilterData.subChapterId = tempSelectedSubChapters;
            dispatch(topUserList(SelectedFilterData))
            dispatch(radarData(SelectedFilterData))
        }


        e.target.parentNode.childNodes.forEach(function (element) {
            if (e.target.checked == true) {
                element.classList.add("change-color")
                element.classList.add("checkbox-color")
            }
            else {
                element.classList.remove("change-color")
            }
        });
    }
    const handleSelectAllSubchapter = (e) => {
        if (e.target.checked == true) {
            var tempSelectedSubChapters = chnageSubChapter.map(li => li.id);
            setSelectedSubchapter(tempSelectedSubChapters)
            SelectedFilterData.subChapterId = tempSelectedSubChapters;
            dispatch(topUserList(SelectedFilterData))
            dispatch(radarData(SelectedFilterData))
            //   localStorage.setItem("Subchapter_List", JSON.stringify(tempSelectedSubChapters));

        }
        else {
            setSelectedSubchapter([])
            //   localStorage.removeItem("Subchapter_List", JSON.stringify(tempSelectedSubChapters));
            SelectedFilterData.subChapterId = [];
            dispatch(topUserList(SelectedFilterData))
            dispatch(radarData(SelectedFilterData))
        }
    }
    return (
        <div>    <div className="CursorPointer form-group mb-1 filter-margin" onClick={() => setIsActive(!isActive)}>
            {isActive ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                 <img alt="" src={branch} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Branch</h3>
                {isActive ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
            </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                 <img alt="" src={branch} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Branch</h3>
                {isActive ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
            </a>}
        </div>
            {isActive && <div className="form-group mb-1 sub-menu-filter mb-3 mr-3 w-100 filter-scrollbar scrollbar-primary">
                <input placeholder='Search Branch' value={BranchName} onChange={handleBranchSearch} className='form-control' />
                <div>
                    <label className='ml-2 CursorPointer'>All</label>
                    <input
                        type="checkbox"
                        name="selectAll"
                        id="selectAll"
                        onChange={handleSelectAllBranch}
                        className="float-right checkbox"
                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                    />
                </div>


                {branchSearch && branchSearch.length > 0 ?

                    branchSearch.map((value, index) => {
                        return (
                            <div>
                                <label className='ml-2 CursorPointer'>{value.title}</label>
                                <input
                                    type="checkbox"
                                    data-questionId={value.id}
                                    checked={selectedBranch.includes(value.id)}
                                    onChange={BranchFilterChange}
                                    className="float-right checkbox"
                                    style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                                />
                            </div>
                        )
                    }) : BranchFilterData?.map((value, index) => {
                        return (
                            <div>
                                <label className='ml-2 CursorPointer'>{value.title}</label>
                                <input
                                    type="checkbox"
                                    data-questionId={value.id}
                                    checked={selectedBranch.includes(value.id)}
                                    onChange={BranchFilterChange}
                                    className="float-right checkbox"
                                    style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                                />
                            </div>
                        )
                    })}


            </div>}


            <div className=" CursorPointer form-group mb-1 filter-margin" onClick={() => setChapter(!chapter)}>
                {chapter ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                     <img alt="" src={document} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Chapters</h3>
                    {chapter ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
                </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                     <img alt="" src={document} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Chapters</h3>
                    {chapter ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
                </a>}
            </div>
            {chapter && <div className="form-group mb-1 sub-menu-filter mb-3 mr-3 w-100 filter-scrollbar scrollbar-primary">
                <input placeholder='Search Chapter' value={chapterName} onChange={handleChapterSearch} className='form-control' />
                <div>
                    <label className='ml-2 CursorPointer'>All</label>
                    <input
                        type="checkbox"
                        name="selectAllChapter"
                        id="selectAllChapter"
                        onChange={handleSelectAllChapter}
                        className="float-right checkbox"
                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                    />
                </div>
                {chapterSearch && chapterSearch.length > 0 ?

                    chapterSearch.map((value, index) => {
                        return (
                            <div>
                                <label className='ml-2 CursorPointer'>{value.title}</label>
                                <input
                                    type="checkbox"
                                    data-questionId={value.id}
                                    checked={selectedChapter.includes(value.id)}
                                    onChange={ChapterFilterChange}
                                    className="float-right checkbox"
                                    style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                                />
                            </div>
                        )
                    }) : ChapterFilterData.map((value, index) => {
                        return (
                            <div>
                                <label className='ml-2 CursorPointer'>{value.title}</label>
                                <input
                                    type="checkbox"
                                    data-questionId={value.id}
                                    checked={selectedChapter.includes(value.id)}
                                    onChange={ChapterFilterChange}
                                    className="float-right checkbox"
                                    style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                                />
                            </div>
                        )
                    })
                }

            </div>}
            <div className="CursorPointer form-group mb-1 filter-margin" onClick={() => setSubChapter(!subChapter)}>
                {subChapter ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                     <img alt="" src={document} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Sub Chapter</h3>
                    {subChapter ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
                </a> : <a className=" form-control text-white quiz border-0 p-0 mb-2 ">
                     <img alt="" src={document} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Sub Chapter</h3>
                    {subChapter ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
                </a>}
            </div>

            {subChapter && <div className="form-group mb-1 sub-menu-filter mb-3 mr-3 w-100 filter-scrollbar scrollbar-primary">
                <input placeholder='Search Sub-Chapter' onChange={handleSubChapterSearch} value={subChapterName} className='form-control' />
                <div>
                    <label className='ml-2 CursorPointer'>All</label>
                    <input
                        type="checkbox"
                        name="selectAll"
                        id="selectAll"
                        onChange={handleSelectAllSubchapter}
                        className="float-right checkbox"
                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                    />
                </div>
                {subChapterSearch && subChapterSearch.length > 0 ?
                    subChapterSearch.map((value, index) => {
                        return (
                            <div>
                                <label className='ml-2 CursorPointer'>{value.title}</label>
                                <input
                                    data-questionId={value.id}
                                    type="checkbox"
                                    checked={selectedSubchapter.includes(value.id)}
                                    onChange={SubchapterFilterChange}
                                    className="float-right checkbox"
                                    style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                                />
                            </div>
                        )
                    }) :
                    SubChapterFilterData.map((value, index) => {
                        return (
                            <div>
                                <label className='ml-2 CursorPointer'>{value.title}</label>
                                <input
                                    data-questionId={value.id}
                                    type="checkbox"
                                    checked={selectedSubchapter.includes(value.id)}
                                    onChange={SubchapterFilterChange}
                                    className="float-right checkbox"
                                    style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                                />
                            </div>
                        )
                    })
                }
            </div>}

        </div>
    )
}

