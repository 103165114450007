import React, { useState, useRef } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { BsImageFill } from "react-icons/bs"
import * as AWS from "aws-sdk";
import awsConfig from "../../../../awsConfi";

const CreateFlashcardQuestion = (props) => {
  
    const [questionOption, setQuestionOpion] = useState([{
        question: "",
        answer: "",
        questionFile: "",
        qImg: ""
    }])

    const questionhandler = (e, index) => {

        const list = [...questionOption];
        list[index]['question'] = e;
        setQuestionOpion(list);
        props.sendList(list)

    }

    const answerhandler = (e, index) => {
         

        const { name, value } = e.target;
        const list = [...questionOption];
        list[index][name] = value;
        setQuestionOpion(list);
        props.sendList(list)

    }

    const handleAddClick = () => {
         
        setQuestionOpion([...questionOption, { question: "", answer: "", questionFile: "" }]);
    };
    const handleRemoveClick = index => {
         
        if (questionOption.length > 1) {
            const list = [...questionOption];
            list.splice(index, 1);
            setQuestionOpion(list);
        }

    };
    const inputFile = useRef(null)
    const BUCKET_NAME = awsConfig.bucketName;
    const FOLDER_NAME = awsConfig.folderName;
    const generateUUID = () => {
        var d = new Date().getTime();
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16;
            if (d > 0) {
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }
    function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }
    function replaceAll(str, match, replacement) {
        return str.replace(new RegExp(escapeRegExp(match), 'g'), () => replacement);
    }
    const [uploadImg, setUploadImg] = useState("")
    const ImageUploadOnAws = async (video, index) => {
        var guid = generateUUID();
        var fileName = guid + '_' + replaceAll(video.name, " ", "_");
        let bucket = new AWS.S3({
            params: { Bucket: BUCKET_NAME }
        });

        let params = {
            Key: "flashcardImages" + "/" + fileName,
            ContentType: video.type,
            Body: video
        };

        bucket
            .upload(params)
            .on("httpUploadProgress", evt => {

            })
            .send(err => {
                if (err == null) {
                     
                    console.log(`File Upload Successfuly`);
                    const list = [...questionOption];
                    list[index]["qImg"] = video;
                    list[index]["questionFile"] = params.Key;
                    setQuestionOpion(list);
                    props.sendList(list)
                    console.log(params.Key);
                     
                }
                else {
                    console.log(err)

                }

            });
    }
    const [selectedImage, setSelectedImage] = useState()
    const imageChange = (e, index) => {
        if (e.target.files && e.target.files.length > 0) {

            var file = e.target.files[0];
            ImageUploadOnAws(file, index);
        }
    }
    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 50,
        },
        preview: {
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            marginBottom: "20px"
        },
        image: { maxWidth: "20%", maxHeight: 100 },
        uploadBtn: {
            backgroundColor: "#E84545",
            borderRadius: "8px",
            opacity: "1",
            borderColor: "transparent",
            padding: "5px",
            color: "#FFFFFF",
            height: "38px",
            fontSize: "16px"
        }

    };
    return (
        <>
            <div className="ReactQuill mt-4 mb-4">
                <label>Add Question And Answer</label>
                {questionOption.map((x, i) => {
                    console.log(i)
                    return (
                        <>


                            <ReactQuill
                                modules={{
                                    toolbar: {
                                        container: [
                                            [{ size: [] }],
                                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                            [{ list: 'ordered' }, { list: 'bullet' }],
                                        ],

                                    }
                                }}
                                theme="snow"
                                style={{ color: 'white' }}
                                value={x.question} name="question"
                                onChange={e => questionhandler(e, i)}
                                className="mb-2" />
                            {/* <label
                                style={styles.uploadBtn}
                                className=" float-right" htmlFor={'upload' + i}><BsImageFill className="mr-1" />Upload</label>
                            <input
                                type='file'
                                id={'upload' + i}
                                // value={x.qImg}
                                name="questionFile"
                                ref={inputFile}
                                style={{ display: 'none' }}
                                accept="image/*"
                                onChange={(e) => imageChange(e, i)}
                            /> */}
                            <div className="ReactQuill">
                                <textarea type="text"
                                    placeholder="Add Answere..."
                                    value={x.answer} name="answer"
                                    onChange={e => answerhandler(e, i)}
                                    className="form-control" rows="2"
                                    style={{ backgroundColor: "#181818", width: "97%", margin: "17px" }} />
                            </div>

                            {x.qImg && (
                                <div
                                    style={styles.preview}
                                >
                                     <img alt=""
                                        src={URL.createObjectURL(x.qImg)}
                                        style={styles.image}
                                        alt="Thumb"
                                    />
                                </div>
                            )}
                        </>

                    )
                })}
                <button className="choice-btn" onClick={handleAddClick}>Add More Question/Anwer</button>
                <button className="remove-choice-btn" onClick={handleRemoveClick}>Remove Question/Anwer</button>

            </div>

        </>
    )
}

export default CreateFlashcardQuestion