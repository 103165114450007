import React, { useState, useEffect, useRef } from 'react'
import { FormGroup, Label, Row, Input, Col } from 'reactstrap'
import cardImg from "../../../assets/images/demo/id.jpg"
import { IoMdAddCircle } from "react-icons/io"
import Switch from "react-switch";
import ProfileServices from '../../services/ProfileServices';
import AllFilterServices from '../../services/AllFilterServices';
import { API_BASE_URL } from '../../../constant';
import Swal from 'sweetalert2';
const Security = () => {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    const [inputField, setInputField] = useState({
        id: Studentdata.id,
        firstName: "",
        lastName: "",
        email: "",
        countryId: "",
        languageId: "",
        universityId: "",
        universities: "",
        studentIdentityPath: "",
        studentLevelId: "",
        studentInterest: []
    })
    const [StudentInterest, setStudentInterest] = useState([])
    async function fetchGetIntrestApi() {
        // setLoading(true)
        var response = await AllFilterServices.getAllSignUpIntrests(Studentdata.CountryId == undefined ? 0 : Studentdata.CountryId,Studentdata.UniversityId == undefined ? 0 : Studentdata.UniversityId)
        setStudentInterest(response.data.payload.studentInterests);
    }
    const [countryList, setCountryList] = useState([])
    async function fetchGetCountryApi() {
        var response = await AllFilterServices.GetCountriesListWithoutAuth()
        setCountryList(response.data.payload);
    }

    const [languagesList, setLanguaes] = useState([])
    async function fetchGetLanguagesApi() {
        var response = await AllFilterServices.GetLangaguesWithoutAuth()
        setLanguaes(response.data.payload);
    }
    const [UniversityList, setUniersityList] = useState([])
    const [levelList, setLevelList] = useState([])

    async function fetchUniversityApi() {
        var res = await AllFilterServices.getAllIntrests(Studentdata.universityId)
        var response = await AllFilterServices.GetUniversitiesWithoutAuth()
        setUniersityList(response.data.payload);
        setLevelList(res.data.payload)

        var response = await AllFilterServices.getAllHospital()
        setUniersityList(response.data.payload);
    }
    const [studentLevel, setStudentLevel] = useState([])
    const [studentInterest, setStudentIntrest] = useState([])
    async function GetStudentById() {
        let response = await ProfileServices.GetStudentById(Studentdata.id);
         
        setInputField(response.data.payload);
        setStudentLevel(response.data.payload.userLevel == null ? "" : response.data.payload.userLevel.title)
        setStudentIntrest(response.data.data == null ? "" : response.data.data)

    }
    const inputsHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        GetStudentById()
        fetchGetCountryApi()
        fetchGetLanguagesApi()
        fetchUniversityApi()
        fetchGetIntrestApi()

    }, [])
    const inputFile = useRef(null)
    const onButtonClick = () => {
        inputFile.current.click();
    };
    const [post, setPost] = useState(false)
    const [selectedImage, setSelectedImage] = useState(false)
    const uploadImg = (e) => {
        setPost(!post)
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImage(e.target.files[0])
            var file = document.querySelector(
                'input[type=file]')['files'][0];
            inputField.studentIdentityPath = file
        }
    }
    const [premium, setPremium] = useState(false);
    const handlePremium = nextChecked => {
        setPremium(nextChecked);
    };

    const submitButton = async () => {
        debugger
        try {
            // await Swal.fire({
            //     title: "Are you sure?",
            //     text: "You won't be able to revert this!",
            //     icon: "warning",
            //     showCancelButton: true,
            //     confirmButtonColor: "#3085d6",
            //     cancelButtonColor: "#d33",
            //     confirmButtonText: "Yes",
            // }).then(async (result) => {
            //     if (result.isConfirmed) {
            //   setLoading(true);

            var response = await ProfileServices.UpdateStudentData(inputField);


            if (response.data.status === true) {
                Swal.fire("Added!", "" + response.data.message, "success");
                // backToRoute()
            } else {
                Swal.fire("Error!", response.data.message, "danger");
            }
            //   setLoading(false);
            //     }
            // });
        }
        catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error,
            })
            //   setLoading(false)
        }


    };
    const [allIds, setAllIds] = useState([])
    const GetIntrestsIds = (e) => {

        var Id = e.target.getAttribute('data-questionId')

        if (e.target.checked == false) {
            var selectedIds = allIds.filter(function (obj) {
                return obj != Id;
            });
            setAllIds(selectedIds)
            inputField.studentInterest = selectedIds
            //   document.getElementById('nextBtn').classList.add('backgroundColor')
        }
        else {
            var selectedIds = allIds;
            selectedIds.push(parseInt(Id))
            var tempData = inputField;
            tempData.InterestIdsArr = selectedIds;
            setAllIds(selectedIds)
            //   document.getElementById('nextBtn').classList.remove('backgroundColor')
            //   document.getElementById('nextBtn').classList.add('signupBtn')
            inputField.studentInterest = selectedIds

        }

    }
    return (

        <>
            <div className='d-flex justify-content-end' >
                <label className='text-[#1809d5]'>Active Status </label>
                <Switch
                    offHandleColor="#000000"
                    onHandleColor="#000000"
                    onChange={handlePremium}
                    checked={premium}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onColor="#72BB53"
                    height={15}
                    width={30}
                    className="m-2"
                />

            </div>

            <div className='quizs security'>
                <Row >
                    <Col md="11" sm="6">
                        <FormGroup>
                            <Label>First Name </Label>
                            <Input className='setting-inputField' type='text' placeholder='First Name' value={inputField.firstName} name="firstName" onChange={inputsHandler} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Last Name </Label>
                            <Input className='setting-inputField' type='text' placeholder='Last Name' value={inputField.lastName} name="lastName" onChange={inputsHandler} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Email </Label>
                            <Input className='setting-inputField' type='email' placeholder='Last Name' value={inputField.email} name="email" onChange={inputsHandler} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Country </Label>
                            <Input className='setting-inputField' type="select" placeholder='Country' value={inputField.countryId} name="countryId" onChange={inputsHandler} >
                                <option value="">Select Country</option>
                                {
                                    countryList.map((item, index) => {
                                        return <option key={index} value={item.id}>{item.name} </option>
                                    })
                                }

                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Language </Label>
                            <Input className='setting-inputField' type='select' placeholder='Language' value={inputField.languageId} name="languageId" onChange={inputsHandler}>
                                <option value="">Select Language</option>
                                {
                                    languagesList.map((item, index) => {
                                        return <option key={index} value={item.id}>{item.name} </option>
                                    })
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>University/Hopital </Label>
                            <Input className='setting-inputField' type='select' placeholder='University' value={inputField.universityId} disabled  >
                                {
                                    UniversityList.map((item, index) => {
                                        return <option key={index} value={item.id}>{item.name} </option>
                                    })
                                }
                            </Input>
                        </FormGroup>
                        <input type="file" id="upload" onChange={uploadImg} hidden />
                        <label for="upload" className='float-right' ><IoMdAddCircle fontSize="1rem" className="ml-5" />Add New</label>
                        {post ||  <img alt="" src={API_BASE_URL + inputField.identityPath} className="setting-inputField" width="700px" />}
                        {selectedImage && (
                            <div
                            //    style={styles.preview}
                            >
                                 <img 
                                    src={URL.createObjectURL(selectedImage)}
                                    width="500px"
                                    style={{ marginLeft: "130px" }}
                                    alt="Thumb"
                                />
                            </div>
                        )}

                        <FormGroup>
                            <Label className='text-[#1809d5]'>Current  Year</Label>
                            <Input name='studentLevelId' className='setting-inputField' type='select' placeholder='2nd Year' onChange={inputsHandler} value={inputField.studentLevelId}>
                                <option>--Select Year--</option>
                                {levelList.map((value, index) => {
                                    return (
                                        <option key={index} value={value.id}>{value.title}</option>
                                    )
                                })}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Speciality Of Interest</Label>
                            <Row className="ml-3">
                                {StudentInterest?.map((item, index) => {
                                    console.log("studentInterest", studentInterest)
                                    const isChecked = studentInterest.some(value => value.id === item.id);

                                    return (
                                        <div className="col-4" key={index}>
                                            <div className="form-check text-left mb-3 text-white">
                                                <input
                                                    type="checkbox"
                                                    name="InterestIdsArr"
                                                    data-questionId={item.id}
                                                    onChange={GetIntrestsIds}
                                                    defaultChecked={isChecked}
                                                    className="float-left checkbox"
                                                    style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                                                />
                                                <label className="mt-1 text-white" id="">{item.title}</label>
                                            </div>
                                        </div>
                                    )
                                })}

                            </Row>
                        </FormGroup>


                    </Col>
                    <button className='rangeButton d-flex justify-content-center mt-3 mb-4' onClick={submitButton}>Save</button>

                </Row>
            </div>
        </>
    )
}

export default Security