import React, { useState, useEffect } from 'react'
import { Row, Col, FormGroup, Input, Label, Select } from "reactstrap"
import AllFilterServices from '../../../services/AllFilterServices'
import AdminServices from '../../../services/AdminServices'
import Swal from "sweetalert2"
const Apply = () => {
  useEffect(async () => {
    var response = await AllFilterServices.getAllCountries()
    setCountryList(response.data.payload);
  }, [])

  const [level, setLevel] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [universityList, setUniversityList] = useState([]);
  const [branchs, setBranch] = useState([]);
  const [chapter, setChapter] = useState([]);
  const [userList, setUserList] = useState([])


  let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
  var data = JSON.parse(localStorageObject);
  
  const GetCountry = async (e) => {
    var response = await AdminServices.GetUniversitiesByCountryId(e.target.value)
    setUniversityList(response.data.payload)
  }
  const GetUniversity = async (e) => {
     
    var response = await AdminServices.GetLevelsByUniversityId(e.target.value)
    setLevel(response.data.payload)
  }
  const GetLevel = async (e) => {

    setLevelId(e.target.value)
  }


  const [levelId, setLevelId] = useState(0);

  const applyBtn = async () => {
    const response = await AdminServices.ApplyCohortAdmin(data.id,levelId)
    if (response.data.status == true) {
      Swal.fire(
        'Applied!',
        response.data.message,
        'success'
      )
    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: response.data.message,
      })
    }
  }

  return (
    <>

      <Row >
        <Col md="4">
          <select className='form-control chapterApply' onChange={GetCountry}>
            <option value="">-- Select Country --</option>
            {countryList.map((value) => (
              <option value={value.id}>{value.name}</option>
            ))}
          </select>

        </Col>
        <Col md="4">
          <select className='form-control chapterApply' onChange={GetUniversity}>
            <option value="">-- Select University --</option>
            {universityList.map((value) => (
              <option value={value.id}>{value.name}</option>
            ))}
          </select>
        </Col>
        <Col md="4">
          <select className='form-control chapterApply' onChange={GetLevel}>
            <option value="">-- Select Level --</option>
            {level.map((value) => (
              <option value={value.id}>{value.title}</option>
            ))}
          </select>

        </Col>
      </Row>
  
      {/* <Row>
        <table className='content-creator-table'>
          <tr>
            <th>ID</th>
            <th>Content Type</th>
            <th>Link to</th>
            <th>Author</th>
            <th>Flag/Like ratio</th>
            <th>Total Flags</th>
            <th>Date Last Flags</th>
            <th>Total views</th>
            <th>Total likes</th>
            <th>like to viewed Ratio</th>
            <th>List of flags reason</th>
            <th>Actions</th>


          </tr>
          <tr>
            <td>Alfreds Futterkiste</td>
            <td>Maria Anders</td>
            <td>Germany</td>
            <td>Alfreds Futterkiste</td>
            <td>Maria Anders</td>
            <td>Germany</td>
            <td>Alfreds Futterkiste</td>
            <td>Maria Anders</td>
            <td>Germany</td>
            <td>Alfreds Futterkiste</td>
            <td>Maria Anders</td>
            <td>Germany</td>
          </tr>
          <tr>
            <td>Alfreds Futterkiste</td>
            <td>Maria Anders</td>
            <td>Germany</td>
            <td>Alfreds Futterkiste</td>
            <td>Maria Anders</td>
            <td>Germany</td>
            <td>Alfreds Futterkiste</td>
            <td>Maria Anders</td>
            <td>Germany</td>
            <td>Alfreds Futterkiste</td>
            <td>Maria Anders</td>
            <td>Germany</td>
          </tr>

        </table>
      </Row> */}
      <Row className='mt-5'>
        <Col md="12">
          <div className='quizs social-search p-3'>
            <p>  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
              when an unknown printer took a galley of type and scrambled it to make a type specimen book.
              It has survived not only five centuries, but also the leap into electronic typesetting,
              remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
              and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
            </p>
            <button onClick={applyBtn} className='change-password-btn d-flex justify-content-center  mt-3 pl-5 pr-5'>Apply</button>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Apply