import React, { useState } from 'react'
import { Col, Row } from "reactstrap"
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css"
import { useSelector, useDispatch } from 'react-redux';
import { GetChapterAdminsCohortAdmins } from '../../../redux/features/AdminSlice';
import AdminServices from '../../../../services/AdminServices';
import { useEffect } from 'react';
const ChapterManagment = (props) => {


    const GetChapterCohortAdminsList = useSelector(state => state.Admin.ChapterCohortAdmin)

    const dispatch = useDispatch();
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    var data = JSON.parse(localStorageObject);
    const [selectedContent, setSelectedContent] = useState(null)
    const [selectedYear, setSelectedYear] = useState(null)

    var SelectedFilterData = {
        userId: data.id,
        branchId: null,
        visibility: null,
        contentType: selectedContent,
        year: selectedYear
    }

    const [chapter, setChapter] = useState([]);
    //     if (props.GetBranchesByLevelId != null) {
    //          
    //         GetBranches()

    //     }

    //   const GetBranches=async()=>{
    //     const res =await AdminServices.GetBranchesByLevelId(props.GetBranchesByLevelId)
    //     setChapter(res.data.payload)
    //   }

    const GetContentType = (e) => {
        setSelectedContent(e.target.value)
        SelectedFilterData.contentType = e.target.value
        dispatch(GetChapterAdminsCohortAdmins(SelectedFilterData))
    }
    // const GetStatus = (e) => {
    //     setSelectedStatus(e.target.value)
    //     SelectedFilterData.status = e.target.value
    //     dispatch(GetChapterAdminsCohortAdmins(SelectedFilterData))
    // }

    const GetYear = (e) => {
        setSelectedYear(e.target.value)
        SelectedFilterData.year = e.target.value
        dispatch(GetChapterAdminsCohortAdmins(SelectedFilterData))
    }
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 10;
    const years = Array.from({ length: 11 }, (_, index) => startYear + index).reverse();


    return (
        <>
            <Row>
                <Col md="6">
                    <select className='form-control chapterApply'
                    // onChange={GetChapters}
                    >
                        <option value="">-- Select Branch --</option>
                        {/* {chapter?.map((value) => (
                            <option value={value.id}>{value.title}</option>
                        ))} */}
                    </select>
                </Col>

                <Col md="6">
                    <select className='form-control chapterApply'
                        onChange={GetContentType}
                    >
                        <option value="">-- Select Content Type --</option>
                        <option value="QUIZ">Quiz</option>
                        <option value="VIDEO">Video</option>
                        <option value="DOCUMENT">Document</option>
                        <option value="FLASHCARD">Flashcard</option>
                    </select>
                </Col>
                <Col md="6">
                    <select className='form-control chapterApply' >
                        <option value="">-- Select Visibility --</option>
                    </select>
                </Col>
                <Col md="6">
                    <select className='form-control chapterApply'
                        onChange={GetYear}
                    >
                        <option value="">-- Select Date Range --</option>
                        {years.map((value, index) => {
                            return (
                                <option key={index} value={value}>{value}</option>
                            )
                        })}
                    </select>
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col md="12">
                    <ReactTable
                        style={{ fontSize: "12px" }}
                        columns={[

                            {
                                Header: "Chapter Admin Name",
                                accessor: "user",
                                Cell: (row) =>(
                                    <div>
                                      {GetChapterCohortAdminsList[row.index].user.firstName+" "+ GetChapterCohortAdminsList[row.index].user.lastName}
                                    </div>

                                )
                            },
                            {
                                Header: "Branch",
                                accessor: "data",
                            },

                            {
                                Header: "Chapter",
                                accessor: "data",
                            },
                            {
                                Header: "Chapter Admin table",
                                accessor: "data",
                            },
                            {
                                Header: "Total Views",
                                accessor: "totalViews",
                            },
                            {
                                Header: "Total Like",
                                accessor: "totalLikes",
                            },
                            {
                                Header: "Like to view ratio",
                                accessor: "#",
                            },
                            {
                                Header: "Like rate",
                                accessor: "rate",
                                Cell: (row) => {
                                    return (
                                        <div>
                                            {GetChapterCohortAdminsList[row.index].likeThisYear - GetChapterCohortAdminsList[row.index].likeLastYear}
                                        </div>
                                    )
                                }
                            },
                            {
                                Header: "Buzz Factor",
                                accessor: "buzzFactor",
                                Cell: (row) => {
                                    return (

                                        <div>
                                            {GetChapterCohortAdminsList[row.index].likeThisYear - ((2 * GetChapterCohortAdminsList[row.index].likeLastYear) + GetChapterCohortAdminsList[row.index].likeYearBeforeLastYear)}
                                        </div>

                                    )
                                }
                            },
                            {
                                Header: "Total Flags",
                                accessor: "totalFlags",
                            },
                            {
                                Header: "Flag to view ratio",
                                accessor: "flagViewRatio",
                            },
                            {
                                Header: "MEDcoin",
                                accessor: "medCoins",
                            },
                            {
                                Header: "Content Chapter Admin",
                                accessor: "data",
                            },
                            {
                                Header: "Dismiss/Enroll",
                                accessor: "data",
                            },
                        ]}
                        defaultPageSize={5}
                        // showPaginationBottom={true}
                        className="-striped -highlight"
                    data={GetChapterCohortAdminsList}
                    // filterable
                    // defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}

                    />
                </Col>
            </Row>
        </>
    )
}

export default ChapterManagment