import React, { useState, useEffect } from 'react'
import { Row, Col, FormGroup, Input, Label, Select, CardBody, ModalBody, Modal } from "reactstrap"
import AllFilterServices from '../../../services/AllFilterServices'
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css"
import AdminServices from '../../../services/AdminServices'
import { GetChapterAdmin } from '../../redux/features/AdminSlice'
import { useDispatch, useSelector } from 'react-redux';
const Manage = () => {
  const dispatch = useDispatch()
  const ChapterAdminList = useSelector(state => state.Admin.ChapterAdminList)
  let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
  var data = JSON.parse(localStorageObject);
  const [universityList, setUniversityList] = useState([]);
  const [level, setLevel] = useState([]);
  const [branchs, setBranch] = useState([]);
  const [chapter, setChapter] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedUniversity, setSelectedUniversity] = useState(null)
  const [selectedLevel, setSelectedLevel] = useState(null)
  const [selectedBranch, setSelectedBranch] = useState(null)
  const [selectedChapter, setSelectedChapter] = useState(null)
  const [selectedContent, setSelectedContent] = useState(null)
  const [selectedYear, setSelectedYear] = useState(null)

  var SelectedFilterData = {
    userId: data.id,
    branchId: selectedBranch,
    chapterId: selectedChapter,
    CountryId: selectedCountry,
    UniversityId: selectedUniversity,
    LevelId: selectedLevel,
    contentType: selectedContent,
    visiblity: null,
    year: selectedYear
  }

  const [countryList, setCountryList] = useState([]);
  useEffect(async () => {
    var response = await AllFilterServices.getAllCountries()
    setCountryList(response.data.payload);

  }, [])
  const GetChapters = async (e) => {
    setSelectedChapter(e.target.value)
    SelectedFilterData.chapterId = e.target.value;
    dispatch(GetChapterAdmin(SelectedFilterData))
  }
  const GetCountry = async (e) => {
    var response = await AdminServices.GetUniversitiesByCountryId(e.target.value)
    setUniversityList(response.data.payload)
    setSelectedCountry(e.target.value)
    SelectedFilterData.CountryId = e.target.value;
    dispatch(GetChapterAdmin(SelectedFilterData))
  }
  const GetUniversity = async (e) => {
     
    var response = await AdminServices.GetLevelsByUniversityId(e.target.value)
    setLevel(response.data.payload)
    setSelectedUniversity(e.target.value)
    SelectedFilterData.UniversityId = e.target.value;
    dispatch(GetChapterAdmin(SelectedFilterData))
  }
  const GetLevel = async (e) => {

    var response = await AdminServices.GetBranchesByLevelId(e.target.value)
    setBranch(response.data.payload)
    setSelectedLevel(e.target.value)
    SelectedFilterData.LevelId = e.target.value;
    dispatch(GetChapterAdmin(SelectedFilterData))
  }
  const GetBranch = async (e) => {
    var response = await AdminServices.GetChaptersByBranchId(e.target.value)
    setChapter(response.data.payload)
    setSelectedBranch(e.target.value)
    SelectedFilterData.branchId = e.target.value;
    dispatch(GetChapterAdmin(SelectedFilterData))
  }

  const GetContentType = (e) => {
    setSelectedContent(e.target.value)
    SelectedFilterData.contentType = e.target.value;
    dispatch(GetChapterAdmin(SelectedFilterData))

  }
  const GetStatus = (e) => {
    dispatch(GetChapterAdmin(SelectedFilterData))
  }
  const GetYear = (e) => {
    dispatch(GetChapterAdmin(SelectedFilterData))
    setSelectedYear(e.target.value)
    SelectedFilterData.year = e.target.value;
  }
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 10;
  const years = Array.from({ length: 11 }, (_, index) => startYear + index).reverse();

  return (
    <>
      <Row>

        <Row >
          <Col md="4">
            <select className='form-control chapterApply' onChange={GetCountry}>
              <option value="">-- Select Country --</option>
              {countryList.map((value) => (
                <option value={value.id}>{value.name}</option>
              ))}
            </select>

          </Col>
          <Col md="4">
            <select className='form-control chapterApply' onChange={GetUniversity}>
              <option value="">-- Select University --</option>
              {universityList.map((value) => (
                <option value={value.id}>{value.name}</option>
              ))}
            </select>
          </Col>
          <Col md="4">
            <select className='form-control chapterApply' onChange={GetLevel}>
              <option value="">-- Select Year --</option>
              {level.map((value) => (
                <option value={value.id}>{value.title}</option>
              ))}
            </select>

          </Col>
          <Col md="4">
            <select className='form-control chapterApply' onChange={GetBranch}>
              <option value="">-- Select Branch --</option>
              {branchs.map((value) => (
                <option value={value.id}>{value.title}</option>
              ))}
            </select>
          </Col>
          <Col md="4">
            <select className='form-control chapterApply' onChange={GetChapters}>
              <option value="">-- Select Chapter --</option>
              {chapter.map((value) => (
                <option value={value.id}>{value.title}</option>
              ))}
            </select>
          </Col>

          <Col md="4">

          </Col>
          <Col md="4">
            <select className='form-control chapterApply' onChange={GetContentType} >
              <option value="">-- Select Content Type --</option>
              <option value="QUIZ">Quiz</option>
              <option value="VIDEO">Video</option>
              <option value="DOCUMENT">Document</option>
              <option value="FLASHCARD">Flashcard</option>
            </select>
          </Col>
          <Col md="4">
            <select className='form-control chapterApply' >
              <option value="">-- Select Visibility --</option>


            </select>
          </Col>
          <Col md="4">
            <select className='form-control chapterApply' onChange={GetYear}>
              <option value="">-- Select Date Range --</option>
              {years.map((value, index) => {
                return (
                  <option key={index} value={value}>{value}</option>
                )
              })}
            </select>
          </Col>
        </Row>



        <Col md="12" className='mt-5'>
          <ReactTable
            style={{ fontSize: "12px" }}
            columns={[

              {
                Header: "Content Creator Table",
                accessor: "creationDate",
              },
              {
                Header: "Total View",
                accessor: "totalViews",
              },

              {
                Header: "Total Likes",
                accessor: "totalLikes",
              },

              {
                Header: "Like Rate",
                accessor: "likeRate",
                Cell: (row) => {
                  return (

                      <div>
                          {ChapterAdminList[row.index].likeThisYear - ChapterAdminList[row.index].likeLastYear}
                      </div>

                  )
              }
              },
              {
                Header: "Like to view ratio (%)",
                accessor: "#",
              },
              {
                Header: "Buzz Factor",
                accessor: "buzzFactor",
                Cell: (row) => {
                  return (

                      <div>
                          {ChapterAdminList[row.index].likeThisYear - ((2 * ChapterAdminList[row.index].likeLastYear) + ChapterAdminList[row.index].likeYearBeforeLastYear)}
                      </div>

                  )
              }
              },
              {
                Header: "Total Flags",
                accessor: "totalFlags",
              },
              {
                Header: "Flag to View ratio (%)",
                accessor: "flagViewRatio",
              },
              {
                Header: "MEDcoin",
                accessor: "medCoins",
              },
             
            ]}
            defaultPageSize={5}
            // showPaginationBottom={true}
            className="-striped -highlight"
          data={ChapterAdminList}
          // filterable
          // defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}

          />
        </Col>
      </Row>
    </>
  )
}

export default Manage