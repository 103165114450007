import React from 'react'
import { Row, Col, FormGroup, Input, Label } from "reactstrap"
import SideBar from '../SideBar'
import Header from '../../../components/Header'
import dummy from "../../assests/dummy.png"
const BlockAccount = () => {
    return (
        <>
            <Header />
            <div className='change-password pt-5' style={{ backgroundColor: "#181818", color: "white" }}>
                <Row>
                    <Col md="1"></Col>
                    <Col md="3">
                        <SideBar />
                    </Col>
                    <Col md="7">
                        <div className='quizs social-search'>
                            <ul className='social-search-list mr-3'>
                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right'>Unblock</button></li>
                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right'>Unblock</button></li>
                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right'>Unblock</button></li>
                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right'>Unblock</button></li>
                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right'>Unblock</button></li>
                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right'>Unblock</button></li>
                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right'>Unblock</button></li>
                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right'>Unblock</button></li>
                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right'>Unblock</button></li>
                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right'>Unblock</button></li>
                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right'>Unblock</button></li>
                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right'>Unblock</button></li>
                            </ul>
                        </div>
                    </Col>
                </Row>

            </div>
        </>
    )
}

export default BlockAccount