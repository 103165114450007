import React, { useState, useEffect } from 'react'
import { Row, Col, FormGroup, Input, Label, Select } from "reactstrap"
import world from "../../../../assets/iCON/noun-country-2450449.png"
import AllFilterServices from '../../../services/AllFilterServices'
import {AiOutlineDownload} from "react-icons/ai"
import { Link } from 'react-router-dom'
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css"
import { GetAllCountryAdmins } from '../../redux/features/AdminSlice'
import { useDispatch, useSelector } from 'react-redux'
import { FileUploader } from "react-drag-drop-files";
import AdminServices from '../../../services/AdminServices'
import Swal from "sweetalert2"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import MedicalEducation from './MedicalEducation'
import UniversityandHospitalList from './UniversityandHospitalList'
import { FILE_TEMPLATE_BASE_URL } from '../../../../constant'
import ProfileServices from '../../../services/ProfileServices'
const Mange = () => {

    const AllCountryAdmins = useSelector(state => state.Admin.AllCountryAdmins)

    const dispatch = useDispatch()
    const [selectedCountry, setSelectedCountry] = useState(null)
    const [selectedContent, setSelectedContent] = useState(null)
    const [selectedStatus, setSelectedStatus] = useState(null)
    const [selectedYear, setSelectedYear] = useState(null)

    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    var data = JSON.parse(localStorageObject);

    var SelectedFilterData = {
        userId: data.id,
        CountryId: selectedCountry,
        contentType: selectedContent,
        status: selectedStatus,
        year: selectedYear
    }
    const [checkedCountry, setCheckedCountry] = useState("")

    async function fetchGetUserApi() {
      var response = await ProfileServices.GetStudentById(data.id);
      console.log("users", response.data);
      if (response.data.payload.isCountryAdmin && !response.data.payload.isSuperAdmin) {
        setCheckedCountry(response.data.payload.countryId)
        var response2 = await AllFilterServices.getAllCountries()
        setCountryList(response2.data.payload);
      }
      else {
        setCheckedCountry("")
        var response2 = await AllFilterServices.getAllCountries()
        setCountryList(response2.data.payload);
      }
    }
    const [countryList, setCountryList] = useState([]);
    useEffect(async () => {
        fetchGetUserApi()
    }, [])

    const GetYear = (e) => {
        setSelectedYear(e.target.value)
        SelectedFilterData.year = e.target.value;
        dispatch(GetAllCountryAdmins(SelectedFilterData))
    }
    const GetContentType = (e) => {
        setSelectedContent(e.target.value)
        SelectedFilterData.contentType = e.target.value;
        dispatch(GetAllCountryAdmins(SelectedFilterData))
    }
    const [countryId, setCountryId] = useState()
    const GetCountry = async (e) => {
        setSelectedCountry(e.target.value)
        setCountryId(e.target.value)
        SelectedFilterData.CountryId = e.target.value;
        dispatch(GetAllCountryAdmins(SelectedFilterData))
    }
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 10;
    const years = Array.from({ length: 11 }, (_, index) => startYear + index).reverse();

    const fileTypes = ["xlsx", "xls", "csv"];
    const uploadUniversityFile = async (e) => {
         
        var response = await AdminServices.AddUniversityFile(e[0], countryId);
        if (response.data.status === true) {
             
            Swal.fire(" Uploaded!", "" + "File Uploaded Successfull", "success");
        } else {
            Swal.fire("Error!", response.data.message, "danger");
        }
    }
    return (
        <>

            <Col md="12">
                <Row >
                    <Col md="12">
                        <FormGroup>
                            <Label>Filter </Label>

                            <select className='form-control chapterApply' onChange={GetCountry} disabled={checkedCountry == "" ? false : true}>
                                <option>-- Select Country --</option>
                                {countryList?.map((value, index) => {
                                    return (
                                        <option value={value.id} selected={checkedCountry == value.id}  >{value.name}</option>
                                    )
                                })}
                            </select>
                        </FormGroup>
                    </Col>

                    <Col md="4">
                        <select className='form-control chapterApply' onChange={GetContentType} >
                            <option value="">-- Select Content Type --</option>
                            <option value="QUIZ">Quiz</option>
                            <option value="VIDEO">Video</option>
                            <option value="DOCUMENT">Document</option>
                            <option value="FLASHCARD">Flashcard</option>
                        </select>
                    </Col>
                    <Col md="4">
                        <select className='form-control chapterApply' >
                            <option value="">-- Select Visibility --</option>
                        </select>
                    </Col>
                    <Col md="4">
                        <select className='form-control chapterApply' onChange={GetYear}>
                            <option value="">-- Select Date Range --</option>
                            {years.map((value, index) => {
                                return (
                                    <option key={index} value={value}>{value}</option>
                                )
                            })}
                        </select>
                    </Col>
                    </Row>
                    {/* <Row>
                        <Col md="4" className='mt-4'>
                            <a href={FILE_TEMPLATE_BASE_URL + "universities_by_country_admin_template.xls"} className='format-download mt-5' download> <AiOutlineDownload fontSize="1.5rem" />Sample</a>
                        </Col>
                        <Col md="8">
                            <FileUploader
                                multiple={true}
                                handleChange={uploadUniversityFile}
                                name="file"
                                types={fileTypes}
                            />

                        </Col>
                    </Row> */}
                    <Row>
                    <Col md="12">
                        <Tabs
                            defaultActiveKey="Manage"
                            id="uncontrolled-tab-example"
                            className="mb-3 mt-3 super-admin-tabs"
                            style={{ fontSize: "12px" }}
                        >
                            <Tab eventKey="Manage" title="University Admin Managment">
                                <Col md="12" className='mt-4'>
                                    <ReactTable
                                        style={{ fontSize: "12px" }}
                                        columns={[

                                            {
                                                Header: "University",
                                                accessor: "#",
                                            },
                                            {
                                                Header: "Uni Admin Name",
                                                accessor: "#",
                                            },

                                            {
                                                Header: "See Uni Admin Table",
                                                accessor: "#",
                                            },

                                            {
                                                Header: "Total View",
                                                accessor: "totalViews",
                                            },
                                            {
                                                Header: "Total Likes",
                                                accessor: "totalLikes",
                                            },
                                            {
                                                Header: "Like To view Ratio",
                                                accessor: "#",
                                            },
                                            {
                                                Header: "Like Rate",
                                                accessor: "likeRate",
                                                Cell: (row) => {
                                                    return (

                                                        <div>
                                                            {AllCountryAdmins[row.index].likeThisYear - AllCountryAdmins[row.index].likeLastYear}
                                                        </div>
                                                    )
                                                }
                                            },
                                            {
                                                Header: "Buzz Factor",
                                                accessor: "buzz",
                                                Cell: (row) => {
                                                    return (

                                                        <div>
                                                            {AllCountryAdmins[row.index].likeThisYear - ((2 * AllCountryAdmins[row.index].likeLastYear) + AllCountryAdmins[row.index].likeYearBeforeLastYear)}
                                                        </div>

                                                    )
                                                }
                                            },
                                            {
                                                Header: "Total Flag",
                                                accessor: "totalFlags",
                                            },
                                            {
                                                Header: "Flag to view ratio",
                                                accessor: "flagViewRatio",
                                            },
                                            {
                                                Header: "Contact Cohort Admin",
                                                accessor: "#",
                                            },
                                            {
                                                Header: "MEDcoin",
                                                accessor: "medCoins",
                                            },
                                            {
                                                Header: "Dismiss/Enroll",
                                                accessor: "#",
                                            },


                                        ]}
                                        defaultPageSize={5}
                                        // showPaginationBottom={true}
                                        className="-striped -highlight"
                                        data={AllCountryAdmins}
                                    // filterable
                                    // defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}

                                    />
                                </Col>

                            </Tab>

                            <Tab eventKey="Vote" title="Local Medical Education Label" >
                                <MedicalEducation />

                            </Tab>
                            <Tab eventKey="Apply" title="Universuty and Hopital " >
                                <UniversityandHospitalList  countryId={selectedCountry}/>
                            </Tab>
                        </Tabs>

                    </Col>
                    </Row>
            </Col>


        </>
    )
}

export default Mange