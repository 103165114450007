import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';

const PrivateRote = () => {
  let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
  var data = localStorageObject != null ? JSON.parse(localStorageObject) : null;
  let history = useHistory();
  useEffect(() => {

    if (data == null || window.location.pathname=="/") {
       
      history.push("/login")
      return
    }
  }, [])
  return (
    <>

    </>
  )
}
export default PrivateRote