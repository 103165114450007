import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import { Tab, Tabs } from 'react-bootstrap';
import Social from './public/Social';
import hat from "../assests/iCON/iCON/noun-student-cap-1441568.png"
import pencil from "../assests/iCON/iCON/noun-edit-684936.png"
import trophy from "../assests/iCON/iCON/noun-trophy-5126269.png"
import dummy from "../../view/assests/dummy.png"
import { Input, ModalFooter } from 'reactstrap';
import { RiUserFollowLine, RiStethoscopeFill } from "react-icons/ri"
import { MdMessage } from "react-icons/md"
import { TbFlag3 } from "react-icons/tb"
import { db } from '../../components/firebase'
import { onValue, ref } from "firebase/database";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import camera from "../../assets/iCON/Group 188.png"
import OtherUserProfileServices from '../services/OtherUserProfileServices';
import { FaGraduationCap } from "react-icons/fa"
import { API_BASE_URL } from '../../constant';
import Swal from "sweetalert2"
const badgeList = [
    {
        imageUrl: trophy,
        title: 'Bronze User',
        des: 'Learn new secrets to creating awesome Microsoft Access databases',
        tag: 'UNLOCK',
        per: '100',
    },
    {
        imageUrl: trophy,
        title: 'Platinum  User',
        des: 'Learn new secrets to creating awesome Microsoft Access databases',
        tag: '95 / 100',
        per: '95',
    },
    {
        imageUrl: trophy,
        title: 'Ultra Powered',
        des: 'Learn new secrets to creating awesome Microsoft Access databases',
        tag: '90 / 100',
        per: '90',
    },
    {
        imageUrl: trophy,
        title: 'Bronze User',
        des: 'Learn new secrets to creating awesome Microsoft Access databases',
        tag: '85 / 100',
        per: '85',
    },
];


const DoctorPofile = () => {

    useEffect(() => {
        SetOnlineStatus()
        getById()
    }, [])

    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    const { id } = useParams()
    const [follow, setFollow] = useState()
    const [block, setBlock] = useState()
    const [GetId, setGetId] = useState({
        firstName: "",
        lastName: "",
        doctorCoverPath: "",
        doctorImagePath: "",

    })
    async function getById() {

             
            let response = await OtherUserProfileServices.getStudentById(id, Studentdata.id, typeof Studentdata.doctorId == 'undefined' ? true : false);
             
            setFollow(response.data.isFollowed)
            setBlock(response.data.isBlocked)
            setGetId(response.data.payload);
      
    }

    const FollowBtn = async () => {
        // if (typeof Studentdata.doctorId == 'undefined') {
        //     var response = await OtherUserProfileServices.FollowStudent(Studentdata.id, id, typeof Studentdata.doctorId == 'undefined' ? true : false, !follow)
        //     Swal.fire(
        //         'Done',
        //         response.data.message,
        //         'success'
        //     )
        //     window.location.reload()
        // }
        // else {
             
        var response = await OtherUserProfileServices.FollowDoctor(Studentdata.id, id,  false, !follow)
        Swal.fire(
            'Done',
            response.data.message,
            'success'
        )
        window.location.reload()
        // }
    }
    const BlockBtn = async () => {
        // if (typeof Studentdata.doctorId == 'undefined') {
        //     var response = await OtherUserProfileServices.BlockStudent(Studentdata.id, id, typeof Studentdata.doctorId == 'undefined' ? true : false, !block)
        //     Swal.fire(
        //         'Done',
        //         response.data.message,
        //         'success'
        //     )
        //     window.location.reload()
        // }
        // else {
        var response = await OtherUserProfileServices.BlockDoctor(Studentdata.id, id, false, !block)
        Swal.fire(
            'Done',
            response.data.message,
            'success'
        )
        window.location.reload()
        // }
    }

    const [isOnline, setIsOnline] = useState(false);


    function SetOnlineStatus() {
        onValue(ref(db, 'onlineUsers/' + '116'), (snapshot) => {
            const data = snapshot.val();
            setIsOnline((data && data.status == "online") ? true : false)
        });
    }




    return (
        <>
            <Header />

            <div className="course-details pb-lg--7 pt-4 pb-5 single-user-profile">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="middle-sidebar-left">
                                <div className="card d-block w-100 border-0 rounded-lg overflow-hidden mb-3">
                                    <div
                                        className="card-body position-relative h200 bg-gradiant-bottom bg-image-cover bg-image-center"
                                        style={{
                                            backgroundImage: `url(${API_BASE_URL + GetId.coverPath})`,
                                        }}
                                    ></div>
                                    <div className="card-body d-block w-100 pl-lg-5 pl-4 pr-4 user-profile-color">
                                        <figure className="avatar mb-0 mt--6 position-relative w90 z-index-1" >

                                             <img alt=""
                                                height="85px"
                                                src={API_BASE_URL + GetId.imagePath}
                                                alt="Profile Picture"
                                                className="float-right p-1 bg-white rounded-circle w-100"
                                            />
                                        </figure>

                                        {block == false ? <span className='float-right'><button className='btn btn-danger ml-2' onClick={BlockBtn}>block</button></span> : <span className='float-right'><button className='btn btn-danger ml-2' onClick={BlockBtn}>Unblock</button></span>}
                                        {follow == false ? <span className='float-right'><button className='btn btn-success ml-2' onClick={FollowBtn}>Follow</button></span> : <span className='float-right'><button className='btn btn-success ml-2' onClick={FollowBtn}>UnFollow</button></span>}
                                        {block == true ? <span className='float-right'><button className='btn btn-primary' disabled>Message</button></span> : <span className='float-right'><button className='btn btn-primary'>Message</button></span>}
                                        <div className="clearfix"></div>
                                        <div className="row" style={{ marginLeft: "-60px" }}>
                                            <div className="col-xl-6 md-mb-2">
                                                <div>
                                                    <RiUserFollowLine fontSize="2rem" color="white" className="mr-2 ml-5" />
                                                    <MdMessage fontSize="2rem" color="white" className="ml-2 mr-2" />
                                                    <TbFlag3 fontSize="1.5rem" color="white" className="ml-5" />
                                                </div>
                                                <h4 className="mt-3 mb-1 text-white">
                                                    {GetId.firstName + " " + GetId.lastName}({isOnline ? "online" : "offline"})
                                                    {/*  <img alt="" src={hat} width="25px" className='ml-2' /> */}
                                                    <RiStethoscopeFill fontSize="1.5rem" className='ml-3' />

                                                </h4>
                                                <span className="font-xssss d-inline-block ml-0" style={{ color: "#72BB53" }} >
                                                    3rd Year
                                                </span>
                                            </div>
                                            <div className="col-11">
                                                <p className="font-xssss lh-28 text-grey-500 mb-0 pl-0 mt-4">
                                                    I have a Business Management degree from Bangalore
                                                    University and a long time Excel expert. I create
                                                    professional Excel reports/dashboards for clients
                                                    and conduct Excel workshops for business
                                                    professionals. Currently am a freelance motion
                                                    graphics artist and a Music producer. I like to be
                                                    productive and creative at work. I like to
                                                    continuously increase my skills and stay in tuned
                                                    with the technology industry.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Tabs
                                    defaultActiveKey="publications"
                                    id="uncontrolled-tab-example"
                                    className="mb-3 mt-5 ml-2 mr-3  justify-content-between border-0 rounded-lg profile-tabs"
                                    style={{ backgroundColor: "#E84545", borderBottom: 'none' }}
                                >
                                    <Tab eventKey="publications" title="Publications">

                                        <Social />
                                    </Tab>
                                    <Tab eventKey="trophies" title="Trophies">
                                        <div className=" d-block w-100 border-0 rounded-lg overflow-hidden ">
                                            <div className="card-body mb-lg-3 pb-0">
                                                <h2 className="fw-400 font-lg d-block text-white">
                                                    Achievement Trophies
                                                    <a
                                                        href="/default-user-profile"
                                                        className="float-right"
                                                    >
                                                    </a>
                                                </h2>
                                            </div>
                                            <div className="card-body pb-0">
                                                <div className="row">
                                                    {badgeList.map((value, index) => (
                                                        // Strat Single Demo
                                                        <div
                                                            className="col-xl-3 col-lg-3 col-md-3"
                                                            key={index}
                                                        >
                                                            <div className=" mb-4 d-block w-100  rounded-lg p-xxl-5 p-4 text-center" style={{ backgroundColor: "#000000", border: "1px solid white" }}>

                                                                <a
                                                                    href="/default-user-profile"
                                                                    className="btn-round-xxxl rounded-lg ml-auto mr-auto"
                                                                >
                                                                     <img alt=""
                                                                        src={value.imageUrl}
                                                                        alt="badge"
                                                                        className="w-100"
                                                                    />
                                                                </a>
                                                                <h4 className="fw-700 font-xsss mt-4" style={{ color: "#E84545" }}>
                                                                    {value.title}
                                                                </h4>
                                                                <p className="fw-500 font-xssss text-grey-500 mt-3">
                                                                    {value.des}
                                                                </p>

                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                        <div className=" d-block w-100 border-0 rounded-lg overflow-hidden ">
                                            <div className="card-body mb-lg-3 pb-0">
                                                <h2 className="fw-400 font-lg d-block text-white">
                                                    Social Trophies
                                                    <a
                                                        href="/default-user-profile"
                                                        className="float-right"
                                                    >
                                                    </a>
                                                </h2>
                                            </div>
                                            <div className="card-body pb-0">
                                                <div className="row">
                                                    {badgeList.map((value, index) => (
                                                        // Strat Single Demo
                                                        <div
                                                            className="col-xl-3 col-lg-3 col-md-3"
                                                            key={index}
                                                        >
                                                            <div className=" mb-4 d-block w-100  rounded-lg p-xxl-5 p-4 text-center" style={{ backgroundColor: "#000000", border: "1px solid white" }}>
                                                                <a
                                                                    href="/default-user-profile"
                                                                    className="btn-round-xxxl rounded-lg ml-auto mr-auto"
                                                                >
                                                                     <img alt=""
                                                                        src={value.imageUrl}
                                                                        alt="badge"
                                                                        className="w-100"
                                                                    />
                                                                </a>
                                                                <h4 className="fw-700 font-xsss mt-4" style={{ color: "#E84545" }}>
                                                                    {value.title}
                                                                </h4>
                                                                <p className="fw-500 font-xssss text-grey-500 mt-3">
                                                                    {value.des}
                                                                </p>

                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" d-block w-100 border-0 rounded-lg overflow-hidden ">
                                            <div className="card-body mb-lg-3 pb-0">
                                                <h2 className="fw-400 font-lg d-block text-white">
                                                    Content Creator Trophies
                                                    <a
                                                        href="/default-user-profile"
                                                        className="float-right"
                                                    >
                                                    </a>
                                                </h2>
                                            </div>
                                            <div className="card-body pb-0">
                                                <div className="row">
                                                    {badgeList.map((value, index) => (
                                                        // Strat Single Demo
                                                        <div
                                                            className="col-xl-3 col-lg-3 col-md-3"
                                                            key={index}
                                                        >
                                                            <div className=" mb-4 d-block w-100  rounded-lg p-xxl-5 p-4 text-center" style={{ backgroundColor: "#000000", border: "1px solid white" }}>
                                                                <a
                                                                    href="/default-user-profile"
                                                                    className="btn-round-xxxl rounded-lg ml-auto mr-auto"
                                                                >
                                                                     <img alt=""
                                                                        src={value.imageUrl}
                                                                        alt="badge"
                                                                        className="w-100"
                                                                    />
                                                                </a>
                                                                <h4 className="fw-700 font-xsss mt-4" style={{ color: "#E84545" }}>
                                                                    {value.title}
                                                                </h4>
                                                                <p className="fw-500 font-xssss text-grey-500 mt-3">
                                                                    {value.des}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="followers" title="Followers">
                                        <Input className='search-follower-inputField d-flex justify-content-center' placeholder='Search' />
                                        <div className='mt-5 social-search'>
                                            <ul className='social-search-list '>
                                                <li>  <img alt="" src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                                                <li>  <img alt="" src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                                                <li>  <img alt="" src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                                                <li>  <img alt="" src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                                                <li>  <img alt="" src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                                                <li>  <img alt="" src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                                                <li>  <img alt="" src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                                                <li>  <img alt="" src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                                                <li>  <img alt="" src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                                                <li>  <img alt="" src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                                                <li>  <img alt="" src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                                                <li>  <img alt="" src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>

                                            </ul>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="following" title="Following">
                                        <Input className='search-follower-inputField d-flex justify-content-center' placeholder='Search' />
                                        <div className='mt-5 social-search'>
                                            <ul className='social-search-list '>
                                                <li>  <img alt="" src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                                                <li>  <img alt="" src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                                                <li>  <img alt="" src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                                                <li>  <img alt="" src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                                                <li>  <img alt="" src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                                                <li>  <img alt="" src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                                                <li>  <img alt="" src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                                                <li>  <img alt="" src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                                                <li>  <img alt="" src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                                                <li>  <img alt="" src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                                                <li>  <img alt="" src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                                                <li>  <img alt="" src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>

                                            </ul>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="bookmarked" title="Bookmarked">
                                        <Social />
                                    </Tab>

                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default DoctorPofile