import React, { useState, useEffect } from 'react';
import EChartsReact from 'echarts-for-react';

const History = () => {
    const [exchangeData, setExchangeData] = useState([]);
    const [fromCurrency, setFromCurrency] = useState('');
    const [toCurrency, setToCurrency] = useState('');
    const [chartOption, setChartOption] = useState({});



    useEffect(() => {
        // Create chart data
        const newChartOption = {
            xAxis: {
                type: 'category',
                data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            },
            yAxis: {
                type: 'value',
            },
            series: [
                {
                    data: [toCurrency],
                    type: 'line',
                    label: {
                        show: true,
                        position: 'top',
                    },
                },
            ],
        };
        setChartOption(newChartOption);
    }, [exchangeData, fromCurrency, toCurrency]);

    return (
        <div className='pt-5' style={{ backgroundColor: "#181818", color: "white", minHeight: '100vh', alignItems: "center" }}>
            <h1 className='text-white'>MEDcoin to CHF value</h1>
            <div>
                <label>From:</label>
                <input
                    type="number"
                    className='form-control chapterApply'
                    value={fromCurrency}
                    onChange={(e) => setFromCurrency(e.target.value)}
                />
                <label>To:</label>
                <input
                    type="number"
                    className='form-control chapterApply'
                    value={toCurrency}
                    onChange={(e) => setToCurrency(e.target.value)}
                />
            </div>
            <div>
                <EChartsReact option={chartOption} style={{ height: '300px' }} />
            </div>
        </div>
    );
};

export default History;
