import Header from '../../../components/Header'
import React, { useState, useEffect } from 'react'
import { AdminMenuBar } from '../SideBar'
import { Row, Col, FormGroup, Input, Label, Select, CardBody, ModalBody, Modal } from "reactstrap"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AllCreation from "../admin-menu/content-admin-tabs/AllCreation"
import MyCreation from "../admin-menu/content-admin-tabs/MyCreation"

const ContentCreater = () => {

return (
  <>
    <Header />
    {/* <Modal
      isOpen={report}
      toggle={createReport.bind(null)}
      className="modal-tags"
      style={{ maxWidth: "350px" }}
    >
      <div style={{ margin: "20px" }}>
        <h3 style={{ color: "white" }}> <img alt="" src={reportIcon} width="20px" className='mr-2' />Report</h3>
        <MdOutlineClose className='tag-close-btn' color='white' onClick={() => setReport(false)} />
        <div className='add-tag-border'></div>
      </div>

      <ModalBody>
        <div>
          <h2 style={{ color: "white" }}>Inappropriate <b>20</b></h2>
          <h2 style={{ color: "white" }}>Misleading <b>4</b></h2>
          <h2 style={{ color: "white" }}>Incorrect Content <b>10</b></h2>
        </div>

      </ModalBody>

    </Modal> */}
    <div className='change-password pt-5' style={{ backgroundColor: "#181818", color: "white",minHeight: '100vh' }}>
      <Row>
        <Col md="1"></Col>
        <Col md="2">
          <AdminMenuBar />
        </Col>
        <Col md="8" style={{ marginTop: "-10px" }}>
          <Tabs
            defaultActiveKey="AllCreation"
            id="uncontrolled-tab-example"
            className="mb-3 mt-3 cohort-admin-tabs"
          >

            <Tab eventKey="MyCreation" title="All Creation">
            <AllCreation />
            </Tab>
            <Tab eventKey="AllCreation" title="My Creation">
              <MyCreation />
            </Tab>
          </Tabs>
        </Col>

      </Row>

    </div>
  </>
)
}

export default ContentCreater