import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../../../../constant";


export const topUserList = createAsyncThunk("user/topUser", async (object) => {

    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);

        var res = await fetch(API_BASE_URL + `api/Quiz/GetQuizWinners?SID=${Studentdata.id}&JWT=${Studentdata.jwt}`, {
        method: 'POST',
        headers: {
            Accept: "application/json",
            "content-type": "application/json"
        },
        body: JSON.stringify(object.data == undefined ? object : object.data)
    });
    var data = await res.json();
    if (data.status) {
        return (
            object.oldQuiz == undefined ? data.payload : object.oldQuiz.concat(data.payload)
        )
    }
    else {
        return [];
    }
})


export const radarData = createAsyncThunk("radar/radarData", async (object) => {

    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);

     debugger
    var res = await fetch(API_BASE_URL + `api/Quiz/GetQuizRadarData?SID=${Studentdata.id}&JWT=${Studentdata.jwt}`, {
        method: 'POST',
        headers: {
            Accept: "application/json",
            "content-type": "application/json"
        },
        body: JSON.stringify(object.data == undefined ? object : object.data)
    });
    var data = await res.json();
     
    if (data.status) {
        return (
            object.oldQuiz == undefined ? data.payload : object.oldQuiz.concat(data.payload)
        )
    }
    else {
        return [];
    }
})


const topUserSlice = createSlice(
    {
        name: "topUser",
        initialState:
        {
            dates: {
                startDate: new Date(),
                endDate: new Date(),
            },
            topUser: [],
            radarData: {
                chapters: [],
                points: [0],
                percentage: [0]
            },
            loading: false
        },
        reducers: {
            updateQuiz: (state, action) => {
                state.topUser = action.payload;
            },
            updateDates: (state, action) => {
                state.dates = action.payload;
            },
        },
        extraReducers: {
            [topUserList.pending]: (state, action) => {
                state.loading = true;
            },
            [topUserList.fulfilled]: (state, action) => {
                state.loading = false;
                state.topUser = action.payload
            },
            [radarData.pending]: (state, action) => {
                state.loading = true;
            },
            [radarData.fulfilled]: (state, action) => {
                state.loading = false;
                state.radarData = action.payload
            }


        }

    })


export const { updateTopUser, updateDates } = topUserSlice.actions;

export default topUserSlice.reducer;