import { configureStore } from '@reduxjs/toolkit'
import QuizSlice from './features/QuizSlice'
import TopUserSlice from '../view/quiz/QuizResult/store/features/TopUserSlice'
import StudySlice from '../view/study/redux/features/StudySlice'
import SocialSlice from '../view/social-feed/redux/feature/SocialSlice'
import AdminSlice from '../view/settings/redux/features/AdminSlice'
export default configureStore({
  reducer: {
    quiz:QuizSlice,
    topUser:TopUserSlice,
    study:StudySlice,
    social:SocialSlice,
    Admin:AdminSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
