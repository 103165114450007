import React from 'react'
import { Row, Col } from 'reactstrap'
import { BsFillPlusCircleFill, BsCardImage } from "react-icons/bs"
import { FiYoutube } from "react-icons/fi"
import { AiOutlineAudio } from "react-icons/ai"
import { useState } from 'react'
import * as AWS from "aws-sdk";
import awsConfig from "../../awsConfi";
import Switch from "react-switch";

export var ImageFileUrl = "";
export var VideoFileUrl = "";
export var AudioFileUrl = "";
export const CreateQuizContent = (props) => {
    const [media, setMedia] = useState({
        imageUrl: "",
        audioUrl: "",
        videoUrl: "",
        contentType: ""
    })
    const [inputField, setInputField] = useState({
        imageUrl: "",
        audioUrl: "",
        videoUrl: "",
        contentType: ""
    })

    const inputsHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value })
        props.sendData(inputField)
    }
    const [retry, setRetry] = useState("none");
    const [btntxt, setBtntxt] = useState("ADD");
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [videoloading, setVideoLoading] = useState(0);
    const [audioloading, setAudioLoading] = useState(0);

    const BUCKET_NAME = awsConfig.bucketName;
    const FOLDER_NAME = awsConfig.folderName;
    const generateUUID = () => {
        var d = new Date().getTime();
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16;
            if (d > 0) {
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }
    function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }
    function replaceAll(str, match, replacement) {
        return str.replace(new RegExp(escapeRegExp(match), 'g'), () => replacement);
    }

    const ImageUploadOnAws = async (video) => {
        setBtntxt("Uploading Video...");
        setLoadingProgress(0);
        var guid = generateUUID();
        var fileName = guid + '_' + replaceAll(video.name, " ", "_");
         ;
        let bucket = new AWS.S3({
            params: { Bucket: BUCKET_NAME }
        });

        let params = {
            Key: "images" + "/" + fileName,
            ContentType: video.type,
            Body: video
        };

        bucket
            .upload(params)
            .on("httpUploadProgress", evt => {
                let progress = parseInt((evt.loaded * 100) / evt.total);
                console.log(progress);
                setLoadingProgress(progress);
            })
            .send(err => {
                 
                if (err == null) {
                    console.log(`File Upload Successfuly`);
                    // setDisable(false);
                    media.imageUrl = awsConfig.AWS_Base_URL+params.Key
                    console.log(params);
                    setBtntxt("ADD");
                    console.log("ImageFileUrl", ImageFileUrl)
                }
                else {
                    console.log(err)
                    setLoadingProgress(0);
                    setBtntxt("Error Uploading Video");
                    setRetry("block");
                }

            });
        props.sendData(media)
    }

    const VideoUploadOnAws = async (video) => {
        setBtntxt("Uploading Video...");
        setLoadingProgress(0);
        var guid = generateUUID();
        var fileName = guid + '_' + replaceAll(video.name, " ", "_");
         ;
        let bucket = new AWS.S3({
            params: { Bucket: BUCKET_NAME }
        });

        let params = {
            Key: "videos" + "/" + fileName,
            ContentType: video.type,
            Body: video
        };

        bucket
            .upload(params)
            .on("httpUploadProgress", evt => {
                let progress = parseInt((evt.loaded * 100) / evt.total);
                console.log(progress);
                setVideoLoading(progress);
            })
            .send(err => {
                 
                if (err == null) {
                    console.log(`File Upload Successfuly`);
                    // setDisable(false);
                    media.videoUrl = awsConfig.AWS_Base_URL+params.Key
                    console.log(params.Key);
                    setBtntxt("ADD");
                }
                else {
                    console.log(err)
                    setVideoLoading(0);
                    setBtntxt("Error Uploading Video");
                    setRetry("block");
                }

            });
        props.sendData(media)
    }



    const AudioUploadOnAws = async (video) => {
        setBtntxt("Uploading Video...");
        setLoadingProgress(0);
        var guid = generateUUID();
        var fileName = guid + '_' + replaceAll(video.name, " ", "_");
         ;
        let bucket = new AWS.S3({
            params: { Bucket: BUCKET_NAME }
        });

        let params = {
            Key: "audios" + "/" + fileName,
            ContentType: video.type,
            Body: video
        };

        bucket
            .upload(params)
            .on("httpUploadProgress", evt => {
                let progress = parseInt((evt.loaded * 100) / evt.total);
                console.log(progress);
                setAudioLoading(progress);
            })
            .send(err => {
                 
                if (err == null) {
                    console.log(`File Upload Successfuly`);
                    // setDisable(false);
                    debugger
                    media.audioUrl = awsConfig.AWS_Base_URL+params.Key
                    setBtntxt("ADD");
                }
                else {
                    console.log(err)
                    setAudioLoading(0);
                    setBtntxt("Error Uploading Video");
                    setRetry("block");
                }

            });
            debugger
        props.sendData(media)
    }

    // const onClick = () => {
    //     props.sendData(media)
    //   }
    const ImageRetry = () => {
        setRetry("none");
        // ImageUploadOnAws(lessonFile);
    }
    const VideoRetry = () => {
        setRetry("none");
        // ImageUploadOnAws(lessonFile);
    }


    const changeImage = (event) => {
        const imageFile = event.target.files[0];
        ImageUploadOnAws(imageFile);
        debugger
    }
    const changeVideo = (event) => {
        const videoFile = event.target.files[0];
        VideoUploadOnAws(videoFile);
    }
    const changeAudio = (event) => {
        const audioUrl = event.target.files[0];
        AudioUploadOnAws(audioUrl)
        setMedia(audioUrl)
    }
    const [contentType, setContentType] = useState(false);
    const handleWorldwide = nextChecked => {
        setContentType(nextChecked);
        inputField.contentType = nextChecked
        media.contentType = nextChecked
         
        props.sendData(media)

    };
    const inputImgRef = React.useRef();
    const onImgClick = () => {
        /*Collecting node-element and performing click*/
        inputImgRef.current.click();
    };
    const inputVideoRef = React.useRef();
    const onVideoClick = () => {
        /*Collecting node-element and performing click*/
        inputVideoRef.current.click();
    };

    const inputAudioRef = React.useRef();
    const onAudioClick = () => {
        /*Collecting node-element and performing click*/
        inputAudioRef.current.click();
    };


    return (
        <div>

            <div className="mt-3 mb-3" >
                <label title="Free/Premium">Free </label>
                <Switch
                title="Free/Premium"
                    onChange={handleWorldwide}
                    checked={contentType}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onColor="#E84545"
                    height={15}
                    width={35}
                    className="mt-3 ml-3 mr-3"
                />
                <label title="Free/Premium"style={{ color: "#E84545" }}>Premium </label>

            </div>

            <Row className=" mb-3 d-flex justify-content-between">

                <Col md="3" className="upload-quiz" htmlFor="image" style={{ cursor: 'pointer' }} onClick={onImgClick}>

                    <p className='ml-5'> <span className='text-white'>Image</span> <span style={{ color: "red" }}> ({loadingProgress}%)</span><span style={{ color: "red", display: retry }}> - Upload Failed <u onClick={ImageRetry} style={{ color: "blue", cursor: "pointer" }}>(Retry)</u></span>  </p>
                    <BsCardImage htmlFor="image" className="input-icons mb-2" fontSize="2.3em" color="white" /> <br></br>
                    <label htmlFor="image">Upload Image</label>
                    <input type="file" ref={inputImgRef} id="image" accept="image/*" onChange={changeImage} hidden />

                </Col>

                <Col md="3" className="upload-quiz" htmlFor="video" style={{ cursor: 'pointer' }} onClick={onVideoClick}>
                    <p className='ml-5'> <span className='text-white'>Video</span> <span style={{ color: "red" }}> ({videoloading}%)</span><span style={{ color: "red", display: retry }}> - Upload Failed <u onClick={VideoRetry} style={{ color: "blue", cursor: "pointer" }}>(Retry)</u></span>  </p>
                    <FiYoutube className="input-icons mb-2" fontSize="2.3em" color="white" /><br></br>
                    <label htmlFor="video" >Upload Video</label>
                    <input type="file" ref={inputVideoRef} id='video' accept=" video/*" onChange={changeVideo} hidden />
                </Col>

                <Col md="3" className="upload-quiz" onClick={onAudioClick} style={{ cursor: 'pointer' }}>
                    <p className='ml-5'> <span className='text-white'>Video</span> <span style={{ color: "red" }}> ({audioloading}%)</span><span style={{ color: "red", display: retry }}> - Upload Failed <u onClick={VideoRetry} style={{ color: "blue", cursor: "pointer" }}>(Retry)</u></span>  </p>
                    <AiOutlineAudio className="input-icons mb-2" fontSize="2.3em" color="white" /><br></br>
                    <label htmlFor="audio">Upload Audio</label>
                    <input type="file" ref={inputAudioRef} accept=" audio/*" id='audio' onChange={changeAudio} hidden />
                </Col>
            </Row>
            <Row className='d-flex justify-content-between text-white'>
                <Col md="1"></Col>
                <Col md="2">
                    Or
                </Col>
                <Col md="2"></Col>
                <Col md="2">
                    Or
                </Col>
                <Col md="2"></Col>
                <Col md="2">
                    Or
                </Col>
            </Row>
            <Row className='d-flex justify-content-between'>

                <Col md="3" className="" >
                    <input type="text" className='form-control' name='imageUrl' value={inputField.imageUrl} placeholder='Type URL' onChange={inputsHandler} />
                </Col>
                <Col md="3" className="">
                    <input type="text" className='form-control' name='videoUrl' value={inputField.videoUrl} placeholder='Type URL' onChange={inputsHandler} />
                </Col>
                <Col md="3" className="" >
                    <input type="text" className='form-control' name='audioUrl' value={inputField.audioUrl} placeholder='Type URL' onChange={inputsHandler} />
                </Col>
            </Row>
        </div>
    )
}

