import React, { useState, useEffect } from 'react'
import { Row, Col, FormGroup } from "reactstrap"
import {MdPreview } from "react-icons/md"
import AllFilterServices from '../../../services/AllFilterServices'
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css"
import { GetContentList } from '../../redux/features/AdminSlice'
import { useSelector, useDispatch } from 'react-redux'
import AdminServices from '../../../services/AdminServices'
import { SessionLogoutMsg } from '../../../../constant'
import { useHistory } from 'react-router-dom';
import QuizModal from '../modals/QuizModal';
import VideoModal from '../modals/VideoModal';
import FlashCardsModal from '../modals/FlashCardsModal';
import DocumentModal from "../modals/DocumentModal"
import { MdDeleteOutline } from "react-icons/md";
import QuizServices from '../../../services/QuizServices';
import Swal from 'sweetalert2';


const MyCreation = () => {
    const dispatch = useDispatch()

    const MyCreationList = useSelector(state => state.Admin.ContentCreatorList)
    const [selectedCountry, setSelectedCountry] = useState(null)
    const [selectedUniversity, setSelectedUniversity] = useState(null)
    const [selectedLevel, setSelectedLevel] = useState(null)
    const [selectedBranch, setSelectedBranch] = useState(null)
    const [selectedChapter, setSelectedChapter] = useState(null)
    const [selectedSubChapter, setSelectedSubChapter] = useState(null)

    const [selectedContent, setSelectedContent] = useState(null)
    const [selectedStatus, setSelectedStatus] = useState(null)
    const [selectedYear, setSelectedYear] = useState(null)
    const [openQuizModal, setOpenQuizModal] = useState(false)
    const [openVideoModal, setOpenVideoModal] = useState(false)
    const [openFlashCardModal, setOpenFlashCardModal] = useState(false)
    const [openDocumentModal, setOpenDocumentModal] = useState(false)
    const [quizId,setQuiz] =useState(null)
    const QuizModalFunction = (e, id, contentType) => {
         
        if (contentType == "QUIZ") {
            setOpenQuizModal(true)
            setOpenVideoModal(false)
            setOpenFlashCardModal(false)
            setOpenDocumentModal(false)

        }
        if (contentType == "VIDEO") {
            setOpenVideoModal(true)
            setOpenQuizModal(false)
            setOpenFlashCardModal(false)
            setOpenDocumentModal(false)
        }
        if (contentType == "FLASHCARD") {
            setOpenFlashCardModal(true)
            setOpenVideoModal(false)
            setOpenQuizModal(false)
            setOpenDocumentModal(false)
        }
        if (contentType == "DOCUMENT") {
            setOpenDocumentModal(true)
            setOpenFlashCardModal(false)
            setOpenVideoModal(false)
            setOpenQuizModal(false)
        }
        setQuiz(id)
    }
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    var data = JSON.parse(localStorageObject);

    var SelectedFilterData = {
        userId: data.id,
        branchId: selectedBranch,
        chapterId: selectedChapter,
        subChapterId: selectedSubChapter,
        CountryId: selectedCountry,
        UniversityId: selectedUniversity,
        LevelId: selectedLevel,
        contentType: selectedContent,
        status: selectedStatus,
        year: selectedYear
    }


    const [level, setLevel] = useState([]);
    const [countryList, setCountryList] = useState([]);
    let history = useHistory()
    useEffect(async () => {
         
        var response = await AllFilterServices.getAllCountries()
        // setCountryList(response.data.payload);
        if (response.data.message == SessionLogoutMsg) {
            history.push("/login")
            localStorage.removeItem('Exe-Med_Student_Info');
          }
          else {
            setCountryList(response.data.payload);
          }
          dispatch(GetContentList(SelectedFilterData))
    }, [])
    const [universityList, setUniversityList] = useState([]);

    const [branchs, setBranch] = useState([]);
    const [chapter, setChapter] = useState([]);

    const GetContentType = (e) => {
        setSelectedContent(e.target.value)
        SelectedFilterData.contentType = e.target.value
        dispatch(GetContentList(SelectedFilterData))
    }
    const GetStatus = (e) => {
        setSelectedStatus(e.target.value)
        SelectedFilterData.status = e.target.value
        dispatch(GetContentList(SelectedFilterData))
    }
    const GetYear = (e) => {
        setSelectedYear(e.target.value)
        SelectedFilterData.year = e.target.value
        dispatch(GetContentList(SelectedFilterData))
    }
    const [subChapter, setSubChapter] = useState([])
    const GetChapters = async (e) => {
         
        var response = await AdminServices.GetSubChaptersByChapterId(e.target.value)
        setSubChapter(response.data.payload)
        setSelectedChapter(e.target.value)
        SelectedFilterData.chapterId = e.target.value;
        dispatch(GetContentList(SelectedFilterData))
    }
    const GetSubChapters = (e) => {
        setSelectedSubChapter(e.target.value)
        SelectedFilterData.subChapterId = e.target.value;
        dispatch(GetContentList(SelectedFilterData))
    }
    
    const GetCountry = async (e) => {
        var response = await AdminServices.GetUniversitiesByCountryId(e.target.value)
        setUniversityList(response.data.payload)
        setSelectedCountry(e.target.value)
        SelectedFilterData.CountryId = e.target.value;
        dispatch(GetContentList(SelectedFilterData))
    }
    const GetUniversity = async (e) => {
         
        var response = await AdminServices.GetLevelsByUniversityId(e.target.value)
        setLevel(response.data.payload)
        setSelectedUniversity(e.target.value)
        SelectedFilterData.UniversityId = e.target.value;
        dispatch(GetContentList(SelectedFilterData))
    }
    const GetLevel = async (e) => {

        var response = await AdminServices.GetBranchesByLevelId(e.target.value)
        setBranch(response.data.payload)
        setSelectedLevel(e.target.value)
        SelectedFilterData.LevelId = e.target.value;
        dispatch(GetContentList(SelectedFilterData))
    }
    const GetBranch = async (e) => {
        var response = await AdminServices.GetChaptersByBranchId(e.target.value)
        setChapter(response.data.payload)
        setSelectedBranch(e.target.value)
        SelectedFilterData.branchId = e.target.value;
        dispatch(GetContentList(SelectedFilterData))
    }

    // const GetChapters = async (e) => {
    //      
    //     setSelectedChapter(e.target.value)
    //     SelectedFilterData.chapterId = e.target.value;
    //     dispatch(GetContentList(SelectedFilterData))
    // }
    const DeleteQuiz = async (id) => {
        const res = await QuizServices.DeleteQuestionById(id)
        Swal.fire(" Deleted!", "" + res.data.message, "success");
        dispatch(GetContentList(SelectedFilterData))
    }


    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 10;
    const years = Array.from({ length: 11 }, (_, index) => startYear + index).reverse();

    return (
        <>
     {openQuizModal ? <QuizModal quizId={quizId} openModal={openQuizModal} /> : ""}
            {openVideoModal ? <VideoModal quizId={quizId} openModal={openVideoModal} /> : ""}
            {openFlashCardModal ? <FlashCardsModal quizId={quizId} openModal={openFlashCardModal} /> : ""}
            {openDocumentModal ? <DocumentModal quizId={quizId} openModal={openDocumentModal} /> : ""}
            <Row>

                <Row >
                    <Col md="4">
                        <select className='form-control chapterApply' onChange={GetCountry}>
                            <option value="">-- Select Country --</option>
                            {countryList.map((value) => (
                                <option value={value.id}>{value.name}</option>
                            ))}
                        </select>

                    </Col>
                    <Col md="4">
                        <select className='form-control chapterApply' onChange={GetUniversity}>
                            <option value="">-- Select University --</option>
                            {universityList.map((value) => (
                                <option value={value.id}>{value.name}</option>
                            ))}
                        </select>
                    </Col>
                    <Col md="4">
                        <select className='form-control chapterApply' onChange={GetLevel}>
                            <option value="">-- Select Year --</option>
                            {level.map((value) => (
                                <option value={value.id}>{value.title}</option>
                            ))}
                        </select>

                    </Col>
                    <Col md="4">
                        <select className='form-control chapterApply' onChange={GetBranch}>
                            <option value="">-- Select Branch --</option>
                            {branchs.map((value) => (
                                <option value={value.id}>{value.title}</option>
                            ))}
                        </select>
                    </Col>
                    <Col md="4">
                        <select className='form-control chapterApply' onChange={GetChapters}>
                            <option value="">-- Select Chapter --</option>
                            {chapter.map((value) => (
                                <option value={value.id}>{value.title}</option>
                            ))}
                        </select>
                    </Col>

                    <Col md="4">
                        <select className='form-control chapterApply' onChange={GetSubChapters}>
                            <option value="">-- Select SubChapter --</option>
                            {subChapter.map((value, index) => (
                                <option key={index} value={value.id}>{value.title}</option>
                            ))}
                        </select>
                    </Col>


                    <Col md="4">
                        <select className='form-control chapterApply' onChange={GetContentType} >
                            <option value="">-- Select Content Type --</option>
                            <option value="QUIZ">Quiz</option>
                            <option value="VIDEO">Video</option>
                            <option value="DOCUMENT">Document</option>
                            <option value="FLASHCARD">Flashcard</option>
                        </select>
                    </Col>
                    <Col md="4">
                        <select className='form-control chapterApply' >
                            <option value="">-- Select Visibility --</option>
                        </select>
                    </Col>
                    <Col md="4">
                        <select className='form-control chapterApply' onChange={GetYear}>
                            <option value="">-- Select Date Range --</option>
                            {years.map((value, index) => {
                                return (
                                    <option key={index} value={value}>{value}</option>
                                )
                            })}
                        </select>
                    </Col>
                    <Col md="4">
                        <select className='form-control chapterApply' >
                            <option value="">-- Select Creator --</option>
                        </select>
                    </Col>
                </Row>
                <Col md="12" className='mt-5'>
                    <ReactTable
                        style={{ fontSize: "12px",textAlign:"center" }}
                        columns={[
                            {
                                Header: 'No.',
                                Cell: (row) => {
                                  return <div>{row.index + 1}</div>;
                                },
                              },
                            {
                                Header: "Date of Creation",
                                accessor: "creationDate",
                            },
                            {
                                Header: "Preview",
                                accessor: "likeRate",
                                Cell: (row) => {
                                    return (

                                        <div>
                                            <MdPreview fontSize='1.5rem' color='#E84545' onClick={(event) => { QuizModalFunction(event, MyCreationList[row.index].id, MyCreationList[row.index].contentType) }} />
                                        </div>

                                    )
                                }
                            },

                            {
                                Header: "Total View",
                                accessor: "totalViews",
                            },

                            {
                                Header: "Total Likes",
                                accessor: "totalLikes",
                            },

                            {
                                Header: "Like Rate",
                                accessor: "likeRate",
                                Cell: (row) => {
                                    return (

                                        <div>
                                            {MyCreationList[row.index].likeThisYear - MyCreationList[row.index].likeLastYear}
                                        </div>

                                    )
                                }
                            },
                            {
                                Header: "Like to view ratio (%)",
                                accessor: "#",
                            },
                            {
                                Header: "Buzz Factor",
                                accessor: "buzzFactor",
                                Cell: (row) => {
                                    return (

                                        <div>
                                            {MyCreationList[row.index].likeThisYear - ((2 * MyCreationList[row.index].likeLastYear) + MyCreationList[row.index].likeYearBeforeLastYear)}
                                        </div>

                                    )
                                }

                            },
                            {
                                Header: "Total Flags",
                                accessor: "totalFlags",
                            },
                            {
                                Header: "Flag to View ratio (%)",
                                accessor: "flagViewRatio",
                            },

                            {
                                Header: "Date last flags",
                                accessor: "lastFlagDate",
                            },
                            {
                                Header: "Flag report",
                                accessor: "#",
                            },
                            {
                                Header: "MEDcoin",
                                accessor: "medCoins",
                            },
                            {
                                Header: "Delete",
                                accessor: "medCoins",
                                Cell: (row) => {
                                    return (
                                        <div>
                                            <MdDeleteOutline fontSize='1.5rem' color='red' onClick={() => { DeleteQuiz(MyCreationList[row.index].id) }} />
                                        </div>

                                    )
                                }
                            },

                        ]}
                        defaultPageSize={5}
                        // showPaginationBottom={true}
                        className="-striped -highlight"
                        data={MyCreationList}
                    // filterable
                    // defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}

                    />
                </Col>
            </Row>
        </>
    )
}

export default MyCreation