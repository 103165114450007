import React, { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import AllFilterServices from '../services/AllFilterServices'
import { useHistory } from 'react-router-dom'
import { brown } from '@material-ui/core/colors'

export var LevelId = []
export var BranchId = []
export var ChapterId = []
export var LanguageId = []
export var SubChapterId = []

export const DropdownMenuList = (props) => {

    const [levelList, setLevelList] = useState([])
    const [branchs, setBranch] = useState([]);
    const [lanaguage, setLanguage] = useState([]);
    const [changeChapter, setChangeChapter] = useState([]);
    const [changeSubChapter, setSubChangeChapter] = useState([]);
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    var data = JSON.parse(localStorageObject);
    const LevelList = async () => {
        var response = await AllFilterServices.getAllIntrests(data.universityId)
        setLevelList(response.data.payload);

    }
    const BranchList = async (levelId) => {
        var startDate = '';
        var endDate = '';
        var response = await AllFilterServices.getAllBranches(startDate, endDate, levelId)
        setBranch(response.data.payload);
    }

    const LanguageList = async () => {
        var response = await AllFilterServices.getAllLanguage()
        setLanguage(response.data.payload);
    }
    useEffect(async () => {
        LevelList()
        LanguageList()

    }, [])
    const [inputField, setInputField] = useState({
        LevelId: 0,
        BranchId: 0,
        ChapterId: 0,
        SubChapterId: 0,
        languageId: 0

    })
    const LanguageChange = (e) => {
        inputField.languageId = e.target.value
        LanguageId.push(e.target.value)
        props.sendDropdownList(inputField)
    }
    const LevelChange = (e) => {
        inputField.LevelId = e.target.value
        LevelId.push(e.target.value)

        props.sendDropdownList(inputField)
        BranchList(e.target.value)
        setChangeChapter([])
        setSubChangeChapter([])

    }
    const BranchChange = async (e) => {

        inputField.BranchId = e.target.value;
        BranchId.push(e.target.value)
        var startDate = '';
        var endDate = '';
        var response = await AllFilterServices.GetAllChaptersByBranchIds([e.target.value], startDate, endDate);
        setChangeChapter(response.data.payload)

    }

    const ChapterChange = async (e) => {
        inputField.ChapterId = e.target.value
        ChapterId.push(e.target.value)
        props.sendDropdownList(inputField)
        var startDate = '';
        var endDate = '';
        var response = await AllFilterServices.GetAllSubChaptersByChapterIds([e.target.value], startDate, endDate);
        setSubChangeChapter(response.data.payload)
    }

    const SubChapterChange = (e) => {
        inputField.SubChapterId = e.target.value
        SubChapterId.push(e.target.value)
        props.sendDropdownList(inputField)

    }

    return (
        <div>
            <h2>Target Audience</h2>
            <Row>
                <Col md="6">
                    <select onChange={LevelChange} className="form-control" >
                        <option value=''>--Select Level--</option>
                        {levelList?.map((value, index) => (
                            <option key={index} value={value.id}>{value.title}</option>
                        ))}
                    </select>
                </Col>
                <Col md="6">
                    <select onChange={LanguageChange} className="form-control" >
                        <option value='' >--Select Language--</option>
                        {lanaguage?.map((value, index) => (
                            <option key={index} value={value.id}>{value.name}</option>
                        ))}
                    </select>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md="4">
                    <select onChange={BranchChange} className="form-control" >
                        <option value='' >--Select Branch--</option>
                        {branchs?.map((value, index) => (
                            <option key={index} value={value.id}>{value.title}</option>
                        ))}
                    </select>
                </Col>
                <Col md="4">
                    <select placeholder="Chapter" className="form-control" onChange={ChapterChange} >
                        <option value='' >--Select Chapter--</option>
                        {changeChapter?.map((value, index) => (
                            <option key={index} value={value.id}>{value.title}</option>
                        ))}
                    </select>
                </Col>
                <Col md="4">
                    <select onChange={SubChapterChange} className="form-control" >
                        <option value='' >--Select Sub-Chapter--</option>
                        {changeSubChapter?.map((value, index) => (
                            <option key={index} value={value.id}>{value.title}</option>
                        ))}
                    </select>
                </Col>


            </Row></div>
    )
}

// export default DropdownMenuList