import React, { useState } from 'react'
import { Row, Col, FormGroup, Input, Label, Select } from "reactstrap"
import Header from '../../../components/Header'
import { AdminMenuBar } from '../SideBar'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import country from "../../../view/assests/iCON/noun-country-2450449.png"
import CountryandLanguage from './super-admin-tabs/CountryandLanguage';
import CountryAdmin from './super-admin-tabs/CountryAdmin';
import Monetisation from './super-admin-tabs/Monetisation';
const SuperAdmin = () => {

    return (
        <>
            <Header />
            <div className='change-password pt-5' style={{ backgroundColor: "#181818", color: "white",minHeight: '100vh' }}>
                <Row>
                    <Col md="1"></Col>
                    <Col md="2">
                        <AdminMenuBar />
                    </Col>
                    <Col md="8" style={{ marginTop: "-30px" }}>
                        <Tabs
                            defaultActiveKey="Country"
                            id="uncontrolled-tab-example"
                            className="mb-3 mt-3 super-admin-tabs"
                        >
                            <Tab eventKey="Country" title="Country & Language">
                                <CountryandLanguage />
                            </Tab>
                            <Tab eventKey="CountryAdmin" title="Country Admin" >
                                <CountryAdmin />
                            </Tab>
                            <Tab eventKey="Monetisation" title="Monetisation" >
                                <Monetisation />
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>

            </div>
        </>
    )
}

export default SuperAdmin