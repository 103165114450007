import React, { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import QuizServices from '../../../services/QuizServices';
import dimand from "../../../assests/iCON/iCON/noun-diamond-1807567.png";
import hand from "../../../assests/iCON/iCON/noun-heart-3515260.png";
import { Link } from 'react-router-dom';
import moment from "moment"
import dummy from "../../../assests/dummy.png"
import user from "../../../assests/iCON/iCON/noun-friend-2531936.png";
import StudyMaterialServices from '../../../services/StudyMaterialServices';
import FileViewer from 'react-file-viewer';
import { API_BASE_URL } from '../../../../constant';
const DocumentModal = (props) => {

  const { openModal, quizId } = props

  const [value, setValue] = useState({})
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  }
  useEffect(() => {
    GetVideoMaterialById()
    setModal(openModal)
  }, [quizId])
  const GetVideoMaterialById = async () => {
     
    const res = await StudyMaterialServices.GetStudyMaterialById(quizId)
    console.log("quiz", res.data.payload)
    setValue(res.data.payload)
  }
  return (
    <>
      <Modal isOpen={modal} toggle={toggle} style={{ backgroundColor: "#181818" }}  >
        <ModalHeader style={{ backgroundColor: "#181818" }} toggle={toggle}><h5 style={{ color: "white" }}>Video Preview</h5></ModalHeader>
        <ModalBody style={{ backgroundColor: "#1D2226" }}>
          <div className='social'>
            <div className=" w-100 border-0 rounded-lg overflow-hidden mr-1 text-grey-900 shadow-lg  mb-5 rounded">
              <div className="card-image w-100 mb-3"
              >
                <Link to={`/doctor-profile/${2}`}><span> <img src={dummy} alt="img" className='mt-2 ml-1' style={{ width: "50px", height: "50px" }} /> </span>
                  <span>Amelia Jeannet <p className='user-des'>3Rd Y MS, University Of Geneva</p></span></Link>
                <Link to="/single-user-profile">  <img src={user} alt="img" className="float-right mr-2 addUser" width="40px" /></Link>
                <div style={{ height: "300px" }}>
                  <iframe src={value.fileUrl?.startsWith("https:") ? value.fileUrl : API_BASE_URL + value.fileUrl} className="w-100 h-100"></iframe>
                  <FileViewer
                    // fileType=".docx"
                    filePath={value.fileUrl?.startsWith("http") ? value.fileUrl : API_BASE_URL + value.fileUrl} />
                </div>              
              </div>

            </div>
          </div>
        </ModalBody>

      </Modal>
    </>
  )
}

export default DocumentModal