import React, { useState } from 'react'
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md"
import calenderImg from "../../../../assets/iCON/noun-calendar-5143299.png"
import { DateRange } from 'react-date-range';
import { useDispatch, useSelector } from "react-redux";
import { radarData, topUserList } from "../../QuizResult/store/features/TopUserSlice"
const Calender = () => {
  const calenderDates = useSelector(state => state.topUser.dates)

  const dispatch = useDispatch();
  const [calendar, setCalander] = useState(false)
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
    }
  ]);
  function chnageDate(item) {
     
    setState([item.range1])
    dispatch(topUserList({
      branchId: [],
      chapterId: [],
      subChapterId: [],
      LanguageId: [],
      CountryId: [],
      UniversityId: [],
      LevelId: [],
      Bookmarked: null,
      NeverAttempted: null,
      Mistakes: null,
      startDate: item.range1.startDate,
      endDate: item.range1.endDate
    }))
    dispatch(radarData({
      branchId: [],
      chapterId: [],
      subChapterId: [],
      LanguageId: [],
      CountryId: [],
      UniversityId: [],
      LevelId: [],
      Bookmarked: null,
      NeverAttempted: null,
      Mistakes: null,
      startDate: item.range1.startDate,
      endDate: item.range1.endDate
    }))

  }
  return (
    <div>
      <div className="form-group mb-1 filter-margin" onClick={() => setCalander(!calendar)}>
        {calendar ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
           <img src={calenderImg} alt="Calender" className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Calender</h3>
          {calendar ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
        </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
           <img src={calenderImg} alt="Calender" className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Calender</h3>
          {calendar ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
        </a>} 
      </div>
      {calendar && <div className="form-group mb-1 sub-menu-filter mb-3 mr-3 w-100">

        <DateRange
          editableDateInputs={true}
          onChange={chnageDate}
          moveRangeOnFirstSelection={false}
          ranges={[
            {
              startDate: new Date(calenderDates.startDate),
              endDate: new Date(calenderDates.endDate),
            }
          ]}
        />
      </div>}</div>
  )
}

export default Calender