import React, { useState } from 'react'
import { Modal, ModalHeader, ModalFooter, Button, ModalBody, Input, Row, Col } from "reactstrap"
import like from "../assests/iCON/iCON/noun-like-1122328.png"
import comments from "../assests/iCON/iCON/noun-comment-3266599.png"
import share from "../assests/iCON/iCON/noun-share-5060531.png"
import flag from "../assests/iCON/iCON/noun-flag-1017687.png"
import { MdOutlineClose } from "react-icons/md"
import { Link, useHistory } from 'react-router-dom';
import medical from "../../assets/images/demo/medical.png"
import dummy from "../assests/dummy.png"
import save from "../assests/iCON/iCON/noun-bookmark-3025188.png"
import { API_BASE_URL } from '../../constant'
import flagRed from "../assests/iCON/iCON/noun-flagRed-1017687.png"
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai"
import { BsBookmark } from "react-icons/bs"
import SocialServices from '../services/SocialServices';
import { useSelector, useDispatch } from 'react-redux';
import { GetAllSocailPost, updatePost } from './redux/feature/SocialSlice';
import reportIcon from "../assests/iCON/iCON/noun-alert-5119017.png"
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import moment from 'moment'


const SocialCommentModal = (props) => {
    const postId = props.singlePost;
    const postList = useSelector(state => state.social.allPost)
    var tempSinglePost = JSON.parse(JSON.stringify(postList));
    var signlePost = tempSinglePost.find(x => x.id == postId)
    const dispatch = useDispatch()
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let data = JSON.parse(localStorageObject);

    const [commentModal, setCommentModal] = useState(true)
    const CommentModal = () => {
        setCommentModal(!commentModal)
    }
    const CloseModal = () => {

        setCommentModal(false)
    }
    const [like, setLike] = useState(
        {
            questionId: "",
            isLike: true,
            userId: data.id,
        }
    )
    const LikeBtn = async (e, value, status) => {
        var tempPost = JSON.parse(JSON.stringify(postList));
        tempPost.find(x => x.id == value.id).isLiked = status;
        tempPost.find(x => x.id == value.id).likesCount = status == true ? value.likesCount + 1 : value.likesCount - 1
        dispatch(updatePost(tempPost));

        var response = await SocialServices.LikeQuestion(like, value.id, value.isLiked);
        if (response.data.status == false) {
            var tempPost2 = JSON.parse(JSON.stringify(postList));
            tempPost2.find(x => x.id == value.id).isLiked = !status;
            dispatch(updatePost(tempPost2))
        }

    }
    const [bookmarked, setBookmarked] = useState(
        {
            questionId: "",
            isLike: true,
            userId: data.id,
        }
    )
    const BookmarkedBtn = async (e, value, status) => {
        var tempQuiz = JSON.parse(JSON.stringify(postList));
        tempQuiz.find(x => x.id == value.id).isBookmarked = status;
        dispatch(updatePost(tempQuiz));

        var response = await SocialServices.BookmarkedQuestion(bookmarked, value.id, value.isBookmarked);
        if (response.data.status == false) {
            var tempQuiz2 = JSON.parse(JSON.stringify(postList));
            tempQuiz2.find(x => x.id == value.id).isBookmarked = !status;
            dispatch(updatePost(tempQuiz2));
        }
    }

    const [report, setReport] = useState(false)
    const [quizId, setQuizId] = useState()
    const createReport = (e, id, isFlag) => {
        setReport(!report)
        setQuizId(id)
    }

    const [qFlag, setQflag] = useState(
        {
            questionId: "",
            isLike: true,
            userId: data.id,
            comment: ""
        })
    const [showField, setShowField] = useState(false)
    const radioBtn = (e) => {
        if (e == "other") {
            setShowField(true)
        }
        else {
            setShowField(false)
            qFlag.comment = e;
        }
    }
    const [flagState, setFlagState] = useState(false)
    const FlagBtn = async (e, value, status) => {
        if (qFlag.comment != "") {
            var tempQuiz = JSON.parse(JSON.stringify(postList));
            tempQuiz.find(x => x.id == quizId).isFlagged = status;
            dispatch(updatePost(tempQuiz));
            var response = await SocialServices.FlagdQuestion(qFlag, quizId, value.isFlagged);

            if (response.data.status == false) {
                var tempQuiz2 = JSON.parse(JSON.stringify(postList));
                tempQuiz2.find(x => x.id == quizId).isFlagged = !status;
                setFlagState(true)
                dispatch(updatePost(tempQuiz2));
            }
            setReport(!report)
        }
    }

    const onCommentChange = async (e, questionId) => {

        var tempQuiz = JSON.parse(JSON.stringify(postList));
        tempQuiz.find(x => x.id == questionId).comment = e.target.value;
        dispatch(updatePost(tempQuiz));
    }
    const CommentBtn = async (e, questionId) => {

        var comment = postList.find(x => x.id == questionId).comment;
        if (!comment) return;

        var response = await SocialServices.CommnetsQuestion(comment, questionId, true, data.id);
        if (response.data.status == true) {
            var tempQuiz = JSON.parse(JSON.stringify(postList));
            tempQuiz.find(x => x.id == questionId).socialPostComments.push({
                comment: comment,
                user: data
            });
            tempQuiz.find(x => x.id == questionId).comment = "";
            dispatch(updatePost(tempQuiz));
        }
        else {
            alert("Error")
        }
    }
    var ImgExt = ['png', "jpg", "jpeg"]


    return (
        <>
            <Modal
                isOpen={report}
                toggle={createReport.bind(null)}
                className="modal-tags"
                style={{ maxWidth: "350px" }}
            >
                <div style={{ margin: "20px" }}>
                    <h3 style={{ color: "white" }}> <img alt="" src={reportIcon} width="20px" className='mr-2' />Report</h3>
                    <MdOutlineClose className='tag-close-btn' color='white' onClick={() => setReport(false)} />
                    <div className='add-tag-border'></div>
                </div>

                <ModalBody>
                    <RadioGroup
                        onChange={radioBtn}
                        vertical>
                        <RadioButton pointColor="#E84545" iconSize={20} value="Inappropriate" style={{ color: "white" }}>
                            Inappropriate
                        </RadioButton>
                        <RadioButton pointColor="#E84545" iconSize={20} value="Misleading">
                            Misleading
                        </RadioButton>
                        <RadioButton pointColor="#E84545" iconSize={20} value="Incorrect">
                            Incorrect Content
                        </RadioButton>
                        <RadioButton pointColor="#E84545" iconSize={20} value="other">
                            Other
                        </RadioButton>
                    </RadioGroup>
                    <div className="sub-menu"></div>
                    {showField ?
                        <input name='comment' value={qFlag.comment} onChange={(e) => { setQflag({ ...qFlag, comment: e.target.value }) }}
                            placeholder='Please Specify' className='form-control' style={{ backgroundColor: "#1D2226" }} /> : <div className="sub-menu"></div>
                    }
                </ModalBody>
                <button className='report-btn'
                    onClick={(e) => FlagBtn(e, signlePost, true)}
                >Submit</button>
            </Modal>
            <Modal
                style={{ maxWidth: "800px" }}
                isOpen={commentModal == true ? props.isShow : commentModal}
                toggle={CommentModal.bind(null)}
                className="modal-tags"
            >
                <ModalBody style={{ padding: "0px" }} >
                    <Row>
                        <Col md="6" className='d-flex align-items-center'>
                            {ImgExt.includes(signlePost?.fileUrl.split(".").pop().toLowerCase()) ? <img alt="" src={signlePost?.fileUrl.startsWith("https://") ? signlePost?.fileUrl : API_BASE_URL + signlePost?.fileUrl} style={{ width: "100%" }} /> :
                                <video width="100%" controls>
                                    <source src={signlePost?.fileUrl.startsWith("https://") ? signlePost?.fileUrl : API_BASE_URL + signlePost?.fileUrl} type="video/mp4" />
                                </video>}
                        </Col>
                        <Col md="6" >
                            <MdOutlineClose className='MdOutlineClose' color='white' onClick={CloseModal} />
                            <Link to="/showUserProfile"><span> <img src={dummy} alt="img" className='mt-2 ml-1' style={{ width: "50px", height: "50px" }} /> </span></Link>
                            <span style={{ color: "#E84545" }}>{signlePost.user.firstName + " " + signlePost.user.lastName}  <p className='des '>{moment(signlePost.user.createdDate).format('L')}</p>
                                <p className='des mt-2'>{signlePost.description}</p>
                            </span>

                            <div className='scrollbar scrollbar-primary' style={{ height: "245px", backgroundColor: "#1D2226" }} >
                                <div className='force-overflow'>
                                    {signlePost.socialPostComments.map((value, index) => {
                                        return (
                                            <div className="row" key={index}>
                                                <div className="col-2 text-left pt-3 m-2">
                                                    <figure className="avatar float-left mb-0">
                                                        <img
                                                            src={API_BASE_URL + value.user.imagePath}
                                                            alt="user"
                                                            className="float-right shadow-none w40 mr-2 rounded-circle"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="col-10 pt-2 modal-comments">
                                                    <div className="mt-3">
                                                        <p className="author-name font-xssss" style={{ color: "white" }}>
                                                            {value.user.firstName + " " + value.user.lastName}
                                                        </p>
                                                        <p className="comment-text lh-24 fw-500 font-xssss text-grey-500" style={{ marginTop: "-20px" }}>
                                                            {value.comment}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                            <div className='commentModal' style={{ backgroundColor: "black", marginLeft: "-29px" }}>
                                <div className='mt-1 pt-2 pl-2'>
                                    {signlePost.isLiked == true ?
                                        <AiFillHeart color="#E84545" fontSize="2em" className="mr-3" onClick={(e) => LikeBtn(e, signlePost, false)} /> :
                                        <AiOutlineHeart fontSize="2em" color='white' className="mr-3" onClick={(e) => LikeBtn(e, signlePost, true)} />}
                                    <img alt="" src={comments} width="25px" className="mr-3" />
                                    <img alt="" src={share} width="25px" />
                                    {signlePost.isBookmarked == true ? <img alt="" src={save} width="23px" className='float-right mr-3 ml-3' onClick={(e) => BookmarkedBtn(e, signlePost, false)} /> :
                                        <BsBookmark color='white' fontSize="2em" className='float-right mr-3 ml-3' onClick={(e) => BookmarkedBtn(e, signlePost, true)} />}
                                    {signlePost.isFlagged == true ? <img alt="" src={flagRed} width="25px" className='float-right'
                                        onClick={(e) => { createReport(e, signlePost.id, false) }}
                                    /> : <img alt="" src={flag} width="25px" className='float-right'
                                        onClick={(e) => { createReport(e, signlePost.id, true) }}
                                    />
                                    }
                                </div><p className='des-time'>{signlePost.likesCount} Likes</p>
                                <div className="card-body p-3">

                                    <input placeholder="Add Comments" id="quizQuestionComment" value={signlePost.comment} className="quizQuestionComment"
                                        onChange={(e) => { onCommentChange(e, signlePost.id) }}
                                    />
                                    <div className='float-right' style={{ color: "#E84545" }}
                                        onClick={(e) => CommentBtn(e, signlePost.id)}
                                    > Send</div>
                                </div>

                            </div>
                            {/* <div className='' style={{ backgroundColor: "black", marginLeft: "-29px" }}>
                                <div className="card-body p-3" style={{ marginTop: "284px" }}>
                                    <div className='mt-2 pt-2 pl-2'>
                                         <img alt="" src={like} width="20px" className="mr-3" />
                                         <img alt="" src={comments} width="20px" className="mr-3" />
                                         <img alt="" src={share} width="20px" />
                                         <img alt="" src={save} width="18px" className='float-right mr-3 ml-3' />
                                         <img alt="" src={flag} width="18px" className='float-right' />
                                    </div>
                                    <h4 className="font-xss mt-2 ml-0 lh-28 mt-0 pb-3 border-bottom" style={{ color: "white" }}></h4>
                                    <span style={{ color: "#BCB2B2" }}>Add Comments</span>
                                    <div className='float-right' style={{ color: "#E84545" }}> Send</div>
                                </div>
                            </div> */}
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    )
}

export default SocialCommentModal