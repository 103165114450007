import React, { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import QuizServices from '../../../services/QuizServices';
import dimand from "../../../assests/iCON/iCON/noun-diamond-1807567.png";
import hand from "../../../assests/iCON/iCON/noun-heart-3515260.png";
import { Link } from 'react-router-dom';
import moment from "moment"
import dummy from "../../../assests/dummy.png"
import user from "../../../assests/iCON/iCON/noun-friend-2531936.png";
import { API_BASE_URL } from '../../../../constant';
const QuizModal = (props) => {

    const { openModal, quizId } = props

    const [value, setValue] = useState({})
    const [modal, setModal] = useState(false);
    const toggle = () => {
        setModal(!modal);
    }
    useEffect(() => {
        GetQuizById()
        setModal(openModal)
    }, [quizId])
    const GetQuizById = async () => {

        const res = await QuizServices.GetQuestionById(quizId)
        console.log("quiz", res.data.payload)
        setValue(res.data.payload)
    }
    return (
        <>
            <Modal isOpen={modal} toggle={toggle} style={{ backgroundColor: "#181818" }}  >
                <ModalHeader style={{ backgroundColor: "#181818" }} toggle={toggle}><h5 style={{ color: "white" }}>Quiz Question Preview</h5></ModalHeader>
                <ModalBody style={{ backgroundColor: "#1D2226" }}>
                    <div
                        className=" p-0  border-0 rounded-lg overflow-hidden mr-1  shadow-lg p-3 mb-5 rounded quizs force-overflow " style={{ width: "auto", height: "auto" }} >
                        <div className="quiz-black-label"><Link to={`/user-profile/${value?.userId}`}><span> <img alt="" src={API_BASE_URL + value.user?.imagePath} className='mt-2 ml-1 rounded-circle' style={{ width: "50px", height: "50px" }} /> </span>
                            <span style={{ color: "#E84545" }}>{value.user?.firstName + " " + value.user?.lastName} </span></Link> </div>
                        <div className=" position-relative d-block mt-5">
                            <p style={{ fontSize: "12px", fontStyle: "italic" }}>{moment(value.createdDate).format('l')}</p>
                            <div className="mt-3 mb-4 ">{value.title?.replace(/<[^>]*>?/gm, '')} </div>
                            {value.questionOptions?.map((optn, index2) => (
                                <div
                                >
                                    {value.questionType == 1 ?
                                        <div>
                                            <input
                                                type="radio"
                                                id={"op" + optn.id}
                                                name={"radio-group" + value.id}
                                                // onChange={ChangeQuestionOption}
                                                data-questionId={value.id}
                                                value={optn.id} />
                                            <label className="my-class" for={"op" + optn.id} id={"label-" + optn.id}>{optn.title} <h1></h1></label>
                                        </div> :
                                        <div>
                                            <input
                                                type="checkbox"
                                                id={"op" + optn.id}
                                                // onChange={ChangeQuestionOption}
                                                data-questionId={value.id}
                                                value={optn.id}
                                                className="float-left checkbox"
                                                style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                                            />
                                            <label className="mt-1 my-class" id={"label-" + optn.id} >{optn.title}</label>

                                        </div>
                                    }
                                </div>
                            ))}

                            <div className="mb-5" style={{ marginTop: "-15px" }}>
                                <h5 className="text-dark text-grey-700 ">

                                    {value.isPremium == true ? <img alt="" src={dimand} className="float-right" width="25px" /> : <img alt="" src={hand} className="float-right" width="25px" />}
                                </h5>
                            </div>
                        </div>
                    </div>
                </ModalBody>

            </Modal>
        </>
    )
}

export default QuizModal