import React, { useState, useEffect, useRef } from 'react';
import Header from '../../components/Header';
import { BsThreeDotsVertical } from "react-icons/bs"
import {
    DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown,
} from 'reactstrap';
import { collection, query, orderBy, where, doc, or, deleteDoc, onSnapshot } from "firebase/firestore";
import { database } from '../../components/firebase';
import Swal from "sweetalert2"
import { MdDelete } from "react-icons/md"
import Inbox from './Inbox';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { API_BASE_URL } from '../../constant';
const UserList = () => {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let data = JSON.parse(localStorageObject);
    const messagesEndRef = useRef(null)
    const { id } = useParams()
    useEffect(() => {
        GettAllUserChatList()
        if (window.location.pathname == `/user-chat/${id}`) {
            setShow(true)
        }
    }, [])

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const [chat, setChat] = useState([]);
    const GettAllUserChatList = async () => {
        const q1 = query(collection(database, "msg"), or(where('senderId', '==', data.id), where('receiverId', '==', data.id)), orderBy("createdAt", "desc"));
        const unsubscribe = onSnapshot(q1, (querySnapshot) => {
            console.log('List Updated')
            let messages = [];
            querySnapshot.forEach((doc) => {
                var dataObj = doc.data();
                if (!messages.some(x => x.chatKey == dataObj.chatKey))
                    messages.push({ ...doc.data(), id: doc.id });
            });
            setChat(messages);
        });
    }
    const [show, setShow] = useState(false)
    const [receiver, setReceiver] = useState(id)
    const showInbox = (e, id) => {
        setShow(true)
        setReceiver(id)
         
    }
    const DeleteDoc = async (id) => {
        const docRef = doc(database, "usg", id);
         
        await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let response = await deleteDoc(docRef)
                    .then(() => {
                        Swal.fire(
                            'Deleted!',
                            "Deleted Sucessfully",
                            'success'
                        )
                    })
                 
                if (response.data.status === true) {
                    // Swal.fire(
                    //     'Success!',
                    //     response.data.message,
                    //     'success'
                    // )
                }
                else {
                    Swal.fire(
                        'Problem!',
                        response.data.message,
                        'danger'
                    )
                }
            }
        })
    }
    return (
        <>
            <Header />
            <div className="admin-chat" style={{height: "93vh"}}>
                <div className="row ml-4 mr-4 pt-3">
                    <div className='col-lg-6 col-xl-4 col-md-6  scrollbar scrollbar-primary'>
                        <div className='force-overflow mr-3'>

                            <div className="serch-input icon-input mb-3 d-flex justify-content-end" >
                                <i className="font-sm ti-search  " style={{ color: "#E84545", fontWeight: "700" }}></i>
                                <input
                                    type="text"
                                    className='form-control-search mt-3'
                                    placeholder='Search'
                                />
                            </div>
                            {chat.map((value, index) => {
                                return (
                                    <>
                                        <div className="all-chat row" key={index} onClick={(e) => { showInbox(e, value.receiverId == data.id ? value.senderId : value.receiverId) }}>
                                            <div className="col-2 text-left pl-4  m-2">
                                                <figure className="avatar float-left mb-0" style={{ marginLeft: "-18px" }}>
                                                     <img alt=""
                                                        src={value.receiverId == data.imagePath ? API_BASE_URL + value.senderImage : API_BASE_URL + value.receiverImage}
                                                        className="float-right shadow-none w50 mr-2 rounded-circle"
                                                    />
                                                </figure>
                                            </div>
                                            <div className="col-10 comments">
                                                <div className="content">.chat-text
                                                    <b className="author-name fw-500 mb-0 ">
                                                        {value.receiverId == data.id ? value.senderName : value.receiverName}
                                                    </b>
                                                    <p className="comment-text lh-24 fw-500 font-xssss text-grey-500 chat-text">
                                                        {value.text}
                                                    </p>
                                                </div>
                                            </div>
                                            <UncontrolledDropdown className="ml-2" >
                                                <DropdownToggle caret style={{ margin: "-15px", backgroundColor: "transparent", border: "none", marginTop: "-4px" }} >
                                                    <BsThreeDotsVertical fontSize="1.5rem" color="white" style={{ marginTop: "22px" }} />
                                                </DropdownToggle>
                                                <DropdownMenu style={{ top: "-9px", left: "40px", backgroundColor: "#181818", marginLeft: "200px" }}>
                                                    <DropdownItem style={{ color: "white", fontWeight: "200", margin: "-13px" }}><MdDelete onClick={DeleteDoc} fontSize="1.4rem" className='mt-1' />Delete<br /><div style={{ fontSize: "9px", marginLeft: "23px", marginTop: "-4px" }}>Delete this Chat?</div></DropdownItem>
                                                    {/* <DropdownItem divider />
                                                    <DropdownItem style={{ color: "white", fontWeight: "200", margin: "-13px" }}><MdOutlineReportProblem fontSize="1.4rem" className='mt-1' />Report<br /><div style={{ fontSize: "9px", marginLeft: "23px", marginTop: "-4px" }}>Are you sure  you wanted to report this?</div></DropdownItem> */}
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                    {show ? <Inbox receiverId={receiver} scrollToBottom={scrollToBottom} /> : ""}

                </div>
            </div>


        </>
    )
}

export default UserList