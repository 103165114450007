import React, { useState, useEffect } from 'react'
import { Link, useParams, NavLink } from "react-router-dom";
import { FaHospitalAlt } from 'react-icons/fa';
import { MdOutlineManageAccounts } from 'react-icons/md';
import { GrUserManager } from 'react-icons/gr';
import setting from "../assests/iCON/noun-setting-5161393 (1).png"
import edit from "../assests/iCON/noun-edit-684936.png"
import change from "../assests/iCON/noun-change-password-4051165 (1).png"
import unfollow from "../assests/iCON/noun-unfollow-1408925.png"
import { CgProfile } from "react-icons/cg";
import ProfileServices from '../services/ProfileServices';



const SideBar = () => {

    return (
        <>
            <h3 className='setting-heading'> <img alt="" src={setting} width="20px" className='ml-2 mr-2' />Settings</h3>
            <div class="sidenav">
                <NavLink to="/profile-setting" > <img alt="" src={edit} width="20px" className='ml-1 mr-2' />Security</NavLink>
                <NavLink to="/change-password" > <img alt="" src={change} width="20px" className='ml-1 mr-2' />  Change Password</NavLink>
                <NavLink to="/block-account">  <img alt="" src={unfollow} width="20px" className='ml-1 mr-2' /> Blocked User</NavLink>
                <NavLink to="/admin-controller" > <CgProfile fontSize="1.3rem" /> Apply to become a contributor </NavLink>
            </div>
        </>
    )
}



export const AdminMenuBar = () => {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    var data = JSON.parse(localStorageObject);

    useEffect(() => {
        fetchGetUserApi()
    }, [])

    const [user, setUser] = useState()
    async function fetchGetUserApi() {
        var response = await ProfileServices.GetStudentById(data.id);
        setUser(response.data.payload);
         
        console.log("users", response.data);
    }

    return (
        <>
            <h3 className='setting-heading'><CgProfile fontSize="1.3rem" /> Admin</h3>
            {user && (
                <div className="sidenav-admin">


                    {user.isSuperAdmin && (
                        <>
                            <NavLink to="/admin-controller">Content Creator</NavLink>
                            <NavLink to="/chapter-admin">Chapter Admin</NavLink>
                            <NavLink to="/cohort-admin">Cohort Admin</NavLink>
                            <NavLink to="/university-admin">University Admin</NavLink>
                            <NavLink to="/country-admin">Country Admin</NavLink>
                            <NavLink to="/super-admin">Super Admin</NavLink>

                        </>
                    )}
                    {user.isSuperAdmin == true ? "" :
                        <>
                            {user.isCountryAdmin && (
                                <>
                                    <NavLink to="/admin-controller">Content Creator</NavLink>
                                    <NavLink to="/chapter-admin">Chapter Admin</NavLink>
                                    <NavLink to="/cohort-admin">Cohort Admin</NavLink>
                                    <NavLink to="/university-admin">University Admin</NavLink>
                                    <NavLink to="/country-admin">Country Admin</NavLink>
                                </>
                            )}

                            {user.isUniversityAdmin && !user.isCountryAdmin && (
                                <>
                                    <NavLink to="/admin-controller">Content Creator</NavLink>
                                    <NavLink to="/chapter-admin">Chapter Admin</NavLink>
                                    <NavLink to="/cohort-admin">Cohort Admin</NavLink>
                                    <NavLink to="/university-admin">University Admin</NavLink>
                                </>
                            )}

                            {user.isCohortAdmin && !user.isUniversityAdmin && !user.isCountryAdmin && (
                                <>
                                    <NavLink to="/admin-controller">Content Creator</NavLink>
                                    <NavLink to="/chapter-admin">Chapter Admin</NavLink>
                                    <NavLink to="/cohort-admin">Cohort Admin</NavLink>
                                </>
                            )}

                            {user.isChapterAdmin && !user.isCohortAdmin && !user.isUniversityAdmin && !user.isCountryAdmin && (
                                <>
                                    <NavLink to="/admin-controller">Content Creator</NavLink>
                                    <NavLink to="/chapter-admin">Chapter Admin</NavLink>
                                    <NavLink to="/cohort-admin">Cohort Admin</NavLink>
                                </>
                            )}

                            {user.isContentCreatorAdmin && !user.isChapterAdmin && !user.isCohortAdmin && !user.isUniversityAdmin && !user.isCountryAdmin && (
                                <>
                                    <NavLink to="/admin-controller">Content Creator</NavLink>
                                    <NavLink to="/chapter-admin">Chapter Admin</NavLink>
                                </>
                            )}

                            {!user.isContentCreatorAdmin && !user.isChapterAdmin && !user.isCohortAdmin && !user.isUniversityAdmin && !user.isCountryAdmin && (
                                <>
                                    <NavLink to="/admin-controller">Content Creator</NavLink>
                                </>
                            )}

                        </>
                    }
                </div>
            )}


        </>
    )
}

// export const RecordBar = () => {
//   const { id } = useParams();

//   return (
//     <>
//       <div class="sidenav">
//         <NavLink to={`/patientRecord/${id}`} activeStyle={{ color: "#e9ecef", backgroundColor: " #007bff" }}>Medical History</NavLink>
//         {/* <Link to="">Allergies</Link> */}
//         <NavLink to={`/labResult/${id}`} activeStyle={{ color: "#e9ecef", backgroundColor: "#007bff " }}>Lab Result</NavLink>

//       </div>
//     </>
//   )
// }

// export const HospitalBar = () => {
//   return (
//     <>
//       <div class="sidenav">
//         <NavLink to="/doctorServices" activeStyle={{ color: "#007bff", backgroundColor: "#e9ecef " }}>Doctor</NavLink>
//         <NavLink to="/pharmacyList" activeStyle={{ color: "#007bff", backgroundColor: "#e9ecef " }}>Pharmacy</NavLink>
//         <NavLink to="/LabServicesList" activeStyle={{ color: "#007bff", backgroundColor: "#e9ecef " }} >Lab</NavLink>
//       </div>
//     </>
//   )
// }


// export const PharmacyBar = () => {
//   return (
//     <>

//       <div class="sidenav">
//         <Link to="/vitalForm">Dispense List</Link>
//         <Link href="#services">Completed</Link>

//       </div>
//     </>
//   )
// }


export default SideBar
