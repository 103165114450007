import React, { useState } from 'react'
import { Row, Col } from 'reactstrap'
const MultipleSelection = (props) => {
    const [questionOption, setQuestionOpion] = useState([{
        title: "",
        isCorrect: false
    }])

    const inputOption = (e,index) => {
         
        const { name, value } = e.target;
        const list = [...questionOption];
        list[index][name] = value=="true"?true:value;
        setQuestionOpion(list);
        props.sendList(list)
       }

    const handleAddClick = () => {
         
        setQuestionOpion([...questionOption, { title: "", isCorrect: false }]);
    };
    const handleRemoveClick = index => {
        const list = [...questionOption];
        const reverseArray=list.reverse()
        reverseArray.splice(index, 1);
        setQuestionOpion(list);
      };
    return (
        <div>
            <Row>
                <label>Add Answer</label>
                {questionOption.map((x, i) => {
                    return (
                        <>
                            <input className="form-control" type="text" value={x.title} name="title" onChange={e => inputOption(e, i)} placeholder="Option..." />
                            <input
                                type="checkbox"
                                value={true} name="isCorrect" 
                                onChange={e => inputOption(e, i)}
                                className="float-left checkbox"
                                style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "7px", marginRight: "7px" }}
                            />
                            <label className="mt-1 " >This is correct Answer</label>
                        </>

                    )
                })}
            
                <br></br>
            </Row>
            <button className="choice-btn" onClick={handleAddClick}>Add Choice</button>
            <button className="remove-choice-btn" onClick={handleRemoveClick}>Remove Choice</button>

        </div>
    )
}

export default MultipleSelection