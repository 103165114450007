import React, { useEffect, useState } from 'react';
import Login from "../../../src/assets/images/5.jpeg"
import {
  Row,
  Col,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";
import Logo from "../../../src/assets/images/Color logo_1.png";
import Swal from 'sweetalert2';
import facebookIcon from "../../../src/assets/iCON/facebook.png"
import passwordIcon from "../../../src/assets/iCON/noun-view-password-4287676.png"
import passwordSeeIcon from "../../../src/assets/iCON/noun-eye.png"
import AuthenticationService from '../services/AuthenticationServices';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import GoogleLogin from 'react-google-login';
import SocialButton from "./SocialButton"
import { gapi } from "gapi-script";
import { FcGoogle } from "react-icons/fc";


const CustomGoogleButton = ({ onClick, disabled }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    style={{
      backgroundColor: "#C74236",
      borderColor: "transparent",
      borderRadius: "7px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "360px",
      margin: "0px auto",
      padding: "2px"
    }}
  >
    <div style={{
      backgroundColor: "white",
      width: "40px",
      height: "40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "3px",
      marginRight: "10px"
    }}>
      <FcGoogle fontSize="1.6rem" />
    </div>
    <span style={{ color: "white", flex: 1, textAlign: "center" }}>
      Sign in with Google
    </span>
  </button>
);

const LoginForm = () => {

  const a = localStorage.getItem("Exe-Med_Student_Info")
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false)

  const [user, setUser] = useState()

  const [login, setLogin] = useState({
    email: "",
    password: ""
  })
  const [changePassword, setChangePassword] = useState({
    confirmPassword: "",
    newPassword: "",
  })
  const [modal1, setModal1] = useState(false);

  let history = useHistory();

  const backToRoute = () => {
    history.push("/quiz");
    window.location.reload()
  };
  useEffect(() => {
    function start() {

      gapi.client?.init({
        clientId: "788944449229-8uh80fbi42ffbo2jaspl1vn0hmj3ggnk.apps.googleusercontent.com",
        scope: 'email',
        
      });
    }

    gapi.load('client:auth2', start);
  }, []);
  const forgetPassword = async () => {
    if (login.email != "") {
      let response = await AuthenticationService.forgetPassword(login.email)
      if (response.data.status == true) {
        Swal.fire(
          'Send!',
          response.data.message,
          'success'
        )
      }

    }
    else {
      alert("Please Enter your email")
    }
  }
  const loginSubmit = async () => {
    try {
      setLoading(true)
      let response = await AuthenticationService.login(login.email, login.password)
      debugger
      if (response.data.status == true) {

        localStorage.setItem("Exe-Med_Student_Info", JSON.stringify(response.data.payload));
        if (response.data.payload.tempPassword != null) {
          setUser(response.data.payload);
          setModal1(!modal1);
        }
        else {
          backToRoute()
        }
        setLoading(false)
      }

      else {
        Swal.fire({
          icon: 'error',
          title: 'Login Failed...',
          text: response.data.message
        })
        setLoading(false)
      }
    }

    catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Login Failed...',
        text: "'Please fill the valid information'"
      })
    }
  }

  const changePasswordBtn = async () => {

    try {
      if (changePassword.newPassword == changePassword.confirmPassword && changePassword.newPassword != "" && changePassword.confirmPassword != "") {
        let response = await AuthenticationService.changePassword(changePassword, user.email, user.tempPassword)
        if (response.data.status == true) {
          backToRoute()
        }
        else {
          Swal.fire({
            icon: 'error',
            title: "Failed",
            text: 'Change Passwrd Failed...',
            text: response.data.message
          })
        }
      }
      else {
        Swal.fire({
          icon: 'error',
          title: "Failed",
          text: 'Change Passwrd Failed...',
        })
      }
    }

    catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Failed...',
        text: "'Please fill the valid information'"
      })
    }
  }

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleSocialLogin = async (user) => {

    console.log(user)
    var CheckUser = {
      email: user._profile.email,
      uuId: user._profile.id,
      imagePath: user._profile.profilePicURL
    }
    var CustomSocialObj = {
      FirstName: user._profile.firstName,
      Email: user._profile.email,
      LastName: user._profile.lastName,
      ImagePath: user._profile.profilePicURL,
      Provider: user._provider,
      UuId: user._profile.id,
      PseudoName: user._profile.name
    }
    try {
      // setLoading(true);

      const response = await AuthenticationService.ValidateSocialUser(CheckUser)

      if (response.data.status === true) {
        localStorage.setItem("Exe-Med_Student_Info", JSON.stringify(response.data.payload));
        history.push("/social")
      } else {
        history.push("/userSignInForm2", { fbUserData: CustomSocialObj })
      }
      // setLoading(false);

    }

    catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    }
  };

  const handleSocialLoginFailure = (err) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: err,
    })
  };


  const responseGoogle = async (user) => {
    console.log(user);

    console.log(user)
    var CheckUser = {
      email: user.profileObj.email,
      uuId: user.profileObj.googleId,
      imagePath: user.profileObj.imageUrl
    }
    var CustomSocialObj = {
      FirstName: user.profileObj.givenName,
      Email: user.profileObj.email,
      LastName: user.profileObj.familyName ||"",
      ImagePath: user.profileObj.imageUrl,
      Provider: user.xc.idpId,
      UuId: user.profileObj.googleId,
      PseudoName: user.profileObj.name
    }
    try {
      // setLoading(true);

      const response = await AuthenticationService.ValidateSocialUser(CheckUser)

      if (response.data.status === true) {
        localStorage.setItem("Exe-Med_Student_Info", JSON.stringify(response.data.payload));
        history.push("/social")
      } else {
        history.push("/userSignInForm3", { fbUserData: CustomSocialObj })
      }
      // setLoading(false);


    }

    catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    }
    setLoading(false)
  }


  return (
    <>

      <div className="main-wrap">
        <Modal
          isOpen={modal1}
          toggle={loginSubmit.bind(null)}
        >
          <ModalHeader toggle={loginSubmit.bind(null)}>Change Password</ModalHeader>
          <ModalBody>
            <Row>
              <Col md="4" className="mt-1">New Password</Col>
              <Col md="8">
                <FormGroup>
                  <input
                    type="password"
                    name="newPassword"
                    className='form-control-modal'
                    value={changePassword.newPassword}
                    required
                    onChange={(e) => { setChangePassword({ ...changePassword, newPassword: e.target.value }) }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="4" className="mt-1">Confirm Password</Col>
              <Col md="8">
                <FormGroup>
                  <input
                    className='form-control-modal'
                    type="password"
                    name="confirmPassword"
                    required
                    value={changePassword.confirmPassword}
                    onChange={(e) => { setChangePassword({ ...changePassword, confirmPassword: e.target.value }) }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-center">
            <Button color="danger" onClick={changePasswordBtn}>
              Submit
            </Button>
          </ModalFooter>

        </Modal>
        <div className="row">
          <div
            className="col-xl-6 col-sm-12"
            style={{
              backgroundImage: `url(${Login})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >

          </div>

          <div className="col-xl-6 col-sm-12 vh-lg-100 align-items-center  bg-black overflow-hidden">
            <div className="card shadow-none border-0 ml-auto mr-auto login-card">
              <div class="container">
                <div class="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-3 mt-3 mb-3 text-center">
                    <img alt=""
                      src={Logo}
                      width="100px"
                    // style={{ marginRight: 80, marginTop: 15 }}
                    />
                    {/*  <img alt="" 
                            src={BASE_URL + users.image}
                            alt='image'
                            width={90}
                          /> */}
                  </div>
                </div>
              </div>
              <div className="card-body rounded-0 text-left">
                <h4 className="display2-md-size mb-3 personal" >
                  Log in into
                  your account
                </h4>

                <form onSubmit={handleSubmit(loginSubmit)}>
                  <div className="form-group icon-input mb-3">
                    <i className="font-sm ti-email pr-0" style={{ color: "#E84545" }}></i>
                    <input
                      name='email'
                      value={login.email}
                      // autoComplete='off'
                      onChange={(e) => { setLogin({ ...login, email: e.target.value }) }}
                      type="text"
                      className="style2-input pl-5 form-control "
                      placeholder="Email Address"
                      required
                    />
                  </div>
                  <div className="form-group icon-input ">
                    <input
                      name='password'
                      value={login.password}
                      autoComplete='off'
                      onChange={(e) => { setLogin({ ...login, password: e.target.value }) }}
                      type={passwordShown ? "text" : "password"}
                      className="style2-input pl-5 form-control font-xssss ls-3"
                      placeholder="Password"
                      required
                    />
                    <i className="font-sm ti-lock pr-0" style={{ color: "#E84545" }}></i>
                    {passwordShown ?
                      <img alt="" src={passwordSeeIcon} onClick={togglePassword} width="22px" style={{ marginTop: "-69px", marginLeft: "325px" }} />
                      :
                      <img alt="" src={passwordIcon} onClick={togglePassword} width="22px" style={{ marginTop: "-69px", marginLeft: "325px" }} />
                    }
                    {passwordShown ? "" :
                      <a
                        className=" font-xssss float-right personal"
                        style={{ marginTop: "-25px" }}
                        onClick={forgetPassword}
                      >
                        Forgot Your Password
                      </a>
                    }

                  </div>
                  {/* <div className="form-check text-left mb-3">
                    <input
                      // type="checkbox"
                      className="form-check-input mt-2"
                      id="exampleCheck1"
                    />
                    <label
                      className="form-check-label font-xssss text-grey-500"
                      htmlFor="exampleCheck1"
                    >
                    Remember me
                    </label>

                  </div> */}
                  <div className="form-group mb-1 login">
                    {loading ? <button
                      disabled={true}
                      type="submit"
                      className="form-control text-center text-white fw-400  colors border-0 p-0 mt-3" >
                      <div className='spinner' style={{ marginLeft: "120px", marginTop: "8px" }}></div><p style={{ marginTop: '-58px' }}>Login in...</p>
                    </button> :
                      <button
                        disabled={loading}
                        type="submit"
                        className="form-control text-center text-white fw-400  colors border-0 p-0 mt-3" >
                        Log in
                      </button>
                    }


                  </div>
                </form>
                <div className="col-sm-12 p-0 text-left">
                  <h6 className="text-grey-500 font-xssss fw-500 mt-0 mb-0 lh-32">
                    Don't have account?
                    <a className="fw-700 ml-1" href='/userSignInForm1' style={{ color: "#E84545" }}>
                      Register Now
                    </a>
                  </h6>
                </div>
                <div className="col-sm-12 p-0 text-center mt-2">
                  <h6 className="mb-0 personal fw-500 font-xssss  mb-3">
                    Or, Sign in with your social account
                  </h6>
                  <div className="mb-1">
                    {/* <GoogleLogin
                      clientId="788944449229-8uh80fbi42ffbo2jaspl1vn0hmj3ggnk.apps.googleusercontent.com"
                      buttonText='Sign in with Google'
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      cookiePolicy={"single_host_origin"}
                      // className="googleBtn"
                      icon={true}
                      style={{width:"300px"}}
                    /> */}
                    <GoogleLogin
                      clientId="788944449229-8uh80fbi42ffbo2jaspl1vn0hmj3ggnk.apps.googleusercontent.com"
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      cookiePolicy={"single_host_origin"}
                      render={renderProps => (
                        <CustomGoogleButton
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                        />
                      )}
                    />

                    <SocialButton
                      provider="facebook"
                      appId="716023643527885"
                      onLoginSuccess={handleSocialLogin}
                      onLoginFailure={handleSocialLoginFailure}
                      className="facebookBtn"
                      style={{ display: "flex", justifyContent: "stretch" }}
                    >
                      <div >
                        <img
                          style={{
                            margin: "3px"
                          }
                          }
                          src={facebookIcon}
                          alt="icon"
                          className="w40"
                        />
                      </div>
                      <div style={{ margin: "9px auto" }}>Sign in with Facebook</div>
                    </SocialButton>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginForm;
