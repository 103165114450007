import React, { useState, useEffect } from 'react'
import { Row, Col, FormGroup, Input, Label, Select } from "reactstrap"
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md"
import { BiCommentDetail } from "react-icons/bi"
import world from "../../../../assets/iCON/noun-country-2450449.png"
import AllFilterServices from '../../../services/AllFilterServices'
import school from "../../../../assets/iCON/noun-university-1340867.png"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Doctors from './manage/Doctors'
import CohortAdmin from './manage/CohortAdmin'
import Cirriculm from './manage/Cirriculm'
import AdminServices from '../../../services/AdminServices'
import { useDispatch, useSelector } from 'react-redux'
import { GetDoctorsUniversityAdmins } from '../../redux/features/AdminSlice'
import { SessionLogoutMsg } from '../../../../constant'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Monetization from './manage/Monetization'
const Mange = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [selectedCountry, setSelectedCountry] = useState(null)
    const [selectedUniversity, setSelectedUniversity] = useState(null)
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    var data = JSON.parse(localStorageObject);

    var SelectedFilterData = {
        userId: data.id,
        CountryId: selectedCountry,
        UniversityId: selectedUniversity,
    }

    const [countryList, setCountryList] = useState([]);
    useEffect(async () => {
         
        var response = await AllFilterServices.getAllCountries()
        if (response.data.message == SessionLogoutMsg) {
            history.push("/login")
            localStorage.removeItem('Exe-Med_Student_Info');
        }
        else {
            setCountryList(response.data.payload);
        }

    }, [])
    const [universityList, setUniversityList] = useState([]);
    useEffect(async () => {
        var response = await AllFilterServices.getAllUniversities()
        if (response.data.message == SessionLogoutMsg) {
            history.push("/login")
            localStorage.removeItem('Exe-Med_Student_Info');
        }
        else {
            setUniversityList(response.data.payload);
        }
    }, [])
    const GetCountry = async (e) => {
        var response = await AdminServices.GetUniversitiesByCountryId(e.target.value)
        setUniversityList(response.data.payload)
        setSelectedCountry(e.target.value)
        SelectedFilterData.CountryId = e.target.value;
        dispatch(GetDoctorsUniversityAdmins(SelectedFilterData))
    }
    const [level, setLevel] = useState([])
    const [universityId, setUniversityId] = useState([])

    const GetUniversity = async (e) => {
         
        var response = await AdminServices.GetLevelsByUniversityId(e.target.value)
        setLevel(response.data.payload)
        setUniversityId(e.target.value)
        setSelectedUniversity(e.target.value)
        SelectedFilterData.UniversityId = e.target.value;
        dispatch(GetDoctorsUniversityAdmins(SelectedFilterData))
    }

    return (
        <>

            <Col md="12">
                <Row >
                    <Col md="6">
                        <select className='form-control chapterApply' onChange={GetCountry}>
                            <option value="">-- Select Country --</option>
                            {countryList?.map((value) => (
                                <option value={value.id}>{value.name}</option>
                            ))}
                        </select>
                    </Col>
                    <Col md="6">
                        <select className='form-control chapterApply' onChange={GetUniversity}>
                            <option value="">-- Select University --</option>
                            {universityList?.map((value) => (
                                <option value={value.id}>{value.name}</option>
                            ))}
                        </select>
                    </Col>

                </Row>
            </Col>
            <Tabs
                defaultActiveKey="Doctor"
                id="uncontrolled-tab-example"
                className="mb-3 mt-3 university-admin-tabs"
            >
                <Tab eventKey="Doctor" title="Doctor">
                    <Doctors />

                </Tab>

                <Tab eventKey="CohortAdmin" title="Cohort Admin" >
                    <CohortAdmin />

                </Tab>
                <Tab eventKey="Cirriculm" title="Cirriculm" >
                    <Cirriculm GetLevelByUniId={level} universityId={universityId} />
                </Tab>

                <Tab eventKey="Monetization" title="Monetization" >
                    <Monetization universityId={universityId}/>
                </Tab>
            </Tabs>

        </>
    )
}

export default Mange