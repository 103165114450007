import React, { useState } from 'react'
import { Row, Col, FormGroup, Input, Label, Select } from "reactstrap"
import Header from '../../components/Header'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Premium from './subscription-tabs/Premium';
import History from './subscription-tabs/History';

const Subcription = () => {

    return (
        <>
            <Header />
            <div className='pt-5 d-flex flex-column ' style={{ backgroundColor: "#181818", color: "white", minHeight: '100vh',alignItems: "center" }}>
                <h1 className='text-white ml-5'>Subscription</h1>
                <Tabs
                    defaultActiveKey="Premium"
                    id="uncontrolled-tab-example"
                    className="mb-3 mt-3 "
                >
                    <Tab eventKey="Premium" title="Get Premium">
                        <Premium/>
                    </Tab>

                    <Tab eventKey="history" title="Order history" >
                        <History/>
                    </Tab>

                </Tabs>

            </div>
        </>
    )
}

export default Subcription