import React from 'react'
import filter from "../../../../assets/iCON/noun-filter-4025735.png"
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md"
import {
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";
import CalenderFilter from "./CalenderFilter"
import { BranchFilter } from './BranchFilter';
import CountryFilter from './CountryFilter';
import UniversityFilter from './UniversityFilter';
import LevelFilter from './LevelFilter';

const AllFilter = () => {
    return (
        <div className="col-md-4 col-sm-12 mt-4" >
            <div style={{ background: "black", marginLeft: "50px" }}>
                <div className=" filter-by" >
                    <div className="form-control border-0 p-0">
                         <img alt="" src={filter} className="float-left mt-2 mr-3 ml-2 w30" /> <h2 className='h2' >  Filter By</h2> <MdOutlineKeyboardArrowDown className="float-right filter-icon" color='#E84545' fontSize="2.3em" />
                    </div>
                </div>
                <CardBody>
                    <CalenderFilter />
                    <BranchFilter />
                    <CountryFilter />
                    <UniversityFilter />
                    <LevelFilter />
                </CardBody>
            </div>
        </div>
    )
}

export default AllFilter