import React from 'react'
import { Row, Col, FormGroup, Input, Label, Select } from "reactstrap"
import Header from '../../../components/Header'
import { AdminMenuBar } from '../SideBar'
import country from "../../../view/assests/iCON/noun-country-2450449.png"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import Apply from './country-admin-tabs/Apply'
import Manage from './country-admin-tabs/Manage'
// import Vote from './country-admin-tabs/Vote'
const CountryAdmin = () => {
    return (
        <>
            <Header />
            <div className='change-password pt-5' style={{ backgroundColor: "#181818", color: "white", minHeight: '100vh' }}>
                <Row>
                    <Col md="1"></Col>
                    <Col md="2">
                        <AdminMenuBar />
                    </Col>
                    <Col md="8" >
                    <Manage />
                        {/* <Tabs
                            defaultActiveKey="Manage"
                            id="uncontrolled-tab-example"
                            className="mb-3 mt-3 country-admin-tabs"
                        >
                            <Tab eventKey="Manage" title="Manage">
                         
                                
                            </Tab>

                            <Tab eventKey="Vote" title="Vote" >
                            <Vote/>
                               
                            </Tab>
                            <Tab eventKey="Apply" title="Apply" >
                            <Apply />
                            </Tab>
                        </Tabs> */}
                    </Col>
                   


                </Row>

            </div>
        </>
    )
}

export default CountryAdmin