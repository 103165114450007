import axios from 'axios';
import { API_BASE_URL } from '../../constant';

class SocialServices {


    CreateSocailPost(object) {
        let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
        let Studentdata = JSON.parse(localStorageObject);
         
        return axios.post(API_BASE_URL + `api/Social/AddSocialPost?SID=${Studentdata.id}&JWT=${Studentdata.jwt}`, {
            fileUrl: object.fileUrl,
            description: object.description,
            visibility: object.visibility,
            hashtags: object.hashtags
        });
    }

    LikeQuestion(object, value, islike) {
        return axios.post(API_BASE_URL + 'api/Social/LikeSocialPost', {
            postId: value,
            isLike: islike == true ? false : true,
            userId: object.userId,
        });
    }

    CommnetsQuestion(comment, qId, isComment, userId) {

        return axios.post(API_BASE_URL + 'api/Social/CommentSocialPost', {
            postId: qId,
            isComment: isComment,
            comment: comment,
            userId: userId
        });
    }
    BookmarkedQuestion(object, value, isBookmarked) {
        return axios.post(API_BASE_URL + 'api/Social/BookmarkSocialPost', {
            postId: value,
            isLike: isBookmarked == true ? false : true,
            userId: object.userId,
        });
    }
    FlagdQuestion(object, value, isFlag) {
         
        return axios.post(API_BASE_URL + 'api/Social/FlagSocialPost', {
            postId: value,
            isLike: isFlag == true ? false : true,
            userId: object.userId,
            comment: object.comment,
        });
    }

    UpdateView(id) {
        return axios.post(API_BASE_URL + `api/Social/UpdateView?id=${id}`)
    }

}

export default new SocialServices();

