import React, { useEffect, useState } from 'react'
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css"
import { useSelector, useDispatch } from 'react-redux'
import { GetCohortAdminsUniversityAdmins } from '../../../redux/features/AdminSlice';
import AdminServices from '../../../../services/AdminServices';
import Swal from 'sweetalert2';
import moment from "moment"
const Monetization = ({ universityId }) => {
    const dispatch = useDispatch()
    const CohortAdminsUniversityAdmins = useSelector(state => state.Admin.CohortAdminsUniversityAdmins)
    const [modal, setModal] = useState(false);
    const [updateLevel, setUpdateLevel] = useState({
        id: '',
        primiumPlan: "",
        academicStartDate: "",
        academicEndDate: ""
    })
    console.log(updateLevel)
    const [selectedContent, setSelectedContent] = useState(null)
    const [selectedStatus, setSelectedStatus] = useState(null)
    const [selectedYear, setSelectedYear] = useState(null)
    const [universityList, setUniversityList] = useState([])


    const toggle = (id) => {
        updateLevel.id = id
        setModal(!modal);
    }

    useEffect(() => {
        GetAllLevelBUniversityId()
    }, [universityId])



    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    var data = JSON.parse(localStorageObject);

    var SelectedFilterData = {
        userId: data.id,
        contentType: selectedContent,
        status: selectedStatus,
        year: selectedYear
    }
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 10;
    const years = Array.from({ length: 11 }, (_, index) => startYear + index).reverse();

    const GetContentType = (e) => {
        setSelectedContent(e.target.value)
        SelectedFilterData.contentType = e.target.value
        dispatch(GetCohortAdminsUniversityAdmins(SelectedFilterData))
    }
    const GetYear = (e) => {
        setSelectedYear(e.target.value)
        SelectedFilterData.year = e.target.value
        dispatch(GetCohortAdminsUniversityAdmins(SelectedFilterData))
    }
    const GetAllLevelBUniversityId = async (e) => {
         
        var response = await AdminServices.GetAllLevelBUniversityId(universityId.length == 0 ? data.universityId : universityId)
        setUniversityList(response.data.payload)
        console.log(response.data.payload)
    }
    const SubmitLevel = async () => {
        const res = await AdminServices.UpdateLevels(updateLevel)
        try {
            if (res.data.status == true) {
                Swal.fire({
                    title: "Sucessfully Update",
                    text: res.data.message,
                    icon: "success"
                });
                setModal(!modal);
                console.log(res.data.status)
                GetAllLevelBUniversityId()
            }
        }
        catch (err) {
            alert(err)
        }


    }

    return (
        <>
            <Modal isOpen={modal} toggle={toggle} style={{ backgroundColor: "#181818" }}  >
                <ModalHeader style={{ backgroundColor: "#181818" }} toggle={toggle}><h5 style={{ color: "white" }}>Update Plan</h5></ModalHeader>
                <ModalBody style={{ backgroundColor: "#1D2226" }}>
                    <div  >
                        <div className=" w-100 border-0 rounded-lg overflow-hidden mr-1 text-grey-900 shadow-lg  mb-5 rounded">
                            <label style={{ color: "white" }}>Primium Plan</label>
                            <input className='form-control mt-2 mb-2'
                                style={{ backgroundColor: "transparent" }}
                                placeholder='Primium Plan'
                                type='text'
                                name='primiumPlan'
                                onChange={(e) => { setUpdateLevel({ ...updateLevel, primiumPlan: e.target.value }) }} />

                            <label style={{ color: "white" }}>Academic Start Date</label>
                            <input className='form-control mt-2 mb-2'
                                style={{ backgroundColor: "transparent" }}
                                type="date"
                                name='academicStartDate'
                                onChange={(e) => { setUpdateLevel({ ...updateLevel, academicStartDate: e.target.value }) }} />
                            <label style={{ color: "white" }}>Academic Start Date</label>
                            <input className='form-control mt-2 mb-2'
                                type="date"
                                style={{ backgroundColor: "transparent" }}
                                name='academicEndDate'
                                onChange={(e) => { setUpdateLevel({ ...updateLevel, academicEndDate: e.target.value }) }} />
                        </div>
                        <button className='create-quiz' onClick={SubmitLevel}>Submit</button>
                    </div>
                </ModalBody>


            </Modal>
            <Row>
                <Col md="4">
                    <select className='form-control chapterApply'
                        onChange={GetContentType}
                    >
                        <option value="">-- Select Content Type --</option>
                        <option value="QUIZ">Quiz</option>
                        <option value="VIDEO">Video</option>
                        <option value="DOCUMENT">Document</option>
                        <option value="FLASHCARD">Flashcard</option>
                    </select>
                </Col>
                <Col md="4">
                    <select className='form-control chapterApply' >
                        <option value="">-- Select Visibility --</option>


                    </select>
                </Col>
                <Col md="4">
                    <select className='form-control chapterApply'
                        onChange={GetYear}
                    >
                        <option value="">-- Select Date Range --</option>
                        {years.map((value, index) => {
                            return (
                                <option key={index} value={value}>{value}</option>
                            )
                        })}
                    </select>
                </Col>
            </Row>
            <ReactTable
                style={{ fontSize: "12px", marginTop: "20px" }}
                columns={[

                    {
                        Header: "Level ID",
                        accessor: "id",
                    },
                    {
                        Header: "Level Label",
                        accessor: "title",
                    },
                    {
                        Header: "Premium Plan and annual plan(in local currency)",
                        accessor: "primiumPlan",
                        Cell: (row) => {
                            return (

                                <div onClick={() => toggle(universityList[row.index].id)}>
                                    {universityList[row.index].primiumPlan}
                                </div>

                            )
                        }
                    },

                    {
                        Header: "Academic start Date",
                        accessor: "academicStartDate",
                        Cell: (row) => {
                            return (

                                <div onClick={() => toggle(universityList[row.index].id)}>
                                    {moment(universityList[row.index].academicStartDate).format("MMM Do YY")}
                                </div>

                            )
                        }
                    },
                    {
                        Header: "Academic End Date",
                        accessor: "academicEndDate",
                        Cell: (row) => {
                            return (

                                <div onClick={() => toggle(universityList[row.index].id)}>
                                    {moment(universityList[row.index].academicEndDate).format("MMM Do YY")}
                                </div>

                            )
                        }
                    },

                ]}
                defaultPageSize={5}
                // showPaginationBottom={true}
                className="-striped -highlight"
                data={universityList}
            // filterable
            // defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}

            />
        </>
    )
}

export default Monetization