import { initializeApp } from "firebase/app"
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCNbOhQN6N5RUA4cvyl9ilY3YTrzz-9vUQ",
    authDomain: "examed-102a5.firebaseapp.com",
    projectId: "examed-102a5",
    storageBucket: "examed-102a5.appspot.com",
    messagingSenderId: "643046982292",
    appId: "1:643046982292:web:9e4eed033409d7c89aea81",
    databaseURL: "https://examed-102a5-default-rtdb.firebaseio.com",
};

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig)
const db = getDatabase(app);
 const auth = getAuth(app);
  const database = getFirestore(app);


export {db,auth,database}