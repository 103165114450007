import React, { useState, useEffect } from 'react'
import { Row, Col, FormGroup, Input, Label, Select } from "reactstrap"
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md"
import world from "../../../../assets/iCON/noun-country-2450449.png"
import AllFilterServices from '../../../services/AllFilterServices'
import { AiFillEye, AiFillEdit, AiFillDelete } from "react-icons/ai"
import { BiCommentDetail } from "react-icons/bi"
import { Link } from 'react-router-dom'
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css"
import { GetAllCountryAdmins } from '../../redux/features/AdminSlice'
import { useDispatch, useSelector } from 'react-redux'
import { FileUploader } from "react-drag-drop-files";
import AdminServices from '../../../services/AdminServices'
import Swal from "sweetalert2"
const CountryAdmin = () => {

   
    return (
        <>

            <Col md="12">
                <Row >
                   
                    <Col md="12" className='mt-4'>
                        <ReactTable
                            style={{ fontSize: "12px" }}
                            columns={[

                                {
                                    Header: "Country",
                                    accessor: "#",
                                },
                                {
                                    Header: "Country Admin Name",
                                    accessor: "#",
                                },

                                {
                                    Header: "See Uni Admin Table",
                                    accessor: "#",
                                },

                                {
                                    Header: "Total View",
                                    accessor: "totalViews",
                                },
                                {
                                    Header: "Total Likes",
                                    accessor: "totalLikes",
                                },
                                {
                                    Header: "Like To view Ratio",
                                    accessor: "#",
                                },
                                {
                                    Header: "Like Rate",
                                    accessor: "likeRate",
                                    // Cell: (row) => {
                                    //     return (

                                    //         <div>
                                    //             {AllCountryAdmins[row.index].likeThisYear - AllCountryAdmins[row.index].likeLastYear}
                                    //         </div>
                                    //     )
                                    // }
                                },
                                {
                                    Header: "Buzz Factor",
                                    accessor: "buzz",
                                    // Cell: (row) => {
                                    //     return (

                                    //         <div>
                                    //             {AllCountryAdmins[row.index].likeThisYear - ((2 * AllCountryAdmins[row.index].likeLastYear) + AllCountryAdmins[row.index].likeYearBeforeLastYear)}
                                    //         </div>

                                    //     )
                                    // }
                                },
                                {
                                    Header: "Total Flag",
                                    accessor: "totalFlags",
                                },
                                {
                                    Header: "Flag to view ratio",
                                    accessor: "flagViewRatio",
                                },
                                {
                                    Header: "Contact Cohort Admin",
                                    accessor: "#",
                                },
                                {
                                    Header: "MEDcoin",
                                    accessor: "medCoins",
                                },
                                {
                                    Header: "Dismiss/Enroll",
                                    accessor: "#",
                                },


                            ]}
                            defaultPageSize={5}
                            // showPaginationBottom={true}
                            className="-striped -highlight"
                            // data={AllCountryAdmins}
                        // filterable
                        // defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}

                        />
                    </Col>
                </Row>
            </Col>


        </>
    )
}

export default CountryAdmin