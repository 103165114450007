import React, { useState } from 'react'
import { Row, Col } from 'reactstrap'
const SingleSelection = (props) => {
    const [questionOption, setQuestionOpion] = useState([{
        title: "",
        isCorrect: false,
    }])

    const inputOption = (e, index) => {
         
        const { name, value } = e.target;
        const list = [...questionOption];
        list[index][name] = value == "true" ? true : value;
        setQuestionOpion(list);
        props.sendList(list)
    }

    const handleAddClick = () => {
        setQuestionOpion([...questionOption, { title: "", isCorrect: false }]);
    };

    const handleRemoveClick = index => {
         
        const list = [...questionOption];
        const reverseArray=list.reverse()
        reverseArray.splice(index, 1);
        setQuestionOpion(list);
    };

    return (
        <div>
            <Row>
                <label>Add Answer</label>
                {questionOption.map((x, i) => {
                    return (
                        <>
                            <input className="form-control mt-2" type="text" value={x.title} name="title" onChange={e => inputOption(e, i)} placeholder="Option.." />
                            <input
                                type="radio"
                                id={"op" + i}
                                value={true} 
                                name="isCorrect"
                                onChange={e => inputOption(e, i)}
                                className="float-left checkbox singleSelection"
                            />
                            <label className="mt-1 " htmlFor={"op" + i} id={"op" + i} >This is correct Answer</label>
                        </>
                    )
                })}
                <br></br>
            </Row>
            <button className="choice-btn" onClick={handleAddClick}>Add Choice</button>
            <button className="remove-choice-btn" onClick={handleRemoveClick}>Remove Choice</button>

        </div>
    )
}

export default SingleSelection