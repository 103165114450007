import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
// import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { AiFillCaretDown, AiOutlineSetting } from "react-icons/ai";
import { BiLogOutCircle } from "react-icons/bi";
import { MdOutlineSubscriptions } from "react-icons/md"
import { CgProfile } from "react-icons/cg";
import { TbWallet } from "react-icons/tb";
import logo from "../assets/images/logo (2).png"
import { useHistory } from 'react-router-dom';
import bell from "../assets/iCON/noun-notification-5129395.png"
import msg from "../assets/iCON/noun-message-5124130.png"
import {
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
  Row
} from "reactstrap";
import { useIdleTimer } from 'react-idle-timer'
import { db } from './firebase'
import { getDatabase, ref, update, set, onDisconnect } from "firebase/database";
import { API_BASE_URL } from '../constant';
const Header = () => {
  const { innerWidth: width, innerHeight: height } = window;
  let history = useHistory();
  let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
  var data = localStorageObject != null ? JSON.parse(localStorageObject) : returnPath();

  function returnPath() {
    window.location.href = "/login";
  }
  function logout() {
    localStorage.removeItem('Exe-Med_Student_Info');

  }
  const timeout = 1800000;
  const [isIdle, setIsIdle] = useState(false)

  const handleOnActive = () => setIsIdle(false)
  function handleOnIdle() {
    setIsIdle(true);
    localStorage.clear();
    window.location.reload();
  }


  const {
    getRemainingTime,
    getLastActiveTime,
    getElapsedTime
  } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle
  })

  function SetLoginStatus(data) {
    try {
      update(ref(db, 'onlineUsers/' + data.id), {
        id: data.id,
        name: data.firstName + " " + data.lastName,
        email: data.email,
        status: "online",
        lastUpdated: new Date().toLocaleString()
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  onDisconnect(ref(db, 'onlineUsers/' + data.id)).update({
    id: data.id,
    name: data.firstName + " " + data.lastName,
    email: data.email,
    status: "offline",
    lastUpdated: new Date().toLocaleString(),
  });

  useEffect(() => {
    if (data == null) {
      history.push("/login")
      return
    }
    SetLoginStatus(data)
  }, [])

  return (

    <>
      <div className="" style={{ borderBottom: "1px solid #E84545" }} >
        <div className="container " >
          <div className="row">
            <div className="col-lg-9 col-xs-9 navbar pt-0 pb-0">
              <Link to="/quiz" style={{ marginLeft: "-55px" }}>
                <img alt="" src={logo} width={45} />
              </Link>
              <div className="header align-middle"  >
                <NavLink to="/social" activeStyle={{ color: "#E84545", backgroundColor: "transparent", borderTop: "2px solid #E84545", paddingTop: "2px", borderRadius: "0px" }}> Social</NavLink>
                <NavLink to="/quiz" activeStyle={{ color: "#E84545", backgroundColor: "transparent", borderTop: "2px solid #E84545", paddingTop: "2px", borderRadius: "0px" }}> Quiz</NavLink>
                <NavLink to="/study" activeStyle={{ color: "#E84545", backgroundColor: "transparent", borderTop: "2px solid #E84545", paddingTop: "2px", borderRadius: "0px" }}> Study</NavLink>
              </div>
            </div>
            <div className="col-lg-3 col-xs-3">
              {width <= 500 ?
                <div className='header-right-icons d-flex justify-content-end align-middle' >
                  <img alt="" src={bell} className="mr-3" width={20} height={20} />
                  <img alt="" src={msg} className="mr-3" width={20} height={20} />
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret className="pro-pic d-flex align-items-center"  >

                      <img alt="" src={API_BASE_URL + data.imagePath} className="user" width={20} height={20} style={{ borderRadius: "15px", marginTop: "-9px", marginLeft: "-19px" }} />
                      <span className="d-none d-md-block ml-2 font-medium" style={{ color: "black" }}>
                        <AiFillCaretDown />
                      </span>
                    </DropdownToggle>
                    <DropdownMenu right className="user-dd">

                      <DropdownItem

                        href="/single-user-profile">
                        <CgProfile fontSize="1.3rem" />  Profile
                      </DropdownItem>
                      <hr />
                      <DropdownItem

                        href="/profile-setting">
                        <AiOutlineSetting fontSize="1.3rem" />   Settings
                      </DropdownItem>
                      <hr />
                      <DropdownItem

                        href="/admin-controller">
                        <CgProfile fontSize="1.3rem" /> Admin Panel
                      </DropdownItem>
                      <hr />
                      <DropdownItem

                        href="#">
                        <TbWallet fontSize="1.3rem" /> Wallet
                      </DropdownItem>
                      <hr />

                      <DropdownItem
                        onClick={logout}
                        href="/login">
                        <BiLogOutCircle fontSize="1.3rem" /> Log Out
                      </DropdownItem>

                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                :
                <div className='header-right-icons d-flex justify-content-end' >
                  <Row >
                    <Col md="2" style={{ marginTop: "-22px" }}>
                      <img alt="" src={bell} className="mr-4" width="25px" style={{ marginTop: "25px" }} />
                    </Col>
                    <div style={{ height: "30px", borderRight: "1px solid gray" }}></div>
                    <Col md="2" style={{ marginTop: "-22px" }}>
                      <Link to="/message">
                        <img alt="" src={msg} className="mr-4" width="25px" style={{ marginTop: "28px" }} />
                      </Link>
                    </Col>
                    <div style={{ height: "30px", borderRight: "1px solid gray" }}></div>
                    <Col md="2">
                      <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret className="pro-pic d-flex align-items-center" style={{ marginTop: "-5px" }} >

                          <img alt="" src={API_BASE_URL + data.imagePath} className="user w30 " style={{ borderRadius: "15px" }} />
                          <span className="d-none d-md-block ml-2 font-medium" style={{ color: "black" }}>
                            <AiFillCaretDown />
                          </span>
                        </DropdownToggle>
                        <DropdownMenu right className="user-dd">

                          <DropdownItem

                            href="/single-user-profile">
                            <CgProfile fontSize="1.3rem" />  Profile
                          </DropdownItem>
                          <hr />
                          <DropdownItem

                            href="/profile-setting">
                            <AiOutlineSetting fontSize="1.3rem" />   Settings
                          </DropdownItem>
                          <hr />
                          <DropdownItem

                            href="/admin-controller">
                            <CgProfile fontSize="1.3rem" /> Admin Panel
                          </DropdownItem>
                          <hr />
                          {/* Commenting for now but change in future */}
                          {/* <DropdownItem

                            href="/wallet">
                            <TbWallet fontSize="1.3rem" /> Wallet
                          </DropdownItem>
                          <hr /> */}
                          {data.isSuperAdmin ? <>
                            <DropdownItem

                              href="/subcription">
                              <MdOutlineSubscriptions fontSize="1.3rem" /> Subscriptions
                            </DropdownItem>
                            <hr />
                          </> : ""}
                          <DropdownItem
                            onClick={logout}
                            href="/login">
                            <BiLogOutCircle fontSize="1.3rem" /> Log Out
                          </DropdownItem>

                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Col>
                  </Row>
                </div>}




            </div>
          </div>
        </div>
      </div>

    </>
  );
}


export default Header;
