import React, { useState, useEffect } from 'react'
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md"
import { SessionLogoutMsg } from '../../../../constant'
import AllFilterServices from '../../../services/AllFilterServices'
import { useHistory } from 'react-router-dom';
import levels from "../../../../assets/iCON/noun-typing-3165306.png"
import { useDispatch, useSelector } from "react-redux";
import { topUserList,radarData } from "../../QuizResult/store/features/TopUserSlice"

const LevelFilter = () => {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
  var data = JSON.parse(localStorageObject);
    const dispatch = useDispatch()
    const [selectedLevel, setSelectedLevel] = useState([])
    var SelectedFilterData = {
        countryId: [],
        startDate: null,
        endDate: null,
        branchId: [],
        chapterId: [],
        subChapterId: [],
        universityId: [],
        levelId: selectedLevel
    }

    const [levelList, setLevelList] = useState([])
    let history = useHistory()
    useEffect(async () => {
        
        var response = await AllFilterServices.getAllIntrests(data.universityId)
        if (response.data.message == SessionLogoutMsg) {
            history.push("/login")
            localStorage.removeItem('Exe-Med_Student_Info');
        }
        else {
            setLevelList(response.data.payload);
        }
    }, [])

    const [level, setLevel] = useState(false);
    const handleSelectAllLevel = (e) => {
         
        if (e.target.checked == true) {
            var tempSelectedLevel = levelList.map(li => li.id);
            setSelectedLevel(tempSelectedLevel)
            SelectedFilterData.levelId = tempSelectedLevel;
            dispatch(topUserList(SelectedFilterData))
            dispatch(radarData(SelectedFilterData))
            //   localStorage.setItem("Level_List", JSON.stringify(tempSelectedLevel));
             
        }
        else {
            setSelectedLevel([])
            //   localStorage.removeItem("Level_List", JSON.stringify(tempSelectedLevel));
            SelectedFilterData.levelId = [];
            dispatch(topUserList(SelectedFilterData))
            dispatch(radarData(SelectedFilterData))
        }

    }

    const [levelName, setLevelName] = useState('');
    const [levelSearch, setLevelSearch] = useState([]);
    const handleLevelSearch = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = levelList.filter((user) => {
                return user.title.toLowerCase().startsWith(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setLevelSearch(results);
        } else {
            setLevelSearch(levelList);
            // If the text field is empty, show all users
        }
        setLevelName(keyword);
    }
    const LevelFilterChange = (e) => {

        var Id = e.target.getAttribute('data-questionId')
        if (e.target.checked == false) {
          var tempSelectedLevel = selectedLevel.filter(function (obj) {
            return obj != Id;
          });
          setSelectedLevel(tempSelectedLevel)
          SelectedFilterData.levelId = tempSelectedLevel;
        //   localStorage.setItem("Level_List", JSON.stringify(tempSelectedLevel));
          dispatch(topUserList(SelectedFilterData))
           dispatch(radarData(SelectedFilterData))
        }
        else {
          var tempSelectedLevel = selectedLevel;
          tempSelectedLevel.push(parseInt(Id))
          setSelectedLevel(tempSelectedLevel)
        //   localStorage.setItem("Level_List", JSON.stringify(tempSelectedLevel));
           
          SelectedFilterData.levelId = tempSelectedLevel;
          dispatch(topUserList(SelectedFilterData))
           dispatch(radarData(SelectedFilterData))
        }
    
        e.target.parentNode.childNodes.forEach(function (element) {
          if (e.target.checked == true) {
            element.classList.add("change-color")
            element.classList.add("checkbox-color")
          }
          else {
            element.classList.remove("change-color")
          }
        });
      }
    return (
        <div>
            <div className="form-group mb-1 filter-margin" onClick={() => setLevel(!level)}>
                {level ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                     <img alt="" src={levels} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Level</h3>
                    {level ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
                </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                     <img alt="" src={levels} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Level</h3>
                    {level ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
                </a>}
            </div>
            {level && <div className="form-group mb-1 sub-menu-filter mb-3 mr-3 w-100 filter-scrollbar scrollbar-primary">
                <input placeholder='Search Level' onChange={handleLevelSearch} value={levelName} className='form-control' />
                <div>
                    <label className='ml-2 CursorPointer'>All</label>
                    <input
                        type="checkbox"
                        name="selectAllLevel"
                        id="selectAllLevel"
                        onChange={handleSelectAllLevel}
                        className="float-right checkbox"
                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                    />
                </div>
                {levelSearch && levelSearch.length > 0 ?

                    levelSearch.map((studentLevel, index) => (
                        <div key={index}>
                            <label className='ml-2'>{studentLevel.title}</label>
                            <input
                                type="checkbox"
                                data-questionId={studentLevel.id}
                                checked={selectedLevel.includes(studentLevel.id)}
                                onChange={LevelFilterChange}
                                className="float-right checkbox"
                                style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                            />
                        </div>
                    )) :
                    levelList.map((studentLevel, index) => (
                        <div key={index}>
                            <label className='ml-2'>{studentLevel.title}</label>
                            <input
                                type="checkbox"
                                data-questionId={studentLevel.id}
                                checked={selectedLevel.includes(studentLevel.id)}
                                onChange={LevelFilterChange}
                                className="float-right checkbox"
                                style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                            />
                        </div>
                    ))}

            </div>}
        </div>
    )
}

export default LevelFilter