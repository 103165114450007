import React, { useState } from 'react'
import { Modal, ModalHeader, ModalFooter, Button, ModalBody, Input } from "reactstrap"
import uplaod from "../assests/iCON/iCON/noun-photo-video-695389.png"
import { MdReportProblem, MdOutlineClose } from "react-icons/md"
import { Link } from 'react-router-dom';
import dummy from "../assests/dummy.png"
import Swal from "sweetalert2"
import SocailServices from "../services/SocialServices"
import * as AWS from "aws-sdk";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import awsConfig from "../../awsConfi";
import { API_BASE_URL } from '../../constant';
const CreatePostSocialModal = (props) => {

    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let data = JSON.parse(localStorageObject);
    const [post, setPost] = useState(false)
    const [content, setContent] = useState("")
    const [postModal, setPostModal] = useState(true)
    const [inputField, setInputField] = useState({
        fileUrl: "",
        description: "",
        visibility: 2,
        hashtags: ""
    })
    var ImgExt = ['png', "jpg", "jpeg"]

    const [selectedImage, setSelectedImage] = useState();
    const imageChange = (e) => {
        setPost(!post)
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImage(e.target.files[0])
            var file = document.querySelector(
                'input[type=file]')['files'][0];
            VideoUploadOnAws(e.target.files[0]);
        }
    };
    function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }
    function replaceAll(str, match, replacement) {
        return str.replace(new RegExp(escapeRegExp(match), 'g'), () => replacement);
    }

    const BUCKET_NAME = awsConfig.bucketName;
    const AWS_Base_URL = awsConfig.AWS_Base_URL;

    const generateUUID = () => {
        var d = new Date().getTime();
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16;
            if (d > 0) {
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }
    const VideoUploadOnAws = async (video) => {
        // setBtntxt("Uploading Video...");
        // setLoadingProgress(0);
        var guid = generateUUID();
        var fileName = guid + '_' + replaceAll(video.name, " ", "_");
        let bucket = new AWS.S3({
            params: { Bucket: BUCKET_NAME }
        });

        let params = {
            Key: "socialMaterial/" + fileName,
            ContentType: video.type,
            Body: video
        };

        bucket
            .upload(params)
            .on("httpUploadProgress", evt => {
                let progress = parseInt((evt.loaded * 100) / evt.total);
                console.log(progress);
                // setVideoLoading(progress);
            })
            .send(err => {
                if (err == null) {
                    console.log(`File Upload Successfuly`);
                    // setDisable(false);
                    console.log(AWS_Base_URL + params.Key);
                    // setBtntxt("ADD");
                    inputField.fileUrl = AWS_Base_URL + params.Key;
                    setContent(AWS_Base_URL + params.Key)

                }
                else {
                    console.log(err)
                    // setVideoLoading(0);
                    // setBtntxt("Error Uploading Video");
                    // setRetry("block");
                }

            });
    }
    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 50,
        },
        preview: {
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
        },
        image: { maxWidth: "100%", maxHeight: 200 },
        select: {
            backgroundColor: "#1D2226",
            color: "white",
            borderRadius: "22px",
            fontWeight: 100,
            fontSize: "12px",
            width: "82px"
        },
        label: {
            marginLeft: "59px",
            color: "#908f8f",
        }
    };
    const [regularTag, setRegularTag] = useState([]);

    const handleRegularTag = (tag) => {
        setRegularTag(tag);
    };

    const CloseModal = () => {
        setPostModal(false)
    }
    const CreateQuiz = async () => {
        if (inputField.fileUrl == "") {
            {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please add a post!',
                })
            }
        }
        else {
            inputField.hashtags = regularTag.join(",");
            try {
                await Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        //   setLoading(true);
                        var response = await SocailServices.CreateSocailPost(inputField);
                        if (response.data.status === true) {
                            Swal.fire("Added!", "" + response.data.message, "success");
                            setPostModal(false)
                            window.location.reload()
                        } else {
                            Swal.fire("Error!", response.data.message, "danger");
                        }
                        //   setLoading(false);
                    }
                });
            }
            catch (error) {
                {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                    })
                }
            }
        }

    }
    return (
        <>
            <Modal
                isOpen={postModal == true ? props.isShow : postModal}
                // toggle={createPostModal.bind(null)}
                className="modal-tags text-white"
            >
                <div style={{ margin: "20px" }}>
                    <h3 style={{ color: "white" }}>Create New Post</h3>
                    <MdOutlineClose className='tag-close-btn' color='white' onClick={CloseModal} />
                    <div className='add-border'></div>
                </div>

                <ModalBody>
                    {post &&
                        <div style={{ marginTop: "-30px" }}>
                            <Link to="/showUserProfile"><span> <img src={API_BASE_URL + data.imagePath} alt="img" className='mt-2 ml-1 rounded-circle' style={{ width: "50px", height: "50px", fontWeight: "500" }} /> </span></Link>
                            <span style={{ color: "#E84545",fontWeight:"500" }}>{data.firstName + " " + data.lastName}<p style={{ marginTop: "-20px" }}><label style={styles.label}>  </label> <select style={styles.select}
                                onChange={(e) => { setInputField({ ...inputField, visibility: e.target.value }) }}
                            >
                                <option value="2" >Anyone </option>
                                <option value="1" >My University </option>
                                <option value="3">My Network</option>
                            </select></p></span>
                            <textarea placeholder='Write Description...' name='description' value={inputField.description}
                                onChange={(e) => { setInputField({ ...inputField, description: e.target.value }) }}
                                className='create-post-textarea ml-1'></textarea>
                        </div>
                    }
                    {post ||
                        <div>
                            <img alt="" src={uplaod} width="140px " style={{ marginLeft: '150px' }} className="mb-5" />
                            <label style={{ marginLeft: '120px' }} className="mb-4">Drag Photos And Videos Here</label><br />
                            {/* <input type="file" style={{ marginLeft: '120px' }} onChange={imageChange} /> */}
                            <input type="file" id="upload" onChange={imageChange} hidden />
                            <label for="upload" style={{ marginLeft: '150px' }} className='create-post-label'>Select From Computer</label>
                        </div>
                    }

                    {selectedImage && (
                        <div style={styles.preview}>

                            {!ImgExt.includes(content.split(".").pop().toLowerCase()) ?
                                <video width="100%" height="200px" controls>
                                    <source src={URL.createObjectURL(selectedImage)} type="video/mp4" />
                                </video>
                                :
                                <img alt="" className='rounded' src={URL.createObjectURL(selectedImage)} style={{ width: "100%", height: "200px" }} />

                            }
                        </div>
                    )}

                </ModalBody>
                {post &&
                    <div className='m-3'>
                        {/* <button className='add-hastags'>Add Hashtag</button> */}
                        <TagsInput
                            value={regularTag}
                            onChange={(tag) => handleRegularTag(tag)}
                            tagProps={{
                                className: "react-tagsinput-tag text-white rounded",
                                name: "tags",
                                placeholder: 'Add hashtag'
                            }}
                        />
                        <button className='create-modal-post' onClick={CreateQuiz} >Post</button>
                    </div>
                }
            </Modal>

        </>
    )
}

export default CreatePostSocialModal