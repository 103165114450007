import React, { useRef, useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Collapse,
} from "reactstrap";
import singup from "../../../../src/assets/images/SignUp.png";
import { AiOutlineDownload } from "react-icons/ai"
import CardImg from "../../../../src/assets/iCON/noun-business-2437536.png"
import AuthenticationServices from "../../services/AuthenticationServices";
import AllFilterServices from "../../services/AllFilterServices"
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { BsFillArrowLeftSquareFill } from "react-icons/bs"
import UserSignInForm2 from "../UserSignInForm2";

const UserSignInForm3 = (props) => {


  console.log("1", props.userFormData1)
  console.log("2", props.userFormData2)

  const [inputField, setInputField] = useState({
    studentId: "",
    hospitalId: 0,
    universityId: 0,
    // identityImage: "",
    countryId: props.userFormData1.CountryId,
    email: props.userFormData1.Email,
    firstName: props.userFormData1.FirstName,
    languageId: props.userFormData1.LanguageId,
    lastName: props.userFormData1.LastName,
    password: props.userFormData1.Password,
    pseudoName: props.userFormData1.PseudoName,
    studentImage: props.userFormData1.StudentImage,
    interestIdsArr: props.userFormData2.InterestIdsArr,
    isStudent: props.userFormData2.IsStudent,
    level: props.userFormData2.Level,
    imagePath: props.userFormData1.ImagePath,
    provider: props.userFormData1.Provider,
    uuId: props.userFormData1.UuId,
  })

  var tempData = inputField;
  const inputsHandler = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value })
    tempData[e.target.name] = e.target.value;
     
    console.log(tempData)
    if (props.userFormData2.IsStudent == true) {
      if (inputField.universityId == "") {
        document.getElementById('nextBtn').classList.add('backgroundColor')
      }
      else {
        document.getElementById('nextBtn').classList.remove('backgroundColor')
        document.getElementById('nextBtn').classList.add('signupBtn')
      }
    }
    else {
      if (inputField.hospitalId == "") {
        document.getElementById('nextBtn').classList.add('backgroundColor')
      }
      else {
        document.getElementById('nextBtn').classList.remove('backgroundColor')
        document.getElementById('nextBtn').classList.add('signupBtn')
      }
    }
  }
  const [UniversityList, setUniersityList] = useState([])
  async function fetchUniversityApi() {
    var response = await AllFilterServices.GetUniversitiesWithoutAuth()
    setUniersityList(response.data.payload);
  }
  const [HospitalList, setHospitalList] = useState([])
  async function fetchHospitalApi() {
    var response = await AllFilterServices.getAllHospital()
    setHospitalList(response.data.payload);
  }
  useEffect(() => {
    fetchUniversityApi()
    fetchHospitalApi()
  }, [])

  const [selectedImage, setSelectedImage] = useState(null);
  const inputFile = useRef(null)
  const onButtonClick = () => {
    inputFile.current.click();
  };

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0])
      var file = document.querySelector(
        'input[type=file]')['files'][0];
      inputField.IdentityImage = file

    }
  };
  let history = useHistory()
  const backToRoute = () => {
    history.push("/login");
  };

  const submitButton = async () => {
     
    if (inputField.hospitalId == "" && inputField.universityId == "") {
      Swal.fire({
        icon: 'error',
        title: 'Failed...',
        text: 'PLease Fill the Complete Information',
      })
    }
    // else if (inputField.identityImage == "") {
    //   try {
    //     await Swal.fire({
    //       title: "Warning",
    //       text: "Please note you will not able to publish content or message other user if your account is not verified",
    //       icon: "warning",
    //       showCancelButton: true,
    //       confirmButtonColor: "#3085d6",
    //       cancelButtonColor: "#d33",
    //       confirmButtonText: "Yes",
    //     }).then(async (result) => {
    //       if (result.isConfirmed) {
    //         // setLoading(true);
    //          
    //         var response = await AuthenticationServices.Fblogin(inputField);
    //         if (response.data.status === true) {
    //           Swal.fire("Added!", "" + response.data.message, "success");
    //           backToRoute()
    //         } else {
    //           Swal.fire("Error!", response.data.message, "danger");
    //         }
    //         // setLoading(false);
    //       }
    //     });

    //   }

    //   catch (error) {
    //     Swal.fire({
    //       icon: 'error',
    //       title: 'Oops...',
    //       text: error,
    //     })
    //   }

    // }
    else {
      try {
        await Swal.fire({
          title: "Are you sure?",
          text: "You want be able to reverse it!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then(async (result) => {
          if (result.isConfirmed) {
            // setLoading(true);
             
            var response = await AuthenticationServices.Fblogin(inputField);
            if (response.data.status === true) {
              Swal.fire("Added!", "" + response.data.message, "success");
              backToRoute()
            } else {
              Swal.fire("Error!", response.data.message, "danger");
            }
            // setLoading(false);
          }
        });

      }

      catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error,
        })
      }
    }
  };
  const [back, setBack] = useState(false)
  const Goback = () => {
    setBack(!back)
  }
  const styles = {
    image: {
      height: 225,
      width: 550
    },
  };

  return (
    <>
      <div className="main-wrap">
        {back || <div className="row">
          <div
            className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat">
             <img alt=""
              src={singup}
              className=" w-50 float-right picture"
              style={{ marginRight: 80, marginTop: 15 }}
            />
          </div>
          <div className="col-xl-7 vh-100 align-items-center  overflow-hidden ">
            <div
              className="card shadow-none border-0 ml-auto mr-auto login-card color"
              style={{ maxWidth: "unset" }}
            >
              <div className="card-body rounded-0 text-left">
                <BsFillArrowLeftSquareFill fontSize="2rem" color='#E84545' onClick={Goback} />
                <h1 class="d-flex flex-row justify-content-center align-items-center mb-3 mt-3 personal">
                  User Registration Details
                </h1>

                <h4 className=" pl-4 mb-2 personal">ID Information</h4>
                <Row>
                  <div class="container">
                    <div class="row">

                      <div class="col-6">
                        <div className="form-group icon-input mb-3 registerUser">
                          {/* <i style={{ color: "#E84545" }} className="font-sm ti-home pr-0"></i> */}
                          <select type="select"
                            name="universityId"
                            id="UniversityId"
                            onChange={inputsHandler}
                            value={inputField.universityId}
                            className="form-control"
                          >
                            <option value="">Select University</option>
                            {
                              UniversityList.map((item, index) => {
                                return <option key={index} value={item.id}>{item.name} </option>
                              })
                            }
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div className="form-group icon-input mb-3 registerUser">
                          {/* <i style={{ color: "#E84545" }} className="font-sm ti-home pr-0"></i> */}
                          <select type="select"
                            name="hospitalId"
                            id="HospitalId"
                            onChange={inputsHandler}
                            value={inputField.hospitalId}
                            className="form-control"

                          >
                            <option value="">Select Hospital</option>
                            {
                              HospitalList.map((item, index) => {
                                return <option key={index} value={item.id}>{item.name} </option>
                              })
                            }
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                </Row>
                {props.userFormData2.IsStudent == true ?
                    <Card className="dark">
                      {selectedImage == null ?
                        <CardBody>
                           <img alt="" src={CardImg} width="70px" className="mt-4" style={{ marginLeft: "300px" }}  />
                          <div class="mb-3 mt-5">
                            <input
                              type='file'
                              id='file' ref={inputFile}
                              style={{ display: 'none' }}
                              accept="image/*"
                              onChange={imageChange} />
                          </div>
                          <button onClick={onButtonClick} className="uploadBtn"><AiOutlineDownload /> Upload Student ID</button>
                          <p className="text-danger">* Please note you will not able to publish content or message other user if your account is not verified</p>
                        </CardBody>
                        :
                        <div>
                          {selectedImage && (
                            <div style={styles.preview}>
                               <img alt=""
                                src={URL.createObjectURL(selectedImage)}
                                style={styles.image}
                                alt="Thumb"
                              />
                            </div>
                          )}
                        </div>
                      }
                    </Card> :
                    <Card className="dark">
                      {selectedImage == null ?
                        <CardBody>
                           <img alt="" src={CardImg} width="70px" className="CardImg mt-4" style={{ marginLeft: "300px" }} />
                          <div class="mb-3 mt-5">
                            <input
                              type='file'
                              id='file' ref={inputFile}
                              style={{ display: 'none' }}
                              accept="image/*"
                              onChange={imageChange} />
                          </div>
                          <button onClick={onButtonClick} className="uploadBtn"><AiOutlineDownload /> Upload proof of Title (hospital badge, diploma..)</button>
                          <p className="text-danger">* Please note you will not able to publish content or message other user if your account is not verified</p>
                        </CardBody>
                        :
                        <div>
                          {selectedImage && (
                            <div style={styles.preview}>
                               <img alt=""
                                src={URL.createObjectURL(selectedImage)}
                                style={styles.image}
                                alt="Thumb"
                              />
                            </div>
                          )}
                        </div>
                      }
                    </Card>}


                <button className='backgroundColor' id="nextBtn" onClick={submitButton}> Start Now</button>
              </div>
            </div>
          </div>
        </div>}
        {back && <UserSignInForm2 userFormData1={props.userFormData1} userFormData2={props.userFormData2} />}
      </div>
    </>
  )
}

export default UserSignInForm3