import React, { useEffect, useState } from 'react';
import forgetPassword from "../../../src/assets/images/forgetPassword.png"
import {
  Row,
  Col,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";
import Logo from "../../../src/assets/images/Color logo_1.png";
import exeMed from "../../assets/images/Color logo with background.png"
import Swal from 'sweetalert2';
import facebookIcon from "../../../src/assets/iCON/facebook.png"
import googleIcon from "../../../src/assets/iCON/google.png"
import passwordIcon from "../../../src/assets/iCON/noun-view-password-4287676.png"
import AuthenticationService from '../services/AuthenticationServices';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { API_BASE_URL } from '../..';
import passwordSeeIcon from "../../../src/assets/iCON/noun-eye.png"

const ForgetPassword = () => {

  const { id, token } = useParams()
  console.log(id, token)
  const { register, handleSubmit, errors } = useForm();

  const [user, setUser] = useState()

  const [changePassword, setChangePassword] = useState({
    id: id,
    token: token,
    confirmPassword: "",
    newPassword: "",
  })

  let history = useHistory();

  const backToRoute = () => {
    history.push("/");
  };



  const forgetPasswordBtn = async () => {
     
    try {
      if (changePassword.newPassword == changePassword.confirmPassword && changePassword.newPassword != "" && changePassword.confirmPassword != "") {
        let response = await AuthenticationService.resetPassword(changePassword)
        if (response.data.status == true) {
          Swal.fire(
            'Success!',
            response.data.message,
            'success'
          )
          backToRoute()
        }
        else {
          Swal.fire({
            icon: 'error',
            title: "Failed",
            text: 'Change Password Failed...',
            text: response.data.message
          })
        }
      }
      else {
        Swal.fire({
          icon: 'error',
          title: "Failed",
          text: 'Change Password Failed...',
        })
      }
    }

    catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Failed...',
        text: "'Please fill the valid information'"
      })
    }
  }

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>

      <div className="main-wrap">

        <div className="row">
          <div
            className="col-xl-6 col-sm-12 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"

          >
             <img alt="" src={forgetPassword}
              className=" w-50 float-right loginCard"
              style={{ marginRight: 80, marginTop: 15 }}
            />
          </div>

          <div className="col-xl-6 col-sm-12 vh-lg-100 align-items-center  bg-black rounded-lg overflow-hidden">
            <div className="card shadow-none border-0 ml-auto mr-auto login-card">
              <div class="container">
                <div class="row mt-5">
                  <div className="col-md-3"></div>
                  <div className="col-md-3 mt-5 mb-3">
                     <img alt=""
                      src={Logo}
                      width="100px"
                    // style={{ marginRight: 80, marginTop: 15 }}
                    />

                  </div>
                </div>
              </div>
              <div className="card-body rounded-0 text-left">
                <h4 className="display2-md-size mb-3 personal" >
                  Please Reset Password
                </h4>

                <form>
                  <div className="form-group icon-input ">
                    <input
                      name='newPassword'
                      value={changePassword.newPassword}
                      onChange={(e) => { setChangePassword({ ...changePassword, newPassword: e.target.value }) }}
                      type={passwordShown ? "text" : "password"}
                      className="style2-input pl-5 form-control font-xssss ls-3"
                      placeholder="New Password"
                      required
                    />

                    <i className="font-sm ti-lock pr-0" style={{ color: "#E84545" }}></i>
                    {passwordShown ?
                       <img alt="" src={passwordSeeIcon} onClick={togglePassword} width="22px" style={{ marginTop: "-69px", marginLeft: "309px" }} />
                      :  <img alt="" src={passwordIcon} onClick={togglePassword} width="22px" style={{ marginTop: "-69px", marginLeft: "309px" }} />}

                  </div>
                  <div className="form-group icon-input ">
                    <input
                      name='confirmPassword'
                      value={changePassword.confirmPassword}
                      onChange={(e) => { setChangePassword({ ...changePassword, confirmPassword: e.target.value }) }}
                      type={passwordShown ? "text" : "password"}
                      className="style2-input pl-5 form-control font-xssss ls-3"
                      placeholder="Confirm New Password"
                      required
                    />

                    <i className="font-sm ti-lock pr-0" style={{ color: "#E84545" }}></i>
                    {passwordShown ?
                       <img alt="" src={passwordSeeIcon} onClick={togglePassword} width="22px" style={{ marginTop: "-69px", marginLeft: "309px" }} />
                      :  <img alt="" src={passwordIcon} onClick={togglePassword} width="22px" style={{ marginTop: "-69px", marginLeft: "309px" }} />}

                  </div>

                  <div className="form-group mb-1 login">
                    <button
                      type="button"
                      onClick={forgetPasswordBtn}
                      className="form-control text-center text-white fw-400  colors border-0 p-0 " >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgetPassword;
