import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './main.scss';
import './assets/css/style.css'
import "./view/Custom.css"
import { Provider } from "react-redux";
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import store from "../src/store/store"
import Chat from './view/userChat/Chat';
import Social from './view/social-feed/Social';
import CreatePost from "./view/social-feed/CreatePost"
import Study from './view/study/Study';

import LoginForm from './view/loginForm/LoginForm';
import FaceBookLogin from "../src/view/loginForm/SocialLogin/FbLogin"
import ForgetPassword from './view/loginForm/ForgetPassword';
import UserSignInForm1 from './view/loginForm/UserSignInForm1';
import UserSignInForm2 from './view/loginForm/UserSignInForm2';

import UsersRegistration from './view/loginForm/UsersRegistration';
import UserSignInForm3 from './view/loginForm/UserSignInForm3';
import PrivateRote from './view/authentication/PrivateRoute';
import Notfound from './pages/Notfound';

// Quiz
import * as serviceWorker from './serviceWorker';
import QuizResult from './view/quiz/QuizResult/QuizResult';
import TopUser from './view/quiz/QuizResult/TopUser';
import RankPosition from './view/quiz/quiz-tabs/Rank-position';
import CreateQuiz from './view/quiz/CreateQuiz';
import Quiz from './view/quiz/Quiz';
import SelectQuestion from "./view/quiz/SelectQuestion"
import Questions from "./view/quiz/Questions"
// Socail
import SearchSocial from './view/social-feed/SearchSocial';
import CreateVideo from "./view/study/videos/CreateVideo"
import CreateDocument from "./view/study/documents/CreateDocument"
import CreateFlashcard from "./view/study/videos/flashcard/CreateFlashcard"
// user profile
import UserProfile from './view/profile/UserProfile';
import ProfileSetting from "../src/view/settings/ProfileSetting"
import ChangePassword from "../src/view/settings/side-bar-menu/ChangePassword"
import BlockAccount from './view/settings/side-bar-menu/BlockAccount';
import ContentCreator from "./view/settings/admin-menu/ContentCreater"
import ChapterAdmin from './view/settings/admin-menu/ChapterAdmin';
import CohortAdmin from './view/settings/admin-menu/CohortAdmin';
import UniversityAdmin from './view/settings/admin-menu/UniversityAdmin';
import CountryAdmin from "./view/settings/admin-menu/CountryAdmin"
import SuperAdmin from "./view/settings/admin-menu/SuperAdmin"
import Subcription from './view/subscription/Subcription';
import Wallet from './view/wallet/Wallet';


import StudentProfile from './view/profile/StudentProfile';
import DoctorProfile from "./view/profile/DoctorPofile"
import userChat from "./view/userChat/UserList"
import TestStripe from "./view/wallet/testStripe/App"

import awsSetup from "./awsSetup";

class Root extends Component {
  render() {
    awsSetup();
    return (

      <BrowserRouter basename={'/'}>
        <Provider store={store}>
          {/* <Provider store={TopUserStore}> */}
          <Switch>

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={PrivateRote}
            />
           
         
           <Route
              exact
              path={`${process.env.PUBLIC_URL}/testStripe`}
              component={TestStripe}
            />



            <Route
              exact
              path={`${process.env.PUBLIC_URL}/login`}
              component={LoginForm}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/faceBookLogin`}
              component={FaceBookLogin}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/authentication/resetPassword/:id?/:token?`}
              component={ForgetPassword}
            />

           

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/notfound`}
              component={Notfound}
            />
           
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/social`}
              component={Social}
            />
            

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/message/:id?`}
              component={Chat}
            />
        
           
            
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/createPost`}
              component={CreatePost}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/study/:id?`}
              component={Study}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/quiz/:id?`}
              component={Quiz}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/selectQuestion`}
              component={SelectQuestion}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/questions`}
              component={Questions}
            />
         
          
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/userSignInForm1`}
              component={UserSignInForm1}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/userSignInForm2`}
              component={UserSignInForm2}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/usersRegistration`}
              component={UsersRegistration}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/userSignInForm3`}
              component={UserSignInForm3}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/quizResult`}
              component={QuizResult}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/topUser`}
              component={TopUser}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/rank-position`}
              component={RankPosition}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/create-quiz`}
              component={CreateQuiz}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/search-socail`}
              component={SearchSocial}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/create-video`}
              component={CreateVideo}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/create-document`}
              component={CreateDocument}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/create-flashcard`}
              component={CreateFlashcard}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/single-user-profile`}
              component={UserProfile}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/user-profile/:id`}
              component={StudentProfile}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/doctor-profile/:id`}
              component={DoctorProfile}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/profile-setting`}
              component={ProfileSetting}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/change-password`}
              component={ChangePassword}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/block-account`}
              component={BlockAccount}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/admin-controller`}
              component={ContentCreator}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/chapter-admin`}
              component={ChapterAdmin}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/cohort-admin`}
              component={CohortAdmin}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/university-admin`}
              component={UniversityAdmin}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/country-admin`}
              component={CountryAdmin}
            />
  <Route
              exact
              path={`${process.env.PUBLIC_URL}/super-admin`}
              component={SuperAdmin}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/user-chat/:id?`}
              component={userChat}
            />
 <Route
              exact
              path={`${process.env.PUBLIC_URL}/subcription`}
              component={Subcription}
            />
<Route
              exact
              path={`${process.env.PUBLIC_URL}/wallet`}
              component={Wallet}
            />

          </Switch>
          {/* </Provider> */}
        </Provider>
        {/* <Footertwo /> */}
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.register();
