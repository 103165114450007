import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalFooter, Button, ModalBody, Input } from "reactstrap"
import { Link, useHistory } from 'react-router-dom';
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import dummy from "../assests/dummy.png"
import { MdOutlineClose } from "react-icons/md"
import { BiLoaderCircle } from 'react-icons/bi'
import { Row, Col } from "reactstrap"
import add from "../assests/iCON/noun-create-1409661.png"
import comments from "../assests/iCON/iCON/noun-comment-3266599.png"
import share from "../assests/iCON/iCON/noun-share-5060531.png"
import flag from "../assests/iCON/iCON/noun-flag-1017687.png"
import flagRed from "../assests/iCON/iCON/noun-flagRed-1017687.png"
import save from "../assests/iCON/iCON/noun-bookmark-3025188.png"
import unsave from "../assests/iCON/noun-bookmark-3025188.png"
import { RxCross2 } from "react-icons/rx";
import user from "../assests/iCON/iCON/noun-friend-2531936.png";
import reportIcon from "../assests/iCON/iCON/noun-alert-5119017.png"
import { useSelector, useDispatch } from 'react-redux';
import { GetAllSocailPost, updatePost } from './redux/feature/SocialSlice';
import Header from '../../components/Header';
import CreatePostSocialModal from './CreatePostSoailModal';
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai"
import SocialServices from '../services/SocialServices';
import { BsBookmark } from "react-icons/bs"
import SocialCommentModal from './SocialCommentModal';
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { AllSocialFilters } from './socialFilers/AllSocialFilters';
import { API_BASE_URL } from '../../constant';
import { getDatabase, ref, update, child, get } from "firebase/database";
import copy from "copy-to-clipboard";
import { db } from '../../components/firebase'

// import TimeTracker from 'react-time-tracker';


let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
let data = JSON.parse(localStorageObject);
const Social = () => {
  const dispatch = useDispatch()
  const [tags, setTags] = React.useState([
    { id: 'Thailand', text: 'Thailand' },
    { id: 'India', text: 'India' },
    { id: 'Vietnam', text: 'Vietnam' },
    { id: 'Turkey', text: 'Turkey' },
    { id: 'Turkey', text: 'Turkey' },
    { id: 'Turkey', text: 'Turkey' },
    { id: 'Turkey', text: 'Turkey' },
    { id: 'Turkey', text: 'Turkey' },
    { id: 'Vietnam', text: 'Vietnam' },

  ]);
  var ImgExt = ['png', "jpg", "jpeg"]

  const KeyCodes = {
    comma: 188,
    enter: 13
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const [post, setPost] = useState(false)
  let history = useHistory();
  const backToRoute = () => {
    history.push("/search-socail");
  };
  useEffect(() => {
    dispatch(GetAllSocailPost({
      postId: null,
      hashtags: [],
      skip: 0,
      search: null,
      countryId: [],
      interestId: [],
      levelId: [],
      universityId: [],
    }));
  }, [])

  const LoadMoreBtn = () => {

    dispatch(GetAllSocailPost({
      data: {
        postId: null,
        hashtags: [],
        skip: 10,
        search: null,
        countryId: [],
        interestId: [],
        levelId: [],
        universityId: [],
      },
      oldQuiz: postList
    }))
  }
  const postList = useSelector(state => state.social.allPost)
  console.log(postList)
  const hideLoadMore = useSelector(state => state.social.hideLoadMore)
  var loadMore = useSelector(state => state.social.loadMore)


  const [modal, setModal] = useState(false)
  const tagsModal = () => {
    setModal(!modal)
  }
  const [commentModal, setCommentModal] = useState(false)
  const [dataModal, setdataModal] = useState()
  const CommentModal = (e, value) => {
    setCommentModal(!commentModal)
    setdataModal(value)

  }


  const [postModal, setPostModal] = useState(false)
  const createPostModal = () => {
    setPostModal(!postModal)
  }


  const [viewComments, setViewComments] = useState(false)

  const handleDelete = i => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = tag => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setTags(newTags);
  };

  const handleTagClick = index => {
    console.log('The tag at index ' + index + ' was clicked');
  };
  const [like, setLike] = useState(
    {
      questionId: "",
      isLike: true,
      userId: data.id,
    }
  )
  const LikeBtn = async (e, value, status) => {

    var tempPost = JSON.parse(JSON.stringify(postList));

    tempPost.find(x => x.id == value.id).isLiked = status;
    tempPost.find(x => x.id == value.id).likesCount = status == true ? value.likesCount + 1 : value.likesCount - 1
    dispatch(updatePost(tempPost));
    var response = await SocialServices.LikeQuestion(like, value.id, value.isLiked);
    if (response.data.status == false) {
      var tempPost2 = JSON.parse(JSON.stringify(postList));
      tempPost2.find(x => x.id == value.id).isLiked = !status;
      dispatch(updatePost(tempPost2))
    }

  }
  const [bookmarked, setBookmarked] = useState(
    {
      questionId: "",
      isLike: true,
      userId: data.id,
    }
  )
  const BookmarkedBtn = async (e, value, status) => {
    var tempQuiz = JSON.parse(JSON.stringify(postList));
    tempQuiz.find(x => x.id == value.id).isBookmarked = status;
    dispatch(updatePost(tempQuiz));

    var response = await SocialServices.BookmarkedQuestion(bookmarked, value.id, value.isBookmarked);
    if (response.data.status == false) {
      var tempQuiz2 = JSON.parse(JSON.stringify(postList));
      tempQuiz2.find(x => x.id == value.id).isBookmarked = !status;
      dispatch(updatePost(tempQuiz2));
    }
  }

  const [report, setReport] = useState(false)
  const [quizId, setQuizId] = useState()
  const createReport = async (e, value, isFlag) => {

    if (isFlag == true) {
      setReport(!report)
      setQuizId(value.id)
    }
    else {
      var tempQuiz = JSON.parse(JSON.stringify(postList));
      tempQuiz.find(x => x.id == value.id).isFlagged = isFlag;
      dispatch(updatePost(tempQuiz));

      var response = await SocialServices.FlagdQuestion(qFlag, value.id, value.isFlagged);

      if (response.data.status == false) {
        var tempQuiz2 = JSON.parse(JSON.stringify(postList));
        tempQuiz2.find(x => x.id == value.id).isFlagged = !isFlag;
        setFlagState(true)
        dispatch(updatePost(tempQuiz2));
      }
    }
  }

  const [qFlag, setQflag] = useState(
    {
      questionId: "",
      isLike: true,
      userId: data.id,
      comment: ""
    })
  const [showField, setShowField] = useState(false)
  const radioBtn = (e) => {
    if (e == "other") {
      setShowField(true)
    }
    else {
      setShowField(false)
      qFlag.comment = e;
    }
  }
  const [flagState, setFlagState] = useState(false)
  const FlagBtn = async (e, value, status) => {
    if (qFlag.comment != "") {
      var tempQuiz = JSON.parse(JSON.stringify(postList));
      tempQuiz.find(x => x.id == quizId).isFlagged = status;
      dispatch(updatePost(tempQuiz));
      var response = await SocialServices.FlagdQuestion(qFlag, quizId, value.isFlagged);

      if (response.data.status == false) {
        var tempQuiz2 = JSON.parse(JSON.stringify(postList));
        tempQuiz2.find(x => x.id == quizId).isFlagged = !status;
        setFlagState(true)
        dispatch(updatePost(tempQuiz2));
      }
      setReport(!report)
    }
  }

  const onCommentChange = async (e, questionId) => {

    var tempQuiz = JSON.parse(JSON.stringify(postList));
    tempQuiz.find(x => x.id == questionId).comment = e.target.value;
    dispatch(updatePost(tempQuiz));
  }
  const CommentBtn = async (e, questionId) => {

    var comment = postList.find(x => x.id == questionId).comment;
    if (!comment) return;

    var response = await SocialServices.CommnetsQuestion(comment, questionId, true, data.id);
    if (response.data.status == true) {
      var tempQuiz = JSON.parse(JSON.stringify(postList));
      tempQuiz.find(x => x.id == questionId).socialPostComments.push({
        comment: comment,
        user: data
      });
      tempQuiz.find(x => x.id == questionId).comment = "";
      dispatch(updatePost(tempQuiz));
    }
    else {
      alert("Error")
    }
  }


  const [regularTag, setRegularTag] = useState([]);

  const handleRegularTag = (tag) => {

    setRegularTag(tag);
  };


  const CloseTagsModal = () => {
    setModal(false)
    setRegularTag([])
    dispatch(GetAllSocailPost({
      postId: null,
      hashtags: [],
      skip: 0,
      search: null,
      countryId: [],
      interestId: [],
      levelId: [],
      universityId: [],
    }));
  }
  const handleRemoveClick = index => {

    const list = [...regularTag]
    list.splice(index, 1);
    setRegularTag(list);
    dispatch(GetAllSocailPost({
      postId: null,
      hashtags: list,
      skip: 0,
      search: null,
      countryId: [],
      interestId: [],
      levelId: [],
      universityId: [],
    }));
  };
  const TagsFilter = () => {
    dispatch(GetAllSocailPost({
      postId: null,
      hashtags: regularTag,
      skip: 0,
      search: null,
      countryId: [],
      interestId: [],
      levelId: [],
      universityId: [],
    }));
    setModal(false)
  }

  const [search, setSearch] = useState(null)
  const SearchUser = (e) => {
    setSearch(e.target.value)
    dispatch(GetAllSocailPost({
      postId: null,
      hashtags: [],
      skip: 0,
      search: null,
      countryId: [],
      interestId: [],
      levelId: [],
      universityId: [],
      search: e.target.value == "" ? null : e.target.value
    }))
  }
  const [socailFilter, setSocialFilter] = useState()
  const sendList = (list) => {

    setSocialFilter(list)
  }
  const [time, setTime] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [pageLoadTime, setPageLoadTime] = useState(Date.now());
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isActive) {
        setTime(Date.now() - pageLoadTime);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [isActive, pageLoadTime]);

  useEffect(() => {

    const handleVisibilityChange = () => {
      setIsActive(!document.hidden);
      if (!document.hidden) {
        setPageLoadTime(Date.now() - time);
      }

    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [time]);

  const formattedTime = React.useMemo(() => {
    const minutes = Math.floor(time / 60000);
    const seconds = ((time % 60000) / 1000).toFixed(0);
    var ms = `${minutes}:${(seconds < 10 ? '0' : '')}${seconds}`
    var a = ms.split(':');
    var mintoSec = (+a[0]) * 60 + (+a[1]);
    console.log(mintoSec)
    return setTimeout(() => TrackSocailTabTime(mintoSec), 3000)
  }, [time]);


  // useEffect(() => {
  // const interval = setInterval(() => {
  // }, 3000);
  // return () => clearInterval(interval);
  // FetchData()

  // }, [])

  var getData = {}
  const [firstTime, setFirstTime] = useState(true);

  const FetchData = (() => {
    const dbRef = ref(getDatabase());
    get(child(dbRef, `onlineUsers/${data.id}`)).then((snapshot) => {
      if (snapshot.exists()) {
        getData = snapshot.val()
        console.log("actual data => ", snapshot.val());
        setFirstTime(false);
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error("errrr", error);
    });
  })
  if (firstTime) {
    FetchData();
  }

  function TrackSocailTabTime(formattedTime) {

    if (getData.hasOwnProperty('timeSpendOnSocial')) {
      var newTimeSpendOnSocial = getData?.timeSpendOnSocial;
    }
    else {
      var newTimeSpendOnSocial = 0
    }
    console.log(newTimeSpendOnSocial, " ", formattedTime)
    try {
      update(ref(db, 'onlineUsers/' + data.id), {
        id: data.id,
        timeSpendOnSocial: newTimeSpendOnSocial + formattedTime
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  const handleMouseEnter = async (id) => {
    await SocialServices.UpdateView(id)
  };

  const handleCopyClick = async (e, value, status) => {
    debugger
    // Asynchronously call copyTextToClipboard
    if (window.location.pathname == `/social/${value.id}`) {
      copy(window.location.href);
      alert("URL Copied", window.location.href)
    }

  }
  return (
    <>
      <Header />
      <Row className='quiz' style={{ minHeight: '100vh' }}>

        <AllSocialFilters regularTag={regularTag} sendList={sendList} search={search} />
        {/* Tags Modal */}
        <Modal
          isOpen={modal}
          toggle={tagsModal.bind(null)}
          className="modal-tags"
        >
          <div className='mb-0' style={{ margin: "20px" }}>
            <h3 style={{ color: "white" }}>Add Tags</h3>
            <MdOutlineClose className='tag-close-btn' color='white' onClick={() => CloseTagsModal()} />
            <div className='add-border'></div>
          </div>
          <ModalBody>
            <Row>
              <Col md="12">
                <TagsInput
                  value={regularTag}
                  onChange={(tag) => handleRegularTag(tag)}
                  tagProps={{
                    className: "react-tagsinput-tag text-white rounded modal-inputField",
                    name: "tags"
                  }}
                />
              </Col>
            </Row>
            <div className='mt-3' >
              {regularTag?.map((item, index) => {
                return (
                  <button className='all-tags'>{item} </button>
                )
              })}

            </div>
          </ModalBody>
          <button className='tags-btn' onClick={TagsFilter}>Done</button>
        </Modal>
        <div className="col-md-8">
          {/* Create Post Modal */}
          {postModal == true ? <CreatePostSocialModal isShow={postModal} /> : ""}
          {/* Add/View Comment Modal */}
          {commentModal == true ? <SocialCommentModal isShow={commentModal} singlePost={dataModal} /> : ""}

          <Row className='mt-2'>

            <Col md="7" sm="5">
              <div className="serch-input icon-input mb-3 d-flex justify-content-end">
                <i className="font-sm ti-search pr-0 " style={{ color: "#E84545", fontWeight: "700" }}></i>
                <input
                  type="text"
                  className='form-control-search mt-3'
                  placeholder='Search'
                  // onClick={backToRoute}
                  onChange={SearchUser}
                />
              </div>
            </Col>
            <Col md="2" sm="4">
              <button className='create-quiz w200'
                onClick={(event) => { createPostModal(event) }}>
                <img alt="" src={add}
                  className="mr-3" width="25px" />Create Post</button>
            </Col>

          </Row>
          <Row>
            <Col md="8" className='m-2'>
              <div className="app" style={{ marginLeft: "-9px" }}>
                <h1 className='text-white'>Tags </h1>
                <div >
                  {regularTag.map((item, index) => {
                    return (
                      <button className='all-tags'>{item}
                        <RxCross2 fontSize="1rem" color='#E84545' className='ml-1'
                          onClick={(tag) => handleRemoveClick(index)}
                        />
                      </button>
                    )
                  })}
                  <button className='tag-btn' onClick={(event) => { tagsModal(event) }}>Add Tags</button>
                </div>
              </div>
            </Col>
          </Row>

          <div className='scrollbar scrollbar-primary'>
            {postList?.map((value, index) => (
              // Strat Single Demo
              <>
                {/* Report Modal */}
                <Modal
                  isOpen={report}
                  toggle={createReport.bind(null)}
                  className="modal-tags"
                  style={{ maxWidth: "350px" }}
                >
                  <div style={{ margin: "20px" }}>
                    <h3 style={{ color: "white" }}> <img alt="" src={reportIcon} width="20px" className='mr-2' />Report</h3>
                    <MdOutlineClose className='tag-close-btn' color='white' onClick={() => setReport(false)} />
                    <div className='add-tag-border'></div>
                  </div>

                  <ModalBody>
                    <RadioGroup
                      onChange={radioBtn}
                      vertical>
                      <RadioButton pointColor="#E84545" iconSize={20} value="Inappropriate" style={{ color: "white" }}>
                        Inappropriate
                      </RadioButton>
                      <RadioButton pointColor="#E84545" iconSize={20} value="Misleading">
                        Misleading
                      </RadioButton>
                      <RadioButton pointColor="#E84545" iconSize={20} value="Incorrect">
                        Incorrect Content
                      </RadioButton>
                      <RadioButton pointColor="#E84545" iconSize={20} value="other">
                        Other
                      </RadioButton>
                    </RadioGroup>
                    <div className="sub-menu"></div>
                    {showField ?
                      <input name='comment' value={qFlag.comment} onChange={(e) => { setQflag({ ...qFlag, comment: e.target.value }) }}
                        placeholder='Please Specify' className='form-control' style={{ backgroundColor: "#1D2226" }} /> : <div className="sub-menu"></div>
                    }

                  </ModalBody>
                  <button className='report-btn'
                    onClick={(e) => FlagBtn(e, value, true)}
                  >Submit</button>
                </Modal>
                <Row>
                  <Col md="10">
                    <div className='social' >
                      <div className="border-0 rounded-lg text-grey-900 shadow-lg  mb-5 rounded">
                        <div
                          onMouseEnter={() => handleMouseEnter(value.id)}
                          className="mb-3" >
                          <Link to={`/user-profile/${value.user.id}`}><span> <img alt="" src={dummy} className='mt-2 ml-1' style={{ width: "50px", height: "50px" }} /> </span>
                            <span>{value.user.firstName + " " + value.user.lastName}   <p className='user-des'>3Rd Y MS, University Of Geneva</p></span></Link>
                          <Link to="/single-user-profile">   <img alt="" src={user} className="float-right mr-2 addUser" width="40px" /></Link>

                          {ImgExt.includes(value.fileUrl.split(".").pop().toLowerCase()) ? <img alt="" src={value.fileUrl.startsWith("https://") ? value.fileUrl : API_BASE_URL + value.fileUrl} style={{ width: "100%" }} /> :
                            <video width="100%" controls>
                              <source src={value.fileUrl.startsWith("https://") ? value.fileUrl : API_BASE_URL + value.fileUrl} type="video/mp4" />
                            </video>}


                        </div>
                        <div>
                          <div className='mt-1 pt-2 pl-2'>
                            {value.isLiked == true ?
                              <AiFillHeart color="#E84545" fontSize="2em" className="mr-3" onClick={(e) => LikeBtn(e, value, false)} /> :
                              <AiOutlineHeart fontSize="2em" color='white' className="mr-3" onClick={(e) => LikeBtn(e, value, true)} />}
                            <img alt="" src={comments} width="25px" className="mr-3" onClick={(event) => { CommentModal(event, value) }} />
                            <Link to={`/social/${value.id}`}> <img alt="" src={share} width="25px" onClick={(e) => { handleCopyClick(e, value) }} /></Link>
                            {value.isBookmarked == true ? <img alt="" src={save} width="23px" className='float-right mr-3 ml-3' onClick={(e) => BookmarkedBtn(e, value, false)} /> :
                              <img alt="" src={unsave} width="23px" className='float-right mr-3 ml-3' onClick={(e) => BookmarkedBtn(e, value, true)} />
                            }
                            {value.isFlagged == true ? <img alt="" src={flagRed} width="25px" className='float-right'
                              onClick={(e) => { createReport(e, value, false) }}
                            /> : <img alt="" src={flag} width="25px" className='float-right'
                              onClick={(e) => { createReport(e, value, true) }}
                            />
                            }
                          </div><p className='des-time mt-2'>{value.likesCount} Likes</p>
                          <p className='caption' style={{ marginBottom: "0px" }}><b>{value.user.firstName + " " + value.user.lastName + " "}</b>{value.description}</p>
                          {value.hashtags == "" ? "" : <p className='caption' style={{ marginTop: "0px" }}><b>{"#" + value.hashtags.replace(/,/g, " " + '#')}</b></p>}

                          <div>
                          </div>

                          <div className="card-body p-3">
                            <h4 className="font-xss mt-2 ml-0 lh-28 mt-0 pb-3 border-bottom text-white" onClick={(event) => { CommentModal(event, value) }}>
                              View {value.socialPostComments?.length} Comments
                            </h4>

                            <input placeholder="Add Comments" id="quizQuestionComment" value={value.comment} className="quizQuestionComment"
                              onChange={(e) => { onCommentChange(e, value.id) }}
                            />
                            <div className='float-right' style={{ color: "#E84545", fontWeight: "500" }}
                              onClick={(e) => CommentBtn(e, value.id)}
                            > Send</div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div
                      className="col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-4"
                      key={index}
                    ></div>
                  </Col>
                </Row>
              </>
              // End Single Demo
            ))}
            {hideLoadMore == false ? <div style={{ marginLeft: "350px" }}>
              <button className="submitBtn mt-4  mb-4" type="button" onClick={LoadMoreBtn}>  {loadMore ? <BiLoaderCircle /> : "Load More"} </button>
            </div> :
              <div></div>
            }
          </div>
        </div>
      </Row>

    </>
  );
}

export default Social;
