import React, { useState, useEffect } from 'react'
import { Row, Col } from "reactstrap"
import dummy from "../assests/dummy.png"
import { Link } from 'react-router-dom'
import { AllSocialFilters } from "./socialFilers/AllSocialFilters"
import Header from '../../components/Header'
import { useDispatch, useSelector } from "react-redux";
import { SocialUsers } from './redux/feature/SocialSlice'
import { API_BASE_URL } from '../../constant'
import { SearchUserFilter } from './socialFilers/SearchUserFilter'
const SearchSocial = (props) => {
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(SocialUsers({
            countryId: [],
            universityId: [],
            levelId: [],
            interestId: [],
            search: ""
        }))
    }, [])
    const users = useSelector(state => state.social.user)
     
    console.log(users)
    const [search, setSearch] = useState()
    const SearchUser = (e) => {
        setSearch(e.target.value)
        dispatch(SocialUsers({
            countryId: socailFilter?.countryId==undefined?[]:socailFilter.countryId,
            universityId: socailFilter?.universityId==undefined?[]:socailFilter.universityId,
            levelId: socailFilter?.levelId==undefined?[]:socailFilter.levelId,
            interestId: socailFilter?.interestId==undefined?[]:socailFilter.interestId,
            search: e.target.value == "" ? null : e.target.value
        }))
    }
    const [socailFilter,setSocialFilter]=useState()
    const sendList = (list) => {
        setSocialFilter(list)
    }
    return (
        <>
            <Header />
            <Row className="quiz">
                <SearchUserFilter search={search} sendList={sendList}  />
                <div className="col-md-8 col-sm-12 ">
                    <Row className='mt-2'>
                        <Col md="10" sm="5">
                            <div className="serch-input icon-input mb-3 d-flex justify-content-end">
                                <i className="font-sm ti-search pr-0 " style={{ color: "#E84545" }}></i>
                                <input
                                    type="text"
                                    className='form-control-search mt-3'
                                    placeholder='Search'
                                    onChange={SearchUser}
                                />
                            </div>
                            <div className='quizs mt-5 social-search scrollbar scrollbar-primary'>
                                <p className='react-seacrh-txt'>Recent Search</p>
                                <ul className='social-search-list force-overflow'>
                                    {users?.map((data, index) => {
                                        return (
                                            <li key={index}>  <img alt="" src={API_BASE_URL + data.imagePath} alt="img" className='m-2 mt-3 rounded-circle' style={{ width: "50px", height: "50px" }} />{data.firstName}<p>{data.pseudoName}</p></li>
                                        )
                                    })}

                                </ul>
                            </div>
                        </Col>

                    </Row>
                </div>

            </Row>
        </>
    )
}

export default SearchSocial
