import React from 'react'
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css"
import Switch from "react-bootstrap-switch";
import "react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css";
import { useSelector } from 'react-redux';
const Doctors = () => {
    const DoctorsUniversityAdmins = useSelector(state => state.Admin.DoctorsUniversityAdmins)
    return (
        <>
            <ReactTable
                style={{ fontSize: "12px" }}
                columns={[

                    {
                        Header: "User",
                        accessor: "data",
                    },
                    {
                        Header: "Created Date",
                        accessor: "data",
                    },

                    {
                        Header: "Level",
                        accessor: "data",
                    },

                    {
                        Header: "Specility",
                        accessor: "data",
                    },
                    {
                        Header: "ID",
                        accessor: "id",
                    },
                    {
                        Header: "Total Flag",
                        accessor: "#",
                    },
                    {
                        Header: "Flags Report",
                        accessor: "#",
                    },
                    {
                        Header: "Contact Doctor",
                        accessor: "#",
                    },
                    {
                        Header: "Verified",
                        accessor: "#",
                    
                    },

                ]}
                defaultPageSize={5}
                // showPaginationBottom={true}
                className="-striped -highlight"
                data={DoctorsUniversityAdmins}
            // filterable
            // defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}

            />
        </>
    )
}

export default Doctors