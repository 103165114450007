import React, { useState } from 'react'
import { Row, Col } from "reactstrap"
import { loadStripe } from '@stripe/stripe-js';
import {
    PaymentElement,
    CardElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import axios from 'axios';
import StripeCheckout from 'react-stripe-checkout';
import Swal from "sweetalert2";
import { API_BASE_URL } from '../../../constant';
import PaymentServices from '../../services/PaymentServices';
const stripePromise = loadStripe('pk_test_51ODQITBRTVsx3zdp6G8UNO2Rw18yOTNxoD0Ov7EYAkoYKEr5RWKaKL0o9KzHJv2cMMxRqTOR3TSrHOwfmbNS5iUM00ZoXqgYcq');

const Ballance = ({ medCoin, user_info }) => {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    var data = JSON.parse(localStorageObject);
    const onToken = async (token) => {
        console.log(token)
        const res = await PaymentServices.PaymnetDone(user_info.id, medCoin?.val, token.id)
        Swal.fire(" Sucess!", "" + res.data.message, "success");
        console.log(res)
    }
    const [payment, setPayment] = useState({
        amount: 10,
        currency: "usd",
        destination: data.bankId
    })
    const handleSubmit = async (event) => {
        if (data.bankId == null) {
            alert("Please Add Bank details First")
        }
        else {
            try {
                const res = await PaymentServices.TransferPayment(payment)
                console.log(res.data)

                // setLoading(false)
                if (!res.status === 200) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed...',
                        text: "Error Savings your Bank details",
                    })
                    // setLoading(false)

                }
                else {
                    Swal.fire(
                        'Success!',
                        "Bank Account Adding  sucessfully",
                        'success'
                    )
                    const BankAccountId = await PaymentServices.SaveTransactionId(data.id, res.data.id)
                    // setLoading(false)
                    console.log(BankAccountId)
                }
            }
            catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Failed...',
                    text: error.response.data.error.message,
                })
            }
        }

    };

    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);


    // const handleSubmit = async (event) => {
    //     event.preventDefault();

    //     if (elements == null) {
    //         return;
    //     }

    //     // Trigger form validation and wallet collection
    //     const { error: submitError } = await elements.submit();

    //     if (submitError) {
    //         // Show error to your customer
    //         setErrorMessage(submitError.message);
    //         return;
    //     }
    //     var bodyFormData = new FormData();
    //     bodyFormData.append("amount", 1099);
    //     bodyFormData.append("currency", "usd");
    //     // Create the PaymentIntent and obtain clientSecret from your server endpoint
    //     const res = await axios.post(API_BASE_URL + 'api/Payment/createIntent', bodyFormData);

    //     const { token: clientSecret } = res.data;
    //     const { error } = await stripe.confirmPayment({
    //         //`Elements` instance that was used to create the Payment Element
    //         elements,
    //         clientSecret,
    //         confirmParams: {
    //             return_url: 'http://localhost:3000/quiz',
    //         },
    //     });

    //     if (error) {
    //         // This point will only be reached if there is an immediate error when
    //         // confirming the payment. Show error to your customer (for example, payment
    //         // details incomplete)
    //         setErrorMessage(error.message);
    //     } else {
    //         // Your customer will be redirected to your `return_url`. For some payment
    //         // methods like iDEAL, your customer will be redirected to an intermediate
    //         // site first to authorize the payment, then redirected to the `return_url`.
    //     }
    // };



    return (
        <>
            <Row className='mt-0 mb-3 ml-5 mr-5 '>

                <Col md="6">
                    <label>Cashable now</label>
                    <input className='form-control chapterApply' />
                </Col>
                <Col md="6">
                    <label>Cashable next year</label>
                    <input className='form-control chapterApply' />
                </Col>
                <Col md="6">
                    <label>MEDcoin</label>
                    <input className='form-control chapterApply' disabled />
                </Col>
                <Col md="6">
                    <label>CHF</label>
                    <input className='form-control chapterApply' value={medCoin?.val} disabled />
                </Col>

                {/* {errorMessage && <div>{errorMessage}</div>}
                <div className='d-flex justify-content-center'>
                    <PaymentElement />
                </div> */}

            </Row>
            <div className='d-flex justify-content-center'>
                {/* 
                <StripeCheckout
                    name={user_info.firstName + user_info.lastName}
                    amount={medCoin?.val}
                    currency='USD'
                    token={onToken}
                    stripeKey="pk_test_51ODQITBRTVsx3zdp6G8UNO2Rw18yOTNxoD0Ov7EYAkoYKEr5RWKaKL0o9KzHJv2cMMxRqTOR3TSrHOwfmbNS5iUM00ZoXqgYcq"
                /> */}
                <button type="button" onClick={handleSubmit} disabled={!stripe || !elements} className="btn" style={{ backgroundColor: "#E84545", color: "white", borderRadius: "25px" }}>Cash Now</button>
            </div>

        </>
    )
}

export default Ballance