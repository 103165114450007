import React, { useState } from 'react'
import { Row, Col, FormGroup, Input, Label } from "reactstrap"
import SideBar from '../SideBar'
import Header from '../../../components/Header'
import passwordIcon from "../../../assets/iCON/noun-view-password-4287676.png"
import hidePassword from "../../../assets/iCON/noun-eye.png"
import ProfileServices from '../../services/ProfileServices'
import Swal from 'sweetalert2'

const ChangePassword = () => {

    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    const [changePassword, setChangePassword] = useState({
        id: Studentdata.id,
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    })


    const changePasswordBtn = async () => {
         
        try {
            if (changePassword.newPassword == changePassword.confirmPassword && changePassword.newPassword != "" && changePassword.confirmPassword != "") {
                
                    var response = await ProfileServices.ChangeUserPassword(changePassword)
                if (response.data.status == true) {
                    //   backToRoute()
                    Swal.fire(
                        'Password Change',
                        response.data.message,
                        'success'
                    )
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: "Failed",
                        text: 'Change Passwrd Failed...',
                        text: response.data.message
                    })
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: "Failed",
                    text: 'Change Passwrd Failed...',
                })
            }
        }

        catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Failed...',
                text: error
            })
        }
    }

    return (
        <>
            <Header />
            <div className='change-password pt-5' style={{ backgroundColor: "#181818", color: "white", height: "800px" }}>
                <Row>
                    <Col md="1"></Col>
                    <Col md="3">
                        <SideBar />
                    </Col>
                    <Col md="7">
                        <div className='quizs security'>
                            <Row >
                                <Col md="11">
                                    <FormGroup style={{ marginBottom: "-25px" }}>
                                        <Label>Old Password </Label>
                                        <Input className='setting-inputField'
                                            name='oldPassword'
                                            type={passwordShown ? "text" : "password"}
                                            value={changePassword.oldPassword}
                                            onChange={(e) => { setChangePassword({ ...changePassword, oldPassword: e.target.value }) }}
                                        />
                                        {passwordShown ?  <img alt="" src={hidePassword} onClick={togglePassword} width="22px" style={{ marginTop: "-75px", marginLeft: "700px" }} />
                                            :  <img alt="" src={passwordIcon} onClick={togglePassword} width="22px" style={{ marginTop: "-75px", marginLeft: "700px" }} />}

                                    </FormGroup>
                                    <FormGroup style={{ marginBottom: "-25px" }}>
                                        <Label>New Password </Label>
                                        <Input className='setting-inputField' name='password' type={passwordShown ? "text" : "password"}
                                            value={changePassword.newPassword}
                                            onChange={(e) => { setChangePassword({ ...changePassword, newPassword: e.target.value }) }}
                                        />
                                        {passwordShown ?  <img alt="" src={hidePassword} onClick={togglePassword} width="22px" style={{ marginTop: "-75px", marginLeft: "700px" }} />
                                            :  <img alt="" src={passwordIcon} onClick={togglePassword} width="22px" style={{ marginTop: "-75px", marginLeft: "700px" }} />}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Confirm New Password </Label>
                                        <Input className='setting-inputField' name='password' type={passwordShown ? "text" : "password"}
                                            value={changePassword.confirmPassword}
                                            onChange={(e) => { setChangePassword({ ...changePassword, confirmPassword: e.target.value }) }}
                                        />
                                        {passwordShown ?  <img alt="" src={hidePassword} onClick={togglePassword} width="22px" style={{ marginTop: "-75px", marginLeft: "700px" }} />
                                            :  <img alt="" src={passwordIcon} onClick={togglePassword} width="22px" style={{ marginTop: "-75px", marginLeft: "700px" }} />}
                                        <p className='d-flex justify-content-end' style={{ color: "#72BB53", marginRight: "-33px" }}>Forget Password?</p>
                                    </FormGroup>
                                </Col>
                                <button className='change-password-btn' onClick={changePasswordBtn}>Change Password</button>
                            </Row>
                        </div>
                    </Col>
                </Row>

            </div>
        </>
    )
}

export default ChangePassword