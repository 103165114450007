import React, { useState, useEffect } from 'react'
import {
    Row, Col, DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap"
import { FaSortAmountUpAlt } from "react-icons/fa"
import VideoMaterialTab from "./tabs/VideoMaterialTab"
import { useDispatch, useSelector } from 'react-redux'
import { videoMaterial, updateStudyDates, StudyBranchesFilter, StudyChapterFilter, StudySubChapterFilter, ChnageTabs, CheckedBranch } from "../redux/features/StudySlice"
import { exportselectedCountry, exportselectedLevel, exportselectedUniversity, exportselectedLanguages, exportselectedBranch, exportselectedSubchapter, exportselectedChapter } from "../StudyFilters"

import moment from 'moment'
const VideoMaterials = () => {
    const tabsBtn = useSelector(state => state.study.tabs)

    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    var data = JSON.parse(localStorageObject);

    let Past_Week_Date = localStorage.getItem("Past_Week_Date_video_" + data?.id);
    var weekDate = JSON.parse(Past_Week_Date);

    let Today_Date = localStorage.getItem("Today_Date_video_" + data?.id);
    var todayDate = JSON.parse(Today_Date);

    let Custom_Tab = localStorage.getItem("Custom_Tab_video_" + data?.id);
    var CustomTab = JSON.parse(Custom_Tab);

    let Past_Month_Date = localStorage.getItem("Past_Month_Date_video_" + data?.id);
    var monthDate = JSON.parse(Past_Month_Date);


    let localStorageObjectBranch = localStorage.getItem("Branche_List_video_" + data?.id);
    var BrancheFilterData = JSON.parse(localStorageObjectBranch);

    let localStorageObjectChapter = localStorage.getItem("Chapter_List_video_" + data?.id);
    var ChapterFilterData = JSON.parse(localStorageObjectChapter);

    let localStorageObjectSubChapter = localStorage.getItem("Subchapter_List_video_" + data?.id);
    var SubChapterFilterData = JSON.parse(localStorageObjectSubChapter);

    let localStorageObjectLanguage = localStorage.getItem("Language_List_video_" + data?.id);
    var LanguageFilterData = JSON.parse(localStorageObjectLanguage);

    let localStorageObjectCountry = localStorage.getItem("Country_List_video_" + data?.id);
    var CountryFilterData = JSON.parse(localStorageObjectCountry);

    let localStorageObjectUniversity = localStorage.getItem("University_List_video_" + data?.id);
    var UniversityFilterData = JSON.parse(localStorageObjectUniversity);

    let localStorageObjectLevel = localStorage.getItem("Level_List_video_" + data?.id);
    var LevelFilterData = JSON.parse(localStorageObjectLevel);

    let localStorageObjectBookmarked = localStorage.getItem("Book_marked_video_" + data?.id);
    var BookmarkedData = JSON.parse(localStorageObjectBookmarked);


    useEffect(() => {
        if (todayDate == null && weekDate == null && monthDate == null && CustomTab == null) {
            var element = document.getElementById("colorChange");
            element.classList.add("btn-red");
            element.classList.remove("create-posts");
            dispatch(videoMaterial({
                startDate: (moment(new Date()).format('YYYY-MM-DD')),
                endDate: (moment(new Date()).format('YYYY-MM-DD')),
                branchId: [],
                chapterId: [],
                subChapterId: [],
                LanguageId: [],
                CountryId: [],
                UniversityId: [],
                LevelId: [],
                Bookmarked: null,
                type: "VIDEO",
                questionCount: 10,
            }))
            dispatch(updateStudyDates({
                startDate: new Date(),
                endDate: new Date(),
            }))
             chnageColor()
        }
        if (todayDate == null && weekDate == null && CustomTab == null && monthDate != null) {
            PastWeekBtn()
        }
        if (weekDate == null && monthDate == null && CustomTab == null && todayDate != null) {
            today()
        }
        if (todayDate == null && monthDate == null && CustomTab == null && weekDate != null) {
            
            ThisWeekQuiz()
        }
        if (todayDate == null && monthDate == null && weekDate == null && CustomTab != null) {
            chnageColor()
        }
    }, [])

    const today = () => {
        dispatch(videoMaterial({
            startDate: (moment(new Date()).format('YYYY-MM-DD')),
            endDate: (moment(new Date()).format('YYYY-MM-DD')),
            branchId: [],
            chapterId: [],
            subChapterId: [],
            LanguageId: LanguageFilterData == null ? exportselectedLanguages : LanguageFilterData,
            CountryId: CountryFilterData == null ? exportselectedCountry : CountryFilterData,
            UniversityId: UniversityFilterData == null ? exportselectedUniversity : UniversityFilterData,
            LevelId: LevelFilterData == null ? exportselectedLevel : LevelFilterData,
            Bookmarked: null,
            questionCount: 10,
            type: "VIDEO"
        }))
        dispatch(updateStudyDates({
            startDate: new Date(),
            endDate: new Date(),
        }))
        dispatch(StudyBranchesFilter({
            startDate: (moment(new Date()).format('YYYY-MM-DD')),
            endDate: (moment(new Date()).format('YYYY-MM-DD')),
        }))

        dispatch(StudyChapterFilter({
            startDate: (moment(new Date()).format('YYYY-MM-DD')),
            endDate: (moment(new Date()).format('YYYY-MM-DD')),
            object: []
        }))
        dispatch(StudySubChapterFilter({
            startDate: (moment(new Date()).format('YYYY-MM-DD')),
            endDate: (moment(new Date()).format('YYYY-MM-DD')),
            object: []
        }))

        dispatch(ChnageTabs(0))

        var element = document.getElementById("colorChange");
        element.classList.remove("create-posts");
        element.classList.add("btn-red");

        var element = document.getElementById("thisWeek");
        element.classList.remove("btn-red");
        element.classList.add("create-posts");

        var elements = document.getElementById("pastWeek");
        elements.classList.remove("btn-red");
        elements.classList.add("create-posts");

        var custom = document.getElementById("customBtn");
        custom.classList.remove("btn-red");
        custom.classList.add("create-posts")

        localStorage.setItem("Today_Date_video_" + data?.id, JSON.stringify({
            startDate: (moment(new Date()).format('YYYY-MM-DD')),
            endDate: (moment(new Date()).format('YYYY-MM-DD')),
        }));
        
        localStorage.removeItem("Custom_Tab_video_" + data?.id, JSON.stringify(CustomTab));
        localStorage.removeItem("Past_Week_Date_video_" + data?.id, JSON.stringify(weekDate));
        localStorage.removeItem("Past_Month_Date_video_" + data?.id, JSON.stringify(monthDate));
    }

    const ThisWeekQuiz = () => {
        const now = new Date();
        const lastweek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);

        dispatch(videoMaterial({
            startDate: (moment(lastweek).format('YYYY-MM-DD')),
            endDate: (moment(now).format('YYYY-MM-DD')),
            branchId: [],
            chapterId: [],
            subChapterId: [],
            LanguageId: LanguageFilterData == null ? exportselectedLanguages : LanguageFilterData,
            CountryId: CountryFilterData == null ? exportselectedCountry : CountryFilterData,
            UniversityId: UniversityFilterData == null ? exportselectedUniversity : UniversityFilterData,
            LevelId: LevelFilterData == null ? exportselectedLevel : LevelFilterData,
            Bookmarked: null,
            type: "VIDEO",
            questionCount: 10,
        }))
        dispatch(updateStudyDates({
            startDate: lastweek,
            endDate: now
        }))
        dispatch(StudyBranchesFilter({
            startDate: (moment(lastweek).format('YYYY-MM-DD')),
            endDate: (moment(now).format('YYYY-MM-DD')),
        }))

        dispatch(StudyChapterFilter({
            startDate: (moment(lastweek).format('YYYY-MM-DD')),
            endDate: (moment(now).format('YYYY-MM-DD')),
            object: []
        }))
        dispatch(StudySubChapterFilter({
            startDate: (moment(lastweek).format('YYYY-MM-DD')),
            endDate: (moment(now).format('YYYY-MM-DD')),
            object: []
        }))
        dispatch(ChnageTabs(0))

        var element = document.getElementById("thisWeek");
        element.classList.remove("create-posts");
        element.classList.add("btn-red");

        var element = document.getElementById("colorChange");
        element.classList.remove("btn-red");
        element.classList.add("create-posts");

        var elements = document.getElementById("pastWeek");
        elements.classList.remove("btn-red");
        elements.classList.add("create-posts");

        var custom = document.getElementById("customBtn");
        custom.classList.remove("btn-red");
        custom.classList.add("create-posts")

        dispatch(StudyBranchesFilter({
            startDate: (moment(lastweek).format('YYYY-MM-DD')),
            endDate: (moment(now).format('YYYY-MM-DD')),
        }))
        localStorage.setItem("Past_Week_Date_video_" + data?.id, JSON.stringify({
            startDate: lastweek,
            endDate: now
        }));
        localStorage.removeItem("Custom_Tab_video_" + data?.id, JSON.stringify(CustomTab));
        localStorage.removeItem("Today_Date_video_" + data?.id, JSON.stringify(todayDate));
        localStorage.removeItem("Past_Month_Date_video_" + data?.id, JSON.stringify(monthDate));
    }

    const PastWeekBtn = () => {

        const now = new Date();
        const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
        dispatch(videoMaterial({
            startDate: (moment(lastMonth).format('YYYY-MM-DD')),
            endDate: (moment(now).format('YYYY-MM-DD')),
            branchId: [],
            chapterId: [],
            subChapterId: [],
            LanguageId: LanguageFilterData == null ? exportselectedLanguages : LanguageFilterData,
            CountryId: CountryFilterData == null ? exportselectedCountry : CountryFilterData,
            UniversityId: UniversityFilterData == null ? exportselectedUniversity : UniversityFilterData,
            LevelId: LevelFilterData == null ? exportselectedLevel : LevelFilterData,
            Bookmarked: null,
            type: "VIDEO",
            questionCount: 10,
        }))
        dispatch(updateStudyDates({
            startDate: lastMonth,
            endDate: now
        }))
        dispatch(StudyBranchesFilter({
            startDate: (moment(lastMonth).format('YYYY-MM-DD')),
            endDate: (moment(now).format('YYYY-MM-DD')),
        }))

        dispatch(StudyChapterFilter({
            startDate: (moment(lastMonth).format('YYYY-MM-DD')),
            endDate: (moment(now).format('YYYY-MM-DD')),
            object: []
        }))
        dispatch(StudySubChapterFilter({
            startDate: (moment(lastMonth).format('YYYY-MM-DD')),
            endDate: (moment(now).format('YYYY-MM-DD')),
            object: []
        }))

        dispatch(ChnageTabs(0))

        var element = document.getElementById("pastWeek");
        element.classList.remove("create-posts");
        element.classList.add("btn-red");

        var element = document.getElementById("colorChange");
        element.classList.remove("btn-red");
        element.classList.add("create-posts");


        var Week = document.getElementById("thisWeek");
        Week.classList.remove("btn-red");
        Week.classList.add("create-posts");

        var custom = document.getElementById("customBtn");
        custom.classList.remove("btn-red");
        custom.classList.add("create-posts")

        localStorage.setItem("Past_Month_Date_video_" + data?.id, JSON.stringify({
            startDate: lastMonth,
            endDate: now
        }));

        localStorage.removeItem("Today_Date_video_" + data?.id, JSON.stringify(todayDate));
        localStorage.removeItem("Past_Week_Date_video_" + data?.id, JSON.stringify(weekDate));
        localStorage.removeItem("Custom_Tab_video_" + data?.id, JSON.stringify(CustomTab));

    }
    const chnageColor = () => {
        var element = document.getElementById("colorChange");
        element.classList.remove("btn-red");
        element.classList.add("create-posts");

        var element = document.getElementById("thisWeek");
        element.classList.remove("btn-red");
        element.classList.add("create-posts");

        var custom = document.getElementById("customBtn");
        custom.classList.remove("create-posts");
        custom.classList.add("btn-red");

        var elements = document.getElementById("pastWeek");
        elements.classList.remove("btn-red");
        elements.classList.add("create-posts");

        const now = new Date();
        const start_date = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
        const end_date = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());
        dispatch(CheckedBranch(true))
        // setHideQuiz(true)
        dispatch(updateStudyDates({
            startDate: start_date,
            endDate: end_date
        }))

        dispatch(StudyBranchesFilter({
            startDate: null,
            endDate: null,
            customTab: 4
        }))

        dispatch(StudyChapterFilter({
            startDate: null,
            endDate: null,
            object: [],
            customTab: 4
        }))
        dispatch(StudySubChapterFilter({
            startDate: null,
            endDate: null,
            object: [],
            customTab: 4
        }))
        dispatch(videoMaterial({
            startDate: null,
            endDate: null,
            branchId: [],
            chapterId: [],
            subChapterId: [],
            LanguageId: LanguageFilterData == null ? exportselectedLanguages : LanguageFilterData,
            CountryId: CountryFilterData == null ? exportselectedCountry : CountryFilterData,
            UniversityId: UniversityFilterData == null ? exportselectedUniversity : UniversityFilterData,
            LevelId: LevelFilterData == null ? exportselectedLevel : LevelFilterData,
            Bookmarked: null,
            questionCount: 10,
            type: "VIDEO"
        }))
        localStorage.setItem("Custom_Tab_video_" + data?.id, JSON.stringify("custom"));

        localStorage.removeItem("start_date");
        localStorage.removeItem("end_date");
        localStorage.removeItem("Today_Date_video_" + data?.id, JSON.stringify(todayDate));
        localStorage.removeItem("Past_Week_Date_video_" + data?.id, JSON.stringify(weekDate));
        localStorage.removeItem("Past_Month_Date_video_" + data?.id, JSON.stringify(monthDate));
    }
    const dispatch = useDispatch();
    const Sorting = (e, isSorting) => {
         
        dispatch(videoMaterial({
            branchId: [],
            chapterId: [],
            subChapterId: [],
            LanguageId: LanguageFilterData == null ? exportselectedLanguages : LanguageFilterData,
            CountryId: CountryFilterData == null ? exportselectedCountry : CountryFilterData,
            UniversityId: UniversityFilterData == null ? exportselectedUniversity : UniversityFilterData,
            LevelId: LevelFilterData == null ? exportselectedLevel : LevelFilterData,
            Bookmarked: null,
            questionCount: 10,
            isSorting: isSorting,
            type: "VIDEO"
        }))
    }

    const [search, setSearch] = useState(null)
    const SearchQuiz = () => {

        dispatch(videoMaterial({
            branchId: [],
            chapterId: [],
            subChapterId: [],
            LanguageId: [],
            CountryId: [],
            UniversityId: [],
            LevelId: [],
            Bookmarked: null,
            questionCount: 10,
            type: "VIDEO",
            search: search == "" ? null : search
        }))
    }
    return (
        <>

            <Row className="quiz">
                <div className="col-md-12 col-sm-12">
                    <Row className='mt-3 ml-1'>
                        <Col md="10">
                            <div className="serch-input icon-input mb-3 d-flex justify-content-end" onKeyPress={SearchQuiz} >
                                <i className="font-sm ti-search pr-0 " style={{ color: "#E84545" }} onClick={SearchQuiz}></i>
                                <input
                                    type="text"
                                    className='form-control-search mt-3'
                                    placeholder='Search'
                                    onChange={SearchQuiz}
                                />
                            </div>
                        </Col>
                    </Row>
                    <div className='ml-4 mb-5'>
                        <button className={`d-none ${tabsBtn == 4 || todayDate == null ? "create-posts" : "btn-red"}`} id='colorChange' onClick={today} >Today</button>
                        &nbsp; &nbsp;
                        <button className={`d-none ${tabsBtn == 4 || weekDate == null ? "create-posts" : "btn-red"}`} id="thisWeek" onClick={ThisWeekQuiz}>This Week</button>
                        &nbsp; &nbsp;
                        <button className={`d-none ${tabsBtn == 4 || monthDate == null ? "create-posts" : "btn-red"}`} id="pastWeek" onClick={PastWeekBtn}>Past 4 Weeks</button>
                        &nbsp; &nbsp;
                        <button className={tabsBtn == 4 || CustomTab != null ? "btn-red " : "create-posts"} onClick={chnageColor} id="customBtn">Custom</button>
                    </div>

                    <UncontrolledDropdown style={{
                        position: "absolute",
                        right: "215px",
                        top: "112px"
                    }}>
                        <DropdownToggle caret style={{ backgroundColor: "#E84545", borderColor: "white" }}><FaSortAmountUpAlt /></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem
                                onClick={(e) => { Sorting(e, false) }}
                            >
                                Top Rated First</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem
                                onClick={(e) => { Sorting(e, true) }}
                            >Newest First</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <VideoMaterialTab />

                </div>

            </Row>
        </>
    )
}

export default VideoMaterials
