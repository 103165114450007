
import React, { useState, useRef } from "react";
import { Row, Col, Card } from "reactstrap"
import { BsImageFill } from "react-icons/bs"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { AiOutlineDownload } from "react-icons/ai"
import Switch from "react-switch";
import Header from "../../components/Header";
import MultipleSelection from "./MultipleSelection";
import SingleSelection from "./SingleSelection";
import { CreateQuizContent, ImageFileUrl, VideoFileUrl, AudioFileUrl } from "./CreateQuizContent";
import { DropdownMenuList, LevelId, ChapterId, BranchId, SubChapterId } from "./DropdownMenuList"
import QuizServices from "../services/QuizServices"
import Swal from "sweetalert2";
import { quizLike } from "../../store/features/QuizSlice";
import * as AWS from "aws-sdk";
import awsConfig from "../../awsConfi";
import AdminServices from "../services/AdminServices";
import Spinner from "../spinner/Spinner";
import { useHistory } from "react-router-dom";
import { API_BASE_URL, FILE_TEMPLATE_BASE_URL } from "../../constant";
const CreateQuiz = () => {
    let history = useHistory()
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    var data = JSON.parse(localStorageObject);
    const [premium, setPremium] = useState(false);
    const [worldwide, setWorlwide] = useState(false);
    const [objList, setObjList] = useState([]);
    const [loading, setLoading] = useState(false)
    const [content, setContent] = useState({
        imageUrl: "",
        videoUrl: "",
        audioUrl: "",
    })
    const sendData = (data) => {
        setContent(data)
    }

    const sendList = (list) => {

        setObjList(list)
    }
    const [dpropdownList, setDropdownList] = useState()
    const sendDropdownList = (list) => {
        setDropdownList(list)

    }

    const [addQuiz, setAddQuiz] = useState({

        userId: data.id,
        questionDetails: "",
        title: "",
        isPremimum: premium,
        questionType: "",
        questionOptions: [],
        countryId:  null,
        questionImageUrl: "",
        languageId: data.languageId,
        universityId: data.universityId

    })
    const handlePremium = nextChecked => {
        setPremium(nextChecked);

    };
    const handleWorldwide = nextChecked => {
        setWorlwide(nextChecked);

    };

    const [select, setSelect] = useState("MultipleSelection")
    const inputHandler = (e) => {
        setSelect(e.target.value)
    }
    const quillChange = (e) => {

        addQuiz.title = e;
    }


    const CreateQuiz = async () => {

        try {
            await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    //   setLoading(true);

                    var response = await QuizServices.CreateQuiz(addQuiz, content, objList, dpropdownList);

                    if (response.data.status === true) {

                        Swal.fire("Added!", "" + response.data.message, "success");
                        history.push("/quiz")

                    } else {
                        Swal.fire("Error!", response.data.message, "danger");
                    }
                    //   setLoading(false);
                }
            });
        }
        catch (error) {
            {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                })
            }
        }
    }

    const inputFile = useRef(null)
    const onButtonClick = () => {
        inputFile.current.click();
    };


    const BUCKET_NAME = awsConfig.bucketName;
    const FOLDER_NAME = awsConfig.folderName;
    const generateUUID = () => {
        var d = new Date().getTime();
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16;
            if (d > 0) {
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }
    function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }
    function replaceAll(str, match, replacement) {
        return str.replace(new RegExp(escapeRegExp(match), 'g'), () => replacement);
    }

    const ImageUploadOnAws = async (video) => {

        var guid = generateUUID();
        var fileName = guid + '_' + replaceAll(video.name, " ", "_");
        ;
        let bucket = new AWS.S3({
            params: { Bucket: BUCKET_NAME }
        });

        let params = {
            Key: "images" + "/" + fileName,
            ContentType: video.type,
            Body: video
        };

        bucket
            .upload(params)
            .on("httpUploadProgress", evt => {

            })
            .send(err => {

                if (err == null) {
                    console.log(`File Upload Successfuly`);
                    // setDisable(false);
                    
                    addQuiz.questionImageUrl = awsConfig.AWS_Base_URL+params.Key
                    console.log(params.Key);
                    console.log("ImageFileUrl", ImageFileUrl)
                }
                else {
                    console.log(err)

                }

            });
    }
    const [selectedImage, setSelectedImage] = useState()
    const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImage(e.target.files[0])
            var file = document.querySelector(
                'input[type=file]')['files'][0];
            //   tempData.StudentImage = file
            const imageFile = e.target.files[0];
            ImageUploadOnAws(imageFile);
        }
    };
    const inputUniRef = React.useRef();
    const uploadQuizQuestion = async (e) => {
        if (data.universityId == null) {
            Swal.fire("Error!", "You Can not Uplod Quiz Question Because You Do not have any Univeristy", "danger");
        }
        else {
            try {
                await Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        setLoading(true);
                        var response = await AdminServices.AddQuizQuestionFile(e.target.files[0], data.universityId, false, data.id);
                        
                        if (response.data.status === true) {
                            Swal.fire(" Uploaded!", "" + response.data.message, "success");
                            setLoading(false);
                        } else {
                            Swal.fire("Error!", response.data.message, "danger");
                            setLoading(false);
                        }

                    }
                });
            } catch (err) {
                console.log("error", err);
            }
        }
    }
    const styles = {
        container: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 50,
        },
        preview: {
            marginTop: 10,
            marginLeft: "-16px",
            display: "flex",
            flexDirection: "column",
        },
        image: { maxWidth: "20%", maxHeight: 320 },
        uploadBtn: {
            backgroundColor: "#E84545",
            borderRadius: "8px",
            opacity: "1",
            borderColor: "transparent",
            padding: "5px",
            color: "#FFFFFF",
            height: "38px",
            margin: "-16px",
            marginRight: "-1px",
            fontSize: "16px"
        }

    };
    return (
        <>
            <Header
                divclassName="shadow-none pos-fixed position-absolute"
                color="light"
            />

            <div className="sub-menu" style={{ minHeight: '100vh' }}>
                <a href={FILE_TEMPLATE_BASE_URL + "quiz_template.xls"} className='float-right mr-5  format-download mt-4' download> <AiOutlineDownload fontSize="1.5rem" />Smaple</a>
                <label className="float-right mr-5 mt-4 upload-btn" htmlFor="uploadQuizQuestion" style={{ padding: "5px 0px 0px 25px" }} >Upload </label>
                <input onChange={uploadQuizQuestion} accept=".xlsx,.xls,.csv" type='file' id='uploadQuizQuestion' ref={inputUniRef} hidden />
                {loading ? <Spinner /> :
                    <div className="ml-5 mr-5" >
                        <h1>  Create New Quiz</h1>
                        <span> <img src={API_BASE_URL + data.imagePath} alt="img" className='mt-2 ml-1 rounded-circle border' width="30px" /> </span>
                        <span className="mt-5 ml-2" style={{ color: "#E84545" }}>{data.firstName + " " + data.lastName}</span>
                        <DropdownMenuList sendDropdownList={sendDropdownList} />


                        <div className="mt-3">
                            <label>Free </label>
                            <Switch
                                onChange={handlePremium}
                                checked={premium}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                onColor="#E84545"
                                height={15}
                                width={35}
                                className="mt-3 ml-3 mr-3"
                            />

                            <label style={{ color: "#E84545" }}>Premium </label>
                        </div>

                        <label className="options mb-3">
                            <input
                                type="checkbox"
                                className="float-left checkbox"
                                style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "4px", marginRight: "7px" }}
                            />&nbsp;
                            I attest that the hereby published content is exclusively my work or does not infringe copyright.
                        </label>
                        {/* <div>
                            <label>Local </label>
                            <Switch
                                onChange={handleWorldwide}
                                checked={worldwide}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                onColor="#E84545"
                                height={15}
                                width={35}
                                className="mt-3 ml-3 mr-3"
                            />

                            <label style={{ color: "#E84545" }}>Worldwide </label>

                        </div> */}
                        <label className="options mb-3">
                            <input
                                type="checkbox"
                                className="float-left checkbox"
                                style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "4px", marginRight: "7px" }}
                            />&nbsp;
                            I understand I cannot spread worldwide content subject to copyright, even if I can have access through my University
                        </label>
                        <form action="#">
                            <p>
                                <input type="radio" id="test1" onChange={inputHandler} value="SingleSelection" name="radio-group" />
                                <label htmlFor="test1">Single Selection</label>
                            </p>
                            <p>
                                <input type="radio" id="test3" value="MultipleSelection" onChange={inputHandler} name="radio-group" />
                                <label htmlFor="test3">Multiple Selection</label>
                            </p>
                        </form>

                        <div className="ReactQuill">
                            <label>Add Question And Answer</label>

                            <label style={styles.uploadBtn} className=" float-right" for="upload"><BsImageFill className="mr-1" />Upload Question Image </label>
                            <input
                                type='file'
                                id='upload' ref={inputFile}
                                style={{ display: 'none' }}
                                accept="image/*"
                                onChange={imageChange} />

                            <ReactQuill
                                modules={{
                                    toolbar: {
                                        container: [
                                            [{ size: [] }],
                                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                            [{ list: 'ordered' }, { list: 'bullet' }],
                                        ],

                                    }
                                }}
                                theme="snow"
                                style={{ color: 'white' }}
                                name="title"
                                onChange={quillChange}
                                value={addQuiz.title}
                                // placeholder="Add Question..."
                                className="mb-2" />
                        </div>
                        {selectedImage && (
                            <div
                                style={styles.preview}
                            >
                                <img
                                    src={URL.createObjectURL(selectedImage)}
                                    style={styles.image}
                                    alt="Thumb"
                                />
                            </div>
                        )}
                        {select == "MultipleSelection" ?
                            <MultipleSelection sendList={sendList} /> :

                            <SingleSelection sendList={sendList} />
                        }
                        {/* <div className="text-white mt-3 more-ques"><BsFillPlusCircleFill /> Add More Questions</div> */}
                        <div className="text-white mt-5 ">Watch Explanation<span className="text-muted">(optional)</span></div>
                        <Row>
                            <Col md="12">
                                <input className="form-control" placeholder="Type here" name="questionDetails"
                                    value={addQuiz.questionDetails}
                                    onChange={(e) => { setAddQuiz({ ...addQuiz, questionDetails: e.target.value }) }}

                                />
                            </Col>
                        </Row>
                        {/* {premium && */}
                        <CreateQuizContent sendData={sendData} />

                        <Row>
                            <Col md="10"></Col>
                            <Col md="2">
                                <button className="create-post-btn mb-5" onClick={CreateQuiz} >Post</button>
                            </Col>
                        </Row>
                    </div>
                }
            </div>

        </>
    )
}
export default CreateQuiz