import React, { useState, useEffect } from 'react'
import { Row, Col, FormGroup, Input, Label, Select } from "reactstrap"
import AllFilterServices from '../../../services/AllFilterServices'
import AdminServices from '../../../services/AdminServices'
import { API_BASE_URL } from '../../../../constant'
const Vote = () => {

  useEffect(async () => {
    var response = await AllFilterServices.getAllCountries()
    setCountryList(response.data.payload);
  }, [])

  const [level, setLevel] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [universityList, setUniversityList] = useState([]);
  const [branchs, setBranch] = useState([]);
  const [chapter, setChapter] = useState([]);
  const [userList, setUserList] = useState([])


  let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
  var data = JSON.parse(localStorageObject);
  const GetChapters = async (e) => {
     
    var response = await AdminServices.GetChapterAdminCandidates(data.id, e.target.value)
    setUserList(response.data.payload)
  }
  const GetCountry = async (e) => {
    var response = await AdminServices.GetUniversitiesByCountryId(e.target.value)
    setUniversityList(response.data.payload)
  }
  const GetUniversity = async (e) => {
     
    var response = await AdminServices.GetLevelsByUniversityId(e.target.value)
    setLevel(response.data.payload)
  }
  const GetLevel = async (e) => {

    var response = await AdminServices.GetBranchesByLevelId(e.target.value)
    setBranch(response.data.payload)
  }
  const GetBranch = async (e) => {
    var response = await AdminServices.GetChaptersByBranchId(e.target.value)
    setChapter(response.data.payload)
  }

  const [vote, setVote] = useState("")
  const voteBtn = async (id,status) => {
     
    userList.find(x => x.id == id).isVotted = status;
    var response = await AdminServices.VoteChapterAdmin(data.id, id)
    setVote(response.data.status)

  }

  return (
    <>
      <Row >
        <Col md="4">
          <select className='form-control chapterApply' onChange={GetCountry}>
            <option value="">-- Select Country --</option>
            {countryList.map((value) => (
              <option value={value.id}>{value.name}</option>
            ))}
          </select>

        </Col>
        <Col md="4">
          <select className='form-control chapterApply' onChange={GetUniversity}>
            <option value="">-- Select University --</option>
            {universityList.map((value) => (
              <option value={value.id}>{value.name}</option>
            ))}
          </select>
        </Col>
        <Col md="4">
          <select className='form-control chapterApply' onChange={GetLevel}>
            <option value="">-- Select Level --</option>
            {level.map((value) => (
              <option value={value.id}>{value.title}</option>
            ))}
          </select>

        </Col>
      </Row>
      <Row className='mt-4 mb-4' >
        <Col md="4">
          <select className='form-control chapterApply' onChange={GetBranch}>
            <option value="">-- Select Branch --</option>
            {branchs.map((value) => (
              <option value={value.id}>{value.title}</option>
            ))}
          </select>
        </Col>
        <Col md="4">
          <select className='form-control chapterApply' onChange={GetChapters}>
            <option value="">-- Select Chapter --</option>
            {chapter.map((value) => (
              <option value={value.id}>{value.title}</option>
            ))}
          </select>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <div className='quizs social-search p-3'>
            <input className='form-control' style={{ backgroundColor: "#181818" }} placeholder='Search' />

            <div className='mt-5 social-search'>
              <ul className='social-search-list '>
                {userList?.map((value, index) => (
                  <>
                    <li key={index}>  <img alt="" src={API_BASE_URL + value.user.imagePath} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />{value.user.firstName + " " + value.user.lastName}
                      <p>3rd y MS, University of Geneva</p>
                      {value.isVotted == true ? <button style={{ marginRight: "0px" }} className='float-right follow'>Voted</button> : <button onClick={() => voteBtn(value.id,true)} className='float-right remove'>Vote</button>}
                      <p style={{ marginRight: "66px", marginTop: "-37px" }} className='float-right' >Total : {value.totalVotes}</p>
                    </li>
                  </>
                ))}

              </ul>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Vote