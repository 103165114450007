import React, { useState, useEffect } from 'react'
import { Row, Col } from "reactstrap"
import AllFilterServices from '../../services/AllFilterServices'
import { useSelector, useDispatch } from 'react-redux'
import AdminServices from '../../services/AdminServices'
const Premium = () => {
    const dispatch = useDispatch()

    const MyCreationList = useSelector(state => state.Admin.ContentCreatorList)
    const [selectedCountry, setSelectedCountry] = useState(null)
    const [selectedUniversity, setSelectedUniversity] = useState(null)
    const [selectedLevel, setSelectedLevel] = useState(null)




    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    var data = JSON.parse(localStorageObject);


    const [level, setLevel] = useState([]);
    const [countryList, setCountryList] = useState([]);
    useEffect(async () => {
        var response = await AllFilterServices.getAllCountries()
        setCountryList(response.data.payload);

    }, [])
    const [universityList, setUniversityList] = useState([]);
    const GetCountry = async (e) => {
        var response = await AdminServices.GetUniversitiesByCountryId(e.target.value)
        setUniversityList(response.data.payload)
        setSelectedCountry(e.target.value)
    }
    const GetUniversity = async (e) => {
         
        var response = await AdminServices.GetLevelsByUniversityId(e.target.value)
        setLevel(response.data.payload)
        setSelectedUniversity(e.target.value)
    }
    const GetLevel = async (e) => {
        var response = await AdminServices.GetBranchesByLevelId(e.target.value)
        setSelectedLevel(e.target.value)
    }

    return (
        <>

            <Row className='mt-0 mb-3 ml-5 mr-5'>
                <Col md="4">
                    <select className='form-control chapterApply' onChange={GetCountry}>
                        <option value="">-- Select Country --</option>
                        {countryList.map((value) => (
                            <option value={value.id}>{value.name}</option>
                        ))}
                    </select>

                </Col>
                <Col md="4">
                    <select className='form-control chapterApply' onChange={GetUniversity}>
                        <option value="">-- Select University --</option>
                        {universityList.map((value) => (
                            <option value={value.id}>{value.name}</option>
                        ))}
                    </select>
                </Col>
                <Col md="4">
                    <select className='form-control chapterApply' onChange={GetLevel}>
                        <option value="">-- Select Year --</option>
                        {level.map((value) => (
                            <option value={value.id}>{value.title}</option>
                        ))}
                    </select>

                </Col>


            </Row>
            <Row className='mt-0 mb-5 ml-5 mr-5'>
                <Col md="4">
                    <input className='form-control chapterApply' placeholder='Price' type='number' />
                </Col>
                <Col md="4">
                    <input className='form-control chapterApply' placeholder='Start Date' type='date' />
                </Col>
                <Col md="4">
                    <input className='form-control chapterApply' placeholder='end Date' type='date' />
                </Col>
                <Col md="12">
                    <label className="mt-3">
                        <input
                            type="checkbox"
                            className="float-left checkbox"
                            style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "7px", marginRight: "7px" }}
                        />&nbsp;
                        I have read and accepted terms and conditions           
                    </label>
                </Col>
            </Row>
        </>
    )
}

export default Premium