import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import { Tab, Tabs } from 'react-bootstrap';
import Social from './public/Social';
import hat from "../assests/iCON/iCON/noun-student-cap-1441568.png"
import pencil from "../assests/iCON/iCON/noun-edit-684936.png"
import trophy from "../assests/iCON/iCON/noun-trophy-5126269.png"
import goldTrophy from "../../assets/images/noun-trophy-1.png"
import siverTrophy from "../../assets/images/noun-trophy-5126269.png"
import BrownzTrophy from "../../assets/images/noun-trophy-2.png"

import socialGoldTrophy from "../../assets/images/Group 277.png"
import socialSilverTrophy from "../../assets/images/Group 278.png"
import socialBrownTrophy from "../../assets/images/Group 279.png"


import CheetahImg from "../../assets/images/Cheetah.png"
import QuizManiac from "../../assets/images/Quiz Maniac.png"
import Bookworm from "../../assets/images/Bookworm.png"
import Rockstar from "../../assets/images/Rockstar.png"
import SocialBird from "../../assets/images/Social Bird.png"
import TheTorch from "../../assets/images/The Torch.png"
import dummy from "../../view/assests/dummy.png"
import { Input, ModalFooter } from 'reactstrap';
import { RiUserFollowLine, RiStethoscopeFill } from "react-icons/ri"
import { MdMessage } from "react-icons/md"
import { TbFlag3 } from "react-icons/tb"
import { db } from '../../components/firebase'
import { onValue, ref } from "firebase/database";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import camera from "../../assets/iCON/Group 188.png"
import OtherUserProfileServices from '../services/OtherUserProfileServices';
import { FaGraduationCap } from "react-icons/fa"
import { API_BASE_URL } from '../../constant';
import Swal from "sweetalert2"
import Spinner from '../spinner/Spinner';


const OtherUserProfile = () => {

    useEffect(() => {
        SetOnlineStatus()
        getById()
        GetAchievementTrophies()
        GetContentCreatorTrophies()
        GetSocialTrophies()
    }, [])

    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const [follow, setFollow] = useState()
    const [achievementTrophies, setAchievementTrophies] = useState([])
    const [contentCreatorTrophies, setContentCreatorTrophies] = useState([])
    const [socialTrophies, setSocialTrophies] = useState([])


    const [block, setBlock] = useState()
    const [GetId, setGetId] = useState({
        firstName: "",
        lastName: "",
        imagePath: "",
        coverPath: "",
        coverPath: ""
    })
    async function getById() {
        let response = await OtherUserProfileServices.getStudentById(id, Studentdata.id, true);
        setFollow(response.data.isFollowed)
        setBlock(response.data.isBlocked)
        setGetId(response.data.payload);


    }
    async function GetAchievementTrophies() {
        let response = await OtherUserProfileServices.GetAchievmentTrophies(id);
        setAchievementTrophies(response.data.payload)
    }
    async function GetContentCreatorTrophies() {
        setLoading(true)
        let response = await OtherUserProfileServices.ContentCreatorTrophies(id);
        setContentCreatorTrophies(response.data.payload)

        setLoading(false)
    }

    async function GetSocialTrophies() {
        let response = await OtherUserProfileServices.SocialTrophies(id);

        setSocialTrophies(response.data.payload)
    }
    const FollowBtn = async () => {
        // if (typeof Studentdata.doctorId == 'undefined') {
        var response = await OtherUserProfileServices.FollowStudent(Studentdata.id, id, true, !follow)
        Swal.fire(
            'Done',
            response.data.message,
            'success'
        )
        window.location.reload()

    }
    const BlockBtn = async () => {
        // if (typeof Studentdata.doctorId == 'undefined') {
        var response = await OtherUserProfileServices.BlockStudent(Studentdata.id, id, true, !block)
        Swal.fire(
            'Done',
            response.data.message,
            'success'
        )
        window.location.reload()
    }

    const [isOnline, setIsOnline] = useState(false);


    function SetOnlineStatus() {
        onValue(ref(db, 'onlineUsers/' + '116'), (snapshot) => {
            const data = snapshot.val();
            setIsOnline((data && data.status == "online") ? true : false)
        });
    }


    return (
        <>
            <Header />

            <div className="course-details pb-lg--7 pt-4 pb-5 single-user-profile">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="middle-sidebar-left">
                                <div className="card d-block w-100 border-0 rounded-lg overflow-hidden mb-3">
                                    <div
                                        className="card-body position-relative h200 bg-gradiant-bottom bg-image-cover bg-image-center"
                                        style={{
                                            backgroundImage: `url(${GetId.coverPath != null ? API_BASE_URL + GetId.coverPath : "https://via.placeholder.com/1200x500.png"})`,
                                        }}
                                    ></div>
                                    <div className="card-body d-block w-100 pl-lg-5 pl-4 pr-4 user-profile-color">
                                        <figure className="avatar mb-0 mt--6 position-relative w90 z-index-1" >
                                            <img
                                                height="85px"
                                                src={API_BASE_URL + GetId.imagePath == null ? "https://via.placeholder.com/1200x500.png" : API_BASE_URL + GetId.imagePath}
                                                alt="Profile Picture"
                                                className="float-right p-1 bg-white rounded-circle w-100"
                                            />
                                        </figure>

                                        {id != Studentdata.id ?
                                            <div>
                                                {block == false ? <span className='float-right'><button className='btn btn-danger ml-2' onClick={BlockBtn}>block</button></span> : <span className='float-right'><button className='btn btn-danger ml-2' onClick={BlockBtn}>Unblock</button></span>}
                                                {follow == false ? <span className='float-right'><button className='btn btn-success ml-2' onClick={FollowBtn}>Follow</button></span> : <span className='float-right'><button className='btn btn-success ml-2' onClick={FollowBtn}>UnFollow</button></span>}
                                                {block == true ? <span className='float-right'> <button className='btn btn-primary' disabled>Message</button></span> : <Link to={`/message/${id}`}><span className='float-right'><button className='btn btn-primary'>Message</button></span></Link>}
                                            </div> : ""}
                                        <div className="clearfix"></div>
                                        <div className="row" style={{ marginLeft: "-60px" }}>
                                            <div className="col-xl-6 md-mb-2">
                                                <div>
                                                    <RiUserFollowLine fontSize="2rem" color="white" className="mr-2 ml-5" />
                                                    <Link to={`/message/${id}`}> <MdMessage fontSize="2rem" color="white" className="ml-2 mr-2" /></Link>
                                                    <TbFlag3 fontSize="1.5rem" color="white" className="ml-5" />
                                                </div>
                                                <h4 className="mt-3 mb-1 text-white">
                                                    {GetId.firstName + " " + GetId.lastName}({isOnline ? "online" : "offline"})
                                                    {/*  <img alt="" src={hat} width="25px" className='ml-2' /> */}
                                                    <FaGraduationCap fontSize="1.5rem" className='ml-3' />

                                                </h4>
                                                <span className="font-xssss d-inline-block ml-0" style={{ color: "#72BB53" }} >
                                                    3rd Year
                                                </span>

                                            </div>

                                            <div className="col-11">
                                                <p className="font-xssss lh-28 text-grey-500 mb-0 pl-0 mt-4">
                                                    {GetId.bio}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Tabs
                                    defaultActiveKey="publications"
                                    id="uncontrolled-tab-example"
                                    className="mb-3 mt-5  justify-content-between border-0 rounded-lg profile-tabs"
                                    style={{ backgroundColor: "#E84545", borderBottom: 'none', paddingRight: "60px", margin: "0px" }}
                                >
                                    <Tab eventKey="publications" title="Publications">
                                        <Social eventKey="publications"/>
                                    </Tab>
                                    <Tab eventKey="trophies" title="Trophies">
                                        <div className=" d-block w-100 border-0 rounded-lg overflow-hidden ">
                                            <div className="card-body mb-lg-3 pb-0">
                                                <h2 className="fw-400 font-lg d-block text-white">
                                                    Trophies
                                                    <a
                                                        href="/default-user-profile"
                                                        className="float-right"
                                                    >
                                                    </a>
                                                </h2>
                                            </div>
                                            <div className="card-body pb-0">
                                                <div className="row">
                                                    {achievementTrophies.map((value, index) => (
                                                        // Strat Single Demo
                                                        <div
                                                            className="col-xl-3 col-lg-3 col-md-3"
                                                            key={index}
                                                        >
                                                            <div className=" mb-4 d-block w-100  rounded-lg p-xxl-5 p-4 text-center" style={{ backgroundColor: "#000000", border: "1px solid white" }}>
                                                                <img
                                                                    src={value.trophy == "Gold" ? goldTrophy : value.trophy == "Bronze" ? BrownzTrophy : siverTrophy}
                                                                    alt="badge"
                                                                    width={80}
                                                                />

                                                                <h4 className="fw-700 font-xsss mt-4" style={{ color: "#E84545" }}>
                                                                    {value.trophy}
                                                                </h4>
                                                                <h3 className="fw-500 font-xssss text-grey-500 mt-3">
                                                                    {value.chapter}
                                                                </h3>

                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                        <div className=" d-block w-100 border-0 rounded-lg overflow-hidden ">
                                            <div className="card-body mb-lg-3 pb-0">
                                                <h2 className="fw-400 font-lg d-block text-white">
                                                    Badges
                                                    <a
                                                        href="/default-user-profile"
                                                        className="float-right"
                                                    >
                                                    </a>
                                                </h2>
                                            </div>

                                            <div className="card-body pb-0">
                                                <div className="row">
                                                    {socialTrophies?.cheetah == true ?
                                                        <div
                                                            className="col-xl-3 col-lg-3 col-md-3"
                                                        >
                                                            <div className=" mb-4 d-block w-100  rounded-lg p-xxl-5 p-2 text-center" style={{ backgroundColor: "#000000", border: "1px solid white" }}>

                                                                <img
                                                                    src={CheetahImg}
                                                                    alt="badge"
                                                                    width={200}
                                                                />

                                                                <h4 className="fw-400 mt-1 mb-3" style={{ color: "white" }}>
                                                                    Cheetah
                                                                </h4>

                                                            </div>
                                                        </div>
                                                        : ""}


                                                    {socialTrophies?.bookworm == true ?
                                                        <div
                                                            className="col-xl-3 col-lg-3 col-md-3"
                                                        >

                                                            <div className=" mb-4 d-block w-100  rounded-lg p-xxl-5 p-2 text-center" style={{ backgroundColor: "#000000", border: "1px solid white" }}>

                                                                <img
                                                                    src={Bookworm}
                                                                    alt="badge"
                                                                    width={200}
                                                                />

                                                                <h4 className="fw-400 mt-1 mb-3" style={{ color: "white" }}>
                                                                    Bookworm
                                                                </h4>


                                                            </div>

                                                        </div> : ""}
                                                    {socialTrophies?.quizManiac == true ?
                                                        <div
                                                            className="col-xl-3 col-lg-3 col-md-3"
                                                        >
                                                            <div className=" mb-4 d-block w-100  rounded-lg p-xxl-5 p-2 text-center" style={{ backgroundColor: "#000000", border: "1px solid white" }}>
                                                                <img
                                                                    src={QuizManiac}
                                                                    alt="badge"
                                                                    width={200}
                                                                />

                                                                <h4 className="fw-400 mt-1 mb-3" style={{ color: "white" }}>
                                                                    Quiz Maniac
                                                                </h4>

                                                            </div>
                                                        </div>
                                                        : ""}
                                                    {socialTrophies?.rockstar == true ?
                                                        <div
                                                            className="col-xl-3 col-lg-3 col-md-3"
                                                        >
                                                            <div className=" mb-4 d-block w-100  rounded-lg p-xxl-5 p-2 text-center" style={{ backgroundColor: "#000000", border: "1px solid white" }}>

                                                                <img
                                                                    src={Rockstar}
                                                                    alt="badge"
                                                                    width={200}
                                                                />


                                                                <h4 className="fw-400 mt-1 mb-3" style={{ color: "white" }}>
                                                                    Rock star
                                                                </h4>

                                                            </div>
                                                        </div>

                                                        : ""}
                                                    {socialTrophies?.socialBird == true ?
                                                        <div
                                                            className="col-xl-3 col-lg-3 col-md-3"
                                                        >
                                                            <div className=" mb-4 d-block w-100  rounded-lg p-xxl-5 p-2 text-center" style={{ backgroundColor: "#000000", border: "1px solid white" }}>

                                                                <img
                                                                    src={SocialBird}
                                                                    alt="badge"
                                                                    width={200}
                                                                />

                                                                <h4 className="fw-400 mt-1 mb-3" style={{ color: "white" }}>
                                                                    Social Bird
                                                                </h4>

                                                            </div>
                                                        </div>
                                                        : ""}


                                                    {socialTrophies?.theTorch == true ?
                                                        <div
                                                            className="col-xl-3 col-lg-3 col-md-3"
                                                        >
                                                            <div className=" mb-4 d-block w-100  rounded-lg p-xxl-5 p-2 text-center" style={{ backgroundColor: "#000000", border: "1px solid white" }}>

                                                                <img
                                                                    src={TheTorch}
                                                                    alt="badge"
                                                                    width={200}
                                                                />
                                                                <h4 className="fw-400 mt-1 mb-3" style={{ color: "white" }}>
                                                                    The Torch
                                                                </h4>


                                                            </div>
                                                        </div>
                                                        : ""}
                                                </div>
                                            </div>
                                        </div>
                                        {loading ? <Spinner /> :
                                            <div className=" d-block w-100 border-0 rounded-lg overflow-hidden ">
                                                <div className="card-body mb-lg-3 pb-0">
                                                    <h2 className="fw-400 font-lg d-block text-white">
                                                        Creator Award
                                                        <a
                                                            href="/default-user-profile"
                                                            className="float-right"
                                                        >
                                                        </a>
                                                    </h2>
                                                </div>
                                                <div className="card-body pb-0">
                                                    {/* <div className=""> */}
                                                    {contentCreatorTrophies.map((value, index) => (
                                                        <>
                                                            <h3 className="fw-400  d-block" style={{ color: 'gray' }}>
                                                                {value.level}
                                                            </h3>
                                                            <div
                                                                className="row"
                                                                key={index}
                                                            >

                                                                {value.trophies.map((trophies, index) => (

                                                                    <div
                                                                        className="col-xl-3 col-lg-3 col-md-3"
                                                                        key={index}
                                                                    >
                                                                        <div className=" mb-4 d-block w-100  rounded-lg p-xxl-5 p-4 text-center" style={{ backgroundColor: "#000000", border: "1px solid white" }}>
                                                                            <img alt=""
                                                                                src={trophies.trophy == "Gold" ? socialGoldTrophy : trophies.trophy == "Bronze" ? socialBrownTrophy : socialSilverTrophy}
                                                                                width={130}
                                                                            />

                                                                            <h4 className="fw-700 font-xsss mt-4" style={{ color: "#E84545" }}>
                                                                                {trophies.trophy}
                                                                            </h4>
                                                                            <h3 className="fw-500 font-xssss text-grey-500 mt-3">
                                                                                {trophies.chapter}
                                                                            </h3>


                                                                        </div>
                                                                    </div>
                                                                ))}

                                                            </div>
                                                        </>

                                                    ))}
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        }
                                    </Tab>
                                    <Tab eventKey="followers" title="Followers">
                                        <Input className='search-follower-inputField d-flex justify-content-center' placeholder='Search' />
                                        <div className='mt-5 social-search'>
                                            <ul className='social-search-list '>
                                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>

                                            </ul>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="following" title="Following">
                                        <Input className='search-follower-inputField d-flex justify-content-center' placeholder='Search' />
                                        <div className='mt-5 social-search'>
                                            <ul className='social-search-list '>
                                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>
                                                <li>  <img src={dummy} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />Amelia Jeannet<p>3rd y MS, University of Geneva</p><button className='float-right remove'>Unfollow</button></li>

                                            </ul>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="bookmarked" title="Bookmarked">
                                        <Social eventKey="bookmarked" />
                                    </Tab>

                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default OtherUserProfile