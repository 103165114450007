import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../constant";


export const quizQuestion = createAsyncThunk("quiz/createQuiz", async (object) => {
 
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);

    var res = await fetch(API_BASE_URL + `api/Quiz/GetFilteredQuestions?SID=${Studentdata.id}&JWT=${Studentdata.jwt}`, {
        method: 'POST',
        headers: {
            Accept: "application/json",
            "content-type": "application/json"
        },
        body: JSON.stringify(object.data == undefined ? object : object.data)
    });
    var data = await res.json();

    if (data.status) {
        return ({
            hideLoadMore: data.payload.length < 10 ? true : false,
            data: object.oldQuiz == undefined ? data.payload : object.oldQuiz.concat(data.payload)
        })
    }
    else {
        return {
            data: []
        };
    }
})

export const BranchesFilter = createAsyncThunk("quiz/quizBranch", async (object) => {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
 debugger
    if (object.startDate == null) {
        var res = await fetch(API_BASE_URL + `api/Branch/GetAllBranches?SID=${Studentdata.id}&JWT=${Studentdata.jwt}&CustomTab=${object.customTab==undefined?0:object.customTab}&levelId=${Studentdata.userLevelId}`, {
            method: 'GET',
        })
    }
    else {
    var res = await fetch(API_BASE_URL + `api/Branch/GetAllBranches?SID=${Studentdata.id}&JWT=${Studentdata.jwt}&StartDate=${object.startDate==undefined?null:object.startDate}&EndDate=${object.endDate==undefined?null:object.endDate}&CustomTab=${object.customTab==undefined?0:object.customTab}&levelId=${Studentdata.userLevelId}`, {
        method: 'GET',

    })
    }
    const data = await res.json();
    var tempSelectedBranch = [];
    data.payload.map((item) => tempSelectedBranch.push(item.id))
    localStorage.setItem("Branch_List_" + Studentdata.id, JSON.stringify(tempSelectedBranch));

    return data.payload;


})

export const ChapterFilter = createAsyncThunk("quiz/quizChapter", async (object) => {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
     
    if (object.startDate == null) {

        var res = await fetch(API_BASE_URL + `api/Chapater/GetAllChaptersByBranchIds?SID=${Studentdata.id}&JWT=${Studentdata.jwt}&CustomTab=${object.customTab==undefined?0:object.customTab}`, {
            method: 'post',
            headers: {
                Accept: "application/json",
                "content-type": "application/json"
            },
            body: JSON.stringify(object.object)

        })
    }
    else {
    var res = await fetch(API_BASE_URL + `api/Chapater/GetAllChaptersByBranchIds?SID=${Studentdata.id}&JWT=${Studentdata.jwt}&StartDate=${object.startDate==undefined?null:object.startDate}&EndDate=${object.endDate==undefined?null:object.endDate}&CustomTab=${object.customTab==undefined?0:object.customTab}`, {
        method: 'post',
        headers: {
            Accept: "application/json",
            "content-type": "application/json"
        },
        body: JSON.stringify(object.object)

    })
    }


    const data = await res.json();
    var tempSelectedChapter = [];
    data.payload.map((item) => tempSelectedChapter.push(item.id))
    localStorage.setItem("Chapter_List_" + Studentdata.id, JSON.stringify(tempSelectedChapter));
    return data.payload;

})


export const SubChapterFilter = createAsyncThunk("quiz/quizSubChapter", async (object) => {
     
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    if (object.startDate == null) {
        var res = await fetch(API_BASE_URL + `api/Chapater/GetAllSubChaptersByChapterIds?SID=${Studentdata.id}&JWT=${Studentdata.jwt}&CustomTab=${object.customTab==undefined?0:object.customTab}`, {
            method: 'post',
            headers: {
                Accept: "application/json",
                "content-type": "application/json"
            },
            body: JSON.stringify(object.object)

        })
    }
    else {
    var res = await fetch(API_BASE_URL + `api/Chapater/GetAllSubChaptersByChapterIds?SID=${Studentdata.id}&JWT=${Studentdata.jwt}&StartDate=${object.startDate==undefined?null:object.startDate}&EndDate=${object.endDate==undefined?null:object.endDate}&CustomTab=${object.customTab==undefined?0:object.customTab}`, {
        method: 'post',
        headers: {
            Accept: "application/json",
            "content-type": "application/json"
        },
        body: JSON.stringify(object.object)

    })
    }

     
    const data = await res.json();
    var tempSelectedSubChapter = [];
    data.payload.map(item => tempSelectedSubChapter.push(item.id))
    localStorage.setItem("Subchapter_List_" + Studentdata.id, JSON.stringify(tempSelectedSubChapter));
    return data.payload;

})


const quizSlice = createSlice(
    {
        name: "post",
        initialState:
        {
            dates: {
                startDate: null,
                endDate: null,
            },
            quiz: [],
            brancheFilter: [],
            chapterByBranchFilter: [],
            subchapterByChapterFilter: [],
            loading: false,
            loadMore: false,
            hideLoadMore: false,
            tabs: 0,
            isChecked: false,
            isBranchFilterChangedInCustomTab: false,
        },
        reducers: {
            updateQuiz: (state, action) => {

                state.quiz = action.payload;
            },
            updateDates: (state, action) => {

                state.dates = action.payload;
            },
            ChnageTabs: (state, action) => {

                state.tabs = action.payload;
            },
            CheckedBranch: (state, action) => {

                state.isChecked = action.payload;
                state.isBranchFilterChangedInCustomTab = action.payload;
                state.isBranchFirstLoad = action.payload;
                state.isChapterFirstLoad = action.payload;
                state.isSubChapterFirstLoad = action.payload;
            },
            BranchFilterChangedInCustomTab: (state, action) => {

                state.isBranchFilterChangedInCustomTab = action.payload;
            },
        },
        extraReducers: {
            [quizQuestion.pending]: (state, action) => {
                // state.loading = true;
                state.firstLoad = true;
                state.loadMore = true;
            },
            [quizQuestion.fulfilled]: (state, action) => {
                // state.loading = false;
                state.loadMore = false;
                state.hideLoadMore = action.payload.hideLoadMore;
                state.quiz = action.payload.data;
                state.firstLoad = false;
            },
            [BranchesFilter.fulfilled]: (state, action) => {
                state.loading = false;
                state.brancheFilter = action.payload;
            },
            [ChapterFilter.fulfilled]: (state, action) => {
                state.loading = false;
                state.chapterByBranchFilter = action.payload;
            },
            [SubChapterFilter.fulfilled]: (state, action) => {
                state.loading = false;
                state.subchapterByChapterFilter = action.payload;
            }
        }

    })


export const { updateQuiz, updateDates, updateBranches, ChnageTabs, CheckedBranch, BranchFilterChangedInCustomTab } = quizSlice.actions;

export default quizSlice.reducer;