import React, { useState, useEffect } from 'react'
import { Row, Col, FormGroup, Input, Label, Select, Modal, ModalBody, ModalHeader } from "reactstrap"
import dummy from "../../../assests/dummy.png"
import { MdOutlineClose } from "react-icons/md"
import { AiOutlineDownload } from "react-icons/ai"
import AllFilterServices from '../../../services/AllFilterServices'
import reportIcon from "../../../assests/iCON/iCON/noun-alert-5119017.png"
import Calendar from 'react-calendar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import StudentManagment from './manage/StudentManagment'
import ChapterManagment from './manage/ChapterManagment'
import AdminServices from '../../../services/AdminServices'
import { useDispatch, useSelector } from 'react-redux'
import { GetStudentCohortAdmin, GetChapterAdminsCohortAdmins } from '../../redux/features/AdminSlice'
import Swal from "sweetalert2"
import { FileUploader } from "react-drag-drop-files";
import { FILE_TEMPLATE_BASE_URL } from '../../../../constant'
import ProfileServices from '../../../services/ProfileServices'

const Mange = () => {

  const dispatch = useDispatch()

  const [loading,setLoading]=useState(false)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedUniversity, setSelectedUniversity] = useState(null)
  const [selectedLevel, setSelectedLevel] = useState(null)
  const [selectedBranch, setSelectedBranch] = useState(null)
  const [selectedChapter, setSelectedChapter] = useState(null)
  const [selectedContent, setSelectedContent] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [selectedYear, setSelectedYear] = useState(null)

  let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
  var data = JSON.parse(localStorageObject);

  var SelectedFilterData = {
    userId: data.id,
    branchId: selectedBranch,
    chapterId: selectedChapter,
    CountryId: selectedCountry,
    UniversityId: selectedUniversity,
    LevelId: selectedLevel,
    contentType: selectedContent,
    status: selectedStatus,
    year: selectedYear
  }
  const [level, setLevel] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [universityList, setUniversityList] = useState([]);
  const [branchs, setBranch] = useState([]);
  const [checkedCountry, setCheckedCountry] = useState("")
  const [checkedUniversity, setCheckedUniversity] = useState("")
  async function fetchGetUserApi() {
    var response = await ProfileServices.GetStudentById(data.id);
    console.log("users", response.data);
    if (response.data.payload.isCountryAdmin && !response.data.payload.isSuperAdmin) {
      setCheckedCountry(response.data.payload.countryId)
      GetCountry(response.data.payload.countryId)
      if (response.data.payload.isUniversityAdmin && !response.data.payload.isSuperAdmin) {
        setCheckedUniversity(response.data.payload.universityId)
        GetUniversity(response.data.payload.universityId)
      }
      else {
        setCheckedUniversity("")
      }
    }
    else {
      setCheckedCountry("")
    }
  }

  async function GetAllCountries() {
    var response = await AllFilterServices.getAllCountries()
    setCountryList(response.data.payload);
  }


  useEffect(async () => {
    fetchGetUserApi()
    GetAllCountries()
  }, [])
  const GetCountry = async (e) => {
    var response = await AdminServices.GetUniversitiesByCountryId(e)
    setUniversityList(response.data.payload)
    setSelectedCountry(e)
    SelectedFilterData.CountryId = e;
    // dispatch(GetStudentCohortAdmin(SelectedFilterData))
    // dispatch(GetChapterAdminsCohortAdmins(SelectedFilterData))
  }

  const GetUniversity = async (e) => {
     
    var response = await AdminServices.GetLevelsByUniversityId(e)
    setLevel(response.data.payload)
    setSelectedUniversity(e)
    SelectedFilterData.UniversityId = e;
    // dispatch(GetStudentCohortAdmin(SelectedFilterData))
    // dispatch(GetChapterAdminsCohortAdmins(SelectedFilterData))

  }
  const GetLevel = async (e) => {

    var response = await AdminServices.GetBranchesByLevelId(e.target.value)
    setBranch(response.data.payload)
    setSelectedLevel(e.target.value)
    SelectedFilterData.LevelId = e.target.value;
    dispatch(GetStudentCohortAdmin(SelectedFilterData))
    dispatch(GetChapterAdminsCohortAdmins(SelectedFilterData))

  }
  const [calender, setCalander] = useState(false)
  const createCalander = () => {
    setCalander(!calender)
  }
  const [value, onChange] = useState(new Date());
  const [report, setReport] = useState(false)
  const createReport = () => {
    setReport(!report)
  }

  const [date, setDate] = useState(false)
  const createDate = () => {
    setDate(!date)
  }
  const uploadCohortFile = async (e) => {
     
    if (selectedLevel == null) {
      Swal.fire("Error!", "Please Select a Level First", "danger");
    }
    else {
      setLoading(true)
      var response = await AdminServices.AddCohortStudyPlanFile(e[0], selectedLevel);
      if (response.data.status === true) {
        setLoading(false)
        Swal.fire(" Uploaded!", "" + "File Uploaded Successfull", "success");
      } else {
        setLoading(false)
        Swal.fire("Error!", response.data.message, "danger");
      }
    }

  }
  const fileTypes = ["xlsx", "xls", "csv"];

  return (
    <>
      <Modal style={{ maxWidth: "500px" }}
        isOpen={calender}
        toggle={createCalander.bind(null)}
        className="modal-tags"
      >
        <ModalHeader toggle={createCalander.bind(null)}>Student Card</ModalHeader>
        <ModalBody>
           <img alt="" src="https://img.freepik.com/premium-vector/drivers-license-plastic-identity-card-vector-flat-illustration-template_501173-456.jpg?w=2000" width="465px" height="240" />
        </ModalBody>


        {/* <button className='report-btn'>Submit</button> */}

      </Modal>
      <Modal
        isOpen={report}
        toggle={createReport.bind(null)}
        className="modal-tags"
        style={{ maxWidth: "350px" }}
      >
        <div style={{ margin: "20px" }}>
          <h3 style={{ color: "white" }}> <img alt="" src={reportIcon} width="20px" className='mr-2' />Report</h3>
          <MdOutlineClose className='tag-close-btn' color='white' onClick={() => setReport(false)} />
          <div className='add-tag-border'></div>
        </div>

        <ModalBody>
          <div>
            <h2 style={{ color: "white" }}>Inappropriate <b>20</b></h2>
            <h2 style={{ color: "white" }}>Misleading <b>4</b></h2>
            <h2 style={{ color: "white" }}>Incorrect Content <b>10</b></h2>
          </div>

        </ModalBody>
        {/* <button className='report-btn'>Submit</button> */}

      </Modal>
      <Modal
        isOpen={date}
        toggle={createDate.bind(null)}
        className="modal-tags"
        style={{ maxWidth: "350px" }}
      >


        <ModalBody>
          <Calendar onChange={onChange} value={value} />

        </ModalBody>
        {/* <button className='report-btn'>Submit</button> */}

      </Modal>
      <Row>
        <Col md="4">
          <select className='form-control chapterApply' onChange={(e) => GetCountry(e.target.value)} disabled={checkedCountry == "" ? false : true}>
            <option value="">-- Select Country --</option>
            {countryList.map((value) => (
              <option value={value.id} selected={checkedCountry == value.id} > {value.name}</option>
            ))}
          </select>

        </Col>
        <Col md="4">
          <select className='form-control chapterApply' onChange={(e) => GetUniversity(e.target.value)} disabled={checkedUniversity == "" ? false : true}>
            <option value="">-- Select University --</option>
            {universityList.map((value) => (
              <option value={value.id} selected={checkedUniversity == value.id} >{value.name}</option>
            ))}
          </select>
        </Col>
        <Col md="4">
          <select className='form-control chapterApply' onChange={GetLevel}>
            <option value="">-- Select Year --</option>
            {level.map((value) => (
              <option value={value.id}>{value.title}</option>
            ))}
          </select>

        </Col>



        {/* <Col md="6">
          <table className='content-creator-table' style={{ margin: "0px" }}>
            <tr>
              <th>Chapter Admin</th>
              <th>Branch</th>
              <th>Chapter</th>
              <th>Total Chapter flags</th>
              <th>Flags/like ratio</th>
              <th>Contact Chapter Admin</th>
              <th>Dismiss</th>
            </tr>
            <tr>
              <td>Alfreds Futterkiste</td>
              <td>hello</td>
              <td>Maria Anders</td>
              <td>Germany</td>
              <td>Germany</td>
              <td><HiDocumentText fontSize="2rem" onClick={(event) => { createReport(event) }} /></td>
              <td style={{ width: "80px" }}><div className="d-flex justify-content-center"><AiFillEdit fontSize="1.5rem" color="green" />  <AiFillDelete fontSize="1.5rem" color="red" /></div></td>
            </tr>


          </table>
        </Col> */}


        {/* <Col md="6" className='mt-3'>
        <butoon style={{border:"1px solid white", padding:"10px",borderRadius:"10px",marginTop:"10px"}}  onClick={(event) => { createDate(event) }} >Calender</butoon>
        </Col>
        <Col md="6" className='mt-3'>
        <butoon style={{border:"1px solid white", padding:"10px",borderRadius:"10px",marginTop:"10px"}}  >Chapter Admin Election date</butoon>
        </Col> */}
      </Row>
      {/* <input type='file' className='mt-4' onChange={uploadCohortFile} accept=".xlsx,.xls,.csv" /> */}
      {loading ? <div className="spinner"></div> :
      <Row>
        <Col md="4" className='mt-4'>
          <a href={FILE_TEMPLATE_BASE_URL + "study_plan_template.xls"} className='format-download mt-5' download> <AiOutlineDownload fontSize="1.5rem" />Smaple</a>
        </Col>
        <Col md="8">
          <FileUploader
            className='mt-4'
            multiple={true}
            handleChange={uploadCohortFile}
            name="file"
            types={fileTypes}
          />
        </Col>
      </Row>
}
      <Tabs
        defaultActiveKey="studentMangament"
        id="uncontrolled-tab-example"
        className="mb-3 mt-3 cohort-admin-tabs"
      >    

        <Tab eventKey="studentMangament" title="Student Mangament">
          <StudentManagment adminId={data.id} SelectedFilterData={SelectedFilterData}  />
        </Tab>
        <Tab eventKey="ChapterAdminMangament" title="Chapter Admin Mangament">
          <ChapterManagment GetBranchesByLevelId={selectedLevel} />
        </Tab>
      </Tabs>

    </>
  )
}

export default Mange