import React,{useState,useEffect} from 'react'
import { Row, Col, FormGroup, Input, Label, Select } from "reactstrap"
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css"
import AllFilterServices from '../../../services/AllFilterServices'
import { CSVLink } from 'react-csv';
const MedicalEducation = () => {
    const [countryList, setCountryList] = useState([]);
  

    return (
        <>  
         <Row className='mt-5'>
        <Col md="12" className='mb-3'>
          <label className="download-btn  float-right mr-3" htmlFor="uploadCountryFile">Upload</label>
          {/* <input type='file'  id='uploadCountryFile'ref={inputImgRef} hidden/> */}
          <CSVLink className="admin-upload-btn  mr-3"
           data={countryList}
           >Download</CSVLink>
        </Col>
      </Row>
             <Col md="12" className='mt-4'>
                                    <ReactTable
                                        style={{ fontSize: "12px" }}
                                        columns={[

                                            {
                                                Header: "University",
                                                accessor: "#",
                                            },
                                            {
                                                Header: "Uni Admin Name",
                                                accessor: "#",
                                            },

                                            {
                                                Header: "See Uni Admin Table",
                                                accessor: "#",
                                            },

                                            {
                                                Header: "Total View",
                                                accessor: "totalViews",
                                            },
                                            {
                                                Header: "Total Likes",
                                                accessor: "totalLikes",
                                            },
                                            {
                                                Header: "Like To view Ratio",
                                                accessor: "#",
                                            },
                                           
                                        
                                            {
                                                Header: "Total Flag",
                                                accessor: "totalFlags",
                                            },
                                            {
                                                Header: "Flag to view ratio",
                                                accessor: "flagViewRatio",
                                            },
                                            {
                                                Header: "Contact Cohort Admin",
                                                accessor: "#",
                                            },
                                            {
                                                Header: "MEDcoin",
                                                accessor: "medCoins",
                                            },
                                            {
                                                Header: "Dismiss/Enroll",
                                                accessor: "#",
                                            },


                                        ]}
                                        defaultPageSize={5}
                                        // showPaginationBottom={true}
                                        className="-striped -highlight"
                                        // data={AllCountryAdmins}
                                    // filterable
                                    // defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}

                                    />
                                </Col>

          
        </>
    )
}

export default MedicalEducation