import React, { useState, useEffect } from 'react'
import { Row, Col, FormGroup, Input, Label, Select, Modal, ModalBody, ModalHeader } from "reactstrap"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import MedcoinSettings from './monetisation-tabs/MedcoinSettings';
import RewardSettings from './monetisation-tabs/RewardSettings';


const Monetisation = () => {

  return (
    <>

      <Tabs
        defaultActiveKey="MedcoinSettings"
        id="uncontrolled-tab-example"
        className="mb-3 mt-3 cohort-admin-tabs"
       >
        <Tab eventKey="MedcoinSettings" title="Medcoin Settings">
          <MedcoinSettings />
        </Tab>
        <Tab eventKey="RewardSettings" title="Reward Settings">
          <RewardSettings />
        </Tab>
      </Tabs>

    </>
  )
}

export default Monetisation