import axios from 'axios';
import { API_BASE_URL } from '../../constant';

class AllFilterServices {

  getAllCountries() {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    return axios.get(API_BASE_URL + `api/Country/GetCountriesList?SID=${Studentdata.id}&JWT=${Studentdata.jwt}`);
  }
  getAllUniversities() {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    return axios.get(API_BASE_URL + `api/University/GetUniversities?SID=${Studentdata.id}&JWT=${Studentdata.jwt}`);
  }
  getAllUniversities() {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    return axios.get(API_BASE_URL + `api/University/GetUniversities?SID=${Studentdata.id}&JWT=${Studentdata.jwt}`);
  }
  getAllLanguage() {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    return axios.get(API_BASE_URL + `api/Language/GetLangagues?SID=${Studentdata.id}&JWT=${Studentdata.jwt}`);
  }
  getAllBranches(StartDate, EndDate,levelId) {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    return axios.get(API_BASE_URL + `api/Branch/GetAllBranches?SID=${Studentdata.id}&JWT=${Studentdata.jwt}&StartDate=${StartDate==undefined?null:StartDate}&EndDate=${EndDate==undefined?null:EndDate}&CustomTab=${0}&levelId=${levelId==undefined?Studentdata.userLevelId:levelId}`);
  }
  getAllChapters() {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    return axios.get(API_BASE_URL + `api/Chapater/GetAllChapters?SID=${Studentdata.id}&JWT=${Studentdata.jwt}`);
  }
  getAllSubchapter() {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    return axios.get(API_BASE_URL + `api/Chapater/GetAllSubChapters?SID=${Studentdata.id}&JWT=${Studentdata.jwt}`);
  }
  GetAllChaptersByBranchIds(object, StartDate, EndDate) {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    return axios.post(API_BASE_URL + `api/Chapater/GetAllChaptersByBranchIds?SID=${Studentdata.id}&JWT=${Studentdata.jwt}&StartDate=${StartDate}&EndDate=${EndDate}`, object)
  }
  GetAllSubChaptersByChapterIds(object, StartDate, EndDate) {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    return axios.post(API_BASE_URL + `api/Chapater/GetAllSubChaptersByChapterIds?SID=${Studentdata.id}&JWT=${Studentdata.jwt}&StartDate=${StartDate==undefined?null:StartDate}&EndDate=${EndDate==undefined?null:EndDate}`, object)
  }
  GetCountriesListWithoutAuth() {
    return axios.get(API_BASE_URL + "api/Country/GetCountriesListWithoutAuth");
  }
  GetLangaguesWithoutAuth() {
    return axios.get(API_BASE_URL + "api/Language/GetLangaguesWithoutAuth");
  }
  GetUniversitiesWithoutAuth() {
    return axios.get(API_BASE_URL + "api/University/GetUniversitiesWithoutAuth");
  }

  getAllIntrests(Id) {
    return axios.get(API_BASE_URL + `api/Util/GetLevelsByUniversityId?Id=${Id}`);
  }
  getAllSignUpIntrests(countryId,UniversityId) {
    debugger
    return axios.get(API_BASE_URL + `api/User/GetInterests?countryId=${countryId}&UniversityId=${UniversityId}`);
  }
  getAllHospital() {
    return axios.get(API_BASE_URL + "api/Hospital/GetHospitals");
  }


}

export default new AllFilterServices();

