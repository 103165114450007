import React, { useState, useEffect } from 'react';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md"
import {
  Card,
  CardBody,
} from "reactstrap";
import calenderImg from "../../assets/iCON/noun-calendar-5143299.png"
import filter from "../../assets/iCON/noun-filter-4025735.png"
import branch from "../../assets/iCON/noun-hub-2586471.png"
import document from "../../assets/iCON/noun-document-1842194.png"
import world from "../../assets/iCON/noun-country-2450449.png"
import school from "../../assets/iCON/noun-university-1340867.png"
import levels from "../../assets/iCON/noun-typing-3165306.png"
import save from "../../assets/iCON/noun-bookmark-3025188.png"
import languages from "../../assets/iCON/language.png";
import AllFilterServices from '../services/AllFilterServices';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useHistory } from 'react-router-dom';
import { SessionLogoutMsg } from "../../constant"
import { useDispatch, useSelector } from "react-redux";
import { StudyBranchesFilter, StudyChapterFilter, StudySubChapterFilter, videoMaterial, updateStudyDates, ChnageTabs, BranchFilterChangedInCustomTab } from "./redux/features/StudySlice"
import moment from "moment"
import { MdOutlineCancel } from "react-icons/md"
require('checkboxes');

let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
var data = JSON.parse(localStorageObject);

let localStorageObjectBranch = localStorage.getItem("Branche_List_video_" + data?.id);
var BrancheFilterData = JSON.parse(localStorageObjectBranch);

let localStorageObjectChapter = localStorage.getItem("Chapter_List_video_" + data?.id);
var ChapterFilterData = JSON.parse(localStorageObjectChapter);

let localStorageObjectSubChapter = localStorage.getItem("Subchapter_List_video_" + data?.id);
var SubChapterFilterData = JSON.parse(localStorageObjectSubChapter);

let localStorageObjectLanguage = localStorage.getItem("Language_List_video_" + data?.id);
var LanguageFilterData = JSON.parse(localStorageObjectLanguage);
var LanguageFilterData = localStorageObjectLanguage == null ? [] : JSON.parse(localStorageObjectLanguage);
LanguageFilterData.push(data?.languageId)

let localStorageObjectCountry = localStorage.getItem("Country_List_video_" + data?.id);
var CountryFilterData = JSON.parse(localStorageObjectCountry);
var CountryFilterData = localStorageObjectCountry == null ? [] : JSON.parse(localStorageObjectCountry);
CountryFilterData.push(data?.countryId)

let localStorageObjectUniversity = localStorage.getItem("University_List_video_" + data?.id);
var UniversityFilterData = JSON.parse(localStorageObjectUniversity);
var UniversityFilterData = localStorageObjectUniversity == null ? [] : JSON.parse(localStorageObjectUniversity);
UniversityFilterData.push(data?.universityId)

let localStorageObjectLevel = localStorage.getItem("Level_List_video_" + data?.id);
var LevelFilterData = localStorageObjectLevel == null ? [] : JSON.parse(localStorageObjectLevel);
LevelFilterData.push(data?.userLevelId)

let localStorageObjectBookmarked = localStorage.getItem("Book_marked_video_" + data?.id);
var BookmarkedData = JSON.parse(localStorageObjectBookmarked);

let localStorageObjectQuestionCount = localStorage.getItem("Question_Count");
var QuestionCount = JSON.parse(localStorageObjectQuestionCount);



export var exportselectedBranch = BrancheFilterData == null ? [] : BrancheFilterData
export var exportselectedChapter = ChapterFilterData == null ? [] : ChapterFilterData
export var exportselectedSubchapter = SubChapterFilterData == null ? [] : SubChapterFilterData
export var exportselectedLanguages = LanguageFilterData == null ? [] : LanguageFilterData
export var exportselectedUniversity = UniversityFilterData == null ? [] : UniversityFilterData
export var exportselectedLevel = LevelFilterData == null ? [] : LevelFilterData
export var selectedBookmarked = BookmarkedData == null ? null : BookmarkedData;
export var exportselectedCountry = CountryFilterData == null ? [] : CountryFilterData
export var startDate = null
export var endDate = null

export const StudyFilters = ({ week }) => {

  const calenderDates = useSelector(state => state.study.dates)
  const BranchFilterData = useSelector(state => state.study.studyBrancheFilter)
  const studyChapterFilterData = useSelector(state => state.study.studyChapterFilter)
  const SubChapterFilterData = useSelector(state => state.study.studySubChapterFilter)
  const isBranchFilterChangedInCustomTab = useSelector(state => state.study.isBranchFilterChangedInCustomTab)


  let localStorageObjectBranch = localStorage.getItem("Branche_List_video_" + data?.id);
  var BranchesFilterData = localStorageObjectBranch ? JSON.parse(localStorageObjectBranch) : []

  let localStorageObjectChapter = localStorage.getItem("Chapter_List_video_" + data?.id);
  var ChaptersFilterData = (localStorageObjectChapter && localStorageObjectChapter != "undefined") ? JSON.parse(localStorageObjectChapter) : [];

  let localStorageObjectSubChapter = localStorage.getItem("Subchapter_List_video_" + data?.id);
  var SubChaptersFilterData = localStorageObjectSubChapter ? JSON.parse(localStorageObjectSubChapter) : []

  var selectedBranch = BranchesFilterData
  var selectedChapter = ChaptersFilterData
  var selectedSubchapter = SubChaptersFilterData
  const [selectedLanguages, setSelectedLanguages] = useState(exportselectedLanguages ? exportselectedLanguages : [data.languageId])
  const [selectedCountry, setSelectedCountry] = useState(exportselectedCountry ? exportselectedCountry : [data.countryId])
  const [selectedUniversity, setSelectedUniversity] = useState(exportselectedUniversity ? exportselectedUniversity : [data.universityId])
  const [selectedLevel, setSelectedLevel] = useState(exportselectedLevel ? exportselectedLevel : [data.userLevelId])

  var SelectedFilterData = {
    branchId: selectedBranch,
    chapterId: selectedChapter,
    subChapterId: selectedSubchapter,
    LanguageId: selectedLanguages,
    CountryId: selectedCountry,
    UniversityId: selectedUniversity,
    LevelId: selectedLevel,
    Bookmarked: selectedBookmarked,
    questionCount: QuestionCount == null ? 10 : QuestionCount,
    startDate: null,
    endDate: null
  }

  const [isActive, setIsActive] = useState(selectedBranch.length == 0 ? false : true);
  const [branchs, setBranch] = useState([]);
  const [chapter, setChapter] = useState(selectedChapter.length == 0 ? false : true);
  const [chapterList, setChapterList] = useState([]);
  const [subChapter, setSubChapter] = useState(selectedChapter.length == 0 ? false : true);
  const [languageList, setLanguageList] = useState([])
  const [levelList, setLevelList] = useState([])
  const [subChapterList, setSubchapterList] = useState([]);
  const [country, setCountry] = useState(true);
  const [countryList, setCountryList] = useState([]);
  const [university, setUniversity] = useState(true);
  const [universityList, setUniversityList] = useState([]);
  const [level, setLevel] = useState(true);
  const [calendar, setCalander] = useState(false)
  const [bookmarked, setBookmarked] = useState(selectedBookmarked == true ? true : false)
  const [language, setLanguage] = useState(true)

  let history = useHistory()
  useEffect(async () => {
    GetAllLanguage()
    GetAllLevel()
    GetAllChapter()
    GetAllSubChapter()
    GetAllCountries()
    GetAllUniveristy()
  }, [])


  const GetAllLanguage = async () => {
    var response = await AllFilterServices.getAllLanguage()
    if (response.data.message == SessionLogoutMsg) {
      history.push("/login")
      localStorage.removeItem('Exe-Med_Student_Info');
    }
    else {
      setLanguageList(response.data.payload);
    }
  }
  const GetAllLevel = async () => {

    var response = await AllFilterServices.getAllIntrests(data.universityId)

    if (response.data.message == SessionLogoutMsg) {
      history.push("/login")
      localStorage.removeItem('Exe-Med_Student_Info');
    }
    else {
      setLevelList(response.data.payload);
    }
  }
  const GetAllChapter = async () => {
    var response = await AllFilterServices.getAllChapters()
    if (response.data.message == SessionLogoutMsg) {
      history.push("/login")
      localStorage.removeItem('Exe-Med_Student_Info');
    }
    else {
      setChapterList(response.data.payload);
    }
  }
  const GetAllSubChapter = async () => {
    var response = await AllFilterServices.getAllSubchapter()
    if (response.data.message == SessionLogoutMsg) {
      history.push("/login")
      localStorage.removeItem('Exe-Med_Student_Info');
    }
    else {
      setSubchapterList(response.data.payload);
    }
  }
  const GetAllCountries = async () => {
    var response = await AllFilterServices.getAllCountries()
    if (response.data.message == SessionLogoutMsg) {
      history.push("/login")
      localStorage.removeItem('Exe-Med_Student_Info');
    }
    else {
      setCountryList(response.data.payload);
    }
  }
  const GetAllUniveristy = async () => {
    var response = await AllFilterServices.getAllUniversities()
    if (response.data.message == SessionLogoutMsg) {
      history.push("/login")
      localStorage.removeItem('Exe-Med_Student_Info');
    }
    else {
      setUniversityList(response.data.payload);
    }
  }
  const FilterUniChange = (e) => {

    var Id = e.target.getAttribute('data-questionId')

    if (e.target.checked == false) {
      var tempSelectedUni = selectedUniversity.filter(function (obj) {
        return obj != Id;
      });
      setSelectedUniversity(tempSelectedUni)
      SelectedFilterData.UniversityId = tempSelectedUni;
      localStorage.setItem("University_List_video_" + data?.id, JSON.stringify(tempSelectedUni));
      dispatch(videoMaterial(SelectedFilterData))
    }
    else {
      var tempSelectedUni = [...selectedUniversity];
      tempSelectedUni.push(parseInt(Id))
      setSelectedUniversity(tempSelectedUni)
      localStorage.setItem("University_List_video_" + data?.id, JSON.stringify(tempSelectedUni));

      SelectedFilterData.UniversityId = tempSelectedUni;
      dispatch(videoMaterial(SelectedFilterData))
    }
    // e.target.parentNode.childNodes.forEach(function (element) {
    //   if (e.target.checked == true) {
    //     element.classList.add("change-color")
    //     element.classList.add("checkbox-color")
    //   }
    //   else {
    //     element.classList.remove("change-color")
    //   }
    // });

  }


  const [name, setName] = useState('');
  const [foundUsers, setFoundUsers] = useState(countryList);
  const handleCountrySearch = (e) => {
    const keyword = e.target.value;

    if (keyword !== '') {
      const results = countryList.filter((user) => {
        return user.name.toLowerCase().includes(keyword.toLowerCase());
      });
      setFoundUsers(results);
    } else {
      setFoundUsers(countryList);
    }
    setName(keyword);
  }
  const [BranchName, setBranchName] = useState('');
  const [branchSearch, setBranchSearch] = useState(countryList);
  const handleBranchSearch = (e) => {

    const keyword = e.target.value;
    // var NotFound = branchs.find(value => value.title == keyword)

    if (keyword !== '') {
      const results = branchs.filter((user) => {
        return user.title.toLowerCase().includes(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setBranchSearch(results);
    } else {
      setBranchSearch(branchs);
      // If the text field is empty, show all users
    }
    setBranchName(keyword);
  }

  const [chapterName, setChapterName] = useState('');
  const [chapterSearch, setChapterSearch] = useState(countryList);
  const handleChapterSearch = (e) => {
    const keyword = e.target.value;
    if (keyword !== '') {
      const results = chapterList.filter((user) => {
        return user.title.toLowerCase().includes(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setChapterSearch(results);
    } else {
      setChapterSearch(chapterList);
      // If the text field is empty, show all users
    }
    setChapterName(keyword);
  }
  const [subChapterName, setSubChapterName] = useState('');
  const [subChapterSearch, setSubChapterSearch] = useState(subChapterList);
  const handleSubChapterSearch = (e) => {
    const keyword = e.target.value;
    if (keyword !== '') {
      const results = subChapterList.filter((user) => {
        return user.title.toLowerCase().includes(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setSubChapterSearch(results);
    } else {
      setSubChapterSearch(subChapterList);
      // If the text field is empty, show all users
    }
    setSubChapterName(keyword);
  }
  const [languageName, setLanguageName] = useState('');
  const [languageSearch, setLanguageSearch] = useState(languageList);
  const handleLanguageSearch = (e) => {
    const keyword = e.target.value;
    if (keyword !== '') {
      const results = languageList.filter((user) => {
        return user.name.toLowerCase().includes(keyword.toLowerCase());
      });
      setLanguageSearch(results);
    } else {
      setLanguageSearch(languageList);
    }
    setLanguageName(keyword);
  }

  const [universityName, setUniversityName] = useState('');
  const [universitySearch, setUniversiitySearch] = useState(universityList);
  const handleUniversitySearch = (e) => {
    const keyword = e.target.value;
    if (keyword !== '') {
      const results = universityList.filter((user) => {
        return user.name.toLowerCase().includes(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setUniversiitySearch(results);
    } else {
      setUniversiitySearch(universityList);
      // If the text field is empty, show all users
    }
    setUniversityName(keyword);
  }

  const [levelName, setLevelName] = useState('');
  const [levelSearch, setLevelSearch] = useState(universityList);
  const handleLevelSearch = (e) => {
    const keyword = e.target.value;
    if (keyword !== '') {
      const results = levelList.filter((user) => {
        return user.title.toLowerCase().includes(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setLevelSearch(results);
    } else {
      setLevelSearch(levelList);
      // If the text field is empty, show all users
    }
    setLevelName(keyword);
  }

  const [chnageChapter, setChnageChapter] = useState([])
  const dispatch = useDispatch();
  const handleSelectAllBranch = async (e) => {
    const now = new Date();
    const start_date = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
    if (e.target.checked == true) {
      var tempSelectedBranch = BranchFilterData.map(li => li.id);
      selectedBranch = tempSelectedBranch
      SelectedFilterData.branchId = tempSelectedBranch;
      dispatch(videoMaterial(SelectedFilterData))
      localStorage.setItem("Branche_List_video_" + data?.id, JSON.stringify(tempSelectedBranch));
      dispatch(ChnageTabs(4))
      dispatch(StudyChapterFilter({
        startDate: (moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(start_date).format('YYYY-MM-DD') ? null : moment(calenderDates.startDate).format('YYYY-MM-DD')),
        endDate: ((moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(start_date).format('YYYY-MM-DD') ? null : moment(calenderDates.endDate).format('YYYY-MM-DD'))),
        object: tempSelectedBranch,
        custumTab: 4
      }))

    }
    else {
      selectedBranch = []
      localStorage.removeItem("Branche_List_video_" + data?.id, JSON.stringify(tempSelectedBranch));
      SelectedFilterData.branchId = [];
      dispatch(ChnageTabs(4))
      dispatch(videoMaterial(SelectedFilterData))

      var tempSelectedChapters = selectedChapter;
      tempSelectedChapters.forEach(element => {
        if (!ChapterFilterData.some(x => x.id == element)) {
          tempSelectedChapters = selectedChapter.filter(function (obj) {
            return obj != element;
          });
        }
      });
      var tempSelectedSubChapters = selectedSubchapter;
      tempSelectedSubChapters.forEach(element => {
        const index = SubChapterFilterData.some(x => x.id == element);

        if (!index) {
          selectedSubchapter = selectedSubchapter.filter(function (obj) {
            return obj != element;
          });
        }
      });

      dispatch(StudyChapterFilter({
        startDate: (moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(start_date).format('YYYY-MM-DD') ? null : moment(calenderDates.startDate).format('YYYY-MM-DD')),
        endDate: ((moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(start_date).format('YYYY-MM-DD') ? null : moment(calenderDates.endDate).format('YYYY-MM-DD'))),
        object: [],
        custumTab: 4
      }))


      dispatch(StudySubChapterFilter({
        startDate: (moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(start_date).format('YYYY-MM-DD') ? null : moment(calenderDates.startDate).format('YYYY-MM-DD')),
        endDate: ((moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(start_date).format('YYYY-MM-DD') ? null : moment(calenderDates.endDate).format('YYYY-MM-DD'))),
        object: [],
        custumTab: 4
      }))
    }



  };

  const handleSelectAllChapter = async (e) => {

    const now = new Date();
    const start_date = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
    if (e.target.checked == true) {
      var tempSelectedChapter = ChapterFilterData.map(li => li.id);
      selectedChapter = tempSelectedChapter
      SelectedFilterData.chapterId = tempSelectedChapter;
      dispatch(videoMaterial(SelectedFilterData))
      dispatch(ChnageTabs(4))
      localStorage.setItem("Chapter_List_" + data?.id, JSON.stringify(tempSelectedChapter));
      // var response2 = await AllFilterServices.GetAllSubChaptersByChapterIds(tempSelectedChapter, (moment(calenderDates.startDate).format('YYYY-MM-DD')), (moment(calenderDates.endDate).format('YYYY-MM-DD')));

      dispatch(StudySubChapterFilter({
        startDate: (moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(start_date).format('YYYY-MM-DD') ? null : moment(calenderDates.startDate).format('YYYY-MM-DD')),
        endDate: ((moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(start_date).format('YYYY-MM-DD') ? null : moment(calenderDates.endDate).format('YYYY-MM-DD'))),
        object: tempSelectedChapter,
        custumTab: 4
      }))

    }
    else {
      selectedChapter = []
      SelectedFilterData.chapterId = [];
      dispatch(ChnageTabs(4))
      dispatch(videoMaterial(SelectedFilterData))
      localStorage.setItem("Chapter_List_" + data?.id, JSON.stringify(tempSelectedChapter));
      var response2 = await AllFilterServices.GetAllSubChaptersByChapterIds([], (moment(calenderDates.startDate).format('YYYY-MM-DD')), (moment(calenderDates.endDate).format('YYYY-MM-DD')));

      dispatch(StudySubChapterFilter({
        startDate: (moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(start_date).format('YYYY-MM-DD') ? null : moment(calenderDates.startDate).format('YYYY-MM-DD')),
        endDate: ((moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(start_date).format('YYYY-MM-DD') ? null : moment(calenderDates.endDate).format('YYYY-MM-DD'))),
        object: [],
        custumTab: 4
      }))
    }

    setChnageSubChapter(response2.data.payload)
    var tempSelectedSubChapters = selectedSubchapter;
    tempSelectedSubChapters.forEach(element => {
      const index = response2.data.payload.some(x => x.id == element);
      if (!index) {
        tempSelectedSubChapters = selectedSubchapter.filter(function (obj) {
          return obj != element;
        });
      }
    });
  }

  const handleSelectAllSubchapter = (e) => {
    if (e.target.checked == true) {
      var tempSelectedSubChapters = SubChapterFilterData.map(li => li.id);
      selectedSubchapter = tempSelectedSubChapters
      SelectedFilterData.subChapterId = tempSelectedSubChapters;
      dispatch(videoMaterial(SelectedFilterData))
      dispatch(ChnageTabs(4))
      localStorage.setItem("Subchapter_List_video_" + data?.id, JSON.stringify(tempSelectedSubChapters));

    }
    else {
      selectedSubchapter = []
      localStorage.removeItem("Subchapter_List_video_" + data?.id, JSON.stringify(tempSelectedSubChapters));
      dispatch(ChnageTabs(4))
      SelectedFilterData.subChapterId = [];
      dispatch(videoMaterial(SelectedFilterData))
    }
  }

  const handleSelectAllLanguage = (e) => {

    if (e.target.checked == true) {
      var tempSelectedLanguage = languageList.map(li => li.id);
      setSelectedLanguages(tempSelectedLanguage)
      SelectedFilterData.LanguageId = tempSelectedLanguage;
      dispatch(videoMaterial(SelectedFilterData))
      localStorage.setItem("Language_List_video_" + data?.id, JSON.stringify(tempSelectedLanguage));

    }
    else {
      setSelectedLanguages([data.languageId])
      localStorage.removeItem("Language_List_video_" + data?.id, JSON.stringify(tempSelectedLanguage));
      SelectedFilterData.LanguageId = [];
      dispatch(videoMaterial(SelectedFilterData))
    }
  }

  const handleSelectAllCountry = (e) => {

    if (e.target.checked == true) {
      var tempSelectedCountry = countryList.map(li => li.id);
      setSelectedCountry(tempSelectedCountry)
      SelectedFilterData.CountryId = tempSelectedCountry;
      dispatch(videoMaterial(SelectedFilterData))
      localStorage.setItem("Country_List_video_" + data?.id, JSON.stringify(tempSelectedCountry));
    }
    else {
      setSelectedCountry([data.countryId])
      localStorage.removeItem("Country_List_video_" + data?.id, JSON.stringify(tempSelectedCountry));
      SelectedFilterData.CountryId = [];
      dispatch(videoMaterial(SelectedFilterData))
    }

  }
  const handleSelectAllUniversity = (e) => {

    if (e.target.checked == true) {
      var tempSelectedUniversity = universityList.map(li => li.id);
      setSelectedUniversity(tempSelectedUniversity)
      SelectedFilterData.UniversityId = tempSelectedUniversity;
      dispatch(videoMaterial(SelectedFilterData))
      localStorage.setItem("University_List_video_" + data?.id, JSON.stringify(tempSelectedUniversity));

    }
    else {
      setSelectedUniversity([data.universityId])
      localStorage.removeItem("University_List_video_" + data?.id, JSON.stringify(tempSelectedUniversity));
      SelectedFilterData.CountryId = [];
      dispatch(videoMaterial(SelectedFilterData))

    }
  }

  const handleSelectAllLevel = (e) => {

    if (e.target.checked == true) {
      var tempSelectedLevel = levelList.map(li => li.id);
      setSelectedLevel(tempSelectedLevel)
      SelectedFilterData.LevelId = tempSelectedLevel;
      dispatch(videoMaterial(SelectedFilterData))
      localStorage.setItem("Level_List_video_" + data?.id, JSON.stringify(tempSelectedLevel));
    }
    else {
      setSelectedLevel([data.userLevelId])
      localStorage.removeItem("Level_List_video_" + data?.id, JSON.stringify(tempSelectedLevel));
      SelectedFilterData.CountryId = [];
      dispatch(videoMaterial(SelectedFilterData))
    }

  }
  const BranchFilterChange = async (e) => {

    if (isBranchFilterChangedInCustomTab) {
      selectedBranch = [];
      dispatch(BranchFilterChangedInCustomTab(false))
    }
    var Id = e.target.getAttribute('data-questionId')
    if (e.target.checked == false) {
      var tempSelectedBranch = selectedBranch.filter(function (obj) {
        return obj != Id;
      });
      dispatch(ChnageTabs(4))
      selectedBranch = tempSelectedBranch
      SelectedFilterData.branchId = tempSelectedBranch;
      localStorage.setItem("Branche_List_video_" + data?.id, JSON.stringify(tempSelectedBranch));
      dispatch(videoMaterial(SelectedFilterData))

    }
    else {
      var tempSelectedBranch = [...selectedBranch];
      tempSelectedBranch.push(parseInt(Id))
      dispatch(ChnageTabs(4))
      selectedBranch = tempSelectedBranch
      localStorage.setItem("Branche_List_video_" + data?.id, JSON.stringify(tempSelectedBranch));

      SelectedFilterData.branchId = tempSelectedBranch;
      dispatch(videoMaterial(SelectedFilterData))
    }

    const now = new Date();
    const start_date = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
    const month_date = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate())
    const week_date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7)


    dispatch(StudyChapterFilter({
      startDate: (moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(start_date).format('YYYY-MM-DD') || moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(month_date).format('YYYY-MM-DD') || moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(week_date).format('YYYY-MM-DD') ? null : moment(calenderDates.startDate).format('YYYY-MM-DD')),
      endDate: ((moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(start_date).format('YYYY-MM-DD') || moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(month_date).format('YYYY-MM-DD') || moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(week_date).format('YYYY-MM-DD') ? null : moment(calenderDates.endDate).format('YYYY-MM-DD'))),
      object: tempSelectedBranch,
      custumTab: 4

    }))
    dispatch(StudySubChapterFilter({
      startDate: (moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(start_date).format('YYYY-MM-DD') || moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(month_date).format('YYYY-MM-DD') || moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(week_date).format('YYYY-MM-DD') ? null : moment(calenderDates.startDate).format('YYYY-MM-DD')),
      endDate: ((moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(start_date).format('YYYY-MM-DD') || moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(month_date).format('YYYY-MM-DD') || moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(week_date).format('YYYY-MM-DD') ? null : moment(calenderDates.endDate).format('YYYY-MM-DD'))),
      object: [],
      custumTab: 4
    }))

    if (e.target.checked == false) {
      var tempSelectedChapters = selectedChapter;

      tempSelectedChapters.forEach(element => {
        if (!ChapterFilterData.some(x => x.id == element)) {
          tempSelectedChapters = selectedChapter.filter(function (obj) {
            return obj != element;
          });
        }
      });
      var response2 = await AllFilterServices.GetAllSubChaptersByChapterIds(selectedChapter, (moment(calenderDates.startDate).format('YYYY-MM-DD')), (moment(calenderDates.endDate).format('YYYY-MM-DD')));
      setChnageSubChapter(response2.data.payload)
      var tempSelectedSubChapters = selectedSubchapter;
      tempSelectedSubChapters.forEach(element => {
        const index = response2.data.payload.some(x => x.id == element);
        if (!index) {
          tempSelectedSubChapters = selectedSubchapter.filter(function (obj) {
            return obj != element;
          });
        }
      });
    }
  }

  const LanguageFilterChange = (e) => {
    var Id = e.target.getAttribute('data-questionId')

    if (e.target.checked == false) {
      var tempSelectedLanguage = selectedLanguages.filter(function (obj) {
        return obj != Id;
      });
      setSelectedLanguages(tempSelectedLanguage)
      SelectedFilterData.LanguageId = tempSelectedLanguage;
      localStorage.setItem("Language_List_video_" + data?.id, JSON.stringify(tempSelectedLanguage));
      dispatch(videoMaterial(SelectedFilterData))
    }
    else {
      var tempSelectedLanguage = [...selectedLanguages];
      tempSelectedLanguage.push(parseInt(Id))
      setSelectedLanguages(tempSelectedLanguage)
      localStorage.setItem("Language_List_video_" + data?.id, JSON.stringify(tempSelectedLanguage));

      SelectedFilterData.LanguageId = tempSelectedLanguage;
      dispatch(videoMaterial(SelectedFilterData))
    }
    // e.target.parentNode.childNodes.forEach(function (element) {
    //   if (e.target.checked == true) {
    //     element.classList.add("change-color")
    //     element.classList.add("checkbox-color")
    //   }
    //   else {
    //     element.classList.remove("change-color")
    //   }
    // });
  }

  const CountryFilterChange = (e) => {
    var Id = e.target.getAttribute('data-questionId')
    if (e.target.checked == false) {
      var tempSelectedCountry = selectedCountry.filter(function (obj) {
        return obj != Id;
      });
      setSelectedCountry(tempSelectedCountry)
      SelectedFilterData.CountryId = tempSelectedCountry;
      localStorage.setItem("Country_List_video_" + data?.id, JSON.stringify(tempSelectedCountry));
      dispatch(videoMaterial(SelectedFilterData))
    }
    else {
      var tempSelectedCountry = [...selectedCountry];
      tempSelectedCountry.push(parseInt(Id))
      setSelectedCountry(tempSelectedCountry)
      localStorage.setItem("Country_List_video_" + data?.id, JSON.stringify(tempSelectedCountry));
      SelectedFilterData.CountryId = tempSelectedCountry;
      dispatch(videoMaterial(SelectedFilterData))
    }
    // e.target.parentNode.childNodes.forEach(function (element) {
    //   if (e.target.checked == true) {
    //     element.classList.add("change-color")
    //     element.classList.add("checkbox-color")
    //   }
    //   else {
    //     element.classList.remove("change-color")
    //   }
    // });
  }

  const LevelFilterChange = (e) => {

    var Id = e.target.getAttribute('data-questionId')
    if (e.target.checked == false) {
      var tempSelectedLevel = selectedLevel.filter(function (obj) {
        return obj != Id;
      });
      setSelectedLevel(tempSelectedLevel)
      SelectedFilterData.LevelId = tempSelectedLevel;
      localStorage.setItem("Level_List_video_" + data?.id, JSON.stringify(tempSelectedLevel));
      // dispatch(videoMaterial(SelectedFilterData))
    }
    else {
      var tempSelectedLevel = [...selectedLevel];
      tempSelectedLevel.push(parseInt(Id))
      setSelectedLevel(tempSelectedLevel)
      localStorage.setItem("Level_List_video_" + data?.id, JSON.stringify(tempSelectedLevel));
      SelectedFilterData.LevelId = tempSelectedLevel;
      // dispatch(videoMaterial(SelectedFilterData))
    }

    // e.target.parentNode.childNodes.forEach(function (element) {
    //   if (e.target.checked == true) {
    //     element.classList.add("change-color")
    //     element.classList.add("checkbox-color")
    //   }
    //   else {
    //     element.classList.remove("change-color")
    //   }
    // });
  }


  const [chnageSubChapter, setChnageSubChapter] = useState([])
  const ChapterFilterChange = async (e) => {

    var Id = e.target.getAttribute('data-questionId')
    if (e.target.checked == false) {
      var tempSelectedChapter = selectedChapter.filter(function (obj) {
        return obj != Id;
      });
      selectedChapter = tempSelectedChapter
      SelectedFilterData.chapterId = tempSelectedChapter;
      dispatch(ChnageTabs(4))
      localStorage.setItem("Chapter_List_video_" + data?.id, JSON.stringify(tempSelectedChapter));
      dispatch(videoMaterial(SelectedFilterData))

    }
    else {
      var tempSelectedChapter = [...selectedChapter];
      tempSelectedChapter.push(parseInt(Id))
      selectedChapter = tempSelectedChapter
      dispatch(ChnageTabs(4))
      localStorage.setItem("Chapter_List_video_" + data?.id, JSON.stringify(tempSelectedChapter));
      SelectedFilterData.chapterId = tempSelectedChapter;
      dispatch(videoMaterial(SelectedFilterData))
    }


    // e.target.parentNode.childNodes.forEach(function (element) {
    //   if (e.target.checked == true) {
    //     element.classList.add("change-color")
    //     element.classList.add("checkbox-color")
    //   }
    //   else {
    //     element.classList.remove("change-color")
    //   }
    // });
    const now = new Date();
    const start_date = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
    const month_date = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate())
    const week_date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7)
    dispatch(StudySubChapterFilter({
      startDate: (moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(start_date).format('YYYY-MM-DD') || moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(month_date).format('YYYY-MM-DD') || moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(week_date).format('YYYY-MM-DD') ? null : moment(calenderDates.startDate).format('YYYY-MM-DD')),
      endDate: ((moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(start_date).format('YYYY-MM-DD') || moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(month_date).format('YYYY-MM-DD') || moment(calenderDates.startDate).format('YYYY-MM-DD') == moment(week_date).format('YYYY-MM-DD') ? null : moment(calenderDates.endDate).format('YYYY-MM-DD'))),
      object: SelectedFilterData.chapterId,
      custumTab: 4
    }))

    var response = await AllFilterServices.GetAllSubChaptersByChapterIds(SelectedFilterData.chapterId, (moment(calenderDates.startDate).format('YYYY-MM-DD')), (moment(calenderDates.endDate).format('YYYY-MM-DD')));

    if (response.data.message == SessionLogoutMsg) {
      history.push("/login")
      localStorage.removeItem('Exe-Med_Student_Info');
    }
    else {
      setChnageSubChapter(response.data.payload)
    }
  }


  const [subChpaterCheckbox, setSubChapterCheckbox] = useState([])
  const SubchapterFilterChange = (e) => {

    var Id = e.target.getAttribute('data-questionid')
    if (e.target.checked == false) {
      var tempSelectedSubChapters = selectedSubchapter.filter(function (obj) {
        return obj != Id;
      });
      setSubChapterCheckbox(tempSelectedSubChapters)
      dispatch(ChnageTabs(4))
      SelectedFilterData.subChapterId = tempSelectedSubChapters;
      localStorage.setItem("Subchapter_List_video_" + data?.id, JSON.stringify(tempSelectedSubChapters));
      dispatch(videoMaterial(SelectedFilterData))
    }
    else {
      var tempSelectedSubChapters = selectedSubchapter;
      tempSelectedSubChapters.push(parseInt(Id))
      // selectedSubchapter = tempSelectedSubChapters
      setSubChapterCheckbox(tempSelectedSubChapters)
      localStorage.setItem("Subchapter_List_video_" + data?.id, JSON.stringify(tempSelectedSubChapters));
      dispatch(ChnageTabs(4))
      SelectedFilterData.subChapterId = tempSelectedSubChapters;
      dispatch(videoMaterial(SelectedFilterData))
    }


    // e.target.parentNode.childNodes.forEach(function (element) {
    //   if (e.target.checked == true) {
    //     element.classList.add("change-color")
    //     element.classList.add("checkbox-color")
    //   }
    //   else {
    //     element.classList.remove("change-color")
    //   }
    // });


  }

  const ChangeBookmark = (e) => {

    if (e.target.checked == false) {
      setBookmarked(false)
      SelectedFilterData.Bookmarked = null;
      dispatch(videoMaterial(SelectedFilterData))
      localStorage.setItem("Book_marked_video_" + data?.id, JSON.stringify(null));
    }
    else {

      setBookmarked(true)
      SelectedFilterData.Bookmarked = true;
      dispatch(videoMaterial(SelectedFilterData))
      localStorage.setItem("Book_marked_video_" + data?.id, JSON.stringify(true));
    }


  }

  async function chnageDate(item) {

    localStorage.setItem("start_date", JSON.stringify(item.range1.startDate));

    dispatch(updateStudyDates({
      startDate: item.range1.startDate,
      endDate: item.range1.endDate
    }))

    dispatch(StudyBranchesFilter({
      startDate: (moment(item.range1.startDate).format('YYYY-MM-DD')),
      endDate: (moment(item.range1.endDate).format('YYYY-MM-DD')),
    }))
    dispatch(videoMaterial({
      branchId: selectedBranch,
      chapterId: selectedChapter,
      subChapterId: selectedSubchapter,
      LanguageId: selectedLanguages,
      CountryId: selectedCountry,
      UniversityId: selectedUniversity,
      LevelId: selectedLevel,
      Bookmarked: selectedBookmarked,
      questionCount: QuestionCount == null ? 10 : QuestionCount,
      startDate: moment(item.range1.startDate).format('YYYY-MM-DD'),
      endDate: moment(item.range1.endDate).format('YYYY-MM-DD')
    }))

  }
  function clearCalender() {
    dispatch(ChnageTabs(4))
    dispatch(updateStudyDates({
      startDate: new Date("0000-01-01T00:00:00Z"),
      endDate: new Date("0000-01-01T00:00:00Z")
    }))
  }

  return (
    <>
      <div className="col-md-4 col-sm-12 mt-4 studyFilter">
        <Card style={{ background: "black" }} className='ml-5 '>
          <div className=" filter-by w-100 " >
            <div className="form-control border-0 p-0">
              <img alt="" src={filter} className="float-left mt-2 mr-3 ml-2 w30" /> <h2 className='h2' >  Filter By</h2> <MdOutlineKeyboardArrowDown className="float-right filter-icon" color='#E84545' fontSize="2.3em" />
            </div>
          </div>
          <CardBody>
            {/* Commenting for now but change in future */}
            {/* <div className="form-group mb-1 filter-margin" onClick={() => setCalander(!calendar)}>
              {calendar ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                 <img alt="" src={calenderImg} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Calender</h3>
                {calendar ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                 <img alt="" src={calenderImg} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Calender</h3>
                {calendar ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a>}
            </div>
            {calendar && <div className="form-group mb-1 sub-menu-filter mb-3 mr-3 w-100">
              <MdOutlineCancel title='Clear Date' fontSize="1.8rem" onClick={clearCalender} color="#E84545" style={{ cursor: "pointer" }} />
              <DateRange
                editableDateInputs={true}
                onChange={chnageDate}
                moveRangeOnFirstSelection={false}
                ranges={[
                  {
                    startDate: new Date(calenderDates.startDate),
                    endDate: new Date(calenderDates.endDate),
                  }
                ]}
              />
            </div>} */}

            <div className="CursorPointer form-group mb-1 filter-margin" onClick={() => setIsActive(!isActive)}>
              {isActive ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                <img alt="" src={branch} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Branch</h3>
                {isActive ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                <img alt="" src={branch} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Branch</h3>
                {isActive ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a>}
            </div>
            {isActive && <div className="form-group mb-1 sub-menu-filter mb-3 mr-3 w-100 filter-scrollbar scrollbar-primary">
              <input placeholder='Search Branch' value={BranchName} onChange={handleBranchSearch} className='form-control' />
              <div>
                <label className='ml-2 CursorPointer'>All</label>
                <input
                  type="checkbox"
                  name="selectAll"
                  id="selectAll"
                  checked={!isBranchFilterChangedInCustomTab && BranchFilterData.length === selectedBranch.length}
                  onChange={handleSelectAllBranch}
                  className="float-right checkbox"
                  style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                />
              </div>


              {branchSearch && branchSearch.length > 0 ?

                branchSearch.map((value, index) => {
                  return (
                    <div>
                      <label className='ml-2 CursorPointer'>{value.title}</label>
                      <input
                        type="checkbox"
                        data-questionId={value.id}
                        checked={selectedBranch.includes(value.id)}
                        onChange={BranchFilterChange}
                        className="float-right checkbox"
                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                      />
                    </div>
                  )
                }) : BranchFilterData?.map((value, index) => {
                  return (
                    <div>
                      <label className='ml-2 CursorPointer'>{value.title}</label>
                      <input
                        type="checkbox"
                        data-questionId={value.id}
                        checked={isBranchFilterChangedInCustomTab ? false : selectedBranch.includes(value.id)}
                        onChange={BranchFilterChange}
                        className="float-right checkbox"
                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                      />
                    </div>
                  )
                })}
            </div>}


            <div className=" CursorPointer form-group mb-1 filter-margin" onClick={() => setChapter(!chapter)}>
              {chapter ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                <img alt="" src={document} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Chapters</h3>
                {chapter ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                <img alt="" src={document} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Chapters</h3>
                {chapter ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a>}
            </div>
            {chapter && <div className="form-group mb-1 sub-menu-filter mb-3 mr-3 w-100 filter-scrollbar scrollbar-primary">
              <input placeholder='Search Chapter' value={chapterName} onChange={handleChapterSearch} className='form-control' />
              <div>
                <label className='ml-2 CursorPointer'>All</label>
                <input
                  type="checkbox"
                  name="selectAllChapter"
                  id="selectAllChapter"
                  checked={studyChapterFilterData.length === selectedChapter.length}
                  onChange={handleSelectAllChapter}
                  className="float-right checkbox"
                  style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                />
              </div>
              {chapterSearch && chapterSearch.length > 0 ?

                chapterSearch.map((value, index) => {
                  return (
                    <div>
                      <label className='ml-2 CursorPointer'>{value.title}</label>
                      <input
                        type="checkbox"
                        data-questionId={value.id}
                        checked={selectedChapter.includes(value.id)}
                        onChange={ChapterFilterChange}
                        className="float-right checkbox"
                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                      />
                    </div>
                  )
                }) : studyChapterFilterData?.map((value, index) => {
                  return (
                    <div>
                      <label className='ml-2 CursorPointer' style={{ maxWidth: "270px" }}>{value.title}</label>
                      <input
                        type="checkbox"
                        data-questionId={value.id}
                        checked={selectedChapter.includes(value.id)}
                        onChange={ChapterFilterChange}
                        className="float-right checkbox"
                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                      />
                    </div>
                  )
                })
              }


            </div>}

            <div className="CursorPointer form-group mb-1 filter-margin" onClick={() => setSubChapter(!subChapter)}>
              {subChapter ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                <img alt="" src={document} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Sub Chapter</h3>
                {subChapter ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a> : <a className=" form-control text-white quiz border-0 p-0 mb-2 ">
                <img alt="" src={document} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Sub Chapter</h3>
                {subChapter ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a>}
            </div>

            {subChapter && <div className="form-group mb-1 sub-menu-filter mb-3 mr-3 w-100 filter-scrollbar scrollbar-primary">
              <input placeholder='Search Sub-Chapter' onChange={handleSubChapterSearch} value={subChapterName} className='form-control' />
              <div>
                <label className='ml-2 CursorPointer'>All</label>
                <input
                  type="checkbox"
                  name="selectAll"
                  id="selectAll"
                  onChange={handleSelectAllSubchapter}
                  className="float-right checkbox"
                  style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                />
              </div>
              {subChapterSearch && subChapterSearch.length > 0 ?
                subChapterSearch.map((value, index) => {
                  return (
                    <div>
                      <label className='ml-2 CursorPointer'>{value.title}</label>
                      <input
                        data-questionId={value.id}
                        type="checkbox"
                        checked={selectedSubchapter.includes(value.id)}
                        onChange={SubchapterFilterChange}
                        className="float-right checkbox"
                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                      />
                    </div>
                  )
                }) :
                SubChapterFilterData?.map((value, index) => {
                  return (
                    <div>
                      <label className='ml-2 CursorPointer' style={{ maxWidth: "270px" }}>{value.title}</label>
                      <input
                        data-questionId={value.id}
                        type="checkbox"
                        checked={selectedSubchapter.includes(value.id)}
                        onChange={SubchapterFilterChange}
                        className="float-right checkbox"
                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                      />
                    </div>
                  )
                })
              }
            </div>}

            {bookmarked ?
              <div className="form-group mb-1 filter-margin " >
                <a className="form-control text-white colors border-0 p-0 mb-2 ">
                  <img alt="" src={save} className="float-left mt-2 mr-2 ml-2 p-1" width="25px" /> <h3 className='text-white '>Bookmarked</h3>

                  <input
                    type="checkbox"
                    onChange={ChangeBookmark}
                    defaultChecked={bookmarked}
                    className="float-right checkbox"
                    style={{ backgroundColor: "#E84545", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "-22px", marginRight: "10px" }}
                  />
                </a>
              </div>
              : <div className="form-group mb-1 filter-margin" >
                <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                  <img alt="" src={save} className="float-left mt-2 mr-2 ml-2 p-1" width="25px" /> <h3 className='text-white '>Bookmarked</h3>

                  <input
                    type="checkbox"
                    onChange={ChangeBookmark}
                    className="float-right checkbox"
                    style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "-22px", marginRight: "10px" }}
                  />
                </a>
              </div>}
            {/* Commenting for now but change in future */}
            {/* <div className="form-group mb-1 filter-margin" onClick={() => setLanguage(!language)}>
              {language ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                 <img alt="" src={languages} className="float-left mt-2 mr-2 ml-2 w30 p-1 text-white" /> <h3 className='text-white '>Language</h3>
                {language ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                 <img alt="" src={languages} className="float-left mt-2 mr-2 ml-2 w30 p-1 text-white" /> <h3 className='text-white '>Language</h3>
                {language ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a>}
            </div>
            {language && <div className="form-group mb-1 sub-menu-filter mb-3 mr-3 w-100 filter-scrollbar scrollbar-primary">
              <input placeholder='Search Language' onChange={handleLanguageSearch} value={languageName} className='form-control' />
              <div>
                <label className='ml-2 CursorPointer'>All</label>
                <input
                  type="checkbox"
                  name="selectAllLanguage"
                  id="selectAllLanguage"
                  onChange={handleSelectAllLanguage}
                  checked={languageList.length === selectedLanguages.length}
                  className="float-right checkbox"
                  style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                />
              </div>
              {languageSearch && languageSearch.length > 0 ?

                languageSearch.map((value, index) => (
                  <div key={index}>
                    <label className='ml-2'>{value.name}</label>
                    <input
                      type="checkbox"
                      data-questionId={value.id}
                      name="languageId"
                      onChange={LanguageFilterChange}
                      checked={selectedLanguages.includes(value.id) || data?.languageId == value.id}
                      className="float-right checkbox"
                      style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                    />
                  </div>
                )) : languageList.map((value, index) => (
                  <div key={index}>
                    <label className='ml-2'>{value.name}</label>
                    <input
                      type="checkbox"
                      data-questionId={value.id}
                      name="languageId"
                      checked={selectedLanguages.includes(value.id)}
                      onChange={LanguageFilterChange}
                      className="float-right checkbox"
                      style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                    />
                  </div>
                ))
              }

            </div>} */}

            <div className="form-group mb-1 filter-margin" onClick={() => setCountry(!country)}>
              {country ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                <img alt="" src={world} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Country</h3>
                {country ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                <img alt="" src={world} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Country</h3>
                {country ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a>}
            </div>

            {country && <div className="form-group mb-1 sub-menu-filter mb-3 mr-3 w-100 filter-scrollbar scrollbar-primary">
              <input
                placeholder='Search Country'
                className='form-control'
                value={name}
                onChange={handleCountrySearch}
                type="search" />

              <div>
                <label className='ml-2 CursorPointer'>All</label>
                <input
                  type="checkbox"
                  name="selectAllCountry"
                  id="selectAllCountry"
                  onChange={handleSelectAllCountry}
                  checked={countryList.length === selectedCountry.length}
                  className="float-right checkbox"
                  style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                />
              </div>

              {foundUsers && foundUsers.length > 0 ?
                foundUsers.map((value, index) => {
                  return (
                    <div key={index}>
                      <label className='ml-2'>{value.name}</label>
                      <input
                        type="checkbox"
                        name="countryId"
                        data-questionId={value.id}
                        checked={selectedCountry.includes(value.id)}
                        onChange={CountryFilterChange}
                        className="float-right checkbox"
                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                      />
                    </div>
                  )
                }) : countryList.map((value, index) => {
                  return (
                    <div key={index}>
                      <label className='ml-2'>{value.name}</label>
                      <input
                        type="checkbox"
                        name="countryId"
                        data-questionId={value.id}
                        checked={selectedCountry.includes(value.id)}
                        onChange={CountryFilterChange}
                        className="float-right checkbox"
                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                      />
                    </div>
                  )
                })
              }

            </div>}

            <div className="form-group mb-1 filter-margin" onClick={() => setUniversity(!university)}>
              {university ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                <img alt="" src={school} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>University</h3>
                {university ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                <img alt="" src={school} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>University</h3>
                {university ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a>}
            </div>
            {university && <div className="form-group mb-1 sub-menu-filter mb-3 mr-3 w-100 filter-scrollbar scrollbar-primary">
              <input placeholder='Search University' onChange={handleUniversitySearch} value={universityName} className='form-control' />
              <div>
                <label className='ml-2 CursorPointer'>All</label>
                <input
                  type="checkbox"
                  name="selectAllUniversity"
                  id="selectAllUniversity"
                  onChange={handleSelectAllUniversity}
                  checked={universityList.length === selectedUniversity.length}
                  className="float-right checkbox"
                  style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                />
              </div>
              {universitySearch && universitySearch.length > 0 ?

                universitySearch.map((value, index) => {
                  return (
                    <div key={index}>
                      <label className='ml-2'>{value.name}</label>
                      <input
                        type="checkbox"
                        data-questionId={value.id}
                        onChange={FilterUniChange}
                        checked={selectedUniversity.includes(value.id)}
                        className="float-right checkbox"
                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                      />
                    </div>
                  )
                }) :
                universityList.map((value, index) => {
                  return (
                    <div key={index}>
                      <label className='ml-2'>{value.name}</label>
                      <input
                        type="checkbox"
                        data-questionId={value.id}
                        onChange={FilterUniChange}
                        checked={selectedUniversity.includes(value.id)}
                        className="float-right checkbox"
                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                      />
                    </div>
                  )
                })
              }


            </div>}
            {/* Commenting for now but change in future */}
            {/* <div className="form-group mb-1 filter-margin" onClick={() => setLevel(!level)}>
              {level ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                 <img alt="" src={levels} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Level</h3>
                {level ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                 <img alt="" src={levels} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Level</h3>
                {level ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a>}
            </div>
            {level && <div className="form-group mb-1 sub-menu-filter mb-3 mr-3 w-100 filter-scrollbar scrollbar-primary">
              <input placeholder='Search Level' onChange={handleLevelSearch} value={levelName} className='form-control' />
              <div>
                <label className='ml-2 CursorPointer'>All</label>
                <input
                  type="checkbox"
                  name="selectAllLevel"
                  id="selectAllLevel"
                  checked={levelList.length === selectedLevel.length}
                  onChange={handleSelectAllLevel}
                  className="float-right checkbox"
                  style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                />
              </div>
              {levelSearch && levelSearch.length > 0 ?

                levelSearch.map((studentLevel, index) => (
                  <div key={index}>
                    <label className='ml-2'>{studentLevel.title}</label>
                    <input
                      type="checkbox"
                      data-questionId={studentLevel.id}
                      checked={selectedLevel.includes(studentLevel.id)}
                      onChange={LevelFilterChange}
                      className="float-right checkbox"
                      style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                    />
                  </div>
                )) :
                levelList.map((studentLevel, index) => (
                  <div key={index}>
                    <label className='ml-2'>{studentLevel.title}</label>
                    <input
                      type="checkbox"
                      data-questionId={studentLevel.id}
                      checked={selectedLevel.includes(studentLevel.id)}
                      onChange={LevelFilterChange}
                      className="float-right checkbox"
                      style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                    />
                  </div>
                ))}
            </div>} */}
          </CardBody>
        </Card>
      </div>
    </>
  )
}

// export default StudyFilters