import React, { useState, useEffect } from 'react'
import { Row, Col, FormGroup, Input, Label, Select } from "reactstrap"
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css"
import AllFilterServices from '../../../services/AllFilterServices'
import { CSVLink } from 'react-csv';
import Swal from "sweetalert2"
import { AiOutlineDownload } from "react-icons/ai"
import AdminServices from '../../../services/AdminServices';
import { FILE_TEMPLATE_BASE_URL } from '../../../../constant';
const UniversityandHospitalList = (props) => {
     
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    var data = JSON.parse(localStorageObject);

    const [universityList, setUniversityList] = useState([])
    const [hospitalList, setHopitalList] = useState([])

    useEffect(() => {
        GetCountry()
        GetHopital()
    }, [props.countryId])
    const GetCountry = async (e) => {
         
        var response = await AdminServices.GetUniversitiesByCountryId(props.countryId==null?data.countryId:props.countryId)
        setUniversityList(response.data.payload)
    }
    const GetHopital = async (e) => {
        var response = await AdminServices.GetHopitalByCountryId(props.countryId==null?data.countryId:props.countryId)
        setHopitalList(response.data.payload)
    }
    const inputUniRef = React.useRef();
    const inputHospitalRef = React.useRef();

    const uploadUniversityFile = async (e) => {
        var response = await AdminServices.AddUniversityFile(e.target.files[0], data.countryId);
        if (response.data.status === true) {
            Swal.fire(" Uploaded!", "" + "File Uploaded Successfull", "success");
        } else {
            Swal.fire("Error!", response.data.message, "danger");
        }
    }

    const uploadHospitalFile = async (e) => {
         
        var response = await AdminServices.AddHospitalFile(e.target.files[0], data.countryId);
        if (response.data.status === true) {
            Swal.fire(" Uploaded!", "" + "File Uploaded Successfull", "success");
        } else {
            Swal.fire("Error!", response.data.message, "danger");
        }
    }
    return (
        <>

            <Row className='mt-5'>

                <Col md="6" className='mb-3'>
                    <label className="download-btn  float-right mr-3" htmlFor="uploadCountryFile">Upload</label>
                    <input onChange={uploadUniversityFile} accept=".xlsx,.xls,.csv" type='file' id='uploadCountryFile' ref={inputUniRef} hidden />

                    <CSVLink className="admin-upload-btn  mr-5"
                        data={universityList}
                    >Download</CSVLink>
                    <a href={FILE_TEMPLATE_BASE_URL + "universities_by_country_admin_template.xls"} className='format-download' download> <AiOutlineDownload fontSize="1.5rem" />Sample</a>
                </Col>

                <Col md="6" className='mb-3'>
                    <label className="download-btn  float-right mr-3" htmlFor="uploadHospitalFile">Upload</label>
                    <input type='file' onChange={uploadHospitalFile} id='uploadHospitalFile' ref={inputHospitalRef} hidden />
                    <CSVLink className="admin-upload-btn  mr-5"
                        data={hospitalList}
                    >Download</CSVLink>
                    <a href={FILE_TEMPLATE_BASE_URL + "hospitals_by_country_admin_template.xls"} className='format-download' download> <AiOutlineDownload fontSize="1.5rem" />Sample</a>

                </Col>
            </Row>
            <Row>
                <Col md="6" className='mt-4'>
                    <ReactTable
                        style={{ fontSize: "12px" }}
                        columns={[

                            {
                                Header: "Id",
                                accessor: "id",
                            },
                            {
                                Header: "University Name",
                                accessor: "name",
                            },



                        ]}
                        defaultPageSize={5}
                        // showPaginationBottom={true}
                        className="-striped -highlight"
                        data={universityList}
                    // filterable
                    // defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}

                    />
                </Col>

                <Col md="6" className='mt-4'>
                    <ReactTable
                        style={{ fontSize: "12px" }}
                        columns={[

                            {
                                Header: "Id",
                                accessor: "id",
                            },
                            {
                                Header: "Hospital Name",
                                accessor: "name",
                            },



                        ]}
                        defaultPageSize={5}
                        // showPaginationBottom={true}
                        className="-striped -highlight"
                        data={hospitalList}
                    // filterable
                    // defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}

                    />
                </Col>

            </Row>
        </>
    )
}

export default UniversityandHospitalList