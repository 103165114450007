import React, { useState } from 'react'
import { Row, Col } from "reactstrap"
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css"
import { useSelector, useDispatch } from 'react-redux'
import { GetCohortAdminsUniversityAdmins } from '../../../redux/features/AdminSlice';
const CohortAdmin = () => {
    const dispatch = useDispatch()
    const CohortAdminsUniversityAdmins = useSelector(state => state.Admin.CohortAdminsUniversityAdmins)

    const [selectedContent, setSelectedContent] = useState(null)
    const [selectedStatus, setSelectedStatus] = useState(null)
    const [selectedYear, setSelectedYear] = useState(null)

    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    var data = JSON.parse(localStorageObject);

    var SelectedFilterData = {
        userId: data.id,
        contentType: selectedContent,
        status: selectedStatus,
        year: selectedYear
    }
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 10;
    const years = Array.from({ length: 11 }, (_, index) => startYear + index).reverse();

    const GetContentType = (e) => {
        setSelectedContent(e.target.value)
        SelectedFilterData.contentType = e.target.value
        dispatch(GetCohortAdminsUniversityAdmins(SelectedFilterData))
    }
    const GetYear = (e) => {
        setSelectedYear(e.target.value)
        SelectedFilterData.year = e.target.value
        dispatch(GetCohortAdminsUniversityAdmins(SelectedFilterData))
    }
    return (
        <>
            <Row>
                <Col md="4">
                    <select className='form-control chapterApply'
                        onChange={GetContentType}
                    >
                        <option value="">-- Select Content Type --</option>
                        <option value="QUIZ">Quiz</option>
                        <option value="VIDEO">Video</option>
                        <option value="DOCUMENT">Document</option>
                        <option value="FLASHCARD">Flashcard</option>
                    </select>
                </Col>
                <Col md="4">
                    <select className='form-control chapterApply' >
                        <option value="">-- Select Visibility --</option>


                    </select>
                </Col>
                <Col md="4">
                    <select className='form-control chapterApply'
                        onChange={GetYear}
                    >
                        <option value="">-- Select Date Range --</option>
                        {years.map((value, index) => {
                            return (
                                <option key={index} value={value}>{value}</option>
                            )
                        })}
                    </select>
                </Col>
            </Row>
            <ReactTable
                style={{ fontSize: "12px", marginTop: "20px" }}
                columns={[

                    {
                        Header: "Cohort Admin Name",
                        accessor: "#",
                    },
                    {
                        Header: "Cohort",
                        accessor: "#",
                    },

                    {
                        Header: "See Cohort Admin Table",
                        accessor: "#",
                    },

                    {
                        Header: "Total View",
                        accessor: "totalViews",
                    },
                    {
                        Header: "Total Like",
                        accessor: "totalLikes",
                    },
                    {
                        Header: "Like to View Ratio",
                        accessor: "#",
                    },
                    {
                        Header: "Like Rate",
                        accessor: "rate",
                        Cell: (row) => {
                            return (

                                <div>
                                    {CohortAdminsUniversityAdmins[row.index].likeThisYear - CohortAdminsUniversityAdmins[row.index].likeLastYear}
                                </div>

                            )
                        }
                    },
                    {
                        Header: "Buzz Factor",
                        accessor: "buzz",
                        Cell: (row) => {
                            return (

                                <div>
                                    {CohortAdminsUniversityAdmins[row.index].likeThisYear - ((2 * CohortAdminsUniversityAdmins[row.index].likeLastYear) + CohortAdminsUniversityAdmins[row.index].likeYearBeforeLastYear)}
                                </div>

                            )
                        }
                    },
                    {
                        Header: "Total Flags",
                        accessor: "totalFlags",
                    },
                    {
                        Header: "Flag to View ratio (%)",
                        accessor: "flagViewRatio",
                    },
                    {
                        Header: "MEDcoin",
                        accessor: "medCoins",
                    },
                    {
                        Header: "Contact Cohort Admin",
                        accessor: "#",
                    },
                    {
                        Header: "Dismiss/Enroll",
                        accessor: "#",
                    },


                ]}
                defaultPageSize={5}
                // showPaginationBottom={true}
                className="-striped -highlight"
                data={CohortAdminsUniversityAdmins}
            // filterable
            // defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}

            />
        </>
    )
}

export default CohortAdmin