import React, { useState, useEffect } from 'react'
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Row, Col
} from "reactstrap"
import reportIcon from "../../../assests/iCON/iCON/noun-alert-5119017.png"
import { MdOutlineClose } from "react-icons/md"
import { BsBookmark } from "react-icons/bs"
import comments from "../../../assests/iCON/iCON/noun-comment-3266599.png"
import share from "../../../assests/iCON/iCON/noun-share-5060531.png"
import flag from "../../../assests/iCON/iCON/noun-flag-1017687.png"
import flagRed from "../../../assests/iCON/iCON/noun-flagRed-1017687.png"
import save from "../../../assests/iCON/iCON/noun-bookmark-3025188.png"
import user from "../../../assests/iCON/iCON/noun-friend-2531936.png";
import bigSize from "../../../assests/iCON/iCON/noun-full-screen-1701604.png";
import { FiHeart } from "react-icons/fi"
import { Link } from 'react-router-dom';
import dummy from "../../../assests/dummy.png"
import { FaSortAmountUpAlt } from "react-icons/fa"
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import { useDispatch, useSelector } from 'react-redux'
import Spinner from "../../../spinner/Spinner"
import { videoMaterial, updateVideo } from "../../redux/features/StudySlice"
import moment from "moment"
import StudyMaterialServices from "../../../services/StudyMaterialServices"
import { API_BASE_URL } from '../../../../constant'
import copy from "copy-to-clipboard";
const VideoTab1 = () => {
  let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
  var data = JSON.parse(localStorageObject);
  const dispatch = useDispatch()
  const videoData = useSelector(state => state.study.video)
  const loading = useSelector(state => state.study.loading)
  const [viewComments, setViewComments] = useState(false)
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  }
  const [like, setLike] = useState(
    {
      questionId: "",
      isLike: true,
      userId: data.id,
    }
  )
  const LikeBtn = async (e, value, status) => {

    var tempQuiz = JSON.parse(JSON.stringify(videoData));

    tempQuiz.find(x => x.id == value.id).isLiked = status;
    tempQuiz.find(x => x.id == value.id).likesCount = status == true ? value.likesCount + 1 : value.likesCount - 1
    dispatch(updateVideo(tempQuiz));


    var response = await StudyMaterialServices.LikeQuestion(like, value.id, value.isLiked);
    if (response.data.status == false) {

      var tempQuiz2 = JSON.parse(JSON.stringify(videoData));
      tempQuiz2.find(x => x.id == value.id).isLiked = !status;
      dispatch(updateVideo(tempQuiz2))
    }

  }
  const CommentBtn = async (e, questionId) => {

    var comment = videoData.find(x => x.id == questionId).comment;
    if (!comment) return;

    var response = await StudyMaterialServices.CommnetsQuestion(comment, questionId, true, data.id);
    if (response.data.status == true) {
      var tempQuiz = JSON.parse(JSON.stringify(videoData));
      tempQuiz.find(x => x.id == questionId).studyMaterialComments.push({
        comment: comment,
        user: data
      });
      tempQuiz.find(x => x.id == questionId).comment = "";
      dispatch(updateVideo(tempQuiz));
    }
    else {
      alert("Error")
    }
  }
  const onCommentChange = async (e, questionId) => {

    var tempQuiz = JSON.parse(JSON.stringify(videoData));
    tempQuiz.find(x => x.id == questionId).comment = e.target.value;
    dispatch(updateVideo(tempQuiz));
  }
  const [bookmarked, setBookmarked] = useState(
    {
      questionId: "",
      isLike: true,
      userId: data.id,
    }
  )
  const BookmarkedBtn = async (e, value, status) => {

    var tempQuiz = JSON.parse(JSON.stringify(videoData));
    tempQuiz.find(x => x.id == value.id).isBookmarked = status;
    dispatch(updateVideo(tempQuiz));

    var response = await StudyMaterialServices.BookmarkedQuestion(bookmarked, value.id, value.isBookmarked);
    if (response.data.status == false) {

      var tempQuiz2 = JSON.parse(JSON.stringify(videoData));
      tempQuiz2.find(x => x.id == value.id).isBookmarked = !status;
      dispatch(updateVideo(tempQuiz2));
    }
  }
  const [report, setReport] = useState(false)
  const [quizId, setQuizId] = useState()
  const createReport = (e, id, isFlag) => {
    setReport(!report)
    setQuizId(id)
  }

  const [qFlag, setQflag] = useState(
    {
      questionId: "",
      isLike: true,
      userId: data.id,
      comment: ""
    }
  )
  const [showField, setShowField] = useState(false)
  const radioBtn = (e) => {

    if (e == "other") {
      setShowField(true)
    }
    else {
      setShowField(false)
      qFlag.comment = e;
    }
  }
  const [flagState, setFlagState] = useState(false)
  const FlagBtn = async (e, value, status) => {

    if (qFlag.comment != "") {
      var tempQuiz = JSON.parse(JSON.stringify(videoData));
      tempQuiz.find(x => x.id == quizId).isFlagged = status;
      dispatch(updateVideo(tempQuiz));
      var response = await StudyMaterialServices.FlagdQuestion(qFlag, quizId, value.isFlagged);

      if (response.data.status == false) {
        var tempQuiz2 = JSON.parse(JSON.stringify(videoData));
        tempQuiz2.find(x => x.id == quizId).isFlagged = !status;
        setFlagState(true)
        dispatch(updateVideo(tempQuiz2));
      }
      setReport(!report)
    }

  }

  const handleCopyClick = async (e, value, status) => {
    // Asynchronously call copyTextToClipboard

    if (window.location.pathname == `/study/${value.id}`) {
      copy(window.location.href);
      alert("URL Copied", window.location.href)
    }

  }
  const handleMouseEnter = async (id) => {
    await StudyMaterialServices.UpdateView(id)
  };


  return (
    <>
      {loading ? <Spinner /> :
        <div>


          {videoData?.map((value, index) => (

            // Strat Single Demo
            <Row >
              {/* Report Modal */}
              <Modal
                isOpen={report}
                toggle={createReport.bind(null)}
                className="modal-tags"
                style={{ maxWidth: "350px" }}
              >
                <div style={{ margin: "20px" }}>
                  <h3 style={{ color: "white" }}> <img alt="" src={reportIcon} width="20px" className='mr-2' />Report</h3>
                  <MdOutlineClose className='tag-close-btn' color='white' onClick={() => setReport(false)} />
                  <div className='add-tag-border'></div>
                </div>

                <ModalBody>
                  <RadioGroup
                    onChange={radioBtn}
                    vertical>
                    <RadioButton pointColor="#E84545" value="Inappropriate" iconSize={20} style={{ color: "white" }}>
                      Inappropriate
                    </RadioButton>
                    <RadioButton pointColor="#E84545" iconSize={20} value="Misleading">
                      Misleading
                    </RadioButton>
                    <RadioButton pointColor="#E84545" iconSize={20} value="Incorrect">
                      Incorrect Content
                    </RadioButton>
                    <RadioButton pointColor="#E84545" iconSize={20} value="other">
                      Other
                    </RadioButton>
                  </RadioGroup>

                  <div className="sub-menu"></div>
                  {showField ?
                    <input name='comment' value={qFlag.comment} onChange={(e) => { setQflag({ ...qFlag, comment: e.target.value }) }}
                      placeholder='Please Specify' className='form-control' style={{ backgroundColor: "#1D2226" }} /> : <div className="sub-menu"></div>
                  }
                </ModalBody>
                <button className='report-btn'
                  onClick={(e) => FlagBtn(e, value, true)}
                >Submit</button>

              </Modal>

              <Modal
                isOpen={modal}
                toggle={toggle.bind(null)}
                style={{ maxWidth: "1000px" }}
              >
                <ModalBody>
                  <img alt="" src={value.fileUrl} width="700px" />
                </ModalBody>

              </Modal>
              <Col md="11">

                <div className='social'>
                  <div className=" w-100 border-0 rounded-lg overflow-hidden mr-1 text-grey-900 shadow-lg  mb-5 rounded">
                    <div className="card-image w-100 mb-3"
                      onMouseEnter={() => handleMouseEnter(value.id)}
                    >
                      <Link to={`/user-profile/${value.user.id}`}><span> <img src={API_BASE_URL + value?.user?.imagePath} alt="img" className='mt-2 ml-1 rounded-circle' style={{ width: "50px", height: "50px" }} /> </span>
                        <span>{value.user.firstName + " " + value.user.lastName} <p className='user-des'>{value?.user?.yearTitle}, {value?.user?.universityName}</p></span></Link>
                      <Link to="/single-user-profile">  <img src={user} alt="img" className="float-right mr-2 addUser" width="40px" /></Link>
                      <iframe className="w-100" width="1200px" height="440" src={value.fileUrl} frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                    </div>
                    <div>
                      <div className='mt-1 pt-2 pl-2'>
                        {value.isLiked == true ?
                          <AiFillHeart color="#E84545" fontSize="2em" className="mr-3" onClick={(e) => LikeBtn(e, value, false)} /> :
                          <AiOutlineHeart fontSize="2em" color='white' className="mr-3" onClick={(e) => LikeBtn(e, value, true)} />}
                        <img src={comments} alt="img" width="25px" className="mr-3" onClick={() => setViewComments(!viewComments)} />
                        <Link to={`/study/${value.id}`}> <img alt="" src={share} width="25px"
                          onClick={(e) => { handleCopyClick(e, value) }}
                        /></Link>
                        {/*  <img alt="" src={bigSize} onClick={toggle} alt="img" className='float-right mr-3 ' width="25px" /> */}
                        {value.isBookmarked == true ? <img alt="" src={save} width="23px" className='float-right mr-3 ml-3' onClick={(e) => BookmarkedBtn(e, value, false)} /> :
                          <BsBookmark color='white' fontSize="2em" className='float-right mr-3 ml-3' onClick={(e) => BookmarkedBtn(e, value, true)} />}

                        {value.isFlagged == true ? <img alt="" src={flagRed} width="25px" className='float-right'
                          onClick={(e) => { createReport(e, value.id, false) }}
                        /> : <img alt="" src={flag} width="25px" className='float-right'
                          onClick={(e) => { createReport(e, value.id, true) }}
                        />
                        }
                      </div>
                      <span className='des-time'>{value.likesCount} Likes</span>
                      <p className='caption ml-2'><b style={{ color: "#E84545" }}>{value.user.firstName + " " + value.user.lastName}</b> {value.description}</p>
                      <div className="card-body p-3">

                        <h4 className="font-xss mt-2 ml-0 lh-28 mt-0 pb-3 border-bottom text-white" onClick={() => setViewComments(!viewComments)}>
                          View {value.studyMaterialComments.length} Comments
                        </h4>
                        <input placeholder="Add Comments" id="quizQuestionComment" value={value.comment} className="quizQuestionComment"
                          onChange={(e) => { onCommentChange(e, value.id) }}
                        />
                        <div className='float-right' style={{ color: "#E84545" }}
                          onClick={(e) => CommentBtn(e, value.id)}
                        > Send</div>
                      </div>


                      <div>
                        {value.studyMaterialComments.map((comment, index) => (
                          <div className="row">
                            <div className="col-2 text-left pl-4 pt-3 m-2">
                              <figure className="avatar float-left mb-0">
                                <img alt=""
                                  src={API_BASE_URL + comment.user.imagePath}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src =
                                      "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png";
                                  }}
                                  className="float-right shadow-none w40 mr-2 rounded-circle"
                                />
                              </figure>
                            </div>
                            <div className="col-10 pt-2 comments">
                              <div className="content" style={{ marginLeft: "20px" }}>
                                <b className="author-name display-6 fw-300 mb-0 ">
                                  {comment.user.firstName + " " + comment.user.lastName}
                                </b>
                                <p className="comment-text lh-24 fw-500 font-xssss text-grey-500 ">
                                  {comment.comment}
                                </p>
                              </div>
                            </div>

                          </div>
                        ))}

                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-4"
                  key={index}
                ></div>
              </Col>
            </Row>
            // End Single Demo
          ))}
        </div>
      }
    </>
  )
}

export default VideoTab1