import React from 'react'
import { Row, Col } from "reactstrap"
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css"

const History = () => {
  return (
    <>
      <div className='ml-5 mr-5 mt-5'>
        <Row>
          <Col md="6">
            <h1 className='text-white d-flex justify-content-center'>Current Order </h1>
            <ReactTable
              style={{ fontSize: "12px" }}
              columns={[

                {
                  Header: "Order Date",
                  accessor: "data",
                },
                {
                  Header: "Country",
                  accessor: "data",
                },
                {
                  Header: "University",
                  accessor: "data",
                },

                {
                  Header: "Year",
                  accessor: "data",
                },
                {
                  Header: "Fees",
                  accessor: "id",
                },
                {
                  Header: "Start Date",
                  accessor: "#",
                },
                {
                  Header: "End Date",
                  accessor: "#",
                },
              ]}
              defaultPageSize={5}
              // showPaginationBottom={true}
              className="-striped -highlight"
            // data={DoctorsUniversityAdmins}
            // filterable
            // defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}

            />
          </Col>

          <Col md="6">
            <h1 className='text-white d-flex justify-content-center'>Past Order </h1>
            <ReactTable
              style={{ fontSize: "12px" }}
              columns={[

                {
                  Header: "Order Date",
                  accessor: "data",
                },
                {
                  Header: "Country",
                  accessor: "data",
                },

                {
                  Header: "University",
                  accessor: "data",
                },

                {
                  Header: "Year",
                  accessor: "data",
                },
                {
                  Header: "Fees",
                  accessor: "id",
                },
                {
                  Header: "Start Date",
                  accessor: "#",
                },
                {
                  Header: "End Date",
                  accessor: "#",
                },
              ]}
              defaultPageSize={5}
              // showPaginationBottom={true}
              className="-striped -highlight"
            // data={DoctorsUniversityAdmins}
            // filterable
            // defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}

            />
          </Col>
        </Row>
      </div>
    </>
  )
}

export default History