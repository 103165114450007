import React, { useEffect, useState } from 'react'
import { QuizFilter } from '../quiz/QuizFilters'
import { Row, Col } from "reactstrap"
import { Link, useParams } from 'react-router-dom'
import videoIcon from "../assests/iCON/noun-video.png";
import FlashcardIcon from "../assests/iCON/noun-flash-5136372.png";
import documentIcon from "../assests/iCON/noun-document-1212456.png";
import createIcon from "../assests/iCON/noun-create-1409661.png";
import VideoMaterials from './videos/VideoMaterials';
import Tabs, { Tab } from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import FlashCardsMaterial from './videos/flashcard/FlashCardsMaterial';
import DocumentsMaterial from './documents/DocumentsMaterial';
import Header from '../../components/Header'
import { FiYoutube } from "react-icons/fi"
import { HiOutlineDocumentText } from "react-icons/hi"
import { TiFlashOutline } from "react-icons/ti"
import { useHistory } from "react-router-dom";
import { StudyFilters } from "./StudyFilters"
import { useSelector, useDispatch } from "react-redux"
import { videoMaterial, ChnageTabs } from "../study/redux/features/StudySlice"
import moment from "moment";
const Study = () => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const [video, setvideo] = useState(true)
  const videoBtn = async () => {

    setvideo(!video)
  }

  const [flashCards, setFlashCards] = useState(false)
  const flashcardBtn = async () => {
     
    setFlashCards(!flashCards)
  }

  useEffect(() => {
    dispatch(videoMaterial({
      startDate: (moment(new Date()).format('YYYY-MM-DD')),
      endDate: (moment(new Date()).format('YYYY-MM-DD')),
      branchId: [],
      chapterId: [],
      subChapterId: [],
      LanguageId: [],
      CountryId: [],
      UniversityId: [],
      LevelId: [],
      Bookmarked: null,
      questionCount: 10,
      type: "VIDEO"
    }))
  }, [])
  let history = useHistory();
  const chnageRoute = () => {
     
    if (allTab == null) {
      history.push("/create-video")
    }
    if (allTab == 1) {
      history.push("/create-video")
    }
    if (allTab == 2) {
      history.push("/create-flashcard")

    }
    if (allTab == 3) {
      history.push("/create-document")
    }
  }

  const [studyType, setStudyType] = useState("VIDEO")
  const [allTab, setAllTabs] = useState()
  const chnageTabs = (e, tab) => {
    setAllTabs(tab)
     
    dispatch(ChnageTabs(0))
    if (allTab == 1) {
      dispatch(videoMaterial({
        startDate: (moment(new Date()).format('YYYY-MM-DD')),
        endDate: (moment(new Date()).format('YYYY-MM-DD')),
        branchId: [],
        chapterId: [],
        subChapterId: [],
        LanguageId: [],
        CountryId: [],
        UniversityId: [],
        LevelId: [],
        Bookmarked: null,
        questionCount: 10,
        type: "VIDEO"
      }))
      setStudyType("VIDEO")
    }
    if (tab == 3) {
      dispatch(videoMaterial({
        startDate: (moment(new Date()).format('YYYY-MM-DD')),
        endDate: (moment(new Date()).format('YYYY-MM-DD')),
        branchId: [],
        chapterId: [],
        subChapterId: [],
        LanguageId: [],
        CountryId: [],
        UniversityId: [],
        LevelId: [],
        Bookmarked: null,
        questionCount: 10,
        type: "DOCUMENT"
      }))
      setStudyType("DOCUMENT")
    }
    if (tab == 2) {
      dispatch(videoMaterial({
        startDate: (moment(new Date()).format('YYYY-MM-DD')),
        endDate: (moment(new Date()).format('YYYY-MM-DD')),
        branchId: [],
        chapterId: [],
        subChapterId: [],
        LanguageId: [],
        CountryId: [],
        UniversityId: [],
        LevelId: [],
        Bookmarked: null,
        questionCount: 10,
        type: "FLASHCARD"
      }))

      setStudyType("FLASHCARD")
    }

  }




  return (
    <>
      <Header />
      <Row className="quiz" style={{minHeight: '100vh'}}>

        <StudyFilters type={studyType} />
        <div className="col-md-8 col-sm-12">

          <Tabs
            onClick={(event, tab) => chnageTabs(event, tab)}
            activeTab="1" className="mt-4" >
            <Tab title={<span style={{ fontWeight: "400" }}><FiYoutube className='mr-2' fontSize="1.8rem" />Videos</span>} className="mr-4">
              <VideoMaterials />
            </Tab>
            <Tab title={<span style={{ fontWeight: "400" }}><TiFlashOutline fontSize="1.8rem" />Flashcards</span>} className="mr-4">
              <FlashCardsMaterial />
            </Tab>
            <Tab title={<span style={{ fontWeight: "400", marginLeft: "-4px" }}><HiOutlineDocumentText fontSize="1.8rem" />Documents</span>} className="mr-4">
              <DocumentsMaterial />
            </Tab>
            <Tab className="ml-5" style={{ backgroundColor: "blue" }} title={
              <button style={{ margin: "-25px", marginLeft: "-27px" }} onClick={chnageRoute} className="study-material" > <img alt="" src={createIcon} />Add Study Material</button>
            }>

            </Tab>
          </Tabs>
        </div>
      </Row>
    </>
  )
}

export default Study
