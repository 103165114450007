import React,{useState,useEffect} from 'react'
import Header from '../../components/Header'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Balance from "./tabs/Ballance"
import BankingDetails from "./tabs/BankingDetails"
import History from "./tabs/History"
import {loadStripe} from '@stripe/stripe-js';
import {
    Elements,
} from '@stripe/react-stripe-js';
import PaymentServices from '../services/PaymentServices';
const stripePromise = loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx');

const Wallet = () => {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    var data = JSON.parse(localStorageObject);
    const [medCoin,setMedcoin]=useState()

    useEffect(async()=>{
        const res=await PaymentServices.MedCoinForContentCreator(data.id)
        setMedcoin(res.data)
        console.log(res.data)
    },[])
    const options = {
        mode: 'payment',
        amount: 1099,
        currency: 'usd',
        // Fully customizable with appearance API.
        appearance: {
            /*...*/
        },
    };

    return (
        <>
            <Header />
            <div className='pt-5 d-flex flex-column ' style={{ backgroundColor: "#181818", color: "white", minHeight: '100vh', alignItems: "center" }}>
                <h1 className='text-white'>My Wallet</h1>
                <Tabs
                    defaultActiveKey="Balance"
                    id="uncontrolled-tab-example"
                    className="mb-3 mt-5"
                >
                    <Tab eventKey="Balance" title="Balance">
                        <Elements stripe={stripePromise} options={options}>
                            <Balance medCoin={medCoin} user_info={data} />
                        </Elements>
                    </Tab>

                    <Tab eventKey="BankingDetails" title="Banking Details" >
                        <BankingDetails />
                    </Tab>

                    <Tab eventKey="History" title="History" >
                        <History />
                    </Tab>
                </Tabs>

            </div>
        </>
    )
}

export default Wallet