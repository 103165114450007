import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import { Row, Col } from "reactstrap"
import { FiHeart } from "react-icons/fi"
import comments from "../../assests/iCON/iCON/noun-comment-3266599.png"
import share from "../../assests/iCON/iCON/noun-share-5060531.png"
import flag from "../../assests/iCON/iCON/noun-flag-1017687.png"
import save from "../../assests/iCON/iCON/noun-bookmark-3025188.png"
import ProfileServices from '../../services/ProfileServices';
import { API_BASE_URL } from '../../../constant';
import FileViewer from 'react-file-viewer';
import { Link, useParams } from 'react-router-dom';

const Social = ({ eventKey }) => {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    const [socialPost, setSocialPost] = useState([])
    const { id } = useParams()

    useEffect(() => {
        GetUserPost()
        GetUserPostedQuestion()
        GetUserPostedStudyMaterial()
    }, [eventKey])
    const GetUserPost = async () => {
        let res;
        debugger
        if (eventKey === "bookmarked") {
            res = await ProfileServices.GetBookMarkPostByUserId(id != undefined ? id : Studentdata.id);
        } else {
            res = await ProfileServices.GetPostByUserId(id != undefined ? id : Studentdata.id);
        }
        setSocialPost(res.data.payload);
    };

    const [postedQuiz, setPostedQuiz] = useState([]);

    const GetUserPostedQuestion = async () => {
        let res;
        if (eventKey === "bookmarked") {
            res = await ProfileServices.GetUserBookmarkedQuestionById(id != undefined ? id : Studentdata.id)
        }
        else {
            res = await ProfileServices.GetUserPostedQuestionById(id != undefined ? id : Studentdata.id)
        }

        setPostedQuiz(res.data.payload)
    }

    const [postedVideo, setPostedVideo] = useState([])
    const [postedDocument, setPostedDocument] = useState([])
    const [postedFlashcards, setPostedFlashcards] = useState([])

    const GetUserPostedStudyMaterial = async () => {
        let res;
        if (eventKey === "bookmarked") {
            res = await ProfileServices.GetUserBookMarkedStudyMaterialById(id != undefined ? id : Studentdata.id)
        }
        else {
            res = await ProfileServices.GetUserPostedStudyMaterialById(id != undefined ? id : Studentdata.id)
        }
        setPostedVideo(res.data.payload.filter(x => x.materialType == "VIDEO"))
        setPostedDocument(res.data.payload.filter(x => x.materialType == "DOCUMENT"))
        setPostedFlashcards(res.data.payload.filter(x => x.materialType == "FLASHCARD"))
    }
    console.log(postedDocument, "postedDocument")
    return (
        <>
            <Tabs
                defaultActiveKey="Social"
                id="uncontrolled-tab-example"
                className="mb-3 mt-3 d-flex justify-content-center"
            >

                <Tab eventKey="Social" title="Social">
                    <div className="row d-block w-100 border-0 rounded-lg overflow-hidden" >
                        <div className=" mb-lg-3 pb-0">
                            <h2 className="fw-400 font-lg d-block">
                                <a
                                    href="/default-user-profile"
                                    className="float-right"
                                >
                                </a>
                            </h2>
                        </div>
                        <div className="card-body pb-0">
                            <div className="row">
                                {socialPost.map((value, index) => (
                                    // Start Single Demo
                                    <div
                                        className="col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-4"
                                        key={index}
                                    >
                                        <div className="w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 course-card">
                                            <div className="card-image w-100 mb-3">
                                                <img
                                                    src={value.fileUrl.startsWith("http") ? value.fileUrl : API_BASE_URL + value.fileUrl}
                                                    alt="POST"
                                                    className="w-100"
                                                    style={{
                                                        height: '200px', // Set a fixed height
                                                        objectFit: 'cover' // Ensure the image covers the container without distortion
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    // End Single Demo
                                ))}

                            </div>
                        </div>
                    </div>
                </Tab>

                <Tab eventKey="Quiz" title="Quiz" >
                    <div className="row d-flex justify-content-around" style={{ userSelect: "none", cursor: "pointer" }}>
                        {postedQuiz?.map((question) => (
                            <div key={question.id} className="col-md-5 rounded mt-5 mb-5 quizs">
                                <div className="mt-3 mb-4">
                                    {question.title}
                                </div>
                                {question.questionOptions.map((option) => (
                                    <div key={option.id}>
                                        <p>
                                            <input type="radio" name={`quiz-option-${question.id}`} />
                                            <label className="my-class">{option.title}</label>
                                        </p>
                                    </div>
                                ))}
                            </div>
                        ))}
                        {/* <div className='col-md-1'></div> */}
                        {/* <div className="col-md-5 rounded mt-5  mb-5 ml-3 quizs">
                            <div className="mt-3 mb-4 ">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Atque sit laboriosam sint sunt possimus aliquam at alias eius, eaque illo! Optio at accusamus laboriosam ipsum ab perspiciatis. Impedit, perspiciatis dolorum. </div>
                            <div>
                                <p>
                                    <input type="radio" />
                                    <label className="my-class" >Option 1<h1></h1></label>
                                </p>
                            </div>

                            <div>
                                <p>
                                    <input type="radio" />
                                    <label className="my-class" >Option 2<h1></h1></label>
                                </p>
                            </div>
                            <div>
                                <p>
                                    <input type="radio" />
                                    <label className="my-class" >Option 3<h1></h1></label>
                                </p>
                            </div>
                            <div className='bg-darks'>
                                <div className='mt-2 pt-2 pl-2'>
                                    <FiHeart fontSize="2em" className="mr-3" />
                                    <img alt="" src={comments} width="25px" className="mr-3" />
                                    <img alt="" src={share} width="25px" />
                                    <img alt="" src={save} width="23px" className='float-right mr-3 ml-3' />
                                    <img alt="" src={flag} width="25px" className='float-right' />
                                </div>

                                <div className="card-body p-3">

                                    <h4 className="font-xss mt-2 ml-0 lh-28 mt-0 pb-3 border-bottom text-white" >
                                        View 08 Comments
                                    </h4>
                                    Add Comments
                                    <div className='float-right' style={{ color: "#E84545" }}> Send</div>
                                </div>


                            </div>
                        </div> */}
                    </div>
                </Tab>
                <Tab eventKey="Study" title="Study" >
                    <div className='d-flex bd-highlight '>
                        <h1 className='text-white me-auto p-2 bd-highlight'>Video</h1>
                        {/* <h2 className='p-2 bd-highlight float-right' style={{ marginLeft: "950px", color: "#E84545" }}>See All</h2> */}
                    </div>


                    <div className="row">
                        {postedVideo.map((value, index) => (
                            // Start Single Demo
                            <div
                                className="col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-4"
                                key={index}
                            >
                                <div className="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 course-card">
                                    <div className="card-image w-100 mb-3">
                                        <video
                                            src={`${value.fileUrl}`}
                                            className="w-100"
                                            controls
                                        >
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            </div>
                            // End Single Demo
                        ))}

                    </div>

                    <div className='d-flex bd-highlight '>
                        <h1 className='text-white me-auto p-2 bd-highlight'>Document</h1>
                        {/* <h2 className='p-2 bd-highlight float-right' style={{ marginLeft: "890px", color: "#E84545" }}>See All</h2> */}
                    </div>

                    <div className="row ">

                        {postedDocument.map((value, index) => (
                            // Strat Single Demo
                            <div
                                className="col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-4"
                                key={index}
                            >
                                <div className="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 course-card">
                                    <div className="card-image w-100 mb-3">

                                        <FileViewer
                                            // fileType=".docx"
                                            filePath={value.fileUrl?.startsWith("http") ? value.fileUrl : API_BASE_URL + value.fileUrl} />
                                    </div>

                                </div>
                            </div>

                            // End Single Demo
                        ))}
                    </div>



                    <div className='d-flex bd-highlight '>
                        <h1 className='text-white me-auto p-2 bd-highlight'>FlashCards</h1>
                        {/* <h2 className='p-2 bd-highlight float-right' style={{ marginLeft: "870px", color: "#E84545" }}>See All</h2> */}
                    </div>
                    <div className="row d-flex justify-content-center">
                        {postedFlashcards?.map((value, index) =>
                            <div className="col-md-5 rounded mb-5 ml-4 quizs" key={index}>
                                {value.studyMaterialQuestions?.map((ques) =>
                                    <>
                                        <div className="mt-3 mb-4 text-center">{ques.question.replace(/<[^>]*>?/gm, '')} </div>
                                        <div style={{ width: "487px", border: "3px solid #181818", marginLeft: "-15px" }}></div>
                                        <div className="mt-3 mb-4 text-center">{ques.answer}.</div>
                                    </>
                                )}

                            </div>
                        )}
                        <div className='col-md-1'></div>

                    </div>

                </Tab>
            </Tabs>

        </>
    )
}

export default Social