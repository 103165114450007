import axios from 'axios';
import { API_BASE_URL } from '../../constant';

class StudyMaterialServices {

  CreateVideoMaterial(object,dpropdownList,content,){
     
    return axios.post(API_BASE_URL + `api/StudyMaterial/AddStudyMaterial?SID=${object.userId}`, {
      levelId:parseInt(dpropdownList.LevelId),
      branchId:parseInt(dpropdownList.BranchId),
      chapterId:parseInt(dpropdownList.ChapterId),
      subChapterId:parseInt(dpropdownList.SubChapterId),
      languageId:parseInt(object.languageId),
      universityId:parseInt(object.universityId),
      materialType:object.type,
      countryId:object.countryId,
      isPremium:object.isPremimum,
      description:object.description,
      fileUrl: content,
      studyMaterialQuestion:content,
      hashtags:object.hashtags
    });
  }
  GetStudyMaterialById(id) {
    return axios.get(API_BASE_URL + `api/StudyMaterial/GetStudyMaterialById?id=${id}`,);
  }
  CreateFlashCardMaterial(object,dpropdownList,content,){
     
    return axios.post(API_BASE_URL + `api/StudyMaterial/AddStudyMaterial?SID=${object.userId}`, {
      levelId:parseInt(dpropdownList.LevelId),
      branchId:parseInt(dpropdownList.BranchId),
      chapterId:parseInt(dpropdownList.ChapterId),
      subChapterId:parseInt(dpropdownList.SubChapterId),
      languageId:parseInt(object.languageId),
      materialType:object.type,
      countryId:object.countryId,
      isPremium:object.isPremimum,
      description:object.description,
      studyMaterialQuestions:content,
      hashtags:object.hashtags
    });
  }
  LikeQuestion(object, value,islike) {
    return axios.post(API_BASE_URL + 'api/StudyMaterial/LikeStudyMaterial', {
      questionId: value,
      isLike: islike==true?false:true,
      userId: object.userId,
      isStudent: object.isStudent
    });
  }

  CommnetsQuestion(comment, qId, isComment, userId) {
    return axios.post(API_BASE_URL + 'api/StudyMaterial/CommentStudyMaterial', {
      questionId: qId,
      isComment: isComment,
      comment:comment,
      userId: userId
    });
  }
  BookmarkedQuestion(object, value,isBookmarked) {
     
    return axios.post(API_BASE_URL + 'api/StudyMaterial/BookmarkStudyMaterial', {
      questionId: value,
      isLike: isBookmarked==true?false:true,
      userId: object.userId,
      isStudent: object.isStudent
    });
  }
  FlagdQuestion(object, value,isFlag) {
     
    return axios.post(API_BASE_URL + 'api/StudyMaterial/FlagStudyMaterial', {
      questionId: value,
      isLike: isFlag==true?false:true,
      userId: object.userId,
      comment:object.comment,
      isStudent: object.isStudent
    });
  }
  UpdateView(id) {
    return axios.post(API_BASE_URL + `api/StudyMaterial/UpdateView?id=${id}`)
}

DeleteStudyMaterialById(id) {
  return axios.delete(API_BASE_URL + `api/StudyMaterial/DeleteStudyMaterial?id=${id}`,);
}
}

export default new StudyMaterialServices();

