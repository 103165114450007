import React, { useState, useEffect } from 'react'
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md"
import school from "../../../../assets/iCON/noun-university-1340867.png"
import { SessionLogoutMsg } from '../../../../constant'
import AllFilterServices from '../../../services/AllFilterServices'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { radarData, topUserList } from "../../QuizResult/store/features/TopUserSlice"

const UniversityFilter = () => {
    const dispatch = useDispatch();
    const [selectedUniversity, setSelectedUniversity] = useState([])
    var SelectedFilterData = {
        universityId: selectedUniversity,
        startDate: null,
        endDate: null,
        branchId: [],
        chapterId: [],
        subChapterId: [],
        countryId: [],
        levelId: []
    }
    let history = useHistory()

    const [university, setUniversity] = useState(selectedUniversity.length == 0 ? false : true);
    const [universityList, setUniversityList] = useState([]);
    useEffect(async () => {
        var response = await AllFilterServices.getAllUniversities()
        if (response.data.message == SessionLogoutMsg) {
            history.push("/login")
            localStorage.removeItem('Exe-Med_Student_Info');
        }
        else {
            setUniversityList(response.data.payload);
        }
    }, [])
    const handleSelectAllUniversity = (e) => {
         
        if (e.target.checked == true) {
            var tempSelectedUniversity = universityList.map(li => li.id);
            setSelectedUniversity(tempSelectedUniversity)
            SelectedFilterData.universityId = tempSelectedUniversity;
            dispatch(topUserList(SelectedFilterData))
            dispatch(radarData(SelectedFilterData))
            //   localStorage.setItem("University_List", JSON.stringify(tempSelectedUniversity));

        }
        else {
            setSelectedUniversity([])
            //   localStorage.removeItem("University_List", JSON.stringify(tempSelectedUniversity));
            SelectedFilterData.universityId = [];
            dispatch(topUserList(SelectedFilterData))
            dispatch(radarData(SelectedFilterData))
        }
    }
    const [universityName, setUniversityName] = useState('');
    const [universitySearch, setUniversiitySearch] = useState(universityList);
    const handleUniversitySearch = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = universityList.filter((user) => {
                return user.name.toLowerCase().startsWith(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setUniversiitySearch(results);
        } else {
            setUniversiitySearch(universityList);
            // If the text field is empty, show all users
        }
        setUniversityName(keyword);
    }
    const FilterUniChange = (e) => {
         
        var Id = e.target.getAttribute('data-questionId')
         
        if (e.target.checked == false) {
            var tempSelectedUni = selectedUniversity.filter(function (obj) {
                return obj != Id;
            });
            setSelectedUniversity(tempSelectedUni)
            SelectedFilterData.universityId = tempSelectedUni;
            //   localStorage.setItem("University_List", JSON.stringify(tempSelectedUni));
            dispatch(topUserList(SelectedFilterData))
            dispatch(radarData(SelectedFilterData))
        }
        else {
            var tempSelectedUni = selectedUniversity;
            tempSelectedUni.push(parseInt(Id))
            setSelectedUniversity(tempSelectedUni)
            //   localStorage.setItem("University_List", JSON.stringify(tempSelectedUni));
             
            SelectedFilterData.universityId = tempSelectedUni;
            dispatch(topUserList(SelectedFilterData))
            dispatch(radarData(SelectedFilterData))
        }
        e.target.parentNode.childNodes.forEach(function (element) {
            if (e.target.checked == true) {
                element.classList.add("change-color")
                element.classList.add("checkbox-color")
            }
            else {
                element.classList.remove("change-color")
            }
        });

    }
    return (
        <div> <div className="form-group mb-1 filter-margin" onClick={() => setUniversity(!university)}>
            {university ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                 <img alt="" src={school} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>University</h3>
                {university ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
            </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                 <img alt="" src={school} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>University</h3>
                {university ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
            </a>}
        </div>
            {university && <div className="form-group mb-1 sub-menu-filter mb-3 mr-3 w-100 filter-scrollbar scrollbar-primary">
                <input placeholder='Search University' onChange={handleUniversitySearch} value={universityName} className='form-control' />
                <div>
                    <label className='ml-2 CursorPointer'>All</label>
                    <input
                        type="checkbox"
                        name="selectAllUniversity"
                        id="selectAllUniversity"
                        onChange={handleSelectAllUniversity}
                        className="float-right checkbox"
                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                    />
                </div>
                {universitySearch && universitySearch.length > 0 ?

                    universitySearch.map((value, index) => {
                        return (
                            <div key={index}>
                                <label className='ml-2'>{value.name}</label>
                                <input
                                    type="checkbox"
                                    data-questionId={value.id}
                                    onChange={FilterUniChange}
                                    checked={selectedUniversity.includes(value.id)}
                                    className="float-right checkbox"
                                    style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                                />
                            </div>
                        )
                    }) :
                    universityList.map((value, index) => {
                        return (
                            <div key={index}>
                                <label className='ml-2'>{value.name}</label>
                                <input
                                    type="checkbox"
                                    data-questionId={value.id}
                                    onChange={FilterUniChange}
                                    checked={selectedUniversity.includes(value.id)}
                                    className="float-right checkbox"
                                    style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                                />
                            </div>
                        )
                    })
                }


            </div>}</div>
    )
}

export default UniversityFilter