import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51ODQITBRTVsx3zdp6G8UNO2Rw18yOTNxoD0Ov7EYAkoYKEr5RWKaKL0o9KzHJv2cMMxRqTOR3TSrHOwfmbNS5iUM00ZoXqgYcq');

export default function App() {

  const options = {
    // passing the client secret obtained from the Stripe Dashboard
    clientSecret:`sk_test_51ODQITBRTVsx3zdpfCJUUL8MoM0RjvoToKg44xew2jjKaSOfGu6zh9FUbQoQnVfi7DW5PF8jzRt8DrmxISp3B7wM00jtCHG3F1`,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm />
    </Elements>
  );
};