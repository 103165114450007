import React, { useState } from 'react'
import {
    Modal,
    ModalBody,
} from "reactstrap"
import { Link } from 'react-router-dom'
import copy from "copy-to-clipboard";
import { ImHappy2, ImSad } from "react-icons/im"
import reportIcon from "../../../../assests/iCON/iCON/noun-alert-5119017.png"
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai"
import { MdOutlineClose } from 'react-icons/md'
import { BsArrowRight, BsArrowLeft } from "react-icons/bs"
import comments from "../../../../assests/iCON/iCON/noun-comment-3266599.png"
import share from "../../../../assests/iCON/iCON/noun-share-5060531.png"
import flag from "../../../../assests/iCON/iCON/noun-flag-1017687.png"
import flagRed from "../../../../assests/iCON/iCON/noun-flagRed-1017687.png"
import save from "../../../../assests/iCON/iCON/noun-bookmark-3025188.png"
import Spinner from "../../../../spinner/Spinner"
import { useDispatch, useSelector } from 'react-redux'
import { updateVideo } from "../../../redux/features/StudySlice"
import { BsBookmark } from "react-icons/bs"
import StudyMaterialServices from "../../../../services/StudyMaterialServices"
import { API_BASE_URL } from '../../../../../constant'
const TodayTab = () => {
    const dispatch = useDispatch();
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    var data = JSON.parse(localStorageObject);
    const FlashcardData = useSelector(state => state.study.video)
    const loading = useSelector(state => state.study.loading)
    const [viewComments, setViewComments] = useState(false)
    const [like, setLike] = useState(
        {
            questionId: "",
            isLike: true,
            userId: data.id,
        }
    )
    const LikeBtn = async (e, value, status) => {

        var tempQuiz = JSON.parse(JSON.stringify(FlashcardData));
        tempQuiz.find(x => x.id == value.id).isLiked = status;
        tempQuiz.find(x => x.id == value.id).likesCount = status == true ? value.likesCount + 1 : value.likesCount - 1
        dispatch(updateVideo(tempQuiz));

        var response = await StudyMaterialServices.LikeQuestion(like, value.id, value.isLiked);
        if (response.data.status == false) {
            var tempQuiz2 = JSON.parse(JSON.stringify(FlashcardData));
            tempQuiz2.find(x => x.id == value.id).isLiked = !status;
            dispatch(updateVideo(tempQuiz2))
        }

    }
    const CommentBtn = async (e, questionId) => {
        var comment = FlashcardData.find(x => x.id == questionId).comment;
        if (!comment) return;
         
        var response = await StudyMaterialServices.CommnetsQuestion(comment, questionId, true, data.id);
        if (response.data.status == true) {
            var tempQuiz = JSON.parse(JSON.stringify(FlashcardData));
            tempQuiz.find(x => x.id == questionId).studyMaterialComments.push({
                comment: comment,
                user: data
            });
            tempQuiz.find(x => x.id == questionId).comment = "";
            dispatch(updateVideo(tempQuiz));
        }
        else {
            alert("Error")
        }
    }
    const onCommentChange = async (e, questionId) => {
         
        var tempQuiz = JSON.parse(JSON.stringify(FlashcardData));
        tempQuiz.find(x => x.id == questionId).comment = e.target.value;
        dispatch(updateVideo(tempQuiz));
    }
    const [bookmarked, setBookmarked] = useState(
        {
            questionId: "",
            isLike: true,
            userId: data.id,
        }
    )
    const BookmarkedBtn = async (e, value, status) => {
         
        var tempQuiz = JSON.parse(JSON.stringify(FlashcardData));
        tempQuiz.find(x => x.id == value.id).isBookmarked = status;
        dispatch(updateVideo(tempQuiz));

        var response = await StudyMaterialServices.BookmarkedQuestion(bookmarked, value.id, value.isBookmarked);
        if (response.data.status == false) {
             
            var tempQuiz2 = JSON.parse(JSON.stringify(FlashcardData));
            tempQuiz2.find(x => x.id == value.id).isBookmarked = !status;
            dispatch(updateVideo(tempQuiz2));
        }
    }
    const [report, setReport] = useState(false)
    const [quizId, setQuizId] = useState()
    const createReport = (e, id) => {
        setReport(!report)
        setQuizId(id)
    }
    const [qFlag, setQflag] = useState(
        {
            questionId: "",
            isLike: true,
            userId: data.id,
            comment: ""
        }
    )
    const [showField, setShowField] = useState(false)
    const radioBtn = (e) => {
         
        if (e == "other") {
            setShowField(true)
        }
        else {
            setShowField(false)
            qFlag.comment = e;
        }
    }
    const [flagState, setFlagState] = useState(false)
    const FlagBtn = async (e, value, status) => {
        if (qFlag.comment != "") {
            var tempQuiz = JSON.parse(JSON.stringify(FlashcardData));
            tempQuiz.find(x => x.id == quizId).isFlagged = status;
            dispatch(updateVideo(tempQuiz));
            var response = await StudyMaterialServices.FlagdQuestion(qFlag, quizId, value.isFlagged);
             
            if (response.data.status == false) {
                var tempQuiz2 = JSON.parse(JSON.stringify(FlashcardData));
                tempQuiz2.find(x => x.id == quizId).isFlagged = !status;
                setFlagState(true)
                dispatch(updateVideo(tempQuiz2));
            }
            setReport(!report)
        }
    }
    const handleCopyClick = async (e, value, status) => {
        // Asynchronously call copyTextToClipboard
         
        if (window.location.pathname == `/study/${value.id}`) {
            copy(window.location.href);
            alert("URL Copied", window.location.href)
        }
    }
    const [ans, setAns] = useState("")
    const [showAns, setShowAns] = useState(false)
    const RevealAns = (e, value, status) => {
         
        var tempQuiz = JSON.parse(JSON.stringify(FlashcardData));
        var ans = tempQuiz.find(x => x.id == value).studyMaterialQuestions;
        setAns(ans[0].answer)
        setShowAns(true)
    }
    const handleAssigneeOnClick = (e, value, incdec) => {
        var tempQuiz2 = JSON.parse(JSON.stringify(FlashcardData));
        tempQuiz2.find(x => x.id == value).levelId = tempQuiz2.find(x => x.id == value).levelId + incdec;
        dispatch(updateVideo(tempQuiz2));
    };

    const handleMouseEnter =async (id) => {
        await StudyMaterialServices.UpdateView(id)
    };

    return (
        <>
            {loading ? <Spinner /> :
                <div className="row" >
                    <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1"></div>
                    {showAns ||
                        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 mb-4 " >
                            {FlashcardData?.map((value, index) => (
                                <>
                                    {/* Report Modal */}
                                    <Modal
                                        isOpen={report}
                                        toggle={createReport.bind(null)}
                                        className="modal-tags"
                                        style={{ maxWidth: "350px" }}
                                    >
                                        <div style={{ margin: "20px" }}>
                                            <h3 style={{ color: "white" }}> <img alt="" src={reportIcon} width="20px" className='mr-2' />Report</h3>
                                            <MdOutlineClose className='tag-close-btn' color='white' onClick={() => setReport(false)} />
                                            <div className='add-tag-border'></div>
                                        </div>

                                        <ModalBody>
                                            <RadioGroup
                                                onChange={radioBtn}
                                                vertical>
                                                <RadioButton pointColor="#E84545" value="Inappropriate" iconSize={20} style={{ color: "white" }}>
                                                    Inappropriate
                                                </RadioButton>
                                                <RadioButton pointColor="#E84545" iconSize={20} value="Misleading">
                                                    Misleading
                                                </RadioButton>
                                                <RadioButton pointColor="#E84545" iconSize={20} value="Incorrect">
                                                    Incorrect Content
                                                </RadioButton>
                                                <RadioButton pointColor="#E84545" iconSize={20} value="other">
                                                    Other
                                                </RadioButton>
                                            </RadioGroup>

                                            <div className="sub-menu"></div>
                                            {showField ?
                                                <input name='comment' value={qFlag.comment} onChange={(e) => { setQflag({ ...qFlag, comment: e.target.value }) }}
                                                    placeholder='Please Specify' className='form-control' style={{ backgroundColor: "#1D2226" }} /> : <div className="sub-menu"></div>
                                            }
                                        </ModalBody>
                                        <button className='report-btn'
                                            onClick={(e) => FlagBtn(e, value, true)}
                                        >Submit</button>

                                    </Modal>

                                    <div className=" w-100 p-0 overflow-hidden mr-1  shadow-lg p-3 mb-5 quizs" style={{ borderRadius: "20px" }}              
                                  onMouseEnter={() => handleMouseEnter(value.id)}
>
                                        <div className=" position-relative d-block">
                                            <div className=" mb-4 ">{value.studyMaterialQuestions[value.levelId]?.question.replace(/<[^>]*>?/gm, '')} </div>
                                        </div>

                                        <div className='bg-darks'>
                                            <div className='mt-2 pt-2 pl-2'>
                                                {value.isLiked == true ?
                                                    <AiFillHeart color="#E84545" fontSize="2em" className="mr-3" onClick={(e) => LikeBtn(e, value, false)} /> :
                                                    <AiOutlineHeart fontSize="2em" color='white' className="mr-3" onClick={(e) => LikeBtn(e, value, true)} />}
                                                 <img alt="" src={comments} onClick={() => setViewComments(!viewComments)} width="25px" className="mr-3" />
                                                <Link to={`/study/${value.id}`}> <img alt="" src={share} width="25px"
                                                    onClick={(e) => { handleCopyClick(e, value) }}
                                                /></Link>
                                                {value.studyMaterialQuestions.length - 1 > value.levelId && <BsArrowRight onClick={(e) => { handleAssigneeOnClick(e, value.id, 1) }}
                                                    fontSize="1.50em" className='float-right mr-2' color='#B1F626' />}
                                                {value.levelId > 0 && <BsArrowLeft onClick={(e) => { handleAssigneeOnClick(e, value.id, -1) }}
                                                    fontSize="1.50em" className='float-right mr-2' color='#B1F626' />}
                                                {value.isBookmarked == true ?  <img alt="" src={save} width="23px" className='float-right mr-3 ml-3' onClick={(e) => BookmarkedBtn(e, value, false)} /> :
                                                    <BsBookmark color='white' fontSize="2em" className='float-right mr-3 ml-3' onClick={(e) => BookmarkedBtn(e, value, true)} />}
                                                {value.isFlagged == true ?  <img alt="" src={flagRed} width="25px" className='float-right'
                                                    onClick={(e) => { createReport(e, value.id) }}
                                                /> :  <img alt="" src={flag} width="25px" className='float-right'
                                                    onClick={(e) => { createReport(e, value.id) }}
                                                />
                                                }
                                            </div>
                                            <span className='des-time'>{value.likesCount} Likes</span>
                                            <div className="card-body p-3">
                                                <h4 className="font-xss mt-2 ml-0 lh-28 mt-0 pb-3 border-bottom text-white" onClick={() => setViewComments(!viewComments)}>
                                                    View {value.studyMaterialComments.length} Comments
                                                </h4>
                                                <input placeholder="Add Comments" id="quizQuestionComment" value={value.comment} className="quizQuestionComment"
                                                    onChange={(e) => { onCommentChange(e, value.id) }}
                                                />
                                                <div className='float-right' style={{ color: "#E84545" }}
                                                    onClick={(e) => CommentBtn(e, value.id)}
                                                > Send</div>
                                            </div>

                                            {viewComments &&
                                                <div>
                                                    {value.studyMaterialComments.map((comment, index) => (
                                                        <div className="row">
                                                            <div className="col-2 text-left pl-4 pt-3 m-2">
                                                                <figure className="avatar float-left mb-0">
                                                                     <img alt=""
                                                                        src={API_BASE_URL + comment.user.imagePath}
                                                                        onError={(e) => {
                                                                            e.target.onerror = null;
                                                                            e.target.src =
                                                                                "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png";
                                                                        }}
                                                                        className="float-right shadow-none w40 mr-2 rounded-circle"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div className="col-10 pt-2 comments">
                                                                <div className="content" style={{ marginLeft: "20px" }}>
                                                                    <b className="author-name display-6 fw-300 mb-0 ">
                                                                        {comment.user.firstName + " " + comment.user.lastName}
                                                                    </b>
                                                                    <p className="comment-text lh-24 fw-500 font-xssss text-grey-500 ">
                                                                        {comment.comment}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    ))}

                                                </div>
                                            }
                                            <div className="d-flex justify-content-around">
                                                <button className="revealAnswere mt-4 mb-4" onClick={(e) => RevealAns(e, value.id)}
                                                    type="button">Reveal Answer</button>
                                            </div>
                                        </div>
                                    </div>

                                </>
                            ))}

                        </div>

                    }
                    {showAns &&
                        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 mb-4 " >
                            <div className=" w-100 p-0 overflow-hidden mr-1  shadow-lg p-3 mb-5 quizs" style={{ borderRadius: "20px" }}>
                                <div className=" position-relative d-block">
                                    <div className="mb-4 ">{ans} 
                                    {/* <Link >(Preview)</Link> */}
                                    </div>
                                </div>
                                <div className='bg-darks'>
                                    <div className="card-body d-flex justify-content-around">
                                        <button onClick={() => setShowAns(!showAns)} className="revealAnswere mt-4 mb-4"
                                            style={{ backgroundColor: "red" }}
                                            type="button"><ImSad className='m-1' />Missed it</button>
                                        <button onClick={() => setShowAns(!showAns)} className="revealAnswere mt-4 mb-4"
                                            style={{ backgroundColor: "white", color: "#E84545" }}
                                            type="button"><ImHappy2 className='m-1' />Got it</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    }
                </div>
            }
        </>
    )
}

export default TodayTab