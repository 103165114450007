import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../../../constant";


export const videoMaterial = createAsyncThunk("study/studyVideo", async (object) => {
     
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);

    var res = await fetch(API_BASE_URL + `api/StudyMaterial/GetFilteredStudyMaterial?SID=${Studentdata.id}&JWT=${Studentdata.jwt}`, {
        method: 'POST',
        headers: {
            Accept: "application/json",
            "content-type": "application/json"
        },
        body: JSON.stringify(object)
    });
    var data = await res.json();
    if (data.status) {
        return (
            data.payload
        )
    }
    else {
        return [];
    }


})


export const StudyBranchesFilter = createAsyncThunk("study/studyBranch", async (object) => {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);

    if (object.startDate == null) {
        var res = await fetch(API_BASE_URL + `api/Branch/GetAllBranches?SID=${Studentdata.id}&JWT=${Studentdata.jwt}&CustomTab=${object.customTab == undefined ? 0 : object.customTab}&levelId=${Studentdata.userLevelId}`, {
            method: 'GET',
        })
    }
    else {
        var res = await fetch(API_BASE_URL + `api/Branch/GetAllBranches?SID=${Studentdata.id}&JWT=${Studentdata.jwt}&StartDate=${object.startDate == undefined ? null : object.startDate}&EndDate=${object.endDate == undefined ? null : object.endDate}&CustomTab=${object.customTab == undefined ? 0 : object.customTab}&levelId=${Studentdata.userLevelId}`, {
            method: 'GET',

        })
    }
    const data = await res.json();
    var tempSelectedBranch = [];
    data.payload.map((item) => tempSelectedBranch.push(item.id))
    localStorage.setItem("Branche_List_video_" + Studentdata?.id, JSON.stringify(tempSelectedBranch));

    return data.payload;
})

export const StudyChapterFilter = createAsyncThunk("study/studyChapter", async (object) => {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
     
    if (object.startDate == null) {

        var res = await fetch(API_BASE_URL + `api/Chapater/GetAllChaptersByBranchIds?SID=${Studentdata.id}&JWT=${Studentdata.jwt}&CustomTab=${object.customTab == undefined ? 0 : object.customTab}`, {
            method: 'post',
            headers: {
                Accept: "application/json",
                "content-type": "application/json"
            },
            body: JSON.stringify(object.object)

        })
    }
    else {
        var res = await fetch(API_BASE_URL + `api/Chapater/GetAllChaptersByBranchIds?SID=${Studentdata.id}&JWT=${Studentdata.jwt}&StartDate=${object.startDate == undefined ? null : object.startDate}&EndDate=${object.endDate == undefined ? null : object.endDate}&CustomTab=${object.customTab == undefined ? 0 : object.customTab}`, {
            method: 'post',
            headers: {
                Accept: "application/json",
                "content-type": "application/json"
            },
            body: JSON.stringify(object.object)

        })
    }


    const data = await res.json();
    var tempSelectedChapter = [];
    data.payload.map((item) => tempSelectedChapter.push(item.id))
    localStorage.setItem("Chapter_List_video_" + Studentdata?.id, JSON.stringify(tempSelectedChapter));
    return data.payload;

})


export const StudySubChapterFilter = createAsyncThunk("study/studySubChapter", async (object) => {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
     
    if (object.startDate == null) {
        var res = await fetch(API_BASE_URL + `api/Chapater/GetAllSubChaptersByChapterIds?SID=${Studentdata.id}&JWT=${Studentdata.jwt}&CustomTab=${object.customTab == undefined ? 0 : object.customTab}`, {
            method: 'post',
            headers: {
                Accept: "application/json",
                "content-type": "application/json"
            },
            body: JSON.stringify(object.object)
        })
    }
    else {
        var res = await fetch(API_BASE_URL + `api/Chapater/GetAllSubChaptersByChapterIds?SID=${Studentdata.id}&JWT=${Studentdata.jwt}&StartDate=${object.startDate == undefined ? null : object.startDate}&EndDate=${object.endDate == undefined ? null : object.endDate}&CustomTab=${object.customTab == undefined ? 0 : object.customTab}`, {
            method: 'post',
            headers: {
                Accept: "application/json",
                "content-type": "application/json"
            },
            body: JSON.stringify(object.object)

        })
    }


    const data = await res.json();
    var tempSelectedSubChapter = [];
    data.payload.map(item => tempSelectedSubChapter.push(item.id))
    localStorage.setItem("Subchapter_List_video_" + Studentdata?.id, JSON.stringify(tempSelectedSubChapter));
    return data.payload;

})


const studySlice = createSlice(
    {
        name: "video",
        initialState:
        {
            dates: {
                startDate: new Date(),
                endDate: new Date()
            },
            studyBrancheFilter: [],
            studyChapterFilter: [],
            studySubChapterFilter: [],
            video: [],
            loading: false,
            tabs: 0,
            isChecked: false,
            isBranchFilterChangedInCustomTab: false,
        },
        reducers: {
            updateVideo: (state, action) => {

                state.video = action.payload;
            },
            updateStudyDates: (state, action) => {

                state.dates = action.payload;
            },
            ChnageTabs: (state, action) => {

                state.tabs = action.payload;
            },
            CheckedBranch: (state, action) => {

                state.isChecked = action.payload;
                state.isBranchFilterChangedInCustomTab = action.payload;
                state.isBranchFirstLoad = action.payload;
                state.isChapterFirstLoad = action.payload;
                state.isSubChapterFirstLoad = action.payload;
            },
            BranchFilterChangedInCustomTab: (state, action) => {

                state.isBranchFilterChangedInCustomTab = action.payload;
            },
        },
        extraReducers: {
            [videoMaterial.pending]: (state, action) => {
                state.loading = true;
            },
            [videoMaterial.fulfilled]: (state, action) => {

                state.loading = false;
                state.video = action.payload
            },
            [StudyBranchesFilter.fulfilled]: (state, action) => {
                state.loading = false;
                state.studyBrancheFilter = action.payload
            },
            [StudyChapterFilter.fulfilled]: (state, action) => {
                state.studyChapterFilter = action.payload;
            },
            [StudySubChapterFilter.fulfilled]: (state, action) => {
                state.studySubChapterFilter = action.payload;
            }
        }

    })


export const { updateVideo, updateStudyDates, ChnageTabs, CheckedBranch, BranchFilterChangedInCustomTab } = studySlice.actions;

export default studySlice.reducer;