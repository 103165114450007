import React, { useState } from 'react'
import {
    Row, Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
} from "reactstrap"
import { IoIosAddCircle } from "react-icons/io"
import { AiFillEdit, AiOutlineDelete, AiOutlineDownload } from "react-icons/ai"
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css"
import AdminServices from '../../../../services/AdminServices';
import { FileUploader } from "react-drag-drop-files";
import Swal from "sweetalert2"
import { FILE_TEMPLATE_BASE_URL } from '../../../../../constant';
import { BiLoaderCircle } from "react-icons/bi"
const Cirriculm = (props) => {

    const [BranchModal, setBranchModal] = useState(false);
    const [ChapterModal, setChapterModal] = useState(false);
    const [subChapterModal, setSubChapterModal] = useState(false);
    const [loading, setLoading] = useState(false)
    const toggle = () => {
        setBranchModal(!BranchModal);
        setBranchData({})
        setShowBtn(false)
    }
    const Chaptertoggle = () => {
        if (branchId == "") {
            alert("Please Select a Chapter First")
        }
        else {
            setChapterModal(!ChapterModal)
            setChapterData({})
            setShowChapterBtn(false)
        }
    }
    const SubChaptertoggle = () => {
        if (ChapterId == "") {
            alert("Please Select a SubChapter First")
        }
        else {
            setSubChapterModal(!subChapterModal)
            setSubChapterData({})
            setShowSubChapterBtn(false)
        }
    }


    const [allBranch, setAllBranch] = useState([])
    const [levelId, setLevelId] = useState(0)
    const GetAllBranch = async (e) => {
        setLevelId(e.target.value)
        var response = await AdminServices.GetAllBranch(e.target.value)
         
        setAllBranch(response.data.payload)
    }
    const [allChapter, setAllChapter] = useState([])

    const GetAllChapter = async (branchId) => {
        var response = await AdminServices.GetAllChapter(branchId)
         
        setAllChapter(response.data.payload)
    }
    const [allSubChapter, setAllSubChapter] = useState([])
    const GetAllSubChapter = async (ChapterId) => {
        var response = await AdminServices.GetAllSubChapter(ChapterId)
         
        setAllSubChapter(response.data.payload)
    }

    const EnterBranch = (e) => {
        setBranchData({ ...BranchData, [e.target.name]: e.target.value })
    }
    const EnterSubChapter = (e) => {
        setSubChapterData({ ...subChapterData, [e.target.name]: e.target.value })
    }


    const AddSubChapter = async () => {
        const res = await AdminServices.AddSubChapter(subChapterData.title, ChapterId)
         
        if (res.data.status == true) {
            setSubChapterModal(!subChapterModal);
            setAllSubChapter([...allSubChapter, {
                title: subChapterData.title
            }]);
        }
    }


    const EnterChapter = (e) => {
        setChapterData({ ...ChapterData, [e.target.name]: e.target.value })
    }
    const AddChapter = async () => {
        const res = await AdminServices.AddChapter(ChapterData.title, branchId)
        if (res.data.status == true) {
            setChapterModal(!ChapterModal);
            setAllChapter([...allChapter, {
                title: ChapterData.title
            }]);
        }
    }

    const AddBranch = async () => {
        const res = await AdminServices.AddBranch(BranchData.title, levelId)
        if (res.data.status == true) {
            setBranchModal(!BranchModal);
            setAllBranch([...allBranch, {
                title: BranchData.title
            }]);
        }
    }


    const [BranchData, setBranchData] = useState({
        title: "",
        parentId: ""
    })

    const [ChapterData, setChapterData] = useState({
        title: "",
        parentId: ""
    })

    const [showBtn, setShowBtn] = useState(false)
    const EditBranchBtn = (id, title) => {
         
        setBranchModal(!BranchModal)
        setBranchData({
            title: title,
            parentId: id
        })
        setShowBtn(true)
    }

    const [showChapterBtn, setShowChapterBtn] = useState(false)
    const EditChapterhBtn = (id, title) => {
         
        setChapterModal(!ChapterModal)
        setChapterData({
            title: title,
            parentId: id
        })
        setShowChapterBtn(true)
    }

    const [showSubChapterBtn, setShowSubChapterBtn] = useState(false)

    const [subChapterData, setSubChapterData] = useState({
        title: "",
        parentId: ""
    })
    const EditSubChapterBtn = (id, title) => {
         
        setSubChapterModal(!subChapterModal)
        setSubChapterData({
            title: title,
            parentId: id
        })
        setShowSubChapterBtn(true)
    }


    const UpdateBranch = async (id) => {
         
        const res = await AdminServices.EditBranch(BranchData)
         
        if (res.data.status == true) {
            setBranchModal(!BranchModal);
        }
    }

    const UpdateChapter = async (id) => {
         
        const res = await AdminServices.EditChapter(ChapterData)
         
        if (res.data.status == true) {
            setChapterModal(!ChapterModal);
        }
    }



    const UpdateSubChapter = async (id) => {
         
        const res = await AdminServices.EditSubChapter(subChapterData)
         
        if (res.data.status == true) {
            setSubChapterModal(!subChapterModal);
        }
    }

    const handleDelete = async (id) => {
         
        const res = await AdminServices.DeleteBranch(id)
        if (res.data.status == true) {
            const filteredArray = allBranch.filter(item => item.id !== id);
            setAllBranch(filteredArray)
        }
    }
    const handleChapterDelete = async (id) => {
         
        const res = await AdminServices.DeleteChapter(id)
        if (res.data.status == true) {
            const filteredArray = allChapter.filter(item => item.id !== id);
            setAllChapter(filteredArray)
        }
    }
    const handleSubChapterDelete = async (id) => {
         
        const res = await AdminServices.DeleteSubChapter(id)
        if (res.data.status == true) {
            const filteredArray = allSubChapter.filter(item => item.id !== id);
            setAllSubChapter(filteredArray)
        }
    }


    const [branchId, setBranchId] = useState("")
    const radioBtn = (e) => {
         
        setBranchId(e.target.value)
        GetAllChapter(e.target.value)
    }

    const [ChapterId, setChapterId] = useState("")
    const ChapterRadioBtn = (e) => {
         
        setChapterId(e.target.value)
        GetAllSubChapter(e.target.value)
    }
    const fileTypes = ["xlsx", "xls", "csv"];

    const uploadCurriculumHierarchyFile = async (e) => {
        if (props.universityId == "" || props.universityId == null) {
            Swal.fire("Error!", "Please Select Univeristy First", "danger");
        }
        else {
            setLoading(true)
            var response = await AdminServices.AddCurriculumHierarchyFile(e[0], props.universityId);
            if (response.data.status === true) {
                 
                Swal.fire(" Uploaded!", "" + "File Uploaded Successfull", "success");
                setLoading(false)
            } else {
                Swal.fire("Error!", response.data.message, "danger");
                setLoading(false)
            }
        }

    }
    return (
        <>
            <Modal isOpen={BranchModal} toggle={toggle} >
                <ModalHeader toggle={toggle}>{showBtn == false ? "Add" : "Update"} Branche</ModalHeader>
                <ModalBody>
                    {/* <select className='form-control chapterApply'>
        <option>-- Select Branch --</option>
        {branch?.map((value) => (
            <option value={value.id}>{value.title}</option>
        ))}
    </select> */}
                    <input className='form-control chapterApply' name='title' value={BranchData.title} placeholder='Enter Branch Title' onChange={EnterBranch} />
                </ModalBody>
                <ModalFooter>
                    {showBtn == false ?
                        <Button color="primary" onClick={AddBranch}>
                            Addd
                        </Button>

                        : <Button color="primary" onClick={UpdateBranch}>
                            Update
                        </Button>
                    }
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>


            <Modal isOpen={ChapterModal} toggle={Chaptertoggle} >
                <ModalHeader toggle={Chaptertoggle}>  {showChapterBtn == false ? "Add" : "Update"} Chapter</ModalHeader>
                <ModalBody>

                    <input className='form-control chapterApply' name='title' value={ChapterData.title} placeholder='Enter Chapter Title' onChange={EnterChapter} />
                </ModalBody>
                <ModalFooter>
                    {showChapterBtn == false ?
                        <Button color="primary" onClick={AddChapter}>
                            Addd
                        </Button> :
                        <Button color="primary" onClick={UpdateChapter}>
                            Update
                        </Button>
                    }

                    <Button color="secondary" onClick={Chaptertoggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={subChapterModal} toggle={SubChaptertoggle} >
                <ModalHeader toggle={SubChaptertoggle}> {showSubChapterBtn == false ? "Add" : "Update"} SubChapter</ModalHeader>
                <ModalBody>

                    <input className='form-control chapterApply' name='title' value={subChapterData.title} placeholder='Enter SubChapter Title' onChange={EnterSubChapter} />
                </ModalBody>
                <ModalFooter>
                    {showSubChapterBtn == false ? <Button color="primary" onClick={AddSubChapter}>
                        Addd
                    </Button> :
                        <Button color="primary" onClick={UpdateSubChapter}>
                            Update
                        </Button>
                    }
                    <Button color="secondary" onClick={SubChaptertoggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>


            <Row>
                <Col md="12">
                    <select className='form-control chapterApply' onChange={GetAllBranch} >
                        <option>-- Select Year --</option>
                        {props.GetLevelByUniId?.map((value) => (
                            <option value={value.id}>{value.title}</option>
                        ))}
                    </select>
                </Col>

            </Row>

            {loading ? <div className="spinner"></div> :
                <Row>
                    <Col md="4" className='mt-4'>
                        <a href={FILE_TEMPLATE_BASE_URL + "curriculum_by_university_admin_template.xlsx"} className='format-download mt-5' download> <AiOutlineDownload fontSize="1.5rem" />Smaple</a>
                    </Col>
                    <Col md="8">
                        <FileUploader
                            multiple={true}
                            handleChange={uploadCurriculumHierarchyFile}
                            name="file"
                            types={fileTypes}
                        />

                    </Col>
                </Row>}

            <Row>
                <Col md="4">
                    <IoIosAddCircle
                        className='mt-3'
                        cursor="pointer"
                        fontSize="2em"
                        onClick={toggle} />
                    <ReactTable
                        style={{ fontSize: "12px", marginTop: "20px" }}
                        columns={[

                            {
                                Header: "Branches",
                                accessor: "title",
                                Cell: (row) => (
                                    <div>
                                        <input type="radio"
                                            onChange={radioBtn}
                                            value={allBranch[row.index].id}
                                            id={"op" + allBranch[row.index].id}
                                            name="radio-group"
                                        // data-questionId={allBranch[row.index].id}
                                        />
                                        <label htmlFor={"op" + allBranch[row.index].id} >{row.value}</label>
                                    </div>
                                )
                            },

                            {
                                Header: "Action",
                                accessor: "#",
                                Cell: (row) => (
                                    <div className="text-center">

                                        <AiFillEdit
                                            color="green"
                                            title="Edit"
                                            cursor="pointer"
                                            fontSize="1.50em"
                                            onClick={() =>
                                                EditBranchBtn(allBranch[row.index].id, allBranch[row.index].title)
                                                // setBranchModal(!BranchModal)
                                            }
                                        />

                                        &nbsp;
                                        {
                                            <AiOutlineDelete
                                                color="#DB1212"
                                                title="Delete"
                                                cursor="pointer"
                                                fontSize="1.50em"
                                                onClick={() => {
                                                    handleDelete(allBranch[row.index].id);
                                                }}
                                            />
                                        }
                                    </div>
                                ),

                                sortable: false,
                                filterable: false,

                            },



                        ]}
                        defaultPageSize={5}
                        className="-striped -highlight"
                        data={allBranch}
                    // filterable
                    // defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}

                    />

                </Col>
                <Col md="4">
                    <IoIosAddCircle
                        className='mt-3'
                        cursor="pointer"
                        fontSize="2em"
                        onClick={Chaptertoggle}
                    />

                    <ReactTable
                        style={{ fontSize: "12px", marginTop: "20px" }}
                        columns={[

                            {
                                Header: "Chapter",
                                accessor: "title",
                                Cell: (row) => (
                                    <div>
                                        <input type="radio"
                                            onChange={ChapterRadioBtn}
                                            value={allChapter[row.index].id}
                                            id={"op" + allChapter[row.index].id}
                                            name="radio-group"
                                        // data-questionId={allChapter[row.index].id}
                                        />
                                        <label htmlFor={"op" + allChapter[row.index].id} >{row.value}</label>
                                    </div>
                                )
                            },
                            {
                                Header: "Action",
                                accessor: "#",
                                Cell: (row) => (
                                    <div className="text-center">

                                        <AiFillEdit
                                            color="green"
                                            title="Edit"
                                            cursor="pointer"
                                            fontSize="1.50em"
                                            onClick={() =>
                                                EditChapterhBtn(allChapter[row.index].id, allChapter[row.index].title)
                                            }
                                        />

                                        &nbsp;
                                        {
                                            <AiOutlineDelete
                                                color="#DB1212"
                                                title="Delete"
                                                cursor="pointer"
                                                fontSize="1.50em"
                                                onClick={() => {
                                                    handleChapterDelete(allChapter[row.index].id);
                                                }}
                                            />
                                        }
                                    </div>
                                ),

                                sortable: false,
                                filterable: false,

                            },


                        ]}
                        defaultPageSize={5}
                        className="-striped -highlight"
                        data={allChapter}
                    // filterable
                    // defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}

                    />

                </Col>
                <Col md="4">
                    <IoIosAddCircle
                        className='mt-3'
                        cursor="pointer"
                        onClick={SubChaptertoggle}
                        fontSize="2em"
                    />

                    <ReactTable
                        style={{ fontSize: "12px", marginTop: "20px" }}
                        columns={[

                            {
                                Header: "SubChapter",
                                accessor: "title",
                            },
                            {
                                Header: "Action",
                                accessor: "#",
                                Cell: (row) => (
                                    <div className="text-center">

                                        <AiFillEdit
                                            color="green"
                                            title="Edit"
                                            cursor="pointer"
                                            fontSize="1.50em"
                                            onClick={() =>
                                                EditSubChapterBtn(allSubChapter[row.index].id, allSubChapter[row.index].title)
                                                // setBranchModal(!BranchModal)
                                            }
                                        />

                                        &nbsp;
                                        {
                                            <AiOutlineDelete
                                                color="#DB1212"
                                                title="Delete"
                                                cursor="pointer"
                                                fontSize="1.50em"
                                                onClick={() => {
                                                    handleSubChapterDelete(allSubChapter[row.index].id);
                                                }}
                                            />
                                        }
                                    </div>
                                ),
                            },

                        ]}
                        defaultPageSize={5}
                        className="-striped -highlight"
                        data={allSubChapter}
                    // filterable
                    // defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)}
                    />

                </Col>
            </Row>
        </>
    )
}

export default Cirriculm