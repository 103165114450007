import React, { useEffect, useState } from "react"
import { QuizFilter } from "../QuizFilters"
import {
    Row,
    Col,
} from "reactstrap";
import { BsFillTrophyFill, BsBookmarkFill } from "react-icons/bs";
import first from "../../../assets/images/1st.png"
import second from "../../../assets/images/2nd.png"
import third from "../../../assets/images/3rd.png"
import noImg from "../../../assets/images/NO IMAGEAVAILABLE.png"
import Chart from "react-apexcharts"
import Header from "../../../components/Header";
import ReactECharts from 'echarts-for-react';
import AllFilter from "../QuizResult/ResultFilter/AllFilter"
import { useDispatch, useSelector } from "react-redux";
import { topUserList, radarData, updateDates } from "../QuizResult/store/features/TopUserSlice"
import { API_BASE_URL } from "../../../constant";
import moment from "moment"
import { BranchesFilter, ChapterFilter, SubChapterFilter } from "../../../store/features/QuizSlice"
import Switch from "react-switch";

const TopUser = () => {
    const dispatch = useDispatch();
    var topUser = useSelector(state => state.topUser.topUser)
    var GetradarData = useSelector(state => state.topUser.radarData)
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    var data = JSON.parse(localStorageObject);

    console.log(topUser)

    useEffect(() => {
        dispatch(topUserList({
            startDate: new Date(),
            endDate: new Date(),
            branchId: [],
            chapterId: [],
            subChapterId: [],
         countryId: [data.countryId],
            universityId: [data.universityId],
            levelId: [data.userLevelId]
        }))

        dispatch(radarData({
            startDate: new Date(),
            endDate: new Date(),
            branchId: [],
            chapterId: [],
            subChapterId: [],
            countryId: [data.countryId],
            universityId: [data.universityId],
            levelId: [data.userLevelId]
        }))

        dispatch(updateDates({
            startDate: new Date(),
            endDate: new Date(),
        }))
    }, [])
    const Today = () => {
        dispatch(topUserList({
            startDate: new Date(),
            endDate: new Date(),
            branchId: [],
            chapterId: [],
            subChapterId: [],
            countryId: [data.countryId],
            universityId: [data.universityId],
            levelId: [data.userLevelId]
        }))
        dispatch(radarData({
            startDate: new Date(),
            endDate: new Date(),
            branchId: [],
            chapterId: [],
            subChapterId: [],
         countryId: [data.countryId],
            universityId: [data.universityId],
            levelId: [data.userLevelId]
        }))

        dispatch(updateDates({
            startDate: new Date(),
            endDate: new Date(),
        }))

        dispatch(BranchesFilter({
            startDate: (moment(new Date()).format('YYYY-MM-DD')),
            endDate: (moment(new Date()).format('YYYY-MM-DD')),
        }))

        var element = document.getElementById("today");
        element.classList.remove("today");
        element.classList.add("Week");

        var element = document.getElementById("week");
        element.classList.remove("Week");
        element.classList.add("today");
        var element = document.getElementById("year");
        element.classList.remove("Week");
        element.classList.add("today");

    }
    const Week = () => {

        const now = new Date();
        const lastweek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
        dispatch(updateDates({
            startDate: lastweek,
            endDate: now
        }))
        dispatch(topUserList({
            startDate: lastweek,
            endDate: new Date(),
            branchId: [],
            chapterId: [],
            subChapterId: [],
         countryId: [data.countryId],
            universityId: [data.universityId],
            levelId: [data.userLevelId]
        }))

        dispatch(radarData({
            startDate: lastweek,
            endDate: new Date(),
            branchId: [],
            chapterId: [],
            subChapterId: [],
         countryId: [data.countryId],
            universityId: [data.universityId],
            levelId: [data.userLevelId]
        }))

        dispatch(BranchesFilter({
            startDate: (moment(lastweek).format('YYYY-MM-DD')),
            endDate: (moment(now).format('YYYY-MM-DD')),
        }))
        var element = document.getElementById("today");
        element.classList.remove("Week");
        element.classList.add("today");
        var element = document.getElementById("week");
        element.classList.remove("today");
        element.classList.add("Week");
        var element = document.getElementById("year");
        element.classList.remove("Week");
        element.classList.add("today");
    }

    const Year = () => {
        const now = new Date();
        const lastyear = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());

        dispatch(updateDates({
            startDate: lastyear,
            endDate: now
        }))

        dispatch(BranchesFilter({
            startDate: (moment(lastyear).format('YYYY-MM-DD')),
            endDate: (moment(now).format('YYYY-MM-DD')),
        }))

        dispatch(ChapterFilter({
            startDate: (moment(lastyear).format('YYYY-MM-DD')),
            endDate: (moment(now).format('YYYY-MM-DD')),
            object: []
        }))

        dispatch(SubChapterFilter({
            startDate: (moment(lastyear).format('YYYY-MM-DD')),
            endDate: (moment(now).format('YYYY-MM-DD')),
            object: []
        }))

        dispatch(topUserList({
            startDate: lastyear,
            endDate: new Date(),
            branchId: [],
            chapterId: [],
            subChapterId: [],
         countryId: [data.countryId],
            universityId: [data.universityId],
            levelId: [data.userLevelId]
        }))
        dispatch(radarData({
            startDate: lastyear,
            endDate: new Date(),
            branchId: [],
            chapterId: [],
            subChapterId: [],
         countryId: [data.countryId],
            universityId: [data.universityId],
            levelId: [data.userLevelId]
        }))

        var element = document.getElementById("year");
        element.classList.remove("today");
        element.classList.add("Week");
        var element = document.getElementById("week");
        element.classList.remove("Week");
        element.classList.add("today");

        var element = document.getElementById("today");
        element.classList.remove("Week");
        element.classList.add("today");
    }
    const [premium, setPremium] = useState(false);

    const handlePremium = nextChecked => {
        setPremium(nextChecked);

    };
    const option = {

        tooltip: {},
        legend: {
            data: ["Total Points", "Avarage Point",]
        },
        radar: {
            // shape: 'circle',
            indicator: GetradarData.chapters
        },
        series: [{
            name: 'Budget vs spending',
            type: 'radar',
            // areaStyle: {normal: {}},
            data: [
                {
                    value: GetradarData.points,
                    name: 'Total Points'
                },
                {
                    value: GetradarData.avgPoints,
                    name: 'Avarage Point'
                }
            ]
        }]
    };

    const option2 = {

        tooltip: {},
        legend: {
            data: ["Total Percentage", "Average Percentage",],
            orient: "vertical",
            x: "left",
            top: "50",
            textStyle: {
                color: 'white'
            }
        },

        radar: {
            // shape: 'circle',
            indicator: GetradarData.chapters
        },
        series: [{
            name: 'Budget vs spending',
            type: 'radar',
            // areaStyle: {normal: {}},
            data: [
                {
                    value: GetradarData.percentage,
                    name: 'Total Percentage'
                },
                {
                    value: GetradarData.avgPercentage,
                    name: 'Average Percentage'
                }
            ]
        }]
    };


    let timer;
    const loadingOption = {
        text: 'Loading...',
        color: '#4413c2',
        textColor: '#270240',
        maskColor: 'rgba(194, 88, 86, 0.3)',
        zlevel: 0
    };

    function onChartReady(echarts) {
        timer = setTimeout(function () {
            echarts.hideLoading();
        }, 1000);
    }

    const users = [
        { id: 4, name: 'Sherlock', points: 3490, isCurrentUser: false },
        { id: 5, name: 'Sherlock', points: 3490, isCurrentUser: false },
        { id: 6, name: 'Byrom', points: 3490, isCurrentUser: true },
        { id: 7, name: 'Sherlock', points: 3490, isCurrentUser: false },
        { id: 8, name: 'Sherlock', points: 3490, isCurrentUser: false },
        { id: 9, name: 'Sherlock', points: 3490, isCurrentUser: false },
        { id: 10, name: 'Sherlock', points: 3490, isCurrentUser: false },
    ];
    return (
        <>
            <Header />
            <div className="quiz-result">
                <div className="row" style={{ minHeight: "100vh" }}>
                    <AllFilter />
                    <div className="col-8">
                        <Row>
                            <Col md="11">
                                <div className="serch-input icon-input mb-3 d-flex justify-content-end mt-2">
                                    <i className="font-sm ti-search pr-0 mt-2" style={{ color: "#E84545" }}></i>
                                    <input
                                        type="text"
                                        className='form-control-search mt-3'
                                        placeholder='Search'
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div className="top-btn">
                            <button className="Week" id="today" onClick={Today}>Today</button>
                            <button className="today" id="week" onClick={Week}>This Week</button>
                            <button className="today" id="year" onClick={Year}>This Year</button>
                            <h1 >Top Gainers</h1>
                            {topUser.length == 0 ?
                                <h1 style={{ margin: "40px 40px 40px 300px", color: "#E84545" }} >No User Found !</h1>
                                :
                                <Row>
                               

                                    <Col md="4" className="top-use-img">
                                        <img alt="" src={second} width="200px" />
                                        <img src={API_BASE_URL + topUser[1]?.imageUrl} alt="lady" className="lady-image-second" onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src =
                                                "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png";
                                        }} />

                                    </Col>
                                    <Col md="4">
                                        <img alt="" src={first} width="236px" />
                                        <img src={API_BASE_URL + topUser[0]?.imageUrl} alt="lady" className="lady-image-first" onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src =
                                                "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png";
                                        }} />
                                    </Col>
                                    <Col md="4">
                                        <img alt="" src={third} width="200px" />
                                        <img src={API_BASE_URL + topUser[2]?.imageUrl} alt="lady" className="lady-image-third" onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src =
                                                "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png";
                                        }} />
                                    </Col>
                                </Row>
                            }
                        </div>
                        <Row className="mt-5">
                            <Col md="6">
                                <h1>All Users</h1>
                            </Col>
                            <Col md="4"></Col>
                            <Col md="2" className="d-flex justify-content-end">
                                <h1 style={{ color: "#E84545" }}>See All</h1>
                            </Col>
                        </Row>

                        <div className="user-list">
                            <div className="user-list-header">
                                <span>No</span>
                                <span>User</span>
                                <span>Best</span>
                                <span>Total Points</span>
                            </div>
                            {topUser.slice(3).map((user, index) => (
                                <div
                                    key={user.id}
                                    className={`user-list-item ${user.isCurrentUser ? 'current-user' : ''}`}
                                >
                                    <span>{user.id}</span>
                                    <span className="user-info">
                                        <img src={API_BASE_URL + user.imageUrl} alt={`${user.name}'s avatar`} className="user-avatar" />
                                        {user.username}
                                    </span>

                                    <span>🏆 {user.best}</span>
                                    <span><BsBookmarkFill fontSize="1.2rem" color="#B1F626" className="mr-1" /> {user.totalPoints}</span>
                                </div>
                            ))}
                        </div>  
                        {GetradarData?.chapters.length == 0 ? "" : <div className="mt-5">
                            <h1 className="mt-5">Students Radar Charts</h1>

                            <div className=" w-100 p-3 border-0 mt-4 rounded-10  shadow-xs overflow-hidden">
                                <div className="mb-4" style={{ marginLeft: "280px" }}>
                                    <label style={{ color: "white" }}>Total Percentage</label>
                                    <Switch
                                        onChange={handlePremium}
                                        checked={premium}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        onColor="#E84545"
                                        height={15}
                                        width={35}
                                        className="ml-2 mr-2"
                                    />
                                    <label style={{ color: "white" }}>Total Points</label>
                                </div>

                                <ReactECharts
                                    option={premium == true ? option : option2}
                                    style={{ height: 400 }}
                                    // onChartReady={onChartReady}
                                    loadingOption={loadingOption}
                                // showLoading={true}
                                />
                            </div>
                        </div>}

                    </div>
                </div>
                {/* <div style={{height:"100px"}}></div> */}

            </div>

        </>
    )
}

export default TopUser