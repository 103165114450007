import React, { useState, useEffect, useRef } from 'react'
import sendIcon from "../../view/assests/iCON/iCON/noun-send-4514372.png"
import smiley from "../../view/assests/iCON/iCON/noun-smiley-2561875.png"
import {
    addDoc, collection, serverTimestamp, query,
    orderBy,
    onSnapshot,
    where,
    limit,
    getDocs,
    or,
    Timestamp
} from "firebase/firestore";
import { database } from '../../components/firebase';
import moment from "moment"
import { API_BASE_URL } from '../../constant';
import EmojiPicker from 'emoji-picker-react';
import OtherUserProfileServices from '../services/OtherUserProfileServices';


const Inbox = (props) => {
    const messagesEndRef = useRef(null)
    const [input, setinput] = useState("");
    const [getMessages, setGetMessage] = useState([]);
    const [selectedEmoji, setSelectedEmoji] = useState("");

    const onEmojiClick = (event, emojiObject) => {
        setSelectedEmoji(event.emoji);
        var tempEmoji = [...input, event.emoji];
        setinput(tempEmoji.join(""))
    };

    const scrollToBottom = () => {
         
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }
    scrollToBottom()

    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let data = JSON.parse(localStorageObject);

    useEffect(async () => {
        getById()
         
        const q = query(
            collection(database, "msg"),
            orderBy("createdAt"),
            limit(50),
            or((where('receiverId', '==', props.receiverId), where('senderId', '==', data.id)), where('receiverId', '==', data.id), where('senderId', '==', props.receiverId))
        );
         
        const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
            let messages = [];
            QuerySnapshot.forEach((doc) => {
                messages.push({ ...doc.data(), id: doc.id });
            });
            setGetMessage(messages);
            console.log("new msgs => ", messages)
        });
    }, []);
    const [getData, setGetData] = useState("")
    async function getById() {
        let response = await OtherUserProfileServices.getStudentById(props.receiverId, data.id, true);
        setGetData(response.data.payload)
    }

    const sendMessage = async (event) => {
        setinput("");
        scrollToBottom()
        event.preventDefault();
         
        if (input.trim() === "") {
            alert("Enter valid message");
            return;
        }
        const { id } = data;
        try {
             
            await addDoc(collection(database, "msg"), {
                text: input,
                receiverName: getData.firstName + " " + getData.lastName,
                senderName: data.firstName + " " + data.lastName,
                senderImage: getData.imagePath,
                receiverImage: data.imagePath,
                mediaType: "none",
                createdAt: serverTimestamp(),
                senderId: id,
                receiverId: parseInt(props.receiverId),
                chatKey: Math.min(id, parseInt(props.receiverId)) + "-" + Math.max(id, parseInt(props.receiverId))
            });
            setSelectedEmoji(false)
        }
        catch (e) {
            console.log(e)
        }

    };
    const handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
            sendMessage(event)
        }
    };


    return (
        <>
            <div className="col-lg-6 col-xl-8 col-md-6 pl-0 pr-0 d-none d-lg-block d-md-block rounded-10 " style={{ backgroundColor: "#181818" }}>
                <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar theme-dark-bg" style={{ backgroundColor: "#181818" }}>
                    <div className="chat-body pl-4">
                        <div className="messages-content" style={{ fontSize: "15px" }} >
                            {getMessages.map((value, index) => value.senderId == data.id ?
                                (
                                    <div className="message-item outgoing-message" key={index}>
                                        <div className="message-user">
                                            <figure className="avatar">
                                                 <img alt=""
                                                    src={API_BASE_URL + data.imagePath}
                                                    alt="avater"
                                                />
                                            </figure>
                                            <div>
                                                <h5 className='text-white'>{data.firstName}</h5>
                                                {/* <div className="time"> {moment(value.createdAt.nanoseconds).format('LT')}</div> */}

                                            </div>
                                        </div>
                                        <div className="message-wrap" style={{ backgroundColor: "#E84545" }}>
                                            {value.text}
                                        </div>
                                    </div>
                                )
                                :
                                <div className="message-item">
                                    <div className="message-user">
                                        <figure className="avatar">
                                             <img alt=""
                                                src={API_BASE_URL + getData?.imagePath}
                                                alt="avater"
                                            />
                                        </figure>
                                        <div>
                                            <h5 className='text-white'>{getData.firstName}</h5>
                                            {/* <div className="time"> {moment(value.createdAt.nanoseconds).format('LT')}</div> */}
                                        </div>
                                    </div>
                                    <div className="message-wrap">
                                        {value.text}
                                    </div>
                                </div>
                            )}
                            <div ref={messagesEndRef} />

                        </div>
                    </div>
                </div>
                <div
                    className="chat-bottom dark-bg  shadow-none theme-dark-bg"
                    style={{ width: '98%', backgroundColor: "transparent" }}
                >
                    <div className="chat-form" style={{ marginLeft: "20px" }}>

                        {selectedEmoji ? (
                            <EmojiPicker
                                onEmojiClick={onEmojiClick}
                            />
                        ) : null}
                        {/* <div className="float-left">
                             <img alt="" src={smiley} style={{ width: "30px", margin: "4px" }} onClick={() => setSelectedEmoji(!selectedEmoji)} />
                        </div> */}
                        <div className="d-flex justify-content-end">
                             <img alt="" src={smiley} style={{ width: "30px", margin: "4px" }} onClick={() => setSelectedEmoji(!selectedEmoji)} />

                            <input type="text"
                                name="input "
                                value={input}
                                onChange={(e) => setinput(e.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="Start typing.." />

                            <button className="sendBtn" style={{ width: "50px", marginLeft: "7px" }} onClick={sendMessage}>
                                 <img alt="" src={sendIcon} width="30px" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Inbox