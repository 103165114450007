import React, { useState, useEffect } from 'react'
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md"
import world from "../../../../assets/iCON/noun-country-2450449.png"
import { SessionLogoutMsg } from '../../../../constant'
import AllFilterServices from '../../../services/AllFilterServices'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { radarData, topUserList } from "../../QuizResult/store/features/TopUserSlice"
const CountryFilter = () => {

    const dispatch=useDispatch()
    const [selectedCountry, setSelectedCountry] = useState([])
    var SelectedFilterData = {
        countryId: selectedCountry,
        startDate: null,
        endDate: null,
        branchId: [],
        chapterId: [],
        subChapterId: [],
        universityId: [],
        levelId: []
    }

    const [country, setCountry] = useState(false);
    let history = useHistory()
    const [countryList, setCountryList] = useState([]);
    useEffect(async () => {
        var response = await AllFilterServices.getAllCountries()
        if (response.data.message == SessionLogoutMsg) {
            history.push("/login")
            localStorage.removeItem('Exe-Med_Student_Info');
        }
        else {
            setCountryList(response.data.payload);
        }
    }, [])
    const [name, setName] = useState('');
    const [foundUsers, setFoundUsers] = useState(countryList);
    const handleCountrySearch = (e) => {
        const keyword = e.target.value;

        if (keyword !== '') {
            const results = countryList.filter((user) => {
                return user.name.toLowerCase().startsWith(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setFoundUsers(results);
        } else {
            setFoundUsers(countryList);
            // If the text field is empty, show all users
        }
        setName(keyword);
    }
    const handleSelectAllCountry = (e) => {
         
        if (e.target.checked == true) {
            var tempSelectedCountry = countryList.map(li => li.id);
            setSelectedCountry(tempSelectedCountry)
            SelectedFilterData.countryId = tempSelectedCountry;
            dispatch(topUserList(SelectedFilterData))
            dispatch(radarData(SelectedFilterData))
            //   localStorage.setItem("Country_List", JSON.stringify(tempSelectedCountry));

        }
        else {
            setSelectedCountry([])
            //   localStorage.removeItem("Country_List", JSON.stringify(tempSelectedCountry));
            SelectedFilterData.countryId = [];
            dispatch(topUserList(SelectedFilterData))
            dispatch(radarData(SelectedFilterData))
        }
    }
    const CountryFilterChange = (e) => {
        var Id = e.target.getAttribute('data-questionId')
        if (e.target.checked == false) {
            var tempSelectedCountry = selectedCountry.filter(function (obj) {
                return obj != Id;
            });
            setSelectedCountry(tempSelectedCountry)
            SelectedFilterData.countryId = tempSelectedCountry;
            //   localStorage.setItem("Country_List", JSON.stringify(tempSelectedCountry));
            dispatch(topUserList(SelectedFilterData))
            dispatch(radarData(SelectedFilterData))
        }
        else {
            var tempSelectedCountry = selectedCountry;
            tempSelectedCountry.push(parseInt(Id))
            setSelectedCountry(tempSelectedCountry)
            //   localStorage.setItem("Country_List", JSON.stringify(tempSelectedCountry));
             
            SelectedFilterData.countryId = tempSelectedCountry;
            dispatch(topUserList(SelectedFilterData))
            dispatch(radarData(SelectedFilterData))
        }
        e.target.parentNode.childNodes.forEach(function (element) {
            if (e.target.checked == true) {
                element.classList.add("change-color")
                element.classList.add("checkbox-color")
            }
            else {
                element.classList.remove("change-color")
            }
        });
    }
    return (
        <div> <div className="form-group mb-1 filter-margin" onClick={() => setCountry(!country)}>
            {country ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                 <img alt="" src={world} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Country</h3>
                {country ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
            </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                 <img alt="" src={world} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Country</h3>
                {country ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
            </a>}
        </div>

            {country && <div className="form-group mb-1 sub-menu-filter mb-3 mr-3 w-100 filter-scrollbar scrollbar-primary">
                <input
                    placeholder='Search Country'
                    className='form-control'
                    value={name}
                    onChange={handleCountrySearch}
                    type="search" />

                <div>
                    <label className='ml-2 CursorPointer'>All</label>
                    <input
                        type="checkbox"
                        name="selectAllCountry"
                        id="selectAllCountry"
                        onChange={handleSelectAllCountry}
                        className="float-right checkbox"
                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                    />
                </div>



                {foundUsers && foundUsers.length > 0 ?
                    foundUsers.map((value, index) => {
                        return (
                            <div key={index}>
                                <label className='ml-2'>{value.name}</label>
                                <input
                                    type="checkbox"
                                    name="countryId"
                                    data-questionId={value.id}
                                    CountryFilterData
                                    checked={selectedCountry.includes(value.id)}
                                    onChange={CountryFilterChange}
                                    className="float-right checkbox"
                                    style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                                />
                            </div>
                        )
                    }) : countryList.map((value, index) => {
                        return (
                            <div key={index}>
                                <label className='ml-2'>{value.name}</label>
                                <input
                                    type="checkbox"
                                    name="countryId"
                                    data-questionId={value.id}
                                    checked={selectedCountry.includes(value.id)}
                                    onChange={CountryFilterChange}
                                    className="float-right checkbox"
                                    style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                                />
                            </div>
                        )
                    })
                }

            </div>}</div>
    )
}

export default CountryFilter