import React, { Component, Fragment } from 'react';

class Adminproductadd extends Component {
  render() {
    return (
      <>

        <div id="content-wrapper" className="d-flex flex-column" style={{ paddingLeft: "0px" }}>
          <div id="content">
            <div className="container px-3 py-4">
              <div className="row">
                <div className="col-lg-12 ">
                  <div className="card border-0 px-4 pt-4 mt-2 rounded-lg admin-form">
                    <div className="card-body d-block">
                      <h4 className="font-xss text-grey-800 mb-4 mt-0 fw-700">
                        Upload video
                      </h4>
                      <input type="file" />

                    </div>
                  </div>
                  <div className="card border-0 px-4 pt-4 mt-2 rounded-lg admin-form">
                    <div className="card-body d-block">
                      <form
                        className="contact_form"
                        name="contact_form"
                        action="#"
                        method="post"
                        noValidate=""
                      >
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label
                                htmlFor="product_sku"
                                className="form-label"
                              >
                                Description
                              </label>
                              <textarea
                                name="form_message"
                                className="form-control h150"
                                rows="6"
                                placeholder="Post Description *"
                              ></textarea>
                            </div>
                          </div>


                        </div>

                      </form>
                    </div>
                  </div>
                  <button className='btn btn-primary mb-5 d-flex justify-content-center add-post'>ADD POST</button>

                </div>
              </div>

            </div>

          </div>
        </div>
      </>
    );
  }
}

export default Adminproductadd;
