import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../../../constant";


export const GetContentList = createAsyncThunk("admin/contentCreator", async (object) => {
     
    var res = await fetch(API_BASE_URL + "api/Admin/GetAllMyContentCreations", {
        method: 'POST',
        headers: {
            Accept: "application/json",
            "content-type": "application/json"
        },
        body: JSON.stringify(object)
    });
    var data = await res.json();
    if (data.status) {
        return (
            data.payload
        )
    }
    else {
        return [];
    }
})


export const GetChapterAdmin = createAsyncThunk("admin/chapter", async (object) => {
     
    var res = await fetch(API_BASE_URL + "api/Admin/GetAllChapterAdmins", {
        method: 'POST',
        headers: {
            Accept: "application/json",
            "content-type": "application/json"
        },
        body: JSON.stringify(object)
    });
    var data = await res.json();
    if (data.status) {
        return (
            data.payload
        )
    }
    else {
        return [];
    }
})


export const GetStudentCohortAdmin = createAsyncThunk("cohort/student", async (object) => {
     
    var res = await fetch(API_BASE_URL + "api/Admin/GetStudentsCohortAdmins", {
        method: 'POST',
        headers: {
            Accept: "application/json",
            "content-type": "application/json"
        },
        body: JSON.stringify(object)
    });
    var data = await res.json();
    if (data.status) {
        return (
            data.payload
        )
    }
    else {
        return [];
    }

})


export const GetChapterAdminsCohortAdmins = createAsyncThunk("cohort/chapterAdmin", async (object) => {
     
    var res = await fetch(API_BASE_URL + "api/Admin/GetChapterAdminsCohortAdmins", {
        method: 'POST',
        headers: {
            Accept: "application/json",
            "content-type": "application/json"
        },
        body: JSON.stringify(object)
    });
    var data = await res.json();
    if (data.status) {
        return (
            data.payload
        )
    }
    else {
        return [];
    }

})

export const GetDoctorsUniversityAdmins = createAsyncThunk("admin/doctor", async (object) => {
     
    var res = await fetch(API_BASE_URL + "api/Admin/GetDoctorsUniversityAdmins", {
        method: 'POST',
        headers: {
            Accept: "application/json",
            "content-type": "application/json"
        },
        body: JSON.stringify(object)
    });
    var data = await res.json();
    if (data.status) {
        return (
            data.payload
        )
    }
    else {
        return [];
    }

})

export const GetCohortAdminsUniversityAdmins = createAsyncThunk("cohortAdmin/universityAdmin", async (object) => {
     
    var res = await fetch(API_BASE_URL + "api/Admin/GetCohortAdminsUniversityAdmins", {
        method: 'POST',
        headers: {
            Accept: "application/json",
            "content-type": "application/json"
        },
        body: JSON.stringify(object)
    });
    var data = await res.json();
    if (data.status) {
        return (
            data.payload
        )
    }
    else {
        return [];
    }

})


export const GetAllCountryAdmins = createAsyncThunk("allUniAdmin/countryAdmin", async (object) => {
     
    var res = await fetch(API_BASE_URL + "api/Admin/GetAllCountryAdmins", {
        method: 'POST',
        headers: {
            Accept: "application/json",
            "content-type": "application/json"
        },
        body: JSON.stringify(object)
    });
    var data = await res.json();
    if (data.status) {
        return (
            data.payload
        )
    }
    else {
        return [];
    }

})
const AdminSlice = createSlice(
    {
        name: "ContentCreatorAdmin",
        initialState:
        {
            ContentCreatorList: [],
            ChapterAdminList: [],
            StudentCohortAdmin: [],
            ChapterCohortAdmin: [],
            DoctorsUniversityAdmins: [],
            CohortAdminsUniversityAdmins: [],
            AllCountryAdmins: []
        },

        extraReducers: {
            [GetContentList.pending]: (state, action) => {
                state.loading = true;
                state.loadMore = true;
            },
            [GetContentList.fulfilled]: (state, action) => {
                state.ContentCreatorList = action.payload;
            },

            [GetChapterAdmin.pending]: (state, action) => {
                state.loading = true;
                state.loadMore = true;
            },
            [GetChapterAdmin.fulfilled]: (state, action) => {
                state.ChapterAdminList = action.payload;
            },
            [GetStudentCohortAdmin.pending]: (state, action) => {

                state.loading = true;
                state.loadMore = true;
            },
            [GetStudentCohortAdmin.fulfilled]: (state, action) => {

                state.StudentCohortAdmin = action.payload;
            },
            [GetChapterAdminsCohortAdmins.pending]: (state, action) => {
                state.loading = true;
                state.loadMore = true;
            },
            [GetChapterAdminsCohortAdmins.fulfilled]: (state, action) => {
                 
                state.ChapterCohortAdmin = action.payload;
            },
            [GetDoctorsUniversityAdmins.pending]: (state, action) => {
                state.loading = true;
                state.loadMore = true;
            },
            [GetDoctorsUniversityAdmins.fulfilled]: (state, action) => {
                state.DoctorsUniversityAdmins = action.payload;
            },

            [GetCohortAdminsUniversityAdmins.pending]: (state, action) => {
                state.loading = true;
                state.loadMore = true;
            },
            [GetCohortAdminsUniversityAdmins.fulfilled]: (state, action) => {
                state.CohortAdminsUniversityAdmins = action.payload;
            },
            [GetAllCountryAdmins.pending]: (state, action) => {
                state.loading = true;
                state.loadMore = true;
            },
            [GetAllCountryAdmins.fulfilled]: (state, action) => {
                state.AllCountryAdmins = action.payload;
            },
        }

    })
export const { } = AdminSlice.actions;

export default AdminSlice.reducer;