import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import singup from "../../../src/assets/images/1.jpg";
import uploadImg from "../../../src/assets/iCON/Group 187.png"
import UserSignInForm2 from './UserSignInForm2';
import AllFilterServices from '../services/AllFilterServices';
import Swal from 'sweetalert2';
import { BsFillArrowLeftSquareFill } from "react-icons/bs"
import { useEffect } from 'react';
import UserSignInForm3 from './UserSignInForm3';
const UserSignInForm1 = (props) => {

  const [next, setNext] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null);
  const [inputField, setInputField] = useState({
    FirstName: props.userFormData1?.FirstName,
    LastName: props.userFormData1?.LastName,
    StudentImage: props.userFormData1?.StudentImage,
    Email: props.userFormData1?.Email,
    Password: props.userFormData1?.Password,
    PseudoName: props.userFormData1?.PseudoName,
    CountryId: props.userFormData1?.CountryId,
    LanguageId: props.userFormData1?.LanguageId,
  })

  var tempData = inputField;
  const inputsHandler = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value })

    tempData[e.target.name] = e.target.value;
    if (!nextBtn(tempData)) {
      document.getElementById('nextBtn').classList.add('backgroundColor')
    }
    else {
      document.getElementById('nextBtn').classList.remove('backgroundColor')
      document.getElementById('nextBtn').classList.add('signupBtn')
    }
  }


  useEffect(() => {
    fetchGetCountryApi()
    fetchGetLanguagesApi()

  }, [])


  const [countryList, setCountryList] = useState([])
  async function fetchGetCountryApi() {
    var response = await AllFilterServices.GetCountriesListWithoutAuth()
    setCountryList(response.data.payload);
  }

  const [languagesList, setLanguaes] = useState([])
  async function fetchGetLanguagesApi() {
    var response = await AllFilterServices.GetLangaguesWithoutAuth()
    setLanguaes(response.data.payload);
  }

  const inputFile = useRef(null)
  const onButtonClick = () => {
    inputFile.current.click();
  };

  const imageChange = (e) => {
    let base64String = "";

    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0])
      var file = document.querySelector(
        'input[type=file]')['files'][0];
      tempData.StudentImage = file
      if (!nextBtn(tempData)) {
        document.getElementById('nextBtn').classList.add('backgroundColor')
      }
      else {
        document.getElementById('nextBtn').classList.remove('backgroundColor')
        document.getElementById('nextBtn').classList.add('signupBtn')
      }
    }
  };
  var arr = [];

  const nextBtn = (tempData) => {

    var isValid = true;
    if (tempData.FirstName == "" || tempData.FirstName == undefined) {

      arr.push('Please fill the Firstname')
      var isValid = false;
    }

    if (tempData.LastName == "" || tempData.LastName == undefined) {

      arr.push('Please fill the LastName')
      var isValid = false;
    }

    // if (tempData.StudentImage == "" || tempData.StudentImage == undefined) {

    //   arr.push('Please fill the Profile picture')
    //   var isValid = false;
    // }

    if (tempData.Email == "" || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(tempData.Email)) {
      arr.push('Please fill the valid Email')
      var isValid = false;
    }

    if (tempData.Password == "" || tempData.Password == undefined) {
      arr.push('Please fill the Password')
      var isValid = false;
    }
    if (tempData.LanguageId == "" || tempData.LanguageId == undefined) {
      arr.push('Please fill the Language')
      var isValid = false;
    }

    if (tempData.CountryId == "" || tempData.CountryId == undefined) {
      arr.push('Please fill the Country')
      var isValid = false;
    }
    if (tempData.PseudoName == "" || tempData.PseudoName == undefined) {
      arr.push('Please fill the Pseudo Name')
      var isValid = false;
    }
    return isValid;
  }
  useEffect(() => {
    if (!nextBtn(inputField)) {
      document.getElementById('nextBtn').classList.add('backgroundColor')

    } else {
      document.getElementById('nextBtn').classList.remove('backgroundColor')
      document.getElementById('nextBtn').classList.add('signupBtn')
    }

  }, [])
  const IsValidation = () => {

    if (!nextBtn(inputField)) {
      var showError = "";
      arr.forEach((item) => {
        showError += item + " ,"
      })

      Swal.fire({
        icon: 'error',
        title: 'Failed...',
        text: showError,
      })

    }
    else {
      setNext(!next)
    }
  }

  return (
    <>
      {next ||
        <div className="main-wrap">
          <div className="row registerUser">

            <div
              className="col-xl-5"
              style={{
                backgroundImage: `url(${singup})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              >
              {/* <img alt=""
                src={singup}
                className="float-right picture"
                style={{ marginRight: 80, marginTop: 15 }}
              /> */}
            </div>
            <div className="col-xl-7 vh-100 align-items-center overflow-hidden pl-0">
              <div
                className=" shadow-none border-0 ml-auto mr-auto login-card color"
                style={{ maxWidth: "unset" }}
              >

                <div className="card-body rounded-0">
                  <Link to="/login">
                    <BsFillArrowLeftSquareFill fontSize="2rem" color='#E84545' /></Link>
                  <h1 className="d-flex flex-row justify-content-center align-items-center mb-3 personal" style={{ marginTop: "-2rem" }}>
                    User Registration Details
                  </h1>

                  <div>

                    <input
                      type='file'
                      id='file' ref={inputFile}
                      style={{ display: 'none' }}
                      accept="image/*"
                      onChange={imageChange} />
                    {inputField.StudentImage != null ?
                      <img alt=""
                        src={URL.createObjectURL(inputField.StudentImage)}
                        width="110px"
                        style={{ borderRadius: "50%" }}
                        className="user-img mb-5"
                        onClick={onButtonClick} /> : <div>

                        {selectedImage == null ?
                          <img alt=""
                            src={uploadImg}
                            width="110px"
                            className="user-img mb-5"
                            onClick={onButtonClick}
                          />
                          : <div>
                            {selectedImage && (
                              <div >
                                <img
                                  src={URL.createObjectURL(selectedImage)}
                                  className="user-img mb-5"
                                  width="110px"
                                  alt="Thumb"
                                />

                              </div>
                            )}
                          </div>}
                      </div>
                    }

                  </div>
                  <h4 className=" pl-4 mb-2 ml-2 personal">Personal Information</h4>
                  <form>
                    <div className="container">
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group icon-input mb-3">
                            <i style={{ color: "#E84545" }} className="font-sm ti-email pr-0"></i>
                            <input
                              type="text"
                              className="style2-input pl-5 form-control"
                              placeholder="Email Address"
                              autoComplete='off'
                              onChange={inputsHandler}
                              name="Email"
                              value={inputField.Email}
                            />
                          </div>
                        </div>
                        <div className="col-6 ">
                          <div className="form-group icon-input mb-3">
                            <i style={{ color: "#E84545" }} className="font-sm ti-lock pr-0"></i>
                            <input
                              type="password"
                              autoComplete='off'
                              className="style2-input pl-5 form-control  "
                              placeholder="Password"
                              onChange={inputsHandler}
                              name="Password"
                              value={inputField.Password}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group icon-input mb-3">
                            {/* <i style={{ color: "#E84545" }} className="font-sm ti-user  pr-0"></i> */}
                            <input
                              type="text"
                              id="firstName"
                              className="style2-input pl-5 form-control "
                              placeholder="First Name"
                              onChange={inputsHandler}
                              name="FirstName"
                              value={inputField.FirstName}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group icon-input mb-3">
                            {/* <i style={{ color: "#E84545" }} className="font-sm ti-user text-grey-500 pr-0"></i> */}
                            <input
                              type="text"
                              id="lastName"
                              autoComplete="off"
                              className="style2-input pl-5 form-control "
                              placeholder="Last Name"
                              onChange={inputsHandler}
                              name="LastName"
                              value={inputField.LastName}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="container">
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group icon-input mb-3">
                            {/* <i style={{ color: "#E84545" }} className="font-sm ti-css3  pr-0"></i> */}
                            <input
                              id="PseudoName"
                              type="text"
                              name="PseudoName"
                              onChange={inputsHandler}
                              autoComplete="off"
                              value={inputField.PseudoName}
                              className="style2-input pl-5 form-control "
                              placeholder="Paseudo Name"
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group icon-input mb-3">
                            {/* <i style={{ color: "#E84545", top: "15px" }} className="font-sm ti-world  pr-0"></i> */}
                            <select type="select"
                              name="CountryId"
                              id="country"
                              onChange={inputsHandler}
                              value={inputField.CountryId}
                              className="form-control"
                              autoComplete="off"
                            >
                              <option>Country</option>
                              {
                                countryList.map((item, index) => {
                                  return <option key={index} value={item.id}>{item.name} </option>
                                })
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group icon-input mb-3">
                            {/* <i style={{ color: "#E84545", top: "15px" }} className="font-sm ti-world  pr-0"></i> */}
                            <select type="select"
                              id="language"
                              name="LanguageId"
                              onChange={inputsHandler}
                              value={inputField.LanguageId}
                              className="form-control"
                              autoComplete="off"
                            >
                              <option>Language</option>
                              {
                                languagesList.map((item, index) => {
                                  return <option key={index} value={item.id}>{item.name} </option>
                                })
                              }
                            </select>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="form-check" style={{ margin: "-14px 34px 0px 0px" }}>
                      <h6 className="text-grey-500 text-right font-xssss fw-500 mt-0 mb-0 lh-32">
                        Already have account?
                        <a href="/login" className="fw-700 ml-1" style={{ color: "#E84545" }} >
                          Log in
                        </a>
                      </h6>
                    </div>
                  </form>
                  <button className='backgroundColor' id="nextBtn" onClick={IsValidation}> Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {/* {next && <UserSignInForm2 userFormData1={inputField} />} */}

      {next && <UserSignInForm3 userFormData1={inputField} />}




    </>
  )
}

export default UserSignInForm1