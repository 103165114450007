import React, { useState } from 'react'
import videoImg from "../../../view/assests/iCON/iCON/noun-photo-video-695389.png"
import { Row, Col } from "reactstrap";
import * as AWS from "aws-sdk";
import awsConfig from "../../../awsConfi";


function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
function replaceAll(str, match, replacement) {
    return str.replace(new RegExp(escapeRegExp(match), 'g'), () => replacement);
}

const VideoContent = (props) => {
    const [post, setPost] = useState(false)
    const [selectedImage, setSelectedImage] = useState();
    const imageChange = (e) => {
        setPost(!post)
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImage(e.target.files[0])
            var file = document.querySelector(
                'input[type=file]')['files'][0];
            VideoUploadOnAws(e.target.files[0]);

        }
    };

    const BUCKET_NAME = awsConfig.bucketName;
    const AWS_Base_URL = awsConfig.AWS_Base_URL;

    const generateUUID = () => {
        var d = new Date().getTime();
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16;
            if (d > 0) {
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }
    const VideoUploadOnAws = async (video) => {
        // setBtntxt("Uploading Video...");
        // setLoadingProgress(0);
        var guid = generateUUID();

        var fileName = guid + '_' + replaceAll(video.name, " ", "_");
        let bucket = new AWS.S3({
            params: { Bucket: BUCKET_NAME }
        });

        let params = {
            Key: "studyMaterial/videos" + "/" + fileName,
            ContentType: video.type,
            Body: video
        };

        bucket
            .upload(params)
            .on("httpUploadProgress", evt => {
                let progress = parseInt((evt.loaded * 100) / evt.total);
                console.log(progress);
                // setVideoLoading(progress);
            })
            .send(err => {
                debugger
                if (err == null) {
                    console.log(`File Upload Successfuly`);
                    // setDisable(false);
                    console.log(AWS_Base_URL + params.Key);
                    // setBtntxt("ADD");
                    props.sendData(AWS_Base_URL + params.Key)

                }
                else {
                    console.log(err, "something wrong")
                    // setVideoLoading(0);
                    // setBtntxt("Error Uploading Video");
                    // setRetry("block");
                }

            });
    }

    const styles = {

        preview: {
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            width: "650px",
            margin: " 0px auto",
        },
        image: { maxWidth: "100%", maxHeight: 200 },

    };
    const VideoChange = (e) => {
        props.sendData(e.target.value)
    }

    return (
        <>
            {post ||
                <div className="upload-video ">
                    <img alt="" src={videoImg} />
                    <input type="file" accept="video/mp4,video/x-m4v,video/*"
                        id="upload" onChange={imageChange} hidden />
                    <h2 for="upload">Drag video Here</h2>
                    <label type="upload" for="upload" className="create-post-btn">Upload</label>
                </div>
            }
            {post &&
                <div className="upload-video ">
                    {selectedImage && (
                        <div
                            style={styles.preview}
                        >
                            <video>
                                <source
                                    src={URL.createObjectURL(selectedImage)}
                                    style={styles.image}
                                    alt="Thumb"
                                >
                                </source>
                            </video>
                        </div>
                    )}
                </div>
            }
            <h2 className="d-flex justify-content-center">Or</h2>
            <Row >
                <Col md="12">
                    <input
                        onChange={VideoChange}
                        className="form-control" placeholder="Post URL here" />
                </Col>
            </Row>
        </>
    )
}

export default VideoContent