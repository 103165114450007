import React, { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import QuizServices from '../../../services/QuizServices';
import dimand from "../../../assests/iCON/iCON/noun-diamond-1807567.png";
import hand from "../../../assests/iCON/iCON/noun-heart-3515260.png";
import { Link } from 'react-router-dom';
import moment from "moment"
import dummy from "../../../assests/dummy.png"
import user from "../../../assests/iCON/iCON/noun-friend-2531936.png";
import StudyMaterialServices from '../../../services/StudyMaterialServices';
const FlashCardsModal = (props) => {

  const { openModal, quizId } = props

  const [value, setValue] = useState({})
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  }
  useEffect(() => {
    GetVideoMaterialById()
    setModal(openModal)
  }, [quizId])
  const GetVideoMaterialById = async () => {
     
    const res = await StudyMaterialServices.GetStudyMaterialById(quizId)
    console.log("quiz", res.data.payload)
    setValue(res.data.payload)
  }
  return (
    <>
      <Modal isOpen={modal} toggle={toggle} style={{ backgroundColor: "#181818" }}  >
        <ModalHeader style={{ backgroundColor: "#181818" }} toggle={toggle}><h5 style={{ color: "white" }}>Flashcard Preview</h5></ModalHeader>
        <ModalBody style={{ backgroundColor: "#1D2226" }}>
          <div className=" w-100 p-0 overflow-hidden mr-1  shadow-lg p-3 mb-5 quizs" style={{ borderRadius: "20px" }}
          >
            <div className=" position-relative d-block">
              {value?.studyMaterialQuestions?.map((value,index)=>{
              <div className=" mb-4 " key={index}>{value.question.replace(/<[^>]*>?/gm, '')} </div>

              })}
            </div>
          </div>
        </ModalBody>

      </Modal>
    </>
  )
}

export default FlashCardsModal