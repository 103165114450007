import React, { useState } from "react";
import { Row, Col, Card } from "reactstrap"
import Switch from "react-switch";
import Header from "../../../components/Header"
import videoImg from "../../../view/assests/iCON/iCON/noun-photo-video-695389.png"
import { DropdownMenuList } from "./DropdownMenuList";
import DocumentContent from "./DocumentContent";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Swal from "sweetalert2"
import { useHistory } from "react-router-dom";
import StudyMaterialServices from "../../services/StudyMaterialServices";
import { API_BASE_URL } from "../../../constant";
const CreateVideo = () => {
    let history = useHistory()
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    var data = JSON.parse(localStorageObject);
    const [videoMaterial, setVideoMaterial] = useState({
        userId: data.id,
        languageId: data.languageId,
        universityId: data.universityId,
        description: "",
        hashtags: "",
        type: "DOCUMENT",
        isPremimum: false,
        countryId: null
    })
    const [dpropdownList, setDropdownList] = useState()
    const sendDropdownList = (list) => {
        setDropdownList(list)

    }
    const [premium, setPremium] = useState(false);
    const [worldwide, setWorlwide] = useState(false);
    const handlePremium = nextChecked => {
        setPremium(nextChecked);
        videoMaterial.isPremimum = nextChecked
    };
    const handleWorldwide = nextChecked => {

        setWorlwide(nextChecked);
        videoMaterial.countryId = nextChecked == true ? data.countryId : null
    };
    const [content, setContent] = useState()
    const sendData = (data) => {
        setContent(data)
    }
    const [regularTag, setRegularTag] = useState([]);

    const handleRegularTag = (tag) => {
        setRegularTag(tag);
    };

    const CreateQuiz = async () => {
        videoMaterial.hashtags = regularTag.join(",");
        try {
            await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    //   setLoading(true);

                    var response = await StudyMaterialServices.CreateVideoMaterial(videoMaterial, dpropdownList, content);

                    if (response.data.status === true) {

                        Swal.fire("Added!", "" + response.data.message, "success");
                        history.push("/study")


                    } else {
                        Swal.fire("Error!", response.data.message, "danger");
                    }
                    //   setLoading(false);
                }
            });
        }
        catch (error) {
            {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                })
            }
        }
    }

    return (
        <>
            <Header
                divclassName="shadow-none pos-fixed position-absolute"
                color="light"
            />
            <div className="sub-menu">
                <div className="ml-5 mr-5">
                    <h1>  Create New Document</h1>
                    <span> <img src={API_BASE_URL + data.imagePath} alt="img" className='mt-2 ml-1 rounded-circle border' width="30px" /> </span>
                    <span className="mt-5 ml-2" style={{ color: "#E84545" }}>{data.firstName + " " + data.lastName}</span>
                    <Row className="mt-4">
                        <Col md="12">
                            <textarea name="description" value={videoMaterial.description}
                                onChange={(e) => { setVideoMaterial({ ...videoMaterial, description: e.target.value }) }}
                                placeholder='Write Description...' className='create-post-textarea'></textarea>
                        </Col>
                        <Col md="12" style={{ borderBottom: '1px solid white', paddingBottom: "15px" }}>
                            <button className='document-hastags mb-3 ml-2'>Add Hashtags</button>
                            <TagsInput
                                value={regularTag}
                                onChange={(tag) => handleRegularTag(tag)}
                                tagProps={{
                                    className: "react-tagsinput-tag text-white rounded",
                                    name: "tags"
                                }}
                            />
                        </Col>
                    </Row>

                    <DropdownMenuList sendDropdownList={sendDropdownList} />

                    <div className="mt-3">
                        <label>Free </label>
                        <Switch

                            onChange={handlePremium}
                            checked={premium}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor="#E84545"
                            height={15}
                            width={30}
                            className="mt-3 ml-3 mr-3"
                        />

                        <label style={{ color: "#E84545" }}>Premium </label>
                    </div>

                    <label className="mt-1 my-class"> I understand that published content can only qualify to premium if it is exclusively my work and does not infringe copyright.
                    </label>
                    <input
                        type="checkbox"
                        className="float-left checkbox"
                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "6px", marginRight: "15px" }}
                    />

                    <DocumentContent sendData={sendData} />

                </div>
                <Row>
                    <Col md="10"></Col>
                    <Col md="2">
                        <button className="create-post-btn mb-5" onClick={CreateQuiz}>Post</button>
                    </Col>
                </Row>
            </div>
        </>
    )
}
export default CreateVideo