import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import { Tab, Tabs } from 'react-bootstrap';
import Social from './public/Social';
import hat from "../assests/iCON/iCON/noun-student-cap-1441568.png"
import pencil from "../assests/iCON/iCON/noun-edit-684936.png"
import trophy from "../assests/iCON/iCON/noun-trophy-5126269.png"
import dummy from "../../view/assests/dummy.png"
import { Input, ModalFooter } from 'reactstrap';
import { RiUserFollowLine } from "react-icons/ri"
import { MdMessage } from "react-icons/md"
import { TbFlag3 } from "react-icons/tb"
import { db } from '../../components/firebase'
import { onValue, ref } from "firebase/database";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import camera from "../../assets/iCON/Group 188.png"
import ProfileServices from '../services/ProfileServices';
import Swal from 'sweetalert2';
import { API_BASE_URL } from '../../constant';
import { FaGraduationCap } from "react-icons/fa"
import { RiStethoscopeFill } from "react-icons/ri"
import Spinner from '../spinner/Spinner';
import goldTrophy from "../../assets/images/noun-trophy-1.png"
import siverTrophy from "../../assets/images/noun-trophy-5126269.png"
import BrownzTrophy from "../../assets/images/noun-trophy-2.png"
import socialGoldTrophy from "../../assets/images/Group 277.png"
import socialSilverTrophy from "../../assets/images/Group 278.png"
import socialBrownTrophy from "../../assets/images/Group 279.png"
import OtherUserProfileServices from '../services/OtherUserProfileServices';

import CheetahImg from "../../assets/images/Cheetah.png"
import QuizManiac from "../../assets/images/Quiz Maniac.png"
import Bookworm from "../../assets/images/Bookworm.png"
import Rockstar from "../../assets/images/Rockstar.png"
import SocialBird from "../../assets/images/Social Bird.png"
import TheTorch from "../../assets/images/The Torch.png"

const UserProfile = () => {

  const [loading, setLoading] = useState(false)
  const [isOnline, setIsOnline] = useState(false);
  const [profileModal, setProfileModal] = useState()
  const [ProfileImage, setProfileImage] = useState(null);
  const [achievementTrophies, setAchievementTrophies] = useState([])
  const [contentCreatorTrophies, setContentCreatorTrophies] = useState([])
  const [socialTrophies, setSocialTrophies] = useState([])

  let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
  let Studentdata = JSON.parse(localStorageObject);

  useEffect(() => {
    GetAchievementTrophies()
    GetContentCreatorTrophies()
    GetSocialTrophies()
    GetUserFollowers()
    GetUserFollowing()
  }, [])
  async function GetAchievementTrophies() {
    let response = await OtherUserProfileServices.GetAchievmentTrophies(Studentdata.id);
    setAchievementTrophies(response.data.payload)
  }
  const [followers, setFollowers] = useState([])
  async function GetUserFollowers() {
    let response = await ProfileServices.GetFollowers(Studentdata.id);
    setFollowers(response.data.payload)
  }
  const [following, setFollowing] = useState([])
  async function GetUserFollowing() {
    let response = await ProfileServices.GetFollowing(Studentdata.id);
    setFollowing(response.data.payload)
  }


  async function GetContentCreatorTrophies() {
    setLoading(true)
    let response = await OtherUserProfileServices.ContentCreatorTrophies(Studentdata.id);
    setContentCreatorTrophies(response.data.payload)
    setLoading(false)
  }

  async function GetSocialTrophies() {
    let response = await OtherUserProfileServices.SocialTrophies(Studentdata.id);
    setSocialTrophies(response.data.payload)
  }
  const [bioField, setBioField] = useState({
    Id: Studentdata.id,
    CoverImage: Studentdata.coverPath,
    ProfileImage: Studentdata.imagePath,
    Bio: Studentdata.bio,
  })
  const handleProfileImg = (e) => {

    if (e.target.files && e.target.files.length > 0) {
      setProfileImage(e.target.files[0])
      var file = document.querySelector(
        'input[type=file]')['files'][0];
      bioField.ProfileImage = e.target.files[0]

    }
  };


  const [CoverImage, setCoverImage] = useState(null);
  const handleFileCoverImg = (e) => {

    if (e.target.files && e.target.files.length > 0) {
      setCoverImage(e.target.files[0])
      var file = document.querySelector(
        'input[type=file]')['files'][0];
      bioField.CoverImage = file
    }
  };

  const inputProfile = useRef(null)
  const onProfileButtonClick = () => {
    inputProfile.current.click();
  };
  const inputCover = useRef(null)
  const onCoverButtonClick = () => {
    inputCover.current.click();
  };

  const ProfileModalOpen = () => {
    setProfileModal(!profileModal)
  }

  function SetOnlineStatus() {
    onValue(ref(db, 'onlineUsers/' + '116'), (snapshot) => {
      const data = snapshot.val();
      setIsOnline((data && data.status == "online") ? true : false)
    });
  }
  const FollowBtn = async (id) => {
    // if (typeof Studentdata.doctorId == 'undefined') {
    var response = await OtherUserProfileServices.FollowStudent(Studentdata.id, id, false,)
    Swal.fire(
      'Done',
      response.data.message,
      'success'
    )
    GetUserFollowing()

  }
  useEffect(() => {
    SetOnlineStatus()
  }, [])

  const submitButton = async () => {
    try {
      await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // setLoading(true);

          var response = await ProfileServices.UpdateStudentProfile(bioField);

          if (response.data.status === true) {
            Swal.fire("Added!", "" + response.data.message, "success");
            localStorage.setItem("Exe-Med_Student_Info", JSON.stringify(response.data.payload));
            window.location.reload();
            // backToRoute()
          } else {
            Swal.fire("Error!", response.data.message, "danger");
          }
          // setLoading(false);
        }
      });
    }
    catch (error) {
      {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error,
        })
        // setLoading(false)
      }
    }

  };
  return (
    <>
      <Header />
      <Modal isOpen={profileModal} toggle={ProfileModalOpen.bind(null)} style={{ maxWidth: "1000px", backgroundColor: "#1D2226" }}>
        <ModalBody className="modal-content-video" style={{ padding: "0px", backgroundColor: "#1D2226" }}>
          <div className="d-block w-100 border-0 rounded-lg overflow-hidden mb-3 edit-user-profile">
            <input
              type='file'
              id='file' ref={inputCover}
              style={{ display: 'none' }}
              accept="image/*"
              onChange={handleFileCoverImg} />
            {CoverImage == null ?
              <div
                className="card-body position-relative h200  bg-image-cover bg-image-center"
                style={{
                  backgroundImage: `url(${Studentdata.coverPath != null ? API_BASE_URL + Studentdata.coverPath : "https://via.placeholder.com/1200x500.png"})`,
                }}
              ></div> :
              <div className="card-body position-relative h200 bg-image-cover bg-image-center">
                <img
                  src={URL.createObjectURL(CoverImage)}
                  alt="Thumb"
                  className='uploadCoverImg'
                />
              </div>}

            <figure className="avatar mb-0 mt--6  w40 z-index-1" style={{ marginRight: "35px", float: "right" }} >
              <img
                style={{ margin: "45px 25px" }}
                src={camera}
                alt="avater"
                className="p-1 rounded-circle w-100"
                onClick={onCoverButtonClick}

              />
            </figure>

            <input
              type='file'
              id='file' ref={inputProfile}
              style={{ display: 'none' }}
              accept="image/*"
              onChange={handleProfileImg} />
            {ProfileImage == null ?
              <div>
                <figure className="avatar mb-0 mt--6  w100 z-index-1" style={{ margin: "0px auto" }} >
                  <img
                    height="100px"
                    src={`${Studentdata.imagePath.startsWith("https://") ? Studentdata.imagePath : API_BASE_URL + Studentdata.imagePath}`}
                    alt="avater"
                    className="p-1 rounded-circle w-100 "
                  />
                </figure>

              </div> :
              <div>
                <figure className="avatar mb-0 mt--6  w100 z-index-1" style={{ margin: "0px auto" }} >
                  <img
                    src={URL.createObjectURL(ProfileImage)}
                    alt="avater"
                    className="p-1 rounded-circle w-100 "
                  />
                </figure>

              </div>}
            <figure className="avatar mb-0 mt--6  w40 z-index-1" style={{ margin: "0px auto" }} >
              <img
                style={{ margin: "20px 35px" }}
                src={camera}
                onClick={onProfileButtonClick}
                alt="avater"
                className="p-1 rounded-circle w-100"
              />
            </figure>

            <div className="card-body d-block w-100 pl-lg-5 pl-4 pr-4 ">
              <label className='mt-3 bio'>Bio</label>
              <div className="d-flex justify-content-center">
                <textarea rows="7" className='edit-profile' name="Bio"
                  onChange={(e) => { setBioField({ ...bioField, Bio: e.target.value }) }}
                  defaultValue={Studentdata.bio} />
              </div>
              <button className='edit-profile-btn mt-3' type='submit' onClick={submitButton}>Save</button>
            </div>
          </div>
        </ModalBody>

      </Modal>
      <div className="course-details pb-lg--7 pt-4 pb-5 single-user-profile">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="middle-sidebar-left">
                <div className="card d-block w-100 border-0 rounded-lg overflow-hidden mb-3">
                  <div
                    className="card-body position-relative h200 bg-image-cover bg-image-center"
                    style={{
                      backgroundImage: `url(${Studentdata.coverPath != null ? API_BASE_URL + Studentdata.coverPath : "https://via.placeholder.com/1200x500.png"})`,
                    }}
                  ></div>
                  <div className="card-body d-block w-100 pl-lg-5 pl-4 pr-4 user-profile-color">
                    <figure className="avatar mb-0 mt--6 position-relative w90 z-index-1" >
                      <img
                        height="90px"
                        src={`${Studentdata.imagePath.startsWith("https://") ? Studentdata.imagePath : API_BASE_URL + Studentdata.imagePath}`}
                        alt="avater"
                        className="float-right p-1  rounded-circle w-100"
                      />
                    </figure>
                    <span className='float-right'> <img src={pencil} onClick={ProfileModalOpen} width="23px" className='ml-3' /></span>
                    <span className='float-right'> <img src={trophy} width="23px" /></span>
                    <div className="clearfix"></div>
                    <div className="row" style={{ marginLeft: "-60px" }}>
                      <div className="col-xl-6 md-mb-2">
                        {/* <div>
                          <RiUserFollowLine fontSize="2rem" color="white" className="mr-2 ml-5" />
                          <MdMessage fontSize="2rem" color="white" className="ml-2 mr-2" />
                          <TbFlag3 fontSize="1.5rem" color="white" className="ml-5" />
                        </div> */}
                        <h4 className=" mb-1 text-white">
                          {Studentdata.firstName + " " + Studentdata.lastName}({isOnline ? "online" : "offline"})
                          {typeof Studentdata.doctorId == 'undefined' ? <FaGraduationCap fontSize="1.5rem" className='ml-3' /> : <RiStethoscopeFill fontSize="1.5rem" className='ml-3' />}
                        </h4>
                        <span className="font-xss d-inline-block ml-0" style={{ color: "#72BB53" }} >
                          3rd Year
                        </span>

                      </div>
                      <div className="col-11">
                        <p className="font-xss lh-28 text-grey-500 mb-0 pl-0 mt-4">
                          {Studentdata.bio}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <Tabs
                  defaultActiveKey="publications"
                  id="uncontrolled-tab-example"
                  className="mb-3 mt-5 ml-2 mr-3  justify-content-between border-0 rounded-lg profile-tabs"
                  style={{ backgroundColor: "#E84545", borderBottom: 'none', paddingRight: "60px", margin: "0px" }}
                >
                  <Tab eventKey="publications" title="Publications">

                    <Social eventKey="publications" />
                  </Tab>
                  <Tab eventKey="trophies" title="Trophies">
                    <div className=" d-block w-100 border-0 rounded-lg overflow-hidden ">
                      <div className="card-body mb-lg-3 pb-0">
                        <h2 className="fw-400 font-lg d-block text-white">
                          Trophies
                          <a
                            href="/default-user-profile"
                            className="float-right"
                          >
                          </a>
                        </h2>
                      </div>
                      <div className="card-body pb-0">
                        <div className="row">
                          {achievementTrophies.map((value, index) => (
                            // Strat Single Demo
                            <div
                              className="col-xl-3 col-lg-3 col-md-3"
                              key={index}
                            >
                              <div className=" mb-4 d-block w-100  rounded-lg  p-4 text-center" style={{ backgroundColor: "#000000", border: "1px solid white" }}>
                                <img
                                  src={value.trophy == "Gold" ? goldTrophy : value.trophy == "Bronze" ? BrownzTrophy : siverTrophy}
                                  alt="badge"
                                  width={80}
                                />

                                <h4 className="fw-700 font-xsss mt-4" style={{ color: "#E84545" }}>
                                  {value.trophy}
                                </h4>
                                <h3 className="fw-500 font-xssss text-grey-500 mt-3">
                                  {value.chapter}
                                </h3>

                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className=" d-block w-100 border-0 rounded-lg overflow-hidden ">
                      <div className="card-body mb-lg-3 pb-0">
                        <h2 className="fw-400 font-lg d-block text-white">
                          Badges
                          <a
                            href="/default-user-profile"
                            className="float-right"
                          >
                          </a>
                        </h2>
                      </div>
                      <div className="card-body pb-0">
                        <div className="row">
                          {socialTrophies?.cheetah == true ?
                            <div
                              className="col-xl-3 col-lg-3 col-md-3"
                            >
                              <div className=" mb-4 d-block w-100  rounded-lg p-xxl-5 p-2 text-center" style={{ backgroundColor: "#000000", border: "1px solid white" }}>

                                <img
                                  src={CheetahImg}
                                  alt="badge"
                                  width={200}
                                />

                                <h4 className="fw-400 mt-1 mb-3" style={{ color: "white" }}>
                                  Cheetah
                                </h4>

                              </div>
                            </div>
                            : ""}


                          {socialTrophies?.bookworm == true ?
                            <div
                              className="col-xl-3 col-lg-3 col-md-3"
                            >

                              <div className=" mb-4 d-block w-100  rounded-lg p-xxl-5 p-2 text-center" style={{ backgroundColor: "#000000", border: "1px solid white" }}>

                                <img
                                  src={Bookworm}
                                  alt="badge"
                                  width={200}
                                />

                                <h4 className="fw-400 mt-1 mb-3" style={{ color: "white" }}>
                                  Bookworm
                                </h4>


                              </div>

                            </div> : ""}
                          {socialTrophies?.quizManiac == true ?
                            <div
                              className="col-xl-3 col-lg-3 col-md-3"
                            >
                              <div className=" mb-4 d-block w-100  rounded-lg p-xxl-5 p-2 text-center" style={{ backgroundColor: "#000000", border: "1px solid white" }}>
                                <img
                                  src={QuizManiac}
                                  alt="badge"
                                  width={200}
                                />

                                <h4 className="fw-400 mt-1 mb-3" style={{ color: "white" }}>
                                  Quiz Maniac
                                </h4>

                              </div>
                            </div>
                            : ""}
                          {socialTrophies?.rockstar == true ?
                            <div
                              className="col-xl-3 col-lg-3 col-md-3"
                            >
                              <div className=" mb-4 d-block w-100  rounded-lg p-xxl-5 p-2 text-center" style={{ backgroundColor: "#000000", border: "1px solid white" }}>

                                <img
                                  src={Rockstar}
                                  alt="badge"
                                  width={200}
                                />


                                <h4 className="fw-400 mt-1 mb-3" style={{ color: "white" }}>
                                  Rock star
                                </h4>

                              </div>
                            </div>

                            : ""}
                          {socialTrophies?.socialBird == true ?
                            <div
                              className="col-xl-3 col-lg-3 col-md-3"
                            >
                              <div className=" mb-4 d-block w-100  rounded-lg p-xxl-5 p-2 text-center" style={{ backgroundColor: "#000000", border: "1px solid white" }}>

                                <img
                                  src={SocialBird}
                                  alt="badge"
                                  width={200}
                                />

                                <h4 className="fw-400 mt-1 mb-3" style={{ color: "white" }}>
                                  Social Bird
                                </h4>

                              </div>
                            </div>
                            : ""}


                          {socialTrophies?.theTorch == true ?
                            <div
                              className="col-xl-3 col-lg-3 col-md-3"
                            >
                              <div className=" mb-4 d-block w-100  rounded-lg p-xxl-5 p-2 text-center" style={{ backgroundColor: "#000000", border: "1px solid white" }}>

                                <img
                                  src={TheTorch}
                                  alt="badge"
                                  width={200}
                                />
                                <h4 className="fw-400 mt-1 mb-3" style={{ color: "white" }}>
                                  The Torch
                                </h4>


                              </div>
                            </div>
                            : ""}
                        </div>
                      </div>
                    </div>
                    {loading ? <Spinner /> :
                      <div className=" d-block w-100 border-0 rounded-lg overflow-hidden ">
                        <div className="card-body mb-lg-3 pb-0">
                          <h2 className="fw-400 font-lg d-block text-white">
                            Creator Award
                            <a
                              href="/default-user-profile"
                              className="float-right"
                            >
                            </a>
                          </h2>
                        </div>
                        <div className="card-body pb-0">
                          {/* <div className=""> */}
                          {contentCreatorTrophies.map((value, index) => (
                            <>
                              <h3 className="fw-400  d-block" style={{ color: 'gray' }}>
                                {value.level}
                              </h3>
                              <div
                                className="row"
                                key={index}
                              >

                                {value.trophies.map((trophies, index) => (

                                  <div
                                    className="col-xl-3 col-lg-3 col-md-3"
                                    key={index}
                                  >
                                    <div className=" mb-4 d-block w-100  rounded-lg p-xxl-5 p-4 text-center" style={{ backgroundColor: "#000000", border: "1px solid white" }}>
                                      <img
                                        src={trophies.trophy == "Gold" ? socialGoldTrophy : trophies.trophy == "Bronze" ? socialBrownTrophy : socialSilverTrophy}
                                        width={130}
                                      />

                                      <h4 className="fw-700 font-xsss mt-4" style={{ color: "#E84545" }}>
                                        {trophies.trophy}
                                      </h4>
                                      <h3 className="fw-500 font-xssss text-grey-500 mt-3">
                                        {trophies.chapter}
                                      </h3>


                                    </div>
                                  </div>
                                ))}

                              </div>
                            </>
                          ))}
                          {/* </div> */}
                        </div>
                      </div>
                    }
                  </Tab>
                  <Tab eventKey="followers" title="Followers">
                    <Input className='search-follower-inputField d-flex justify-content-center' placeholder='Search' />
                    <div className='mt-5 social-search'>
                      <ul className='social-search-list '>
                        {followers.map((value, index) => {
                          return (
                            <li key={index}>
                              <img src={API_BASE_URL + value.imagePath} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />{value.firstName + " " + value.lastName}<p>{value.yearTitle}, {value.universityName}</p><button className='float-right follow'>Follow Back</button><button className='float-right remove'>Remove</button></li>
                          )
                        })}

                      </ul>
                    </div>
                  </Tab>
                  <Tab eventKey="following" title="Following">
                    <Input className='search-follower-inputField d-flex justify-content-center' placeholder='Search' />
                    <div className='mt-5 social-search'>
                      <ul className='social-search-list '>
                        {following?.map((value, index) => {
                          return (
                            <li key={index}>  <img src={API_BASE_URL + value.imagePath} alt="img" className='m-2 mt-3' style={{ width: "50px", height: "50px" }} />{value.firstName + " " + value.lastName}<p>{value.yearTitle}, {value.universityName}</p><button className='float-right remove' onClick={() => FollowBtn(value.id)}>Unfollow</button></li>
                          )
                        })}

                      </ul>
                    </div>
                  </Tab>
                  <Tab eventKey="bookmarked" title="Bookmarked">
                    <Social eventKey="bookmarked" />
                  </Tab>

                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default UserProfile