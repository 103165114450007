import React, { useState } from "react";
import { Row, Col, Card } from "reactstrap"
import Switch from "react-switch";
import Header from "../../../../components/Header"
import { DropdownMenuList } from "./DropdownMenuList";
import CreateFlashcardQuestion from "./CreateFlashcardQuestion";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Swal from "sweetalert2"
import { useHistory } from "react-router-dom";
import StudyMaterialServices from "../../../services/StudyMaterialServices";
import { API_BASE_URL } from "../../../../constant";
const CreateVideo = () => {
    let history = useHistory()
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    var data = JSON.parse(localStorageObject);
    const [videoMaterial, setVideoMaterial] = useState({
        userId: data.id,
        languageId: data.languageId,
        countryId: data.countryId,
        universityId: data.universityId,
        type: "FLASHCARD",
        isPremimum: false,
        countryId: null

    })
    const [dpropdownList, setDropdownList] = useState()
    const sendDropdownList = (list) => {
        setDropdownList(list)

    }
    const [premium, setPremium] = useState(false);
    const handlePremium = nextChecked => {
        setPremium(nextChecked);
        videoMaterial.isPremimum = nextChecked
    };

    const [content, setContent] = useState()
    const sendList = (list) => {

        setContent(list)
    }
    const [regularTag, setRegularTag] = useState([]);

    const handleRegularTag = (tag) => {
        setRegularTag(tag);
    };

    const CreateQuiz = async () => {
        try {
            await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    //   setLoading(true);

                    var response = await StudyMaterialServices.CreateFlashCardMaterial(videoMaterial, dpropdownList, content);

                    if (response.data.status === true) {

                        Swal.fire("Added!", "" + response.data.message, "success");
                        history.push("/study")

                    } else {
                        Swal.fire("Error!", response.data.message, "danger");
                    }
                    //   setLoading(false);
                }
            });
        }
        catch (error) {
            {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                })
            }
        }
    }

    return (
        <>
            <Header
                divclassName="shadow-none pos-fixed position-absolute"
                color="light"
            />
            <div className="sub-menu">
                <div className="ml-5 mr-5" >
                    <h1>  Create New Flashcard</h1>
                    <span> <img src={API_BASE_URL + data.imagePath} alt="img" className='mt-2 ml-1 rounded-circle border' width="30px" /> </span>
                    <span className="mt-5 ml-2" style={{ color: "#E84545" }}>{data.firstName + " " + data.lastName}</span>

                    <DropdownMenuList sendDropdownList={sendDropdownList} />

                    <div className="mt-3">
                        <label>Free </label>
                        <Switch

                            onChange={handlePremium}
                            checked={premium}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor="#E84545"
                            height={15}
                            width={30}
                            className="mt-3 ml-3 mr-3"
                        />

                        <label style={{ color: "#E84545" }}>Premium </label>
                    </div>

                    <label className="mt-1 my-class"> I understand that published content can only qualify to premium if it is exclusively my work and does not infringe copyright.
                    </label>
                    <input
                        type="checkbox"
                        className="float-left checkbox"
                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "6px", marginRight: "15px" }}
                    />
                    <CreateFlashcardQuestion sendList={sendList} />

                </div>
                <Row>
                    <Col md="10"></Col>
                    <Col md="2">
                        <button className="create-post-btn mb-5" onClick={CreateQuiz}>Post</button>
                    </Col>
                </Row>
            </div>
        </>
    )
}
export default CreateVideo