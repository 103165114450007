import React, { useState, useEffect, useRef } from 'react';
import { collection, query, orderBy, where, doc, or, deleteDoc, onSnapshot } from "firebase/firestore";
import { database } from '../../components/firebase';
import Inbox from './Inbox';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { API_BASE_URL } from '../../constant';
import {BiImageAlt} from "react-icons/bi"

const ChatList = (props) => {

    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let data = JSON.parse(localStorageObject);
    const messagesEndRef = useRef(null)
    const [chat, setChat] = useState([]);
    const ParamsId = useParams()

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const GettAllUserChatList = async () => {
        
        const q1 = query(collection(database, "msg"), or(where('senderId', '==', data.id), where('receiverId', '==', data.id)), orderBy("createdAt", "desc"));
        const unsubscribe = onSnapshot(q1, (querySnapshot) => {
            console.log('List Updated')
            let messages = [];
            querySnapshot.forEach((doc) => {
                var dataObj = doc.data();
                if (!messages.some(x => x.chatKey == dataObj.chatKey))
                    messages.push({ ...doc.data(), id: doc.id });
            });
            
            props.SendReceiverId(ParamsId.id,messages)
            setChat(messages);
            
            if (window.location.pathname == `/message/${ParamsId.id}`) {
                props.SendReceiverId(ParamsId.id,messages)
            }
            else {
                if (messages.length > 0) {
                    props.SendReceiverId(messages[0].receiverId,messages)
                }
            }
        });

    }
    useEffect(() => {
        GettAllUserChatList()

    }, [])
    const showInbox = (e, id) => {
        props.SendReceiverId(id,chat)


    }

    return (
        <>
            <div className="col-lg-6 col-xl-4 col-md-6 chat-left scroll-bar border-right-light pl-4 pr-4 mt-4 ">
                <form action="#" className="mt-0  pt-3">
                    <div className="search-form mb-5" >
                        <i className="ti-search font-xs"></i>
                        <input
                            type="text"
                            className="form-control-search"
                            placeholder="Search here."
                        />
                    </div>
                </form>

                <div className="section full">
                    <ul className="list-group list-group-flush" style={{ margin: "0px", marginTop: "-25px" }} >
                        {chat.map((value, index) => (
                            // Start Single Demo
                            <li
                                onClick={(e) => { showInbox(e, value.receiverId == data.id ? value.senderId : value.receiverId) }}
                                className="no-icon pl-0 all-chat"
                                key={index}
                            >
                                <figure className="avatar float-left mb-0 mr-3 ml-2"
                                >
                                     <img alt=""
                                        src={value.receiverId == data.imagePath ? API_BASE_URL + value.senderImage : API_BASE_URL + value.receiverImage}
                                        alt="avater"
                                        className="w45 rounded-circle"
                                    />
                                </figure>
                                <h3 className="fw-700 mb-0 mt-1">
                                    <span className="font-xsss d-block">
                                        {value.receiverId == data.id ? value.senderName : value.receiverName}
                                    </span>
                                </h3>
                                {value.mediaType=="images" ||value.mediaType=="videos" ?<span className="text-color chat-text">
                                    <BiImageAlt/>
                                </span> :   <span className="text-color chat-text">
                                    {value.text}
                                </span>}
                             
                                {/* <span
                                className={`${value.status} ms-auto btn-round-xss badge d-block p-0`}
                            ></span> */}
                            </li>
                            // End Single Demo
                        ))}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default ChatList