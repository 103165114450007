import React, { useState, useEffect, useRef } from 'react'
import { Col, Row, CardTitle } from "reactstrap"
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css"
import AllFilterServices from '../../../services/AllFilterServices';
import Switch from "react-bootstrap-switch";
import "react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { CSVLink, CSVDownload } from "react-csv";
import AdminServices from '../../../services/AdminServices';
import { BiCloudUpload } from "react-icons/bi"
import { AiOutlineDownload } from "react-icons/ai"
import Swal from "sweetalert2"

const CountryandLanguage = (props) => {
  const [countryList, setCountryList] = useState([])
  const [languageList, setLanguageList] = useState([])

  useEffect(() => {
    GetAllCountries()
    GetAllLanguages()
  }, [])
  const GetAllCountries = async () => {
    var response = await AdminServices.getAllAdminCountries()
    setCountryList(response.data.payload);
    console.log(response.data.payload)

  }
  const GetAllLanguages = async () => {
    var response = await AdminServices.getAllAdminLanguage()
    setLanguageList(response.data.payload);
    console.log(response.data.payload)
  }
  const handleCountrySwitch = async (el, state, id) => {
     
    var response = await AdminServices.UpdateCountryStatus(id, state)
    if (response.data.status) {
      Swal.fire("Success!", "" + response.data.message, "success");
    }
    GetAllCountries()
  }
  const handleLangugaeSwitch = async (el, state, id) => {
     
    var response = await AdminServices.UpdateLanguageStatus(id, state)
    if (response.data.status) {
      Swal.fire("Success!", "" + response.data.message, "success");
    }
    GetAllLanguages()
  }
  const inputImgRef = React.useRef();
  const inputLanguageRef = React.useRef();


  return (
    <>

      <Row className='mt-5'>
        <Col md="6" className='mb-3'>
          {/* <label className="download-btn  float-right mr-3" htmlFor="uploadCountryFile"><BiCloudUpload fontSize="1.5rem" className='mr-1' />Upload</label>
          <input type='file' id='uploadCountryFile' ref={inputImgRef} hidden /> */}
          <CSVLink className="admin-upload-btn  mr-3" data={countryList}><AiOutlineDownload fontSize="1.5rem" className='mr-1' />Download</CSVLink>
        </Col>

        <Col md="6" className='mb-3'>
          {/* <label className="download-btn  float-right mr-3" htmlFor="uploadLanguageFile"><BiCloudUpload fontSize="1.5rem" className='mr-1' />Upload</label>
          <input type='file' id='uploadLanguageFile' ref={inputLanguageRef} hidden /> */}
          <CSVLink className="admin-upload-btn  mr-3" data={languageList}><AiOutlineDownload fontSize="1.5rem" className='mr-1' />Download</CSVLink>
        </Col>
      </Row>


     
      <Row>
        <Col md="6">

          <ReactTable

            columns={[
              {
                Header: "Name",
                accessor: "name",
              },
              {
                width: 200, 
                Header: "Status",
                accessor: "status",
                Cell: (row) => (
                  <div className="d-flex justify-content-center" >
                    <Switch bsSize="mini" offColor="danger" onColor="success" onText="Active" offText="Blocked"
                      onChange={(el, state) => handleCountrySwitch(el, state, countryList[row.index].id)}
                      name='test'
                      value={countryList[row.index].isActive == 1 ? true : false}
                    />
                  </div>
                ),
              },
              // {
              //     Header: "Action",
              //     accessor: "actions",
              //     Cell: row => (

              //         <div className="d-flex justify-content-center">
              //             {countryList[row.index].admin == null ?
              //                 <Button color="danger" onClick={() => { toggle(countryList[row.index].id, true) }}>
              //                     Assigned Admin
              //                 </Button> :

              //                 <div>{countryList[row.index].admin.firstName+" "+countryList[row.index].admin.firstName} <MdOutlineCancel onClick={() => { toggle(countryList[row.index].id, false) }} color='red' fontSize="1.5rem" /></div>

              //             }

              //         </div>
              //     ),
              //     sortable: false,
              //     filterable: false,
              // }
            ]}
            defaultPageSize={5}
            showPaginationBottom={true}
            className="-striped -highlight"
            data={countryList}
            filterable

          // defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)
          // }
          />
        </Col>

        <Col md="6">

          <ReactTable
            columns={[
              {
                Header: "Name",
                accessor: "name",
              },
              {
                width: 200,
                Header: "Status",
                accessor: "status",
                Cell: (row) => (
                  <div className="d-flex justify-content-center" >
                    <Switch bsSize="mini" offColor="danger" onColor="success" onText="Active" offText="Blocked"
                        onChange={(el, state) => handleLangugaeSwitch(el, state, languageList[row.index].id)}
                      name='test'
                      value={languageList[row.index].isActive == 1 ? true : false}
                    />
                  </div>
                ),
              },
              // {
              //     Header: "Action",
              //     accessor: "actions",
              //     Cell: row => (

              //         <div className="d-flex justify-content-center">
              //             {countryList[row.index].admin == null ?
              //                 <Button color="danger" onClick={() => { toggle(countryList[row.index].id, true) }}>
              //                     Assigned Admin
              //                 </Button> :

              //                 <div>{countryList[row.index].admin.firstName+" "+countryList[row.index].admin.firstName} <MdOutlineCancel onClick={() => { toggle(countryList[row.index].id, false) }} color='red' fontSize="1.5rem" /></div>

              //             }

              //         </div>
              //     ),
              //     sortable: false,
              //     filterable: false,
              // }
            ]}
            defaultPageSize={5}
            showPaginationBottom={true}
            className="-striped -highlight"
            data={languageList}
            filterable
          // defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row)
          // }
          />
        </Col>
      </Row>
    </>
  )
}

export default CountryandLanguage