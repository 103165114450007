import React, { useState, useEffect } from 'react';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md"
import {
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import filter from "../../../assets/iCON/noun-filter-4025735.png"
import world from "../../../assets/iCON/noun-country-2450449.png"
import school from "../../../assets/iCON/noun-university-1340867.png"
import levels from "../../../assets/iCON/noun-typing-3165306.png"
import save from "../../../assets/iCON/noun-special-2355497@2x.png"
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useDispatch, useSelector } from 'react-redux';
import { SocialUsers } from '../redux/feature/SocialSlice'
import AllFilterServices from "../../services/AllFilterServices"
require('checkboxes');


export const SearchUserFilter = (props) => {
  const dispatch = useDispatch()
  const [selectedCountry, setSelectedCountry] = useState([])
  const [selectedUniversity, setSelectedUniversity] = useState([])
  const [selectedLevel, setSelectedLevel] = useState([])
  const [selectedIntrest, setSelectedIntrest] = useState([])

  var SelectedFilterData = {
    countryId: selectedCountry,
    interestId: selectedIntrest,
    levelId: selectedLevel,
    universityId: selectedUniversity,
    search: props.search

  }

  const [country, setCountry] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const GetAllCountries = async () => {
    var response = await AllFilterServices.getAllCountries()
    setCountryList(response.data.payload);
  }
  const [university, setUniversity] = useState(false);
  const [universityList, setUniversityList] = useState([]);
  useEffect(async () => {
    GetAllCountries()
    GetAllUniversity()
    GetAllLevel()
  }, [])

  const GetAllUniversity = async () => {
    var response = await AllFilterServices.getAllUniversities()
    setUniversityList(response.data.payload);
  }
  const [level, setLevel] = useState(false);
  const [Intrest, setIntrest] = useState(false);

  const [name, setName] = useState('');
  const [foundUsers, setFoundUsers] = useState(countryList);
  const handleCountrySearch = (e) => {
    const keyword = e.target.value;
    if (keyword !== '') {
      const results = countryList.filter((user) => {
        return user.name.toLowerCase().includes(keyword.toLowerCase());
      });
      setFoundUsers(results);
    } else {
      setFoundUsers(countryList);
    }
    setName(keyword);
  }
  const [universityName, setUniversityName] = useState('');
  const [universitySearch, setUniversiitySearch] = useState(universityList);
  const handleUniversitySearch = (e) => {
    const keyword = e.target.value;
    if (keyword !== '') {
      const results = universityList.filter((user) => {
        return user.name.toLowerCase().includes(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setUniversiitySearch(results);
    } else {
      setUniversiitySearch(universityList);
      // If the text field is empty, show all users
    }
    setUniversityName(keyword);
  }

  const [levelList, setLevelList] = useState([])
  const [intrestList, setIntrestList] = useState([])
  const GetAllLevel = async () => {
    var response = await AllFilterServices.getAllIntrests()
    setLevelList(response.data.payload.studentLevels);
    setIntrestList(response.data.payload.studentInterests)
  }
  const [levelName, setLevelName] = useState('');
  const [levelSearch, setLevelSearch] = useState(universityList);
  const handleLevelSearch = (e) => {
    const keyword = e.target.value;
    if (keyword !== '') {
      const results = levelList.filter((user) => {
        return user.title.toLowerCase().includes(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setLevelSearch(results);
    } else {
      setLevelSearch(levelList);
      // If the text field is empty, show all users
    }
    setLevelName(keyword);
  }
  const [intrestName, setIntrestName] = useState('');
  const [intrestsSearch, setintrestsSearch] = useState(universityList);
  const handleIntrestsSearch = (e) => {
    const keyword = e.target.value;
    if (keyword !== '') {
      const results = levelList.filter((user) => {
        return user.title.toLowerCase().includes(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setLevelSearch(results);
    } else {
      setLevelSearch(levelList);
      // If the text field is empty, show all users
    }
    setLevelName(keyword);
  }

  const CountryFilterChange = (e) => {
    var Id = e.target.getAttribute('data-questionId')
    if (e.target.checked == false) {
      var tempSelectedCountry = selectedCountry.filter(function (obj) {
        return obj != Id;
      });
      setSelectedCountry(tempSelectedCountry)
      SelectedFilterData.countryId = tempSelectedCountry;
      dispatch(SocialUsers(SelectedFilterData))
      props.sendList(SelectedFilterData)
    }
    else {
      var tempSelectedCountry = selectedCountry;
      tempSelectedCountry.push(parseInt(Id))
      setSelectedCountry(tempSelectedCountry)
      SelectedFilterData.countryId = tempSelectedCountry;
      dispatch(SocialUsers(SelectedFilterData))
      props.sendList(SelectedFilterData)

    }
  }
  const handleSelectAllCountry = (e) => {
    if (e.target.checked == true) {
      var tempSelectedCountry = countryList.map(li => li.id);
      setSelectedCountry(tempSelectedCountry)
      SelectedFilterData.countryId = tempSelectedCountry;
      dispatch(SocialUsers(SelectedFilterData))
      props.sendList(SelectedFilterData)
    }
    else {
      setSelectedCountry([])
      SelectedFilterData.countryId = [];
      dispatch(SocialUsers(SelectedFilterData))
      props.sendList(SelectedFilterData)
    }
  }
  const handleSelectAllUniversity = (e) => {

    if (e.target.checked == true) {
      var tempSelectedUniversity = universityList.map(li => li.id);
      setSelectedUniversity(tempSelectedUniversity)
      SelectedFilterData.universityId = tempSelectedUniversity;
      dispatch(SocialUsers(SelectedFilterData))
      props.sendList(SelectedFilterData)

    }
    else {
      setSelectedUniversity([])
      SelectedFilterData.universityId = [];
      dispatch(SocialUsers(SelectedFilterData))
      props.sendList(SelectedFilterData)

    }
  }
  const handleSelectAllLevel = (e) => {

    if (e.target.checked == true) {
      var tempSelectedLevel = levelList.map(li => li.id);
      setSelectedLevel(tempSelectedLevel)
      SelectedFilterData.levelId = tempSelectedLevel;
      dispatch(SocialUsers(SelectedFilterData))
      props.sendList(SelectedFilterData)
    }
    else {
      setSelectedLevel([])
      SelectedFilterData.levelId = [];
      dispatch(SocialUsers(SelectedFilterData))
      props.sendList(SelectedFilterData)
    }

  }
  const handleSelectAllIntrest = (e) => {

    if (e.target.checked == true) {
      var tempSelectedIntrest = intrestList.map(li => li.id);
      setSelectedIntrest(tempSelectedIntrest)
      SelectedFilterData.interestId = tempSelectedIntrest;
      dispatch(SocialUsers(SelectedFilterData))
      props.sendList(SelectedFilterData)
    }
    else {
      setSelectedIntrest([])
      SelectedFilterData.interestId = [];
      dispatch(SocialUsers(SelectedFilterData))
      props.sendList(SelectedFilterData)
    }

  }

  const FilterUniChange = (e) => {
    var Id = e.target.getAttribute('data-questionId')
    if (e.target.checked == false) {
      var tempSelectedUni = selectedUniversity.filter(function (obj) {
        return obj != Id;
      });
      setSelectedUniversity(tempSelectedUni)
      SelectedFilterData.universityId = tempSelectedUni;
      dispatch(SocialUsers(SelectedFilterData))
    }
    else {
      var tempSelectedUni = selectedUniversity;
      tempSelectedUni.push(parseInt(Id))
      setSelectedUniversity(tempSelectedUni)
      SelectedFilterData.universityId = tempSelectedUni;
      dispatch(SocialUsers(SelectedFilterData))
    }
  }

  const LevelFilterChange = (e) => {
    var Id = e.target.getAttribute('data-questionId')
    if (e.target.checked == false) {
      var tempSelectedLevel = selectedLevel.filter(function (obj) {
        return obj != Id;
      });
      setSelectedLevel(tempSelectedLevel)
      SelectedFilterData.levelId = tempSelectedLevel;
      dispatch(SocialUsers(SelectedFilterData))
    }
    else {
      var tempSelectedLevel = selectedLevel;
      tempSelectedLevel.push(parseInt(Id))
      setSelectedLevel(tempSelectedLevel)
      SelectedFilterData.levelId = tempSelectedLevel;
      dispatch(SocialUsers(SelectedFilterData))
    }
  }

  const IntrestFilterChange = (e) => {
    var Id = e.target.getAttribute('data-questionId')
    if (e.target.checked == false) {
      var tempSelectedIntrest = selectedIntrest.filter(function (obj) {
        return obj != Id;
      });
      setSelectedIntrest(tempSelectedIntrest)
      SelectedFilterData.interestId = tempSelectedIntrest;
      dispatch(SocialUsers(SelectedFilterData))
    }
    else {
      var tempSelectedIntrest = selectedIntrest;
      tempSelectedIntrest.push(parseInt(Id))
      setSelectedIntrest(tempSelectedIntrest)
      SelectedFilterData.interestId = tempSelectedIntrest;
      dispatch(SocialUsers(SelectedFilterData))
    }
  }

  return (
    <>
      <div class="col-md-4 col-sm-12 mt-4">
        <Card style={{ background: "black", marginLeft: "50px" }}>
          <div className=" filter-by w-100 " >
            <div className="form-control border-0 p-0">
               <img alt="" src={filter} className="float-left mt-2 mr-3 ml-2 w30" /> <h2 className='h2' >  Filter By</h2> <MdOutlineKeyboardArrowDown className="float-right filter-icon" color='#E84545' fontSize="2.3em" />
            </div>
          </div>
          <CardBody>

            {/* <div className="form-group mb-1 filter-margin" onClick={() => setCalander(!calendar)}>
              {calendar ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                 <img alt="" src={calenderImg} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>All</h3>
              </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                 <img alt="" src={calenderImg} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>All</h3>
              </a>}
            </div> */}

            <div className="form-group mb-1 filter-margin" onClick={() => setCountry(!country)}>
              {country ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                 <img alt="" src={world} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Country</h3>
                {country ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                 <img alt="" src={world} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Country</h3>
                {country ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a>}
            </div>

            {country && <div className="form-group mb-1 sub-menu-filter mb-3 mr-3 w-100 scrollbar scrollbar-primary">
              <input
                placeholder='Search Country'
                className='form-control'
                value={name}
                onChange={handleCountrySearch}
                type="search" />
              <div>
                <label className='ml-2 CursorPointer'>All</label>
                <input
                  type="checkbox"
                  name="selectAllCountry"
                  id="selectAllCountry"
                  onChange={handleSelectAllCountry}
                  className="float-right checkbox"
                  style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                />
              </div>

              {foundUsers && foundUsers.length > 0 ?
                foundUsers.map((value, index) => {
                  return (
                    <div key={index}>
                      <label className='ml-2'>{value.name}</label>
                      <input
                        type="checkbox"
                        name="countryId"
                        data-questionId={value.id}
                        // checked={selectedCountry.includes(value.id)}
                        onChange={CountryFilterChange}
                        className="float-right checkbox"
                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                      />
                    </div>
                  )
                }) : countryList.map((value, index) => {
                  return (
                    <div key={index}>
                      <label className='ml-2'>{value.name}</label>
                      <input
                        type="checkbox"
                        name="countryId"
                        data-questionId={value.id}
                        checked={selectedCountry.includes(value.id)}
                        onChange={CountryFilterChange}
                        className="float-right checkbox"
                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                      />
                    </div>
                  )
                })
              }

            </div>}

            <div className="form-group mb-1 filter-margin" onClick={() => setUniversity(!university)}>
              {university ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                 <img alt="" src={school} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>University</h3>
                {university ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                 <img alt="" src={school} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>University</h3>
                {university ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a>}
            </div>
            {university && <div className="form-group mb-1 sub-menu-filter mb-3 mr-3 w-100">
              <input placeholder='Search University'
                onChange={handleUniversitySearch}
                value={universityName}
                className='form-control' />
              <div>
                <label className='ml-2 CursorPointer'>All</label>
                <input
                  type="checkbox"
                  name="selectAllUniversity"
                  id="selectAllUniversity"
                  onChange={handleSelectAllUniversity}
                  className="float-right checkbox"
                  style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                />
              </div>
              {universitySearch && universitySearch.length > 0 ?

                universitySearch.map((value, index) => {
                  return (
                    <div key={index}>
                      <label className='ml-2'>{value.name}</label>
                      <input
                        type="checkbox"
                        data-questionId={value.id}
                        onChange={FilterUniChange}
                        checked={selectedUniversity.includes(value.id)}
                        className="float-right checkbox"
                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                      />
                    </div>
                  )
                }) :
                universityList.map((value, index) => {
                  return (
                    <div key={index}>
                      <label className='ml-2'>{value.name}</label>
                      <input
                        type="checkbox"
                        data-questionId={value.id}
                        onChange={FilterUniChange}
                        checked={selectedUniversity.includes(value.id)}
                        className="float-right checkbox"
                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                      />
                    </div>
                  )
                })
              }


            </div>}



            <div className="form-group mb-1 filter-margin" onClick={() => setLevel(!level)}>
              {level ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                 <img alt="" src={levels} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Year</h3>
                {level ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                 <img alt="" src={levels} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Year</h3>
                {level ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a>}
            </div>
            {level && <div className="form-group mb-1 sub-menu-filter mb-3 mr-3 w-100">
              <input placeholder='Search Level'
                onChange={handleLevelSearch}
                value={levelName}
                className='form-control' />
              <div>
                <label className='ml-2 CursorPointer'>All</label>
                <input
                  type="checkbox"
                  name="selectAllLevel"
                  id="selectAllLevel"
                  onChange={handleSelectAllLevel}
                  className="float-right checkbox"
                  style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                />
              </div>
              {levelSearch && levelSearch.length > 0 ?

                levelSearch.map((studentLevel, index) => (
                  <div key={index}>
                    <label className='ml-2'>{studentLevel.title}</label>
                    <input
                      type="checkbox"
                      data-questionId={studentLevel.id}
                      checked={selectedLevel.includes(studentLevel.id)}
                      onChange={LevelFilterChange}
                      className="float-right checkbox"
                      style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                    />
                  </div>
                )) :
                levelList.map((studentLevel, index) => (
                  <div key={index}>
                    <label className='ml-2'>{studentLevel.title}</label>
                    <input
                      type="checkbox"
                      data-questionId={studentLevel.id}
                      checked={selectedLevel.includes(studentLevel.id)}
                      onChange={LevelFilterChange}
                      className="float-right checkbox"
                      style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                    />
                  </div>
                ))}
            </div>}


            <div className="form-group mb-1 filter-margin" onClick={() => setIntrest(!Intrest)}>
              {Intrest ? <a className="form-control   text-white  colors border-0 p-0 mb-2 ">
                 <img alt="" src={save} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Speciality Of Intrest</h3>
                {Intrest ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a> : <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                 <img alt="" src={save} className="float-left mt-2 mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Speciality Of Intrest</h3>
                {Intrest ? <MdOutlineKeyboardArrowUp className="float-right mt-2 branch-icon" fontSize="2.3em" /> : < MdOutlineKeyboardArrowDown className="float-right branch-icon" fontSize="2.3em" />}
              </a>}
            </div>
            {Intrest && <div className="form-group mb-1 sub-menu-filter mb-3 mr-3 w-100">
              <input placeholder='Search Level'
                onChange={handleIntrestsSearch}
                value={intrestName}
                className='form-control' />
              <div>
                <label className='ml-2 CursorPointer'>All</label>
                <input
                  type="checkbox"
                  name="selectAllLevel"
                  onChange={handleSelectAllIntrest}
                  className="float-right checkbox"
                  style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                />
              </div>
              {intrestsSearch && intrestsSearch.length > 0 ?

                intrestsSearch.map((studentInterests, index) => (
                  <div key={index}>
                    <label className='ml-2'>{studentInterests.title}</label>
                    <input
                      type="checkbox"
                      data-questionId={studentInterests.id}
                      checked={selectedIntrest.includes(studentInterests.id)}
                      onChange={IntrestFilterChange}
                      className="float-right checkbox"
                      style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                    />
                  </div>
                )) :
                intrestList.map((studentInterests, index) => (
                  <div key={index}>
                    <label className='ml-2'>{studentInterests.title}</label>
                    <input
                      type="checkbox"
                      data-questionId={studentInterests.id}
                      checked={selectedIntrest.includes(studentInterests.id)}
                      onChange={IntrestFilterChange}
                      className="float-right checkbox"
                      style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                    />
                  </div>
                ))}
            </div>}


            {/* {attempted ? <div className="form-group mb-1 filter-margin" onClick={() => setAttempted(!attempted)}>
              <a className="form-control text-white colors border-0 p-0 mb-2 ">
                 <img alt="" src={never} className="float-left attempt mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>First Name</h3>

              </a>
            </div> : <div className="form-group mb-1 filter-margin" onClick={() => setAttempted(!attempted)}>
              <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                 <img alt="" src={never} className="float-left attempt mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>First Name</h3>
              </a>
            </div>}

            {mistake ? <div className="form-group mb-1 filter-margin" onClick={() => setMistakes(!mistake)}>
              <a className="form-control text-white colors border-0 p-0 mb-2 ">
                 <img alt="" src={never} className="float-left mistake mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Name</h3>
              </a>
            </div> : <div className="form-group mb-1 filter-margin" onClick={() => setMistakes(!mistake)}>
              <a className="form-control text-white quiz border-0 p-0 mb-2 ">
                 <img alt="" src={never} className="float-left mistake mr-2 ml-2 w30 p-1" /> <h3 className='text-white '>Name</h3>
              </a>
            </div>} */}

          </CardBody>
        </Card>
      </div>
    </>
  )
}

// export default QuizFilter