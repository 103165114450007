import React, { useState } from 'react'
import { Row, Col, Input } from 'reactstrap'
import Swal from 'sweetalert2'
import AdminServices from '../../../../services/AdminServices'
const MedcoinSettings = () => {

  const [input, setInput] = useState({
    mgForContentCreator: "",
    mgForChapterAdmin: "",
    mgForCohortAdmin: "",
    mgForUniversityAdmin: "",
    mgForCountryAdmin: ""
  })

  const submitBtn = async () => {
    try {
      await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //   setLoading(true);
           
          var response = await AdminServices.MedCoinSettings(input);
           
          if (response.data.status === true) {
             
            Swal.fire("Added!", "" + response.data.message, "success");

          } else {
            Swal.fire("Error!", response.data.message, "danger");
          }
          //   setLoading(false);
        }
      });
    }
    catch (error) {
      {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    }
  }

  return (
    <>
      <div className='quizs p-5 security ' >
        <h1 className='text-white d-flex justify-content-center mb-5'>Formal code window for editing Formula</h1>
        <Row>
          <Col md="4">
            <h5 className='text-white'>RC = Royalties Coefficient (Like target publication status)  </h5>
          </Col>
          <Col md="4">
            <h5 className='text-white'>  SUIW = Source User Instant Weight </h5>
          </Col>
          <Col md="4">
            <h5 className='text-white'> TUIW = Target User Instant Weight</h5>
          </Col>
          <Col md="4">
            <h5 className='text-white'>  PC = Penalty Coefficient (Like target publication status)</h5>
          </Col>
          <Col md="4">
            <h5 className='text-white'>  1MED_PL = 1 Medcoin per Like</h5>
          </Col>
          <Col md="4">
            <h5 className='text-white'>  1MED_PPR = 1MEDcoin Per Publication Redflag</h5>
          </Col>

          <Col md="4">
            <h5 className='text-white'>  CH = x_chapter </h5>
          </Col>

          <Col md="4">
            <h5 className='text-white'>  MED_CH = MEDcoin gain in the relevant Chapter </h5>
          </Col>

          <Col md="4">
            <h5 className='text-white'>  CO = x_cohort </h5>
          </Col>

          <Col md="4">
            <h5 className='text-white'>  MED_CO = MEDcoin gain in the relevant Cohort </h5>
          </Col>

          <Col md="4">
            <h5 className='text-white'>  CO = x_cohort </h5>
          </Col>

          <Col md="4">
            <h5 className='text-white'>  MED_CO = MEDcoin gain in the relevant Cohort </h5>
          </Col>

          <Col md="4">
            <h5 className='text-white'>  URP = User Redflag Penalty </h5>
          </Col>
          <Col md="4">
            <h5 className='text-white'>  1MED_PUR = 1MEDcoin Per User Redflag </h5>
          </Col>
          <Col md="4">
            <h5 className='text-white'>  Uni = x_university </h5>
          </Col>

          <Col md="4">
            <h5 className='text-white'>  MED_UNI = MEDcoin gain in the relevant University </h5>
          </Col>

          <Col md="4">
            <h5 className='text-white'>  Con = x_country </h5>
          </Col>

          <Col md="4">
            <h5 className='text-white'>  MED_CON = MEDcoin gain in the relevant country </h5>
          </Col>


        </Row>
      </div>
      <div className='quizs p-5 security mt-3' >
        <Row>
          <Col md="6">
            <h4 className='text-white'>MEDcoin gain for Content Creator =</h4>
          </Col>
          <Col md="6">
            <Input className='' name='mgForContentCreator'
              value={input.mgForContentCreator}
              onChange={(e) => { setInput({ ...input, mgForContentCreator: e.target.value }) }}
              type='text' placeholder=''
              style={{ margin: "-20px", backgroundColor: "rgb(24, 24, 24)" }} />
          </Col>
        </Row>
      </div>
      <div className='quizs p-5 security mt-3' >
        <Row>
          <Col md="6">
            <h4 className='text-white'>Medcoin gain for Chapter Admin =</h4>
          </Col>
          <Col md="6">
            <Input className='' name='mgForChapterAdmin'
              value={input.mgForChapterAdmin}
              onChange={(e) => { setInput({ ...input, mgForChapterAdmin: e.target.value }) }} type='text' placeholder='' style={{ margin: "-20px", backgroundColor: "rgb(24, 24, 24)" }} />
          </Col>
        </Row>
      </div>
      <div className='quizs p-5 security mt-3' >
        <Row>
          <Col md="6">
            <h4 className='text-white'>Medcoin gain for Cohort Admin =</h4>
          </Col>
          <Col md="6">
            <Input className='' type='text'
              name='mgForCohortAdmin'
              value={input.mgForCohortAdmin}
              onChange={(e) => { setInput({ ...input, mgForCohortAdmin: e.target.value }) }} placeholder='' style={{ margin: "-20px", backgroundColor: "rgb(24, 24, 24)" }} />
          </Col>
        </Row>
      </div>
      <div className='quizs p-5 security mt-3' >
        <Row>
          <Col md="6">
            <h4 className='text-white'>Medcoin gain for University Admin =</h4>
          </Col>
          <Col md="6">
            <Input className='' name='mgForUniversityAdmin'
              value={input.mgForUniversityAdmin}
              onChange={(e) => { setInput({ ...input, mgForUniversityAdmin: e.target.value }) }} type='text' placeholder='' style={{ margin: "-20px", backgroundColor: "rgb(24, 24, 24)" }} />
          </Col>
        </Row>
      </div>
      <div className='quizs p-5 security mt-3 mb-3' >
        <Row>
          <Col md="6">
            <h4 className='text-white'>Medcoin gain for Country Admin =</h4>
          </Col>
          <Col md="6">
            <Input className='' name='mgForCountryAdmin'
              value={input.mgForCountryAdmin}
              onChange={(e) => { setInput({ ...input, mgForCountryAdmin: e.target.value }) }} type='text' placeholder='' style={{ margin: "-20px", backgroundColor: "rgb(24, 24, 24)" }} />
          </Col>
        </Row>
      </div>

      <div className='d-flex justify-content-center mt-5 mb-5'>
        <button className='btn text-white' onClick={submitBtn}> Save and update
          terms and
          conditions</button>
      </div>

    </>
  )
}

export default MedcoinSettings