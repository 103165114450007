import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../../../constant";

export const SocialUsers = createAsyncThunk("socail/SocialUser", async (object) => {

    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    var res = await fetch(API_BASE_URL + `api/Social/GetFilteredSocialUsers?SID=${Studentdata.id}&JWT=${Studentdata.jwt}`, {
        method: 'POST',
        headers: {
            Accept: "application/json",
            "content-type": "application/json"
        },
        body: JSON.stringify(object)
    });
    var data = await res.json();
    if (data.status) {
        return (
            data.payload
        )
    }
    else {
        return [];
    }

})


export const GetAllSocailPost = createAsyncThunk("socail/SocialUser", async (object) => {
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    let Studentdata = JSON.parse(localStorageObject);
    var res = await fetch(API_BASE_URL + `api/Social/GetFilteredSocialPost?SID=${Studentdata.id}&JWT=${Studentdata.jwt}`, {
        method: 'POST',
        headers: {
            Accept: "application/json",
            "content-type": "application/json"
        },
        body: JSON.stringify(object.data == undefined ? object : object.data)
    });
    var data = await res.json();
    if (data.status) {
        return (
            {
                hideLoadMore: data.payload.length < 10 ? true : false,
                data: object.oldQuiz == undefined ? data.payload : object.oldQuiz.concat(data.payload)

            }
        )
    }
    else {
        return [];
    }

})



const socialSlice = createSlice(
    {
        name: "Social",
        initialState:
        {

            user: [],
            loading: false,
            hideLoadMore: false,
            loadMore: false,
            allPost: []
        },
        reducers: {
            updatePost: (state, action) => {
                state.allPost = action.payload;
            },

        },
        extraReducers: {
            [SocialUsers.pending]: (state, action) => {
                state.loading = true;
            },
            [SocialUsers.fulfilled]: (state, action) => {
                state.loading = false;
                state.user = action.payload
            },
            [GetAllSocailPost.pending]: (state, action) => {
                state.loading = true;
                state.loadMore = true;
            },
            [GetAllSocailPost.fulfilled]: (state, action) => {

                state.loading = false;
                state.loadMore = false;
                state.hideLoadMore = action.payload.hideLoadMore;
                state.allPost = action.payload.data;
            },
        }

    })
export const { updatePost } = socialSlice.actions;

export default socialSlice.reducer;