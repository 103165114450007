import React, { useState, useRef } from "react"
import { Link } from 'react-router-dom';
import dummy from "../../assests/dummy.png"
import user from "../../assests/iCON/iCON/noun-friend-2531936.png";
import commentsImg from "../../assests/iCON/iCON/noun-comment-3266599.png"
import share from "../../assests/iCON/iCON/noun-share-5060531.png"
import flag from "../../assests/iCON/iCON/noun-flag-1017687.png"
import flagRed from "../../assests/iCON/iCON/noun-flagRed-1017687.png"
import videogreen from "../../assests/iCON/noun-video-1841846@2x.png"
import Imggreen from "../../assests/iCON/noun-image-5079181@2x.png"
import microGreen from "../../assests/iCON/noun-microphone-982542@3x.png"
import textGreen from "../../assests/iCON/noun-text-3548498@2x.png"
import save from "../../assests/iCON/iCON/noun-bookmark-3025188.png"
import videogray from "../../../assets/iCON/noun-video-1841846@2x.png"
import imggray from "../../../assets/iCON/noun-image-5079181@2x.png"
import microgray from "../../../assets/iCON/noun-microphone-982542@2x.png"
import textgray from "../../../assets/iCON/noun-text-3548498@2x.png"
import { API_BASE_URL, AUDIO_BASE_URL } from "../../../constant";
import QuizServises from "../../services/QuizServices"
import Swal from "sweetalert2"
import { useHistory } from 'react-router-dom';
import Spinner from "../../spinner/Spinner"
import dimand from "../../assests/iCON/iCON/noun-diamond-1807567.png";
import hand from "../../assests/iCON/iCON/noun-heart-3515260.png";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai"
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import copy from "copy-to-clipboard";
import { MdOutlineClose } from "react-icons/md"
import reportIcon from "../../assests/iCON/iCON/noun-alert-5119017.png"
import moment from "moment"
import { BiLoaderCircle } from "react-icons/bi"
import { quizQuestion, quizLike, updateQuiz } from "../../../store/features/QuizSlice"
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap"
import { BsBookmark } from "react-icons/bs"
import { Watermark } from '@hirohe/react-watermark';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import * as AWS from "aws-sdk";
import awsConfig from "../../../awsConfi";
import QuizServices from "../../services/QuizServices";
const TodayQuiz = (props) => {
    const dispatch = useDispatch();
    const hideLoadMore = useSelector(state => state.quiz.hideLoadMore)

    // get user from localstorage
    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    var data = JSON.parse(localStorageObject);

    let localStorageObjectBranch = localStorage.getItem("Branch_List_" + data?.id);
    var BrancheFilterData = JSON.parse(localStorageObjectBranch);

    let localStorageObjectChapter = localStorage.getItem("Chapter_List_" + data?.id);
    var ChapterFilterData = (localStorageObjectChapter && localStorageObjectChapter != "undefined") ? JSON.parse(localStorageObjectChapter) : [];

    let localStorageObjectSubChapter = localStorage.getItem("Subchapter_List_" + data?.id);
    var SubChapterFilterData = JSON.parse(localStorageObjectSubChapter);

    let localStorageObjectLanguage = localStorage.getItem("Language_List_" + data?.id);
    var LanguageFilterData = JSON.parse(localStorageObjectLanguage);

    let localStorageObjectCountry = localStorage.getItem("Country_List_" + data?.id);
    var CountryFilterData = JSON.parse(localStorageObjectCountry);

    let localStorageObjectUniversity = localStorage.getItem("University_List_" + data?.id);
    var UniversityFilterData = JSON.parse(localStorageObjectUniversity);

    let localStorageObjectLevel = localStorage.getItem("Level_List_" + data?.id);
    var LevelFilterData = JSON.parse(localStorageObjectLevel);

    let localStorageObjectBookmarked = localStorage.getItem("Book_marked");
    var BookmarkedData = JSON.parse(localStorageObjectBookmarked);

    let localStorageObjectNeverAttempt = localStorage.getItem("Never_Attempt");
    var NeverAttempted = JSON.parse(localStorageObjectNeverAttempt);

    let localStorageObjectMistakes = localStorage.getItem("Mistakes");
    var LocalMistakes = JSON.parse(localStorageObjectMistakes);


    var quizfilterData = useSelector(state => state.quiz)
    var loading = useSelector(state => state.quiz.firstLoad)
    var loadMore = useSelector(state => state.quiz.loadMore)


    //   like Question

    const [like, setLike] = useState(
        {
            questionId: "",
            isLike: true,
            userId: data.id,
        }
    )
    let PerlOfDay = localStorage.getItem(`Exe-Med_Perl_Consume/${moment().format('l')}`);
    var PerlOfDayQuiz = JSON.parse(PerlOfDay);
    const PerlDay = (value, status) => {
        localStorage.setItem(`Exe-Med_Perl_Consume/${moment().format('l')}`, value.id);
        if (PerlOfDayQuiz) {
            Swal.fire({
                icon: 'error',
                title: 'Perl of the day',
                text: 'You Have use your Perl of the day free Question!',
            })
        }
        else {
            var tempQuiz = JSON.parse(JSON.stringify(quizfilterData.quiz));
            tempQuiz.find(x => x.id == value.id).isPremium = status;
            dispatch(updateQuiz(tempQuiz));
        }
    }
    const LikeBtn = async (e, value, status) => {

        var tempQuiz = JSON.parse(JSON.stringify(quizfilterData.quiz));
        tempQuiz.find(x => x.id == value.id).isLiked = status;
        tempQuiz.find(x => x.id == value.id).likesCount = status == true ? value.likesCount + 1 : value.likesCount - 1
        dispatch(updateQuiz(tempQuiz));
        // setLikeCount(status==true? value.likesCount+1:value.likesCount-1)
        var response = await QuizServises.LikeQuestion(like, value.id, value.isLiked);
        if (response.data.status == false) {

            var tempQuiz2 = JSON.parse(JSON.stringify(quizfilterData.quiz));
            tempQuiz2.find(x => x.id == value.id).isLiked = !status;
            dispatch(updateQuiz(tempQuiz2))
        }

    }

    const onCommentChange = async (e, questionId) => {
        var tempQuiz = JSON.parse(JSON.stringify(quizfilterData.quiz));
        tempQuiz.find(x => x.id == questionId).comment = e.target.value;
        dispatch(updateQuiz(tempQuiz))
        // setViewComments(!viewComments)
        // setViewComments(viewComments)
    }

    //comments Question
    const [comments, setComments] = useState(
        {
            questionId: "",
            isComment: true,
            comment: "",
            userId: data.id,
        }
    )

    const CommentBtn = async (e, questionId) => {

        var comment = quizfilterData.quiz.find(x => x.id == questionId).comment;
        if (!comment) return;
        var response = await QuizServises.CommnetsQuestion(comment, questionId, true, data.id);
        if (response.data.status == true) {
            var tempQuiz = JSON.parse(JSON.stringify(quizfilterData.quiz));
            tempQuiz.find(x => x.id == questionId).questionComments.push({
                comment: comment,
                user: data
            });
            tempQuiz.find(x => x.id == questionId).comment = "";
            dispatch(updateQuiz(tempQuiz))

            // setViewComments(!viewComments)
            // setViewComments(viewComments)
        }
        else {
            alert("Error")
        }
    }
    //   Bookmarked Question
    // const [bookmarkStatus,setBookmarkedStatus]=useState(false)
    const [bookmarked, setBookmarked] = useState(
        {
            questionId: "",
            isLike: true,
            userId: data.id,
        }
    )
    const BookmarkedBtn = async (e, value, status) => {
        var tempQuiz = JSON.parse(JSON.stringify(quizfilterData.quiz));
        tempQuiz.find(x => x.id == value.id).isBookmarked = status;
        dispatch(updateQuiz(tempQuiz))

        var response = await QuizServises.BookmarkedQuestion(bookmarked, value.id, value.isBookmarked);
        if (response.data.status == false) {

            var tempQuiz2 = JSON.parse(JSON.stringify(quizfilterData.quiz));
            tempQuiz2.find(x => x.id == value.id).isBookmarked = !status;
            dispatch(updateQuiz(tempQuiz2))
        }

    }
    const [qFlag, setQflag] = useState(
        {
            questionId: "",
            isLike: true,
            userId: data.id,
            comment: ""
        }
    )


    const [report, setReport] = useState(false)
    const [quizId, setQuizId] = useState()
    const createReport = async (e, value, isFlag) => {
        if (isFlag == true) {
            setReport(!report)
            setQuizId(value.id)
        }
        else {
            var tempQuiz = JSON.parse(JSON.stringify(quizfilterData.quiz));
            tempQuiz.find(x => x.id == value.id).isFlagged = isFlag;
            dispatch(updateQuiz(tempQuiz))
            var response = await QuizServises.FlagdQuestion(qFlag, value.id, value.isFlagged);

            if (response.data.status == false) {
                var tempQuiz2 = JSON.parse(JSON.stringify(quizfilterData.quiz));
                tempQuiz2.find(x => x.id == quizId).isFlagged = !isFlag;
                setFlagState(true)
                dispatch(updateQuiz(tempQuiz))
            }
        }


    }
    const [flagState, setFlagState] = useState(false)
    const FlagBtn = async (e, value, status) => {
        var tempQuiz = JSON.parse(JSON.stringify(quizfilterData.quiz));
        tempQuiz.find(x => x.id == quizId).isFlagged = status;
        dispatch(updateQuiz(tempQuiz))
        var response = await QuizServises.FlagdQuestion(qFlag, quizId, value.isFlagged);
        if (response.data.status == false) {
            var tempQuiz2 = JSON.parse(JSON.stringify(quizfilterData.quiz));
            tempQuiz2.find(x => x.id == quizId).isFlagged = !status;
            setFlagState(true)
            dispatch(updateQuiz(tempQuiz))
        }
        setReport(!report)


    }
    const [showField, setShowField] = useState(false)
    const radioBtn = (e) => {
        if (e == "other") {
            setShowField(true)
        }
        else {
            setShowField(false)
            qFlag.comment = e;
        }
    }

    // video modal
    const [modal, setModal] = useState(false);
    const [videoPath, setVideoPath] = useState("");
    const [imgPath, setImgPath] = useState("");
    const [correctionElement, setCorrectionElement] = useState("");

    const videoModalOpen = (event, value) => {
        document.getElementById('videoModal').setAttribute("src", videoPath);
    }

    const ImgModalOpen = (event, value) => {
        document.getElementById('ImgModal').setAttribute("src", imgPath);
    }

    const toggle = (event, value) => {

        if (value != null) {
            if (document.getElementById('watchExpl-' + value.id).classList.contains("green")) {
                setVideoPath(value.videoUrl);
                setModal(!modal);
                setCorrectionElement(value.correctionElementUserName)
            }
            else {
                alert("Please Submit Your Answere First")
            }
        }
        else setModal(!modal);
    }
    // audio modal
    const [modal4, setModal4] = useState(false);
    const toggle4 = (event, value) => {
        if (value != null) {
            if (document.getElementById('watchExpl-' + value.id).classList.contains("green")) {
                setModal4(!modal4);
                setCorrectionElement(value.correctionElementUserName)
            }
            else {
                alert("Please Submit Your Answere First")
            }
        }
        else setModal4(!modal4);
    }
    // Image modal
    const [imgModal, setImgModal] = useState("")
    const [modal1, setModal1] = useState(false);
    const toggle1 = (event, value) => {
        if (value != null) {
            if (document.getElementById('watchExpl-' + value.id).classList.contains("green")) {
                setModal1(!modal1);
                setCorrectionElement(value.correctionElementUserName)
                setImgModal(value.imageUrl)

            }
            else {
                alert("Please Submit Your Answere First")
            }
        }
        else
            setModal1(!modal1);

    }
    // Desc modal
    const [modal3, setModal3] = useState(false);
    const toggle3 = (event, value) => {
        if (value != null) {
            if (document.getElementById('watchExpl-' + value.id).classList.contains("green")) {
                setModal3(!modal3);
            }
            else {
                alert("Please Submit Your Answere First")
            }
        }
        else setModal3(!modal3);
    }

    const [quesAudio, setQuesAudio] = useState()
    const audiotoggle = (e, value) => {
        setQuesAudio(value.audioUrl)
        setModal4(true)
        // toggle4
    }
    const [imgUrl, setImgUrl] = useState(false);
    const [QuesimgUrl, setQuesImgUrl] = useState(false);

    const ImgUrlToggle = (event, value) => {
        debugger
        setImgUrl(!imgUrl);
        if (imgUrl == true) {
            console.log("null")
        }
        else {
            setImgPath(value.questionImageUrl)
            setQuesImgUrl(value.questionImageUrl)

        }
    }
    console.log(imgUrl, "imgUrl")
    const [viewComments, setViewComments] = useState(false)
    const [selectOpt, setSelectOpt] = useState([])

    // get all Quizes

    const submitAns = async (value, event) => {
        if (selectOpt.find(x => x.questionId == value.id) != null) {
            document.getElementById('watchExpl-' + value.id).classList.add("green");
            value.questionOptions.forEach(function (correct) {
                if (correct.isCorrect == true) {
                    document.getElementById('label-' + correct.id).classList.add("green");
                }
                else {
                    document.getElementById('label-' + correct.id).classList.add("change-color");
                    localStorage.removeItem(`Exe-Med_Perl_Consume/${moment().format('l')}`);

                }
                document.getElementById('op' + correct.id).disabled = true;
            })
        }
        var response = await QuizServises.AddQuizQuestion(selectOpt);

    }

    const ChangeQuestionOption = (e) => {

        // changing color 
        var qId = e.target.getAttribute('data-questionId')
        var mainQuestion = quizfilterData.quiz.find(x => x.id == qId);
        e.target.parentNode.childNodes.forEach(function (element) {
            if (mainQuestion.questionType == 1) {
                mainQuestion.questionOptions.forEach(function (element) {
                    document.getElementById('label-' + element.id).classList.remove("change-color");
                });
            }
            if (e.target.checked == true) {
                element.classList.add("change-color")
            }
            else {
                element.classList.remove("change-color")
            }
        });
        var questionOb = selectOpt.find(x => x.questionId == qId);

        // push data into an array
        if (questionOb == null) {
            var isCorrect = IsCorrectAnswer(quizfilterData.quiz, qId, [e.target.value]);
            var tempArr = selectOpt;
            tempArr.push({
                userId: data.id,
                questionId: qId,
                isCorrect: isCorrect,
                options: [e.target.value],
                questionType: mainQuestion.questionType,
                answerTime: questionTimes
            })
            setSelectOpt(tempArr)
            document.getElementById('submitbtn-' + qId).classList.add("colorRed");
        }
        else {
            if (e.target.checked == true) {

                questionOb.options.push(e.target.value);
                var tempArr = selectOpt;
                tempArr.find(x => x.questionId == qId).options = questionOb.options;
                var isCorrect = IsCorrectAnswer(quizfilterData.quiz, qId, questionOb.options);
                tempArr.find(x => x.questionId == qId).isCorrect = isCorrect;
                setSelectOpt(tempArr)
                document.getElementById('submitbtn-' + qId).classList.add("colorRed");
            }
            else {
                questionOb.options = questionOb.options.filter(function (obj) {
                    return obj !== e.target.value;
                });
                // if (questionOb.options.length == 0) {
                //     var tempArr = selectOpt;
                //     tempArr = tempArr.filter(function (obj) {
                //         return obj.questionId !== qId;
                //     });
                //     setSelectOpt(tempArr)
                // }
                // else {
                var tempArr = selectOpt;
                tempArr.find(x => x.questionId == qId).options = questionOb.options;
                var isCorrect = IsCorrectAnswer(quizfilterData.quiz, qId, questionOb.options);
                tempArr.find(x => x.questionId == qId).isCorrect = isCorrect;
                setSelectOpt(tempArr)
                // }
                // document.getElementById('submitbtn-' + qId).classList.add("colorGray");
            }
        }
    }

    function IsCorrectAnswer(tempQuiz, qId, questionObOptions) {
        var mainQuestion = tempQuiz.find(x => x.id == qId);
        if (mainQuestion && mainQuestion.questionOptions != null) {
            var correctoptions = mainQuestion.questionOptions.filter(function (obj) {
                return obj.isCorrect == true;
            });
            var correctIds = correctoptions.map((obj) => obj.id + "");
            var isEqual = (JSON.stringify(correctIds.sort()) === JSON.stringify(questionObOptions.sort()));
            return isEqual;
        }
        return false;
    }

    let history = useHistory();

    const backToRoute = () => {
        history.push("/quizResult");
    };

    // submit all quiz ans
    // const submitButton = async () => {
    //     modelTitle = "Are You Sure you wanted to Submit!";
    //     modelText = "You won't be able to revert this!";
    //  
    //     if (selectOpt.filter(x => x.questionType == 1).length != quizfilterData.quiz.payload?.filter(x => x.questionType == 1).length) {
    //         var modelTitle = "You left some question unanswered, are you sure you want to proceed?";
    //         var modelText = "You won't be able to revert this!";
    //     }
    //     try {
    //         await Swal.fire({
    //             title: modelTitle,
    //             text: modelText,
    //             icon: "warning",
    //             showCancelButton: true,
    //             confirmButtonColor: "#3085d6",
    //             cancelButtonColor: "#d33",
    //             confirmButtonText: "Yes",
    //         }).then(async (result) => {
    //          
    //             if (result.isConfirmed) {
    //              
    //                 // selectOpt.forEach((value) => {
    //                 //     var qOptions = quizfilterData.quiz.payload?.find(x => x.id == value.questionId).questionOptions;
    //                 //     qOptions.forEach(function (correct) {
    //                 //         if (correct.isCorrect == true) {
    //                 //             document.getElementById('label-' + correct.id).classList.add("green");
    //                 //         }
    //                 //         else {
    //                 //             document.getElementById('label-' + correct.id).classList.add("change-color");
    //                 //         }
    //                 //         document.getElementById('op' + correct.id).disabled = true;
    //                 //     })
    //                 // })
    //                 // setLoading(true)
    //              
    //                 var response = await QuizServises.AddQuizQuestion(selectOpt);
    //                 // setLoading(false)
    //                 if (response.data.status === true) {
    //                     Swal.fire("Added!", "" + "Quiz Submited Successfully", "success");
    //                     // window.location.reload();
    //                     // backToRoute()

    //                 } else {
    //                     Swal.fire("Error!", response.data.message, "danger");
    //                 }
    //             }
    //         });
    //     }
    //     catch (error) {
    //         console.log(error)
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Oops...',
    //             text: 'Something went wrong!',
    //         })
    //     }
    // };


    const handleCopyClick = async (e, value, status) => {
        // Asynchronously call copyTextToClipboard
        if (window.location.pathname == `/quiz/${value.id}`) {
            copy(window.location.href);
            alert("URL Copied", window.location.href)
        }

    }
    const a = localStorage.getItem("Exe-Med_Student_Info")
    const studentName = JSON.parse(a)


    const LoadMoreBtn = () => {
        console.log(quizfilterData.quiz)
        dispatch(quizQuestion({
            data: {
                branchId: BrancheFilterData ? BrancheFilterData : [],
                chapterId: ChapterFilterData ? ChapterFilterData : [],
                subChapterId: SubChapterFilterData ? SubChapterFilterData : [],
                LanguageId: LanguageFilterData ? LanguageFilterData : [],
                CountryId: CountryFilterData ? CountryFilterData : [],
                UniversityId: UniversityFilterData ? UniversityFilterData : [],
                LevelId: LevelFilterData ? LevelFilterData : [],
                NeverAttempted: NeverAttempted,
                Bookmarked: BookmarkedData,
                Mistakes: LocalMistakes,
                questionCount: props.count == undefined || null ? 10 : props.count,
                skip: 100,
                startDate: null,
                endDate: null,
            }, oldQuiz: quizfilterData.quiz
        }))
    }
    const [questionTimes, setQuestionTimes] = useState({});
    const [currentQuestionId, setCurrentQuestionId] = useState(null);
    const [startTime, setStartTime] = useState(null);

    const handleMouseEnter = async (questionId) => {
        setCurrentQuestionId(questionId);
        setStartTime(Date.now());
        await QuizServises.UpdateView(questionId)


    };

    const handleMouseLeave = () => {
        if (currentQuestionId && startTime) {
            const currentTime = Date.now();
            const timeElapsed = Math.floor((currentTime - startTime) / 1000);
            setQuestionTimes(prevTimes => (timeElapsed));
            setCurrentQuestionId(null);
            setStartTime(null);
        }
    };
    useEffect(() => {
        return () => {
            if (currentQuestionId && startTime) {
                handleMouseLeave();
            }
        };
    }, [currentQuestionId, startTime]);

    const inputImgRef = React.useRef();
    const onImgClick = () => {
        debugger
        /*Collecting node-element and performing click*/
        inputImgRef.current.click();
    };
    const changeImage = (event) => {
        const imageFile = event.target.files[0];
        ImageUploadOnAws(imageFile);
    }

    const inputVideoRef = useRef(null);

    const onVideoClick = () => {
        inputVideoRef.current.click();
    };
    const changeVideo = (event) => {
        const videoFile = event.target.files[0];
        VideoUploadOnAws(videoFile);
        console.log('Selected video:', videoFile);
    };


    const inputAudioRef = React.useRef();
    const onAudioClick = () => {
        /*Collecting node-element and performing click*/
        inputAudioRef.current.click();
    };
    const changeAudio = (event) => {
        const audioFile = event.target.files[0];
        AudioUploadOnAws(audioFile);
    }
    const [media, setMedia] = useState({
        imageUrl: "",
        audioUrl: "",
        videoUrl: "",
    })
    const BUCKET_NAME = awsConfig.bucketName;
    const AWS_Base_URL = awsConfig.AWS_Base_URL;
    const generateUUID = () => {
        var d = new Date().getTime();
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16;
            if (d > 0) {
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }
    function escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }
    function replaceAll(str, match, replacement) {
        return str.replace(new RegExp(escapeRegExp(match), 'g'), () => replacement);
    }

    const ImageUploadOnAws = async (video) => {
        // setBtntxt("Uploading Video...");
        // setLoadingProgress(0);
        var guid = generateUUID();
        var fileName = guid + '_' + replaceAll(video.name, " ", "_");
        ;
        let bucket = new AWS.S3({
            params: { Bucket: BUCKET_NAME }
        });

        let params = {
            Key: "images" + "/" + fileName,
            ContentType: video.type,
            Body: video
        };

        bucket
            .upload(params)
            .on("httpUploadProgress", evt => {
                let progress = parseInt((evt.loaded * 100) / evt.total);
                console.log(progress);
                // setLoadingProgress(progress);
            })
            .send(err => {

                if (err == null) {
                    console.log(`File Upload Successfuly`);
                    // setDisable(false);
                    media.imageUrl = AWS_Base_URL + params.Key
                    console.log(params.Key);
                    // setBtntxt("ADD");
                    console.log("ImageFileUrl", media)
                    apiCall()
                }
                else {
                    console.log(err)
                }

            });
    }
    const VideoUploadOnAws = async (video) => {
        // setBtntxt("Uploading Video...");
        // setLoadingProgress(0);
        var guid = generateUUID();
        var fileName = guid + '_' + replaceAll(video.name, " ", "_");
        ;
        let bucket = new AWS.S3({
            params: { Bucket: BUCKET_NAME }
        });

        let params = {
            Key: "video" + "/" + fileName,
            ContentType: video.type,
            Body: video
        };

        bucket
            .upload(params)
            .on("httpUploadProgress", evt => {
                let progress = parseInt((evt.loaded * 100) / evt.total);
                console.log(progress);
                // setLoadingProgress(progress);
            })
            .send(err => {

                if (err == null) {
                    console.log(`File Upload Successfuly`);
                    // setDisable(false);
                    media.videoUrl = AWS_Base_URL + params.Key
                    console.log(params.Key);
                    // setBtntxt("ADD");
                    console.log("VideoFileUrl", media)
                    apiCall()
                }
                else {
                    console.log(err)
                }

            });
    }
    const AudioUploadOnAws = async (video) => {
        // setBtntxt("Uploading Video...");
        // setLoadingProgress(0);
        var guid = generateUUID();
        var fileName = guid + '_' + replaceAll(video.name, " ", "_");
        ;
        let bucket = new AWS.S3({
            params: { Bucket: BUCKET_NAME }
        });

        let params = {
            Key: "audio" + "/" + fileName,
            ContentType: video.type,
            Body: video
        };

        bucket
            .upload(params)
            .on("httpUploadProgress", evt => {
                let progress = parseInt((evt.loaded * 100) / evt.total);
                console.log(progress);
                // setLoadingProgress(progress);
            })
            .send(err => {

                if (err == null) {
                    console.log(`File Upload Successfuly`);
                    // setDisable(false);
                    media.audioUrl = params.Key
                    console.log(params.Key);
                    // setBtntxt("ADD");
                    console.log("AudioFileUrl", media)
                    apiCall()
                }
                else {
                    console.log(err)
                }

            });
    }
    const apiCall = async () => {
        try {
            const res = await QuizServices.UpdateCorrectionElement(media, data, currentQuestionId)
            if (res.data.status) {
                Swal.fire(" Uploaded!", "" + res.data.message, "success");
            }
            else {
                Swal.fire("Error!", res.data.message, "danger");
            }
        }
        catch (error) {
            Swal.fire("Error!", error, "danger");
        }

    }
    return (
        <>
            {/* {loading ? <Spinner /> : */}
            <div className="row">

                <div
                    className="col-xl-10 col-lg-10 col-md-10 col-sm-10 mt-4 mb-4 se tMargin scrollbar scrollbar-primary copy-txt" style={{ userSelect: "none", cursor: "pointer" }}>
                    {quizfilterData.quiz?.map((value, index) => (

                        <Watermark
                            textSize="15"
                            lineHeight='1.6'
                            multiline="false"
                            textColor="#707172"
                            text={studentName.firstName + " " + studentName.lastName}
                        >
                            <Modal
                                isOpen={modal1}
                                toggle={toggle1.bind(null)}

                            >
                                <ModalHeader toggle={toggle1.bind(null)}>Watch Image Explanation {correctionElement == "" || correctionElement == null ? "" : <span className="ml-4">Correction Element By : {correctionElement}</span>} </ModalHeader>
                                <ModalBody>
                                    <img alt="" src={imgModal?.startsWith("http") ? imgModal : API_BASE_URL + imgModal} width="465px" height="auto" />
                                </ModalBody>

                            </Modal>
                            <Modal
                                isOpen={modal3}
                                toggle={toggle3.bind(null)}
                            >
                                <ModalHeader toggle={toggle3.bind(null)}>See Description</ModalHeader>
                                <ModalBody>
                                    {value.questionDetails}
                                </ModalBody>
                                <ModalFooter>
                                </ModalFooter>
                            </Modal>
                            <Modal
                                isOpen={modal4}
                                toggle={audiotoggle.bind(null)}
                            >
                                <ModalHeader toggle={toggle4.bind(null)}>See Audio</ModalHeader>
                                <ModalBody>
                                    <audio controls="controls" src={quesAudio?.startsWith("http") ? quesAudio : API_BASE_URL + quesAudio} preload="auto" ></audio>
                                </ModalBody>
                                <ModalFooter>
                                </ModalFooter>
                            </Modal>
                            {/* Report Modal */}
                            <Modal
                                isOpen={report}
                                toggle={createReport.bind(null)}
                                className="modal-tags"
                                style={{ maxWidth: "350px" }}
                            >
                                <div style={{ margin: "20px" }}>
                                    <h3 style={{ color: "white" }}> <img alt="" src={reportIcon} width="20px" className='mr-2' />Report</h3>
                                    <MdOutlineClose className='tag-close-btn' color='white' onClick={() => setReport(false)} />
                                    <div className='add-tag-border'></div>
                                </div>

                                <ModalBody>
                                    <RadioGroup
                                        onChange={radioBtn}
                                        vertical>
                                        <RadioButton pointColor="#E84545" value="Inappropriate" iconSize={20} style={{ color: "white" }}>
                                            Inappropriate
                                        </RadioButton>
                                        <RadioButton pointColor="#E84545" iconSize={20} value="Misleading">
                                            Misleading
                                        </RadioButton>
                                        <RadioButton pointColor="#E84545" iconSize={20} value="Incorrect">
                                            Incorrect Content
                                        </RadioButton>
                                        <RadioButton pointColor="#E84545" iconSize={20} value="other">
                                            Other
                                        </RadioButton>
                                    </RadioGroup>

                                    <div className="sub-menu"></div>
                                    {showField ?
                                        <input name='comment' value={qFlag.comment} onChange={(e) => { setQflag({ ...qFlag, comment: e.target.value }) }}
                                            placeholder='Please Specify' className='form-control' style={{ backgroundColor: "#1D2226" }} /> : <div className="sub-menu"></div>
                                    }
                                </ModalBody>
                                <button className='report-btn'
                                    onClick={(e) => FlagBtn(e, value, true)}
                                >Submit</button>

                            </Modal>
                            {value.isPremium && !studentName.isPremium && (
                                <div className="d-flex justify-content-around" style={{ zIndex: "1", position: "inherit", top: "250px" }}>
                                    <button className="btn" style={{ backgroundColor: "#E84545", color: "white", borderRadius: "25px" }} onClick={() => PerlDay(value, false)}>Pearl of the day</button>
                                    <button className="btn" style={{ backgroundColor: "#E84545", color: "white", borderRadius: "25px" }}>Get annual Premium Membership</button>
                                    <button className="btn" style={{ backgroundColor: "#E84545", color: "white", borderRadius: "25px" }}>Start free trial</button>
                                </div>
                            )}

                            <div
                                onMouseEnter={() => handleMouseEnter(value.id)}
                                onMouseLeave={handleMouseLeave}
                                className=" p-0  border-0 rounded-lg overflow-hidden mr-1  shadow-lg p-3 mb-5 rounded quizs force-overflow "
                                style={value.isPremium && !studentName.isPremium ? { filter: "blur(5px)", pointerEvents: "none", width: "auto", height: "auto" } : { width: "auto", height: "auto" }} >

                                <div className="quiz-black-label"><Link to={`/user-profile/${value.user.id}`}><span> <img src={API_BASE_URL + value?.user?.imagePath} alt="img" className='mt-2 ml-1 rounded-circle' style={{ width: "50px", height: "50px" }} /> </span>
                                    <span style={{ color: "#E84545" }}>{value?.user?.firstName + " " + value?.user?.lastName} <p className='user-des' style={{ marginTop: "none" }}> {value?.user?.yearTitle}, {value?.user?.universityName}</p></span>
                                </Link> <Link to={`/user-profile/${value.user.id}`}> <img alt="" style={{ marginTop: "-45px" }} src={user} className="float-right mr-2 addUser" width="40px" /></Link>
                                </div>

                                <div className='float-right mt-3' id={"watchExpl-" + value.id} ><span style={{ fontSize: "14px" }}> Watch Explanation</span>
                                    {value.videoUrl == "" ?
                                        <>
                                            {value.videoUrl == ""
                                                && value.imageUrl == ""
                                                && value.audioUrl == ""
                                                && (value.questionDetails == "" || value.questionDetails == null)
                                                ? <>
                                                    <input type="file" ref={inputVideoRef} id="video" accept="video/*" onChange={changeVideo} hidden />
                                                    <img alt="" src={videogray} width="20px" className="ml-2" onClick={onVideoClick} />
                                                </> : <img alt="" src={videogray} width="20px" className="ml-2" />
                                            }
                                        </>
                                        : <img alt="" src={videogreen} width="20px" onClick={(event) => { toggle(event, value) }} className="ml-2 CursorPointer" />}
                                    {value.imageUrl == '' ?
                                        <>
                                            {value.videoUrl == ""
                                                && value.imageUrl == ""
                                                && value.audioUrl == ""
                                                && (value.questionDetails == "" || value.questionDetails == null)
                                                ? <>
                                                    <input type="file" ref={inputImgRef} id="image" accept="image/*" onChange={changeImage} hidden />
                                                    <img alt="" src={imggray} width="19px" className="ml-3 mr-2" onClick={onImgClick} />
                                                </> : <img alt="" src={imggray} width="19px" className="ml-3 mr-2" />

                                            }
                                        </>


                                        : <img alt="" src={Imggreen} width="19px" onClick={(event) => { toggle1(event, value) }} className="ml-3 mr-2 CursorPointer" />}
                                    {value.audioUrl == "" ?
                                        <>
                                            {value.videoUrl == ""
                                                && value.imageUrl == ""
                                                && value.audioUrl == ""
                                                && (value.questionDetails == "" || value.questionDetails == null)
                                                ? <>
                                                    <img alt="" src={microgray} width="14px" className=" ml-2 mr-2" onClick={onAudioClick} />
                                                    <input type="file" ref={inputAudioRef} id="audio" accept="audio/*" onChange={changeAudio} hidden />

                                                </> : <img alt="" src={microgray} width="14px" className=" ml-2 mr-2" />
                                            }
                                        </>
                                        : <img alt="" src={microGreen} width="14px" onClick={(event) => { audiotoggle(event, value) }} className="ml-2 mr-2 CursorPointer" />}
                                    {value.questionDetails == "" || value.questionDetails == null ? <img alt="" src={textgray} width="16px" className="ml-1" /> : <img alt="" src={textGreen} width="16px" onClick={(event) => { toggle3(event, value) }} className="ml-1 CursorPointer" />}
                                </div>

                                <div className=" position-relative d-block mt-5">
                                    <p style={{ fontSize: "12px", fontStyle: "italic" }}>{moment(value.createdDate).format('l')}</p>
                                    <div className="mt-3 mb-4 ">{value.title.replace(/<[^>]*>?/gm, '')}  </div>
                                    {value.questionImageUrl != "" ? <img className="mb-4" onClick={(event) => { ImgUrlToggle(event, value) }} src={value.questionImageUrl && value.questionImageUrl.startsWith("http") ? value.questionImageUrl : API_BASE_URL + value.questionImageUrl} alt="Img" width={150} /> : ""}
                                    {value.questionOptions.map((optn, index2) => (
                                        <div
                                            key={index2}
                                        >
                                            {value.questionType == 1 ?
                                                <div>
                                                    <input
                                                        type="radio"
                                                        id={"op" + optn.id}
                                                        name={"radio-group" + value.id}
                                                        onChange={ChangeQuestionOption}
                                                        data-questionId={value.id}
                                                        value={optn.id} />
                                                    <label className="my-class" for={"op" + optn.id} id={"label-" + optn.id}>{optn.title} <h1></h1></label>
                                                </div> :
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        id={"op" + optn.id}
                                                        onChange={ChangeQuestionOption}
                                                        data-questionId={value.id}
                                                        value={optn.id}
                                                        className="float-left checkbox"
                                                        style={{ backgroundColor: "transparent", borderRadius: "3px", borderColor: "white", color: "#E84545", marginTop: "10px", marginRight: "7px" }}
                                                    />
                                                    <label className="mt-1 my-class" id={"label-" + optn.id} >{optn.title}</label>

                                                </div>
                                            }
                                        </div>
                                    ))}
                                    <div className="mt-3 mb-5">
                                        <h5 className="text-dark text-grey-700 ">
                                            <button className="colorGray" id={"submitbtn-" + value.id} type="button" onClick={(event) => { submitAns(value, event) }}>Submit Answer</button>
                                            {value.isPremium == true ?
                                                <img alt="" src={dimand} className="float-right" width="25px" /> :
                                                <img alt="" src={hand} className="float-right" width="25px" />}
                                        </h5>
                                    </div>
                                </div>
                                <div className='bg-darks'>
                                    <div className='mt-2 pt-2 pl-2'>
                                        {value.isLiked == true ?
                                            <AiFillHeart color="#E84545" fontSize="2em" className="mr-3" onClick={(e) => LikeBtn(e, value, false)} /> :
                                            <AiOutlineHeart fontSize="2em" className="mr-3" onClick={(e) => LikeBtn(e, value, true)} />}

                                        <img alt="" src={commentsImg} width="25px" className="mr-3" onClick={() => setViewComments(!viewComments)} />
                                        <Link to={`/quiz/${value.id}`}> <img alt="" src={share} width="25px" onClick={(e) => { handleCopyClick(e, value) }} /></Link>
                                        {value.isBookmarked == true ? <img alt="" src={save} width="23px" className='float-right mr-3 ml-3' onClick={(e) => BookmarkedBtn(e, value, false)} /> :
                                            <BsBookmark fontSize="2em" className='float-right mr-3 ml-3' onClick={(e) => BookmarkedBtn(e, value, true)} />}
                                        {value.isFlagged == true ? <img alt="" src={flagRed} width="25px" className='float-right'
                                            onClick={(e) => { createReport(e, value, false) }}
                                        /> : <img alt="" src={flag} width="25px" className='float-right'
                                            onClick={(e) => { createReport(e, value, true) }}
                                        />
                                        }
                                    </div>
                                    <span className='des-time'>{value.likesCount} Likes</span>
                                    {/* <p className='caption ml-2'><b style={{ color: "#E84545" }}>Amelia Jeannet Lorem</b> Ipsum Is Simply Dummy Text Of The Printing And Typesetting Industry.Lorem Ipsum. More</p> */}
                                    <div className="card-body p-3">

                                        <h4 className="font-xss mt-2 ml-0 lh-28 mt-0 pb-3 border-bottom text-white" onClick={() => setViewComments(!viewComments)}>
                                            View {value.questionComments.length} Comments
                                        </h4>
                                        <input placeholder="Add Comments" id="quizQuestionComment" value={value.comment} className="quizQuestionComment" onChange={(e) => { onCommentChange(e, value.id) }}
                                        />
                                        <div className='float-right' style={{ color: "#E84545" }} onClick={(e) => CommentBtn(e, value.id)}> Send</div>
                                    </div>
                                    {viewComments &&
                                        <div>
                                            {value.questionComments.map((comment, index) => (
                                                <div className="row">

                                                    <div className="col-2 text-left pl-4 pt-3 m-2">
                                                        <figure className="avatar float-left mb-0">
                                                            <img alt=""
                                                                src={API_BASE_URL + comment.user.imagePath}
                                                                onError={(e) => {

                                                                    e.target.onerror = null;
                                                                    e.target.src =
                                                                        "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png";
                                                                }}
                                                                className="float-right shadow-none w40 mr-2 rounded-circle"
                                                            />
                                                        </figure>
                                                    </div>
                                                    <div className="col-10 pt-2 comments">
                                                        <div className="content" style={{ marginLeft: "20px" }}>
                                                            <b className="author-name display-6 fw-300 mb-0 ">
                                                                {comment.user.firstName + " " + comment.user.lastName}
                                                            </b>
                                                            <p className="comment-text lh-24 fw-500 font-xssss text-grey-500 ">
                                                                {comment.comment}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            </div>

                        </Watermark>
                    ))}

                    <Modal onOpened={videoModalOpen} isOpen={modal} toggle={toggle.bind(null)} className="modal-content-video" style={{ maxWidth: "1000px" }}>
                        <ModalHeader toggle={toggle.bind(null)}>
                            Watch Video Explanation
                            {correctionElement && (
                                <span className="ml-4">Correction Element By: {correctionElement}</span>
                            )}
                        </ModalHeader>                            <ModalBody className="modal-content-video">
                            <Watermark
                                textSize="15"
                                lineHeight='1.6'
                                multiline="false"
                                textColor="#707172"
                                text={studentName.firstName + " " + studentName.lastName}>
                                <iframe width="970px" height="440" id="videoModal" src="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </Watermark>
                        </ModalBody>
                    </Modal>
                    <Modal
                        onOpened={ImgModalOpen}
                        isOpen={imgUrl}
                        toggle={ImgUrlToggle.bind(null)}
                        style={{ maxWidth: "800px" }}

                    >
                        <ModalHeader toggle={ImgUrlToggle.bind(null)}>Question Image {correctionElement == "" || correctionElement == null ? "" : <span className="ml-4">Correction Element By : {correctionElement}</span>} </ModalHeader>
                        <ModalBody>
                            <img alt="" src={QuesimgUrl && QuesimgUrl.startsWith("http") ? QuesimgUrl : API_BASE_URL + QuesimgUrl} id="ImgModal" width="100%" height="auto" />
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </Modal>

                </div>
                {hideLoadMore == false ?
                    <div style={{ marginLeft: "350px" }}>
                        <button className="submitBtn mt-4  mb-4" type="button" onClick={LoadMoreBtn}>  {loading ? <div className='spinner'></div> : "Load More"} </button>
                    </div>
                    : ""}

            </div>


        </>
    )
}

export default TodayQuiz